import { Box, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Mutation } from 'react-apollo';
import { Helmet } from 'react-helmet';
import { RouteComponentProps, withRouter } from 'react-router';

import { MUTATION_REQUEST_NEW_PASSWORD } from '../../../graphql/mutations/users';
import ForgotPasswordForm from './ForgotPasswordForm';
import MainLayout from '../../../layouts/Main';
import CustomButton from '../../../components/Rebranding/CustomButton';

export interface IRequestedNewPassword {
  status: boolean;
  email?: string;
}

const ForgotPassword = ({ history }: RouteComponentProps<{}>) => {
  const [requestedPassword, setRequestedPassword] = useState<IRequestedNewPassword>({ status: false, email: null });

  return (
    <MainLayout searchPage={false}>
      <Helmet>
        <title>Esqueci minha senha | Allugator</title>
        <meta property='og:title' content='Esqueci minha senha | Allugator' />
      </Helmet>

      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        backgroundColor: '#27AE60',
        paddingY: { md: '80px', xs: '0px' },
        marginTop: {xs: '30px', md: '0px'}
      }}>
        <Stack
          spacing={'40px'}
          sx={{
            backgroundColor: '#F4F4EF',
            maxWidth: { md: '610px', xs: '100%' },
            padding: { md: '128px', xs: '32px 19px' },
            borderRadius: { md: '40px', xs: '0px' }
          }}>
          {
            !requestedPassword.status ? (
              <>
                <Typography fontSize={{ md: 65, xs: 37 }} fontWeight={700} fontFamily={'Denton Condensed'} color={'#27AE60'} lineHeight={{ md: '87px', xs: '49px' }}>Esqueceu a sua senha?</Typography>
                <Typography fontSize={{ md: 21, xs: 16 }} fontFamily={'Chillax'} color={'#2D2D38'}>Digite o seu endereço de e-mail e enviaremos as instruções para você fazer a redefinição de senha.</Typography>
                {
                  <Mutation mutation={MUTATION_REQUEST_NEW_PASSWORD}>
                    {(requestNewPassword) => <ForgotPasswordForm submit={requestNewPassword} setRequestedPassword={setRequestedPassword} />}
                  </Mutation>
                }
              </>
            ) : (
              <>
                <Typography fontSize={{ md: 65, xs: 37 }} fontWeight={700} fontFamily={'Denton Condensed'} color={'#27AE60'} lineHeight={{ md: '87px', xs: '49px' }}>
                  E-mail enviado!
                </Typography>
                <Typography fontSize={{ md: 21, xs: 16 }} fontFamily={'Chillax'} color={'#2D2D38'}>
                  Se você estiver cadastrado, enviamos instruções de redefinição de senha para o email:
                </Typography>
                <Typography fontSize={{ md: 21, xs: 16 }} fontFamily={'Chillax'} color={'#27AE60'}>
                  {requestedPassword.email}
                </Typography>

                <CustomButton
                  variant="contained"
                  fullWidth
                  size="large"
                  color="primary"
                  onClick={() => history.push('/')}
                  title={'ir para página incial'}
                />
              </>
            )
          }
        </Stack>
      </Box>
    </MainLayout>
  );
}

export default withRouter(ForgotPassword);
