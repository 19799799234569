import React from 'react';
import { Box, Typography } from "@mui/material";

interface IProps {
    message?: string;
}

const ContentEmpty = ({ message }: IProps) => {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', padding: '20px' }}>
            <Typography variant="body1">{message || 'Nenhuma parcela obtida'}</Typography>
        </Box>
    )
};

export default ContentEmpty;