import React, { useState } from "react";
import QRCode from "qrcode.react";
import Icon from "../../../components/UI/Icon";
import { ButtonCopyQRCode, InformationViewQRCodePix } from "./styled";
import toReal from "../../../helpers/toReal";
import moment from "moment";

interface IProps {
  qrCode: string;
  amount: number;
  createdAt: string;
}

const ViewQRCodePix = ({ qrCode, amount, createdAt }: IProps) => {
  const [codCopiado, setCodCopiado] = useState<boolean>(false);
  const validatePix = moment
    .tz(createdAt, "America/Sao_Paulo")
    .add("24", "hours")
    .format("DD/MM/yyyy HH:mm:ss");

  const handleCopCodigo = () => {
    navigator.clipboard.writeText(qrCode);
    setCodCopiado(true);
    setTimeout(() => {
      setCodCopiado(false);
    }, 3000);
  };

  return (
    <div
      style={{
        margin: "0px auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <InformationViewQRCodePix>
        Valor {toReal(amount)} <br />
        <span>Válido até {validatePix}</span>
      </InformationViewQRCodePix>
      <QRCode size={220} value={qrCode} />
      <div style={{ marginTop: 12 }}>
        <ButtonCopyQRCode type="button" onClick={handleCopCodigo}>
          <span>{codCopiado ? "Código copiado" : "Copiar código"}</span>
          {codCopiado ? <Icon>check</Icon> : <Icon>content_copy</Icon>}
        </ButtonCopyQRCode>
      </div>
    </div>
  );
};

export default ViewQRCodePix;
