import React, { ReactNode } from 'react';
import Button from '@mui/material/Button';
import { ButtonProps, CircularProgress, SxProps, Theme } from '@mui/material';

interface IProps {
  title: string;
  style?: React.CSSProperties & SxProps<Theme>;
  onClick?: () => void;
  disabled?: boolean;
  startIcon?: JSX.Element | ReactNode;
  endIcon?: JSX.Element | ReactNode;
  loading?: boolean;
}

const CustomButton = ({
  title,
  startIcon,
  endIcon,
  style,
  onClick,
  variant = "contained",
  size = "small",
  disabled = false,
  loading = false,
  sx,
}: ButtonProps & IProps) => {
  return (
    <Button
      variant={variant}
      size={size}
      sx={{
        borderRadius: '21px',
        fontFamily: 'Chillax',
        textTransform: 'none',
        backgroundColor: '#27AE60',
        color: '#2D2D38',
        fontSize: '23px',
        padding: '0 45px',
        boxShadow: 'none',
        ...style,
        ...sx,
      }}
      onClick={onClick}
      disabled={disabled || loading}
      endIcon={!loading ? endIcon : null}
      startIcon={startIcon}
    >
      {!loading ? title : <CircularProgress size={'30px'} />}
    </Button>
  )
};

export default CustomButton;