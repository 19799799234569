export interface IBanner {
  banner: boolean;
  bannerText: string;
}

export enum ITypePayment {
  credit_card = 'credit_card',
  boleto = 'boleto',
  pix = 'pix',
}

export interface IPromotionalFlags {
  showPriceRecurrence: boolean;
  promotionalPeriod: boolean;
  preRelease: boolean;
  showPreownedProducts: boolean;
}

export enum PaymentStatusTypes {
  PROCESSING = 'processing',
  PAID = 'paid',
  UNPAID = 'unpaid',
}