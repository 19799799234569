import { InfoOutlined } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import toReal from '../../../../../helpers/toReal';
import { IAppState } from '../../../../../store';

interface IProps {
    amount: number;
}

const InfoOtherPayment = ({amount}: IProps) => {
    return (
        <Stack direction={'row'} sx={{
            backgroundColor: '#E5F3FA',
            borderRadius: '4px',
            padding: '14px 16px 14px 17px',
            marginTop: '32px'
        }}>
            <InfoOutlined sx={{ fill: '#013654' }} />
            <Stack spacing={'4px'} sx={{ paddingLeft: '13px' }}>
                <Typography fontSize={'17px'}
                    color={'#013654'}
                    fontWeight={600}
                >
                    Pague sua entrada com boleto
                </Typography>
                <Stack spacing={'30px'}>
                    <Typography fontSize={'15px'} color={'#013654'} >
                        Imprima o boleto e pague no banco ou pague pela internet utilizando o código de barras do boleto. Seu boleto será gerado ao aceitar nossos Termos de Uso e Aviso de Proteção de Dados e clicar em “Finalizar”.
                    </Typography>
                    <Typography fontSize={'15px'} color={'#013654'} fontWeight={600}>
                        Valor da entrada: {toReal(amount)}
                    </Typography>
                </Stack>
            </Stack>
        </Stack>
    )
};

export default connect((state: IAppState) => ({
    amount: state.checkoutRecurrence.paymentEntry.amount,
}))(InfoOtherPayment);