import { Stack, Typography } from '@mui/material';
import React from 'react';
import { IFormikPersonalDataRecurrenceFields } from '..';
import formatCPF, { formatCNPJ } from '../../../../helpers/formatDocument';

const SummaryUser = ({
  document,
  email,
  cnpj,
  fantasyName
}: IFormikPersonalDataRecurrenceFields) => {
  return (
    <Stack>
      {cnpj && (
        <div>
          <Stack direction={'row'}>
            <Typography variant="caption" fontSize={16}>
            {fantasyName}
            </Typography>
          </Stack>
          <Stack direction={'row'}>
            <Typography variant="caption" fontWeight={'bold'}>
              CNPJ:&nbsp;
            </Typography>
            <Typography variant="caption" fontSize={13}>
            {formatCNPJ(cnpj)}
            </Typography>
          </Stack>
        </div>
      )}
        <Stack direction={'row'}>
          <Typography variant="caption" fontWeight={'bold'}>
            CPF:&nbsp;
          </Typography>
          <Typography variant="caption" fontSize={13}>
            {formatCPF(document)}
          </Typography>
        </Stack>
        <Stack direction={'row'}>
          <Typography variant="caption" fontWeight={'bold'}>
            Email:&nbsp;
          </Typography>
          <Typography variant="caption" fontSize={13}>
            {email}
          </Typography>
        </Stack>
    </Stack>
  );
};

export default SummaryUser;
