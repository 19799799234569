import { Grid, Stack, Typography } from '@mui/material';
import React from 'react';
// import logoMercadoPago from './../../assets/img/mercado-pago-logo-4.png';
import { Box } from '@mui/system';

import cards from './../../assets/img/cards.png';
import logoPagarme from "./../../assets/img/Footer/pagarme_oficial.png";
import logoFacebook from './../../assets/img/socialMedia/logo_facebook.svg';
import logoInstagram from './../../assets/img/socialMedia/logo_instagram.svg';
import logoTiktok from './../../assets/img/socialMedia/logo_tiktok.svg';
import logoTwitter from './../../assets/img/socialMedia/logo_twitter.svg';
// import LogoAllugator from '../LogoAllugator/index';
import logo from '../../assets/img/Home/allu_new_logo.png';


const ProductFooter = () => {
  const today = new Date();
  return (
    <Grid
      container
      sx={{
        background: '#252633',
        width: '100%',
      }}
    >
      <Grid
        container
        sx={{
          width: '1440px',
          margin: '0px auto',
          paddingY: '40px',
          paddingX: {
            xs: '18px ',
            sm: '36px',
            md: '155px',
          },
        }}
      >
        <Grid item xs={12} md={3}>
          <Stack>
            <Typography variant="subtitle1" fontSize={17} color={'#FFFF'}>
              Contato: lite@allugator.com
            </Typography>
            <Typography
              variant="caption"
              fontSize={13}
              color={'#FFFF'}
              sx={{ paddingTop: '32px' }}
            >
              R. Maranhão, 330 - Santa Efigênia
            </Typography>
            <Typography
              variant="caption"
              fontSize={13}
              color={'#FFFF'}
              sx={{ paddingBottom: '16px' }}
            >
              Belo Horizonte - MG, 30150-331
            </Typography>
            <Typography variant="caption" fontSize={13} color={'#FFFF'}>
              CNPJ: 25.053.921/0001-43
            </Typography>
          </Stack>
        </Grid>
        <Grid item md={1}></Grid>
        <Grid
          item
          md={4}
          sx={{
            display: {
              xs: 'none',
              sm: 'none',
              md: 'flex',
            },
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Stack spacing={'24px'}>
            <img src={logo} alt='allu logo' />
            <Typography variant="caption" fontSize={13} color={'#D1D1D1'}>
              © {today.getFullYear()} Allugator - Assinatura de Eletrônicos.
            </Typography>
          </Stack>
        </Grid>
        <Grid item md={1}></Grid>
        <Grid
          item
          xs={12}
          md={3}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'end'}
        >
          <Box>
            <img
              // src={logoMercadoPago}
              src={logoPagarme}
              width={78}
              height={28}
              alt="logo_pagarme"
            />
          </Box>
          <Box sx={{ padding: '24px 0' }}>
            <img
              src={cards}
              width={223}
              height={48}
              alt="Logos Cartões Aceitos"
            />
          </Box>
          <Typography variant="subtitle1" fontSize={17} color={'#F6F6F6'}>
            Siga nossas redes:
          </Typography>
          <Stack
            direction="row"
            spacing={'32px'}
            sx={{
              paddingTop: '8px',
            }}
          >
            <a
              href="https://www.instagram.com/allugatoroficial/"
              target={'_blank'}
              rel="noreferrer"
            >
              <img
                src={logoInstagram}
                width={25}
                height={24}
                alt="Logo Instatram"
              />
            </a>

            <a
              href="https://twitter.com/allugatorofc"
              target={'_blank'}
              rel="noreferrer"
            >
              <img
                src={logoTwitter}
                width={25}
                height={24}
                alt="Logo Twitter"
              />
            </a>

            <a
              href="https://www.tiktok.com/@allugator"
              target={'_blank'}
              rel="noreferrer"
            >
              <img
                src={logoTiktok}
                width={25}
                height={24}
                alt="Logo Tiktok"
              />
            </a>

            <a
              href="https://www.facebook.com/allugatordotcom"
              target={'_blank'}
              rel="noreferrer"
            >
              <img
                src={logoFacebook}
                width={25}
                height={24}
                alt="Logo Facebook"
              />
            </a>
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: {
              sx: 'flex',
              sm: 'flex',
              md: 'none',
            },
            flexDirection: 'column',
            alignItems: 'flex-start',
            marginTop: '2rem',
          }}
        >
          <Stack spacing={'24px'}>
            {/* <LogoAllugator theme="white" justifyContent={'start'} /> */}
            <img src={logo} alt='allu logo' />
            <Typography variant="caption" fontSize={13} color={'#D1D1D1'}>
              © {today.getFullYear()} Allugator - Assinatura de Eletrônicos.
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProductFooter;
