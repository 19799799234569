import { ApolloQueryResult } from 'apollo-client';
import * as React from 'react';
import { Mutation, MutationFn, Query, QueryResult } from 'react-apollo';

import Loader from '../../../components/LoaderBuffer';
import QueryRenderer from '../../../components/QueryRenderer';
import Icon from '../../../components/UI/Icon';
import { IMutationAddShippingToOrder, MUTATION_ADD_SHIPPING_TO_ORDER } from '../../../graphql/mutations/checkoutOrders';
import { MUTATION_ADD_ADDRESS } from '../../../graphql/mutations/users';
import { IQueryMyOrders } from '../../../graphql/queries/checkoutOrders';
import { IQueryCurrentUser, QUERY_CURRENT_USER } from '../../../graphql/queries/users';
import { IPublicUser, IUserAddress } from '../../../graphql/types/users';
import AddAddressForm from './AddAddressForm';
import AddShippingForm from './AddShippingForm';
import { AddressBox, Information, Wrapper } from './styled';

interface IProps {
  orderId: string;

  goBack: () => void;
  refetchOrders: () => Promise<ApolloQueryResult<IQueryMyOrders>>;
}

interface IState {
  selectedAddress?: number;
  newAddressForm: boolean;
}

class AddShipping extends React.Component<IProps, IState> {
  public state: IState = {
    newAddressForm: false,
  };

  public renderCurrentUser = (result: QueryResult<IQueryCurrentUser>) => (
    <QueryRenderer
      error={<div>Error. Contate o suporte através do chat</div>}
      loading={<Loader />}
      result={result}
      render={({ data }: QueryResult<IQueryCurrentUser>) => this.renderForm(data.currentUser, result.refetch)}
    />
  )

  public selectAddress = (address: number) => {
    const self = this;
    return () => self.setState({ selectedAddress: address });
  }

  public renderAddresses = (addresses: IUserAddress[] = []) => {
    return addresses.map((address, index) => (
      <AddressBox
        selected={index === this.state.selectedAddress}
        onClick={this.selectAddress(index)}
        key={address.identifier}
      >
        <div>{ address.identifier }</div>
        <div>{ address.street }, { address.number } - { address.neighborhood }</div>
        <div>{ address.city } - { address.state }</div>
      </AddressBox>
    )).reverse();
  }

  public setNewAddressForm = (state: boolean) => {
    const self = this;
    return (event?: React.FormEvent) => {
      if (event) { event.preventDefault(); }
      self.setState({ newAddressForm: state });
    };
  }

  public renderForm = (user: IPublicUser, refetch: () => Promise<ApolloQueryResult<IQueryCurrentUser>>) => {
    const { state, props, setNewAddressForm } = this;
    const { orderId, goBack, refetchOrders } = props;
    const { newAddressForm, selectedAddress } = state;

    return (
      <Wrapper>
        <Information style={{ marginBottom: 12 }}>
          Selecione o endereço de frete
        </Information>

        <AddressBox onClick={this.setNewAddressForm(!newAddressForm)}>
          <div><Icon>add</Icon>Novo endereço</div>
        </AddressBox>

        {
          newAddressForm
          ? (
            <Mutation mutation={MUTATION_ADD_ADDRESS}>
              {
                (addAddress) => (
                  <AddAddressForm
                    submit={addAddress}
                    refetch={(e) => {
                      refetch().then(() => setNewAddressForm(false)(e));
                    }}
                  />
                )
              }
            </Mutation>
          )
          : null
        }

        { this.renderAddresses(user.addresses) }

        {
          Number.isInteger(selectedAddress)
          ? (
            <Mutation mutation={MUTATION_ADD_SHIPPING_TO_ORDER}>
              {
                (addShippingToOrder: MutationFn<IMutationAddShippingToOrder>) => (
                  <AddShippingForm
                    user={user}
                    orderId={orderId}
                    selectedAddress={selectedAddress}
                    address={user.addresses[selectedAddress]}
                    submit={addShippingToOrder}
                    refetch={() => {
                      refetchOrders().then(() => {
                        goBack();
                        window.scrollTo(0, 0);
                      });
                    }}
                  />
                )
              }
            </Mutation>
          )
          : null
        }
      </Wrapper>
    );
  }

  public render() {
    return (
      <Query query={QUERY_CURRENT_USER}>
        { this.renderCurrentUser }
      </Query>
    );
  }
}

export default AddShipping;
