import { Form } from 'formik';
import { Link } from 'react-router-dom';
import MaskedInput from 'react-text-mask';
import styled, { keyframes } from 'styled-components';
import { media } from '../helpers/style';
import { blue, gray1, gray2, gray3, gray4, gray5, gray6, gray9, green1, green2, red, white } from '../style/colors';

export const RawButtonStyle = `
  border: none;
  background: none;
  outline: none;

  &:focus, &:active {
    outline: none;
  }

  &:disabled {
    background-color: ${gray2};
  }
`;

export const RawButton = styled.button`${RawButtonStyle}`;

export const RawLinkStyle = `
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
`;

export const RawLink = styled(Link)`
  ${RawButtonStyle};
  ${RawLinkStyle};
`;

export const RawAnchor = styled.a`
  ${RawButtonStyle};
  ${RawLinkStyle};
`;

export const CommonDiv = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const CommonButtonStyle = `
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 2em;
  background-color: ${white};
  box-shadow: 0 0 0 1px ${gray3};
  transition: box-shadow 0.2s, color 0.2s;
  color: ${gray6};
  cursor: pointer;
  font-weight: 700;

  &:hover {
    box-shadow: 0 0 0 2px ${gray3};
    color: ${gray9};
  }
`;

export const CommonButton = styled(RawButton)`${CommonButtonStyle}`;
export const CommonLink = styled(RawLink)`${CommonButtonStyle}`;
export const CommonAnchor = styled(RawAnchor)`${CommonButtonStyle}`;

export const PrimaryButtonStyle = `
  background-color: ${blue};
  border-radius: 3em;
  padding: 0.6em 0;
  font-size: 3em;
  line-height: 1.5em;
  font-weight: 700;
  color: ${white};

  &:hover {
    cursor: pointer;
  }
`;

export const PrimaryButton = styled(RawButton)`${PrimaryButtonStyle}`;
export const PrimaryLink = styled(RawLink)`${PrimaryButtonStyle}`;

export const SuccessButton = styled(PrimaryButton)`
  width: 100%;
  background-color: ${green1};
  transition: background-color 0.2s;

  &:hover {
    background-color: ${green2};
  }

  &:disabled {
    &:hover {
      background-color: #e9ecef;
    }
  }
`;

export const SelectButton = styled(PrimaryButton)`
  width: 35%;
  background-color: ${green1};
  transition: background-color 0.2s;
  font-size: 12px;

  &:hover {
    background-color: ${green2};
  }
`;

const inputStyle = `
  width: calc(100% - 2.2em);
  font-size: 2.5em;
  padding: 0.5em 1.1em;
  border-radius: 6px;
  border: none;
  outline: none;
  box-shadow: 0 0 0 1px ${gray3};
  transition: box-shadow 0.2s;

  &:focus, &:hover {
    outline: none;
    box-shadow: 0 0 0 2px ${gray3};
  }

  &::placeholder {
    color: ${gray3};
  }
  button {
    background: black;
  }
`;

export const CommonInput = styled.input`
  ${inputStyle}
  ${() => `${media.small`
      border: 1px solid #dee2e6;
    `}`
  }
  &&::-webkit-file-upload-button{
    visibility: hidden;
  }
  ${(props) => {
    if (props.type === 'file') {
      return (`
        display:block;
        z-index: 3;
        background: transparent;
        &:hover {
          cursor: pointer;
        }
      `);
    }
  }}
`;
export const CommonMaskedInput = styled(MaskedInput)`
  ${inputStyle}
  ${() => `${media.small`
      border: 1px solid #dee2e6;
    `}`
  }
`;

export const InputWrapper = styled(CommonDiv)`
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: ${(props: { width?: number }) => props.width || 100}%;
`;

export const InputLabel = styled.label`
  padding: 1em 0;
  font-size: 2.5em;
  font-weight: 700;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
`;

export const DisclaimerText = styled.label`
  padding: 1em 0;
  font-size: 2em;
  font-weight: 500;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
`;

export const ValidationFeedback = styled(CommonDiv)`
  color: red;
  font-size: 2em;
  padding: 0.5em 0;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const FormikFixedForm = styled(Form)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const blackOpen = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.7;
  }
`;

const blackClose = keyframes`
  0% {
    opacity: 0.7;
  }

  99% {
    z-index: 9;
  }

  100% {
    z-index: -3;
    opacity: 0;
    display: none;
  }
`;

export const BlackBox = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100vh;
  background-color: black;
  opacity: 0.7;

  animation: ${blackOpen} 0.5s;
  animation-fill-mode: forwards;
`;

export const BlackBoxClose = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  width: 100%;
  height: 100vh;
  background-color: black;
  opacity: 0.7;

  animation: ${blackClose} 0.5s;
  animation-fill-mode: forwards;
`;

export const ModalWrapper = styled(CommonDiv)``;

export const ModalContent = styled(CommonDiv)`
  z-index: 101;
  position: fixed;
  background-color: ${white};
  top: calc(50% - 240px);
  left: calc(50% - 180px);
  width: 360px;
  min-height: 400px;
  box-shadow: 0 0 10px ${gray4};
  border-radius: 6px;

  ${media.small`
      width: 90vw;
      left: unset;
      max-height: 90vh;
      border-radius: unset;
      top: 5vh;
      height: fit-content;
      left: 5vw;
      min-height: 360px;
    `
  }
`;

export const ModalCloseIcon = styled(CommonButton)`
  position: absolute;
  width: fit-content;
  top: 18px;
  right: 18px;
  box-shadow: unset;

  &:hover {
    box-shadow: unset;
  }
`;

export const GeosuggestWrapper = styled.div`
  font-size: 18px;
  position: relative;
  width: 100%;

  .geosuggest__input {
    ${inputStyle}
    font-size: 18px;
  }

  .geosuggest__suggests {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    max-height: 25em;
    padding: 0;
    margin-top: -1px;
    background: ${white};
    border: 1px solid ${gray3};
    border-radius: 0 0 6px 6px;
    border-top-width: 0;
    overflow-x: hidden;
    overflow-y: auto;
    list-style: none;
    z-index: 5;
    -webkit-transition: max-height 0.2s, border 0.2s;
            transition: max-height 0.2s, border 0.2s;
  }
  .geosuggest__suggests--hidden {
    max-height: 0;
    overflow: hidden;
    border-width: 0;
  }

  .geosuggest__item {
    font-size: 18px;
    padding: .5em .65em;
    cursor: pointer;
  }
  .geosuggest__item:hover,
  .geosuggest__item:focus {
    background: ${gray1};
  }
  .geosuggest__item--active {
    background: ${gray3};
  }
  .geosuggest__item--active:hover,
  .geosuggest__item--active:focus {
    background: ${gray2};
  }
  .geosuggest__item__matched-text {
    font-weight: bold;
  }
`;

export const AutocompleteBlocker = styled.input`
  display: none;
`;

export const FieldHint = styled.p`
  font-size: 18px;
  color: ${gray5};
  margin: 10px 0 10px 0;
`;

export const FieldError = styled.p`
  font-size: 16px;
  margin: 10px 0 0 0;
  color: red;
`;

interface IBarWrapperProps {
  progress?: number;
  progressColor?: string;
  bgColor?: string;
  fontColor?: string;
}

interface IBarPromoProps {
  progress?: number;
}

const barProgressAnimation = (progress: number) => keyframes`
  0% {
    right: 100%
  }
  100% {
    right: ${100 - progress}%
  }
`;
export const BarWrapper = styled(CommonDiv) <IBarWrapperProps>`
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  padding: 6px 0;
  background-color: ${(props) => props.bgColor || green2};
  font-size: 16px;
  font-weight: 500;
  color: ${(props) => props.fontColor || white};
  z-index: 9;
  width: 100%;
  text-align: center;

  ${media.small`
      font-size: 14px;
      bottom: 0;
      top: unset;
      flex-direction: column;
    `
  }

  span {
    position: absolute;
    background: ${(props) => props.progressColor || 'transparent'};
    top: 0;
    right: ${(props) => 100 - props.progress || 0}%;
    left: 0;
    bottom: 0;
    animation-fill-mode: forwards;
    animation: ${(props) => barProgressAnimation(props.progress)} 1s;
  }
  `;

export const BarPromo = styled(CommonDiv) <IBarPromoProps>`
  flex-direction: column;
  position: fixed;
  top: 30px;
  left: 0;
  padding: 6px 0;
  background-color: red;
  font-size: 20px;
  font-weight: 600;
  color: white;
  z-index: 9;
  width: 100%;
  text-align: center;

  ${media.small`
        font-size: 14px;
        bottom: 0;
        top: unset;
        flex-direction: column;
      `
  }

    span {
      position: absolute;
      background: ${red};
      top: 0;
      right: ${(props) => 100 - props.progress || 0}%;
      left: 0;
      bottom: 0;
      animation-fill-mode: forwards;
      animation: ${(props) => barProgressAnimation(props.progress)} 0.5s;
    }
  `;

export const BarRedirect = styled(CommonDiv)`
  position: fixed;
  top: 0;
  left: 0;
  padding: 6px 0;
  background-color: red;
  color: ${white};
  z-index: 100;
  width: 100%;

  ${media.small`
      bottom: 0;
      top: unset;
      flex-direction: column;
    `
  }
  `;

export const BarText = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: ${white};
  z-index: 9;
  width: 100%;
  text-align: center;

  ${media.small`
      font-size: 10px;
      width: 100%;
    `
  }
`;

export const RedirectButton = styled.button`
  border: 1px solid black;
  background-color: ${red};
  font-size: 11px;
  font-weight: 500;
  color: ${white};
  z-index: 9;
  width: 9%;
  text-align: center;
  border-radius: 10px;

  &:hover {
    cursor: pointer;
    background: ${blue}
  }

  ${media.small`
      font-size: 5px;
      width: 18%;
      margin: 2px 0 0 0;
    `
  }
`;

export const CheckBoxLabel = styled.label`
  padding: 1em 0;
  font-size: 2em;
  font-weight: 700;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;

  input {
    height: 25px;
    width: 25px;
  }
`;

export const CountDownBarWrapper = styled(CommonDiv)`
  position: fixed;
  top: 0;
  left: 0;
  padding: 6px 0;
  background-color: #363636;
  font-size: 16px;
  font-weight: 500;
  color: yellow;
  z-index: 9;
  width: 100%;
  text-align: center;

  ${media.small`
      font-size: 7px;
      padding: 5px 0;
      bottom: 0;
      top: unset;
    `
  }
`;
export const CountDownWrapper = styled.div`
  margin-left: 20px;

  ${media.small`
      margin-left: 5px;
    `
  }
`;
