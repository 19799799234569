import { Stack, Typography } from "@mui/material";
import React from "react";
import { IProduct } from "../../../../graphql/types/products";

const GeneralInformation = (product: IProduct) => {
  return (
    <Stack spacing={"12px"}>
      <Typography
        sx={{
          fontFamily: "Lato",
          fontWeight: "600",
          fontSize: "17px",
          lineHeight: "23.35px",
          letterSpacing: "0.15px",
          color: "#000000"
        }}
      >
        Informações gerais
      </Typography>
      <Stack spacing={"4px"}>
        <Typography
          sx={{
            fontFamily: "Lato",
            fontWeight: "500",
            fontSize: "15px",
            lineHeight: "23.55px",
            letterSpacing: "0.1px",
            color: "#000000",
            opacity: "87%"
          }}
        >
          Prazo de entrega:&nbsp;
          <span
            style={{
              fontFamily: "Lato",
              fontWeight: "500",
              fontSize: "15px",
              lineHeight: "23.55px",
              letterSpacing: "0.1px",
              color: "#000000",
              opacity: "60%"
            }}
          >
            {product?.deadline || 25} dias úteis
          </span>
        </Typography>
        <Typography
          sx={{
            fontFamily: "Lato",
            fontWeight: "500",
            fontSize: "15px",
            lineHeight: "23.55px",
            letterSpacing: "0.1px",
            color: "#000000",
            opacity: "87%"
          }}
        >
          Proteção:&nbsp;
          <span
            style={{
              fontFamily: "Lato",
              fontWeight: "500",
              fontSize: "15px",
              lineHeight: "23.55px",
              letterSpacing: "0.1px",
              color: "#000000",
              opacity: "60%"
            }}
          >
            Proteção completa contra furto qualificado e roubo
          </span>
        </Typography>
        <Typography
          sx={{
            fontFamily: "Lato",
            fontWeight: "500",
            fontSize: "15px",
            lineHeight: "23.55px",
            letterSpacing: "0.1px",
            color: "#000000",
            opacity: "87%"
          }}
        >
          Cor:&nbsp;
          <span
            style={{
              fontFamily: "Lato",
              fontWeight: "500",
              fontSize: "15px",
              lineHeight: "23.55px",
              letterSpacing: "0.1px",
              color: "#000000",
              opacity: "60%"
            }}
          >
            A cor da imagem é meramente ilustrativa. A cor do produto não pode
            ser escolhida, pois está sujeita a disponibilidade em estoque do
            fornecedor.
          </span>
        </Typography>
      </Stack>
    </Stack>
  );
};

export default GeneralInformation;
