import { Box, Link, Stack, Typography } from "@mui/material";
import React, { useEffect, useLayoutEffect, useRef } from "react";
import { WithApolloClient, withApollo } from "react-apollo";
import { connect } from "react-redux";
import { ActionCreator, AnyAction, Dispatch } from "redux";
import { ICartState } from "../../../../actionReducers/Cart";
import { ICreditCard, PaymentType } from "../../../../actionReducers/Checkout";
import {
  IFastCheckoutAction,
  SET_TOKEN_CREDIT_CARD_FAST_CHECKOUT
} from "../../../../actionReducers/FastCheckout";
import AddressInformation from "../../../../components/Pages/Checkout/AddressInformation";
import GeneralInformation from "../../../../components/Pages/Checkout/GeneralInformation";
import Config from "../../../../config";
import { IProduct } from "../../../../graphql/types/products";
import { IUserAddress } from "../../../../graphql/types/users";
import Cookies from "../../../../helpers/Cookies";
import { googleTag } from "../../../../helpers/analytics";
import toReal from "../../../../helpers/toReal";
import { IAppState } from "../../../../store";
import { EXTERNAL_LEAD_ID_FAST_CHECKOUT } from "../../../../boot/constants";
// import { useAnalytics } from "../../../../hooks/useAnalytics";

interface IProps {
  product: IProduct;
  paymentType: PaymentType;
  creditCard: ICreditCard;
  amountMonth: number;
  cart: ICartState;
  loading: boolean;
  total: number;
  subTotal: number;
  hasDiscount: boolean;
  deliveryAddress: IUserAddress;
  liveloOrigin?: boolean;
  liveloScore?: number;
  setTokenCreditCard: ActionCreator<IFastCheckoutAction>;
  liveloClubScore?: number;
}

const ResumeCheckout = ({
  product,
  creditCard,
  amountMonth,
  total,
  deliveryAddress,
  paymentType,
  liveloOrigin,
  liveloScore,
  setTokenCreditCard,
  liveloClubScore
}: IProps & WithApolloClient<{}>) => {
  // const analytics = useAnalytics();

  useLayoutEffect(() => {
    (async function() {
      const card = {
        card_number: creditCard.number,
        card_cvv: creditCard.cvv,
        card_holder_name: creditCard.holderName,
        card_expiration_date: creditCard.expirationDate
      };

      await pagarme.client
        .connect({ encryption_key: Config.PagarmeV3Key })
        .then((client: any) => client.security.encrypt(card))
        .then(async (hash: string) => {
          setTokenCreditCard({ token: hash });
        });
    })();
  }, []);

  const initialRef = useRef(null);

  useEffect(() => {
    initialRef.current?.scrollIntoView(false);
  }, []);

  const origin = Cookies.get("origin_fast_checkout");
  const lead_id = Cookies.get(EXTERNAL_LEAD_ID_FAST_CHECKOUT) ?? '';

  useEffect(() => {
    const paymentMode = Cookies.get("paymentmode_fast_checkout");

    const { code, state, city, street, number } = deliveryAddress;

    googleTag("review_stage", {
      send_to: "G-MFSWHTC64V",
      origin: origin,
      product: product?.slug,
      paymentMode: paymentMode,
      paymentType: paymentType,
      lead_id,
      address: {
        code: code,
        state: state,
        city: city,
        street: street,
        number: number
      }
    });

    dataLayer.push({
      event: "review_stage",
      product: product?.slug,
      paymentMode: paymentMode,
      paymentType: paymentType,
      origin,
      address: {
        code: code,
        state: state,
        city: city,
        street: street,
        number: number
      }
    });
  }, []);

  return (
    <Stack spacing={"24px"}>
      <GeneralInformation {...product} />

      <AddressInformation {...deliveryAddress} />

      <Stack spacing={"12px"}>
        <Typography
          fontFamily={"Chillax"}
          fontSize={21}
          fontWeight={500}
          color={"#007D34"}
        >
          Informações de pagamento
        </Typography>
        {paymentType === PaymentType.CREDIT_CARD ? (
          <>
            <Stack spacing={"4px"}>
              <Typography fontFamily={"Chillax"} fontSize={16} fontWeight={400}>
                Forma de pagamento:{" "}
                <span style={{ color: "rgba(0, 0, 0, 0.6)" }}>
                  Cartão de Crédito
                </span>
              </Typography>
              <Typography fontFamily={"Chillax"} fontSize={16} fontWeight={400}>
                Cartão:&nbsp;
                <span style={{ color: "rgba(0, 0, 0, 0.6)" }}>
                  ****
                  {creditCard &&
                    creditCard.number &&
                    creditCard.number.substring(creditCard.number.length - 4)}
                </span>
              </Typography>
              <Typography fontFamily={"Chillax"} fontSize={16} fontWeight={400}>
                {creditCard &&
                  creditCard.holderName &&
                  creditCard.holderName.toUpperCase()}
              </Typography>
              <Typography fontFamily={"Chillax"} fontSize={16} fontWeight={400}>
                Número de parcelas:&nbsp;
                <span style={{ color: "rgba(0, 0, 0, 0.6)" }}>
                  {creditCard.installments}x de {toReal(amountMonth || total)}{" "}
                  (sem juros)
                </span>
              </Typography>
              {liveloOrigin && (
                <Typography fontWeight={600}>
                  {`Ganhe ${liveloScore} pontos Livelo`}
                  {liveloClubScore
                    ? `Pontos Padrão: ${liveloScore}`
                    : `Ganhe ${liveloScore} pontos Livelo`}
                </Typography>
              )}
              {liveloOrigin && liveloClubScore && (
                <Typography fontWeight={600}>
                  {liveloClubScore
                    ? `Pontos Clube LIVELO: ${liveloClubScore}`
                    : ``}
                </Typography>
              )}
            </Stack>
          </>
        ) : (
          <Stack spacing={"8px"}>
            <Typography fontFamily={"Chillax"} fontSize={16} fontWeight={400}>
              Forma de pagamento:{" "}
              <span style={{ color: "rgba(0, 0, 0, 0.6)" }}>
                {paymentType === PaymentType.PIX ? "PIX" : "PICPAY"}
              </span>
            </Typography>
            <Typography fontFamily={"Chillax"} fontSize={16} fontWeight={400}>
              Informações importantes sobre o pagamento:
            </Typography>
            <Stack spacing={"4px"}>
              <Typography
                fontFamily={"Chillax"}
                fontSize={12}
                fontWeight={400}
                sx={{ display: "list-item", listStylePosition: "inside" }}
              >
                Após finalizar o pedido, realize o pagamento em até 24 horas.
                Caso contrário, o pedido será cancelado e um novo deverá ser
                feito
              </Typography>
              <Typography
                fontFamily={"Chillax"}
                fontSize={12}
                fontWeight={400}
                sx={{ display: "list-item", listStylePosition: "inside" }}
              >
                Você pode consultar o{" "}
                {paymentType === PaymentType.PIX
                  ? "QR Code"
                  : "Link de pagamento"}{" "}
                em Meus Pedidos durante esse período
              </Typography>
            </Stack>
          </Stack>
        )}
      </Stack>

      <Box>
        <Typography fontFamily={"Chillax"} fontSize={12} fontWeight={400}>
          Ao finalizar o pagamento, você estará de acordo com os{" "}
          <Link href={Config.termosDeUsoUrl} target="_blank">
            Termos de Uso
          </Link>
          &nbsp; e
          <Link href={Config.avisoProtecaoDadosUrl} target="_blank">
            &nbsp;Aviso de Proteção de Dados
          </Link>
          &nbsp; da Allugator relativos à assinatura.
        </Typography>
      </Box>
    </Stack>
  );
};

export default connect(
  (state: IAppState) => ({
    paymentType: state.fastCheckout.paymentType,
    creditCard: state.fastCheckout.creditCard,
    hasDiscount: state.fastCheckout.hasDiscount,
    amountMonth: state.fastCheckout.amountMonth,
    cart: state.cart,
    total: state.cart.total,
    subTotal: state.cart.subtotal,
    loading: state.general.loading,
    deliveryAddress: state.fastCheckout.deliveredAddress
  }),
  (dispatch: Dispatch<AnyAction>) => ({
    setTokenCreditCard: cardInfo =>
      dispatch(SET_TOKEN_CREDIT_CARD_FAST_CHECKOUT(cardInfo))
  })
)(withApollo(ResumeCheckout));
