import styled from 'styled-components';
import { CommonDiv } from '../../components/styled';
import { media } from '../../helpers/style';
import { gray0, gray5, white } from '../../style/colors';
import { mainFontSize } from '../../style/constants';
import { ProfileInput, ProfileMaskedInput } from '../Profile/components/styled';

export const MainLayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'Roboto', sans-serif;
  font-size: ${mainFontSize}px;
  align-items: center;
  justify-content: space-around;
  background-color: ${white};
  padding-top: 20px;
  width: 100%;
  height: 100vh;

  ${media.small`
    margin-top: 450px;
  `}
`;

export const Title = styled.span`
  font-size: 20px;
  font-family: 'Gilroy', 'Roboto', sans-serif;
  margin-bottom: 40px;
`;

export const Subtitle = styled.span`
  font-size: 16px;
  font-family: 'Gilroy', 'Roboto', sans-serif;
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: #75C078;
  width: 331px;
  height: 43px;
  letter-spacing: 1px;
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;
  user-select: none;
  border-radius: 10px;
  transition: ease-in-out 0.2s background-color;
  border: 0px;
  margin-top: 20px;

  &:hover {
    background-color: #7ED982;
  }

  ${media.medium `
    width: 70%;
    font-size: 16px;
  `}

  ${media.small `
    width: 70%;
    font-size: 16px;
  `}
`;

export const RouteWrapper = styled(CommonDiv)`
align-items: flex-start;
justify-content: space-around;
width: 100%;
margin-top: 50px;
${media.small`
  flex-direction: column;
  align-items: center;
`}
`;

export const LeftContent = styled(CommonDiv)`
  align-items: flex-start;  
  width: 60%;
  justify-content: space-around;

  @media(max-width: 1593px) {
    align-items: center;
    flex-direction: column;
  }
  ${media.small`
    width: 90%;
    flex-direction: column;
  `}
`;

export const ContentDescription = styled(CommonDiv)`
  flex-direction: column;
  width: 40%;
  margin-top: 50px;
  img{
    width: 50%;
    margin-bottom: 50px;
  }
  @media(max-width: 1593px){
    width: 55%;
  }
  ${media.small`
    margin-top: 20px;
    width: 100%;
    align-items: center;
`}
`;

export const ContentCard = styled(CommonDiv)`
  flex-direction: column;
  width: 45%;
  margin-top: 140px;
  img{
    margin-bottom: 20px;
  }
  @media(max-width: 1593px){
    width: 55%;
    margin: 70px 0 50px 0;
  }
  ${media.small`
  width: 100%;
  align-items: center;
  margin-top: 70px;
`}
`;

export const ContentProduct = styled(CommonDiv)`
  flex-direction: column;
  width: 30%;
`;

const CheckoutInputStyle = `
  padding: 16px 12px !important;
  border-radius: 4px;
  background-color: ${gray0};
  width: calc(100% - 24px);

  &::placeholder {
    color: ${gray5};
    font-size: 22px;
    ${media.medium`
      font-size: 19px;
    `}
  }
  ${media.medium`
    width: 100%;
    padding:0 !important;
  `}
`;

export const CheckoutMaskedInput = styled(ProfileMaskedInput)`
  ${CheckoutInputStyle};
  box-shadow:none;
  background-color: #fff;
  font-size: 1.3em;
  border-radius: 1px;
  border-bottom: 1px solid #000000;
  outline: none;
  padding: 5px 12px !important;
  margin-bottom: 10px !important;
  &:hover, &:focus{
    border: none;
    border-bottom: 1px solid #000000;
    box-shadow:none;
  }
  ${media.medium`
    border: none !important;
    border-bottom: 1px solid #000000 !important;
    font-size: 20px;
    margin-top: 0px !important;
  `}

  ${media.small`
    margin-top: 0px !important;
  `}
`;

export const InputLabel = styled.label`
  padding: 1em 0;
  font-size: 1.2em;
  font-weight: bold;
  color: #3A3A3A;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  font-family: 'Gilroy', 'Roboto', sans-serif;
  width: 100%;
  ${media.small`
    font-size: 1.0em;
  `}
`;

export const InputLabelHelp = styled.label`
  padding: 1em 0;
  font-size: 1.2em;
  font-weight: bold;
  color: #3A3A3A;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  font-family: 'Gilroy', 'Roboto', sans-serif;
  width: 100%;
  ${media.small`
    font-size: 1.0em;
  `}
`;

export const IconHelp = styled.label`
  padding-left: 3em;
  position: absolute;
  
  p {
    position: absolute;
    visibility: hidden;
    width: 120px;
    background-color: #282828;
    color: #fff;
    text-align: center;
    border-radius: 4px;
    font-size: 14px;
    font-weight: normal;
    z-index: 1;
    padding: 7px;
  }
  
  div{
    position: absolute;
  }

  &&:hover div {
    p {
      visibility: visible;
    }
  }

  ${media.small`
    p {
      font-size: 12px;
      width: 100px;
    }
  `}
`;

export const CheckoutInput = styled(ProfileInput)`
  ${CheckoutInputStyle};
  box-shadow:none;
  background-color: #fff;
  font-size: 20px;
  border-radius: 1px;
  border-bottom: 1px solid #000000;
  outline: none;
  padding: 5px 12px !important;
  margin-bottom: 10px !important;
  &:hover, &:focus{
    border: none;
    border-bottom: 1px solid #000000;
    box-shadow:none;
  }
  ${media.medium`
    border: none !important;
    border-bottom: 1px solid #000000 !important;
    font-size: 20px;
    margin-top: 0px !important;
  `}
  ${media.small`
    margin-top: 0px !important;
  `}
`;

export const ContentExpirationCard = styled(CommonDiv)`
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const ValidationFeedback = styled(CommonDiv)`
  color: red;
  font-size: 1em;
  align-items: flex-start;
  justify-content: flex-start;
  ${media.small`
    font-size: 0.8em;
  `}
`;

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

export const ContentAddress = styled.div`
  width: 95%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #F9F8F8;
  margin-top: 25px;
  padding: 15px 10px 15px 10px;
  font-weight: bold;
  font-size: 1.2em;
  border-style: solid;
  border-width: 1px;
`;

export const ButtonOnlyIcon = styled.button`
  border: 0px;
  border-radius: 45%;
  &:hover {
    background-color: #7ED982;
  }
`;

export const Modal = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 20px;
  width: 25%;
  height: 20vh;
  padding: 5px;
  border-radius: 8px;
  background-color: white;
  z-index: 4;
  box-shadow: 8px 6px 20px 5px rgba(0,0,0,0.1);
  font-family: 'Gilroy', 'Roboto', sans-serif;
  font-weight: bold; 
  text-align: center;
  margin:0 auto;
  position: absolute;
  left: 0;
  right:0;
  top: 50%; 

  -webkit-transform:translateY(-50%);
  -moz-transform:translateY(-50%);
  -ms-transform:translateY(-50%);
  -o-transform:translateY(-50%);
  transform:translateY(-50%);

  @media (max-width: 1275px) {
    font-size: 15px;
    width: 30%;
  }
  
  @media (max-width: 1050px) {
    font-size: 14px;
    width: 45%;
  }
  
  @media (max-width: 650px) {
    font-size: 14px;
    width: 80%;
  }
`;

interface IModalOpen {
  modalOpen?: boolean;
}

export const Background = styled.div`
  width: 100%;
  min-height: 100vh;
  font-family: 'Lato', sans-serif;
  opacity: ${({ modalOpen }: IModalOpen) => modalOpen ? 0.2 : 1};
  pointer-events: ${({ modalOpen }: IModalOpen) => modalOpen ? 'none' : 'all'};
  user-select: ${({ modalOpen }: IModalOpen) => modalOpen ? 'none' : 'auto'};
  transition: .5 all ease-in;
  
  @media (max-width: 1050px) {
    width: 100%;
  }
`;


