import React from "react"

import { Button } from "@mui/material"

import { steps } from "../../../../actionReducers/FastCheckout";

function BackStepButton({ currentTab, changeTab }) {

    const handleBackStep = () => {
        const currentTabIndex = steps.findIndex((value) => currentTab === value);

        const tab = steps[currentTabIndex - 1]

        changeTab(tab)
    }

    return (
        <Button
            variant="text"
            title="Voltar"
            sx={{
                color: '#000',
                fontWeight: 'medium',
                fontFamily: 'Chillax',
                letterSpacing: 1.25
            }}
            onClick={handleBackStep}
        >
            Voltar
        </Button>
    )
}

export default BackStepButton