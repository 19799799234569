import React, { useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import { ActionCreator, AnyAction, Dispatch } from 'redux';

import { toast } from "react-toastify";
import { ICreditCard } from "../../../actionReducers/Checkout";
import { IProfileAction, SET_CREDIT_CARD_TOKEN_PROFILE } from "../../../actionReducers/Profile";
import config from "../../../config";
import { IAppState } from "../../../store";

interface IProps {
  creditCard?: ICreditCard;
  setTokenCreditCard: ActionCreator<IProfileAction>;
}

const ProfileAddCardPagarme = ({ creditCard, setTokenCreditCard }: IProps) => {

  useLayoutEffect(() => {
    if (creditCard && creditCard.number && creditCard.holderName && creditCard.expirationDate && creditCard.cvv){
      (async function(){
        if (typeof pagarme === undefined) {
          toast.error('Por favor, atualize a tela antes de incluir seu cartão.');
          return;
        }
        
        const card = {
          card_number: creditCard.number,
          card_cvv: creditCard.cvv,
          card_holder_name: creditCard.holderName,
          card_expiration_date: creditCard.expirationDate,
        };
        
        await pagarme.client
          .connect({ encryption_key: config.PagarmeV3Key })
          .then((client: any) => client.security.encrypt(card))
          .then(async (hash: string) => {
            setTokenCreditCard({ token: hash });
          });
      }());
    }
  }, [creditCard.number, creditCard.holderName, creditCard.expirationDate, creditCard.cvv])

  return (<></>)

};

export default connect((state: IAppState) => ({
  creditCard: state.profile.creditCard,
}), (dispatch: Dispatch<AnyAction>) => ({
  setTokenCreditCard: (cardInfo) => dispatch(SET_CREDIT_CARD_TOKEN_PROFILE(cardInfo)),
}))(ProfileAddCardPagarme);