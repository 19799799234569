import { Field, FieldProps, FormikErrors, FormikTouched } from 'formik';
import * as React from 'react';
import MaskedInput from 'react-text-mask';

import { CommonDiv } from '../../../components/styled';
import { IValues } from './AddShippingForm';
import { FormHeader, InputWrapper, ValidationFeedback } from './styled';

interface IProps {
  touched: FormikTouched<IValues>;
  errors: FormikErrors<IValues>;
}

const CardForm: React.SFC<IProps> = (props) => {
  const { errors, touched } = props;

  return (
    <div>
      <FormHeader>
        Dados do cartão
      </FormHeader>

      <Field
        name='cardNumber'
        render={({ field }: FieldProps<IValues>) => (
          <InputWrapper>
            <label>Número do Cartão</label>
            <MaskedInput
              {...field}
              type='text'
              placeholder='0000 0000 0000 0000'
              autoComplete='cc-number'
              guide={false}
              mask={[
                /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ',
                /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ',
                /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ',
                /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/,
              ]}
            />
            {
              touched.cardNumber && !!errors.cardNumber
              && <ValidationFeedback>{errors.cardNumber}</ValidationFeedback>
            }
          </InputWrapper>
        )}
      />

      <Field
        name='cardName'
        render={({ field }: FieldProps<IValues>) => (
          <InputWrapper>
            <label>Nome do titular (como no cartão)</label>
            <input
              {...field}
              type='cardName'
              placeholder='Nome do titular (como no cartão)'
              autoComplete='cc-name'
            />
            {
              touched.cardName && !!errors.cardName
              && <ValidationFeedback>{errors.cardName}</ValidationFeedback>
            }
          </InputWrapper>
        )}
      />

      <CommonDiv style={{ justifyContent: 'space-between' }}>
        <Field
          name='cardExpiration'
          render={({ field }: FieldProps<IValues>) => (
            <InputWrapper style={{
              width: '48%',
            }}>
              <label>Validade</label>
              <MaskedInput
                {...field}
                type='text'
                placeholder='MM/YY'
                autoComplete='cc-exp'
                guide={false}
                mask={[
                  /[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/,
                ]}
              />
              {
                touched.cardExpiration && !!errors.cardExpiration
                && <ValidationFeedback>{errors.cardExpiration}</ValidationFeedback>
              }
            </InputWrapper>
          )}
        />

        <Field
          name='cvv'
          render={({ field }: FieldProps<IValues>) => (
            <InputWrapper style={{
              width: '48%',
            }}>
              <label>CVV</label>
              <input
                {...field}
                type='text'
                placeholder='000'
                autoComplete='cc-csc'
                maxLength={4}
              />
              {
                touched.cvv && !!errors.cvv
                && <ValidationFeedback>{errors.cvv}</ValidationFeedback>
              }
            </InputWrapper>
          )}
        />
      </CommonDiv>
    </div>
  );
};

export default CardForm;
