import styled from "styled-components";
import { CommonDiv } from "../../../components/styled";

export const ContainerBackground = styled(CommonDiv)`
  position: relative;
  .phone {
    position: absolute;
    left: 0%;
    top: 13.4%;
    z-index: 0;
  }

  @media (max-width: 1536px) {
    justify-content: flex-start;
    .phone {
      width: 270px;
      height: 473px;
    }
  }
  @media (max-width: 1200px) {
    justify-content: flex-start;
    .phone {
      width: 268px;
      height: 468px;
    }
  }

  @media (max-width: 900px) {
    justify-content: flex-end;
    .phone {
      position: relative;
      width: 109px;
      height: 190px;
    }
  }
`;