import { MonetizationOnOutlined } from '@mui/icons-material';
import { Button, Checkbox, CircularProgress, Stack, Typography } from '@mui/material';
import { Formik } from 'formik';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { ICreditCard } from '../../../../actionReducers/Checkout';
import { IExpectedPayments } from '../../../../graphql/types/checkoutOrders';
import toReal from '../../../../helpers/toReal';
import ValidatorCreditCard from '../../../../validators/validatorCreditCard';
import CustomModal from "../../../CustomModal";
import FormCard from '../../../FormCard';
import InputText from '../../../Inputs/InputText';

const initialValues: ICreditCard = {
    number: '',
    holderName: '',
    expirationDate: '',
    cvv: '',
    installments: 1,
};

interface IProps {
    currentCardPayment: IExpectedPayments;
    open: boolean,
    handleClose: () => void;
    amountMonth: number;
    submit: (value: ICreditCard, changeCardAllCharges?: boolean) => void;
    loading: boolean;
    oldRecurrence: boolean;
}

const PaymentModal = ({ currentCardPayment: { start, end, index }, open, handleClose, amountMonth, submit, loading, oldRecurrence }: IProps) => {

    const [errors, setErrors] = useState<boolean>(true);
    const [changeCardAllCharges, setChangeCardAllCharges] = useState<boolean>(true);

    useEffect(() => {
        setErrors(true);
    }, [open]);

    const handleBlurFields = (field: string, value: string | number, errors: any) => {
        if (!field || !value) return;
        if (Object.keys(errors).length) {
            setErrors(true);
            return;
        }
        setErrors(false);
    };

    const handleChangeCvv = (value: string, errors: any) => {
        if (value && value.length >= 3) {
            if (Object.keys(errors).length === 1 && errors.cvv) {
                setErrors(false);
                return;
            }
        }
        setErrors(true);
    };

    return (
        <CustomModal open={open} handleClose={handleClose}>
            <Stack direction={'row'} spacing={'10px'}>
                <MonetizationOnOutlined sx={{ fill: 'rgba(0, 0, 0, 0.54)' }} />
                <Typography variant='body1' >FORMA DE PAGAMENTO</Typography>
            </Stack>
            <Typography
                variant='subtitle1'
                sx={{ padding: '24px 0px 20px' }}>
                O pagamento da parcela será feito exclusivamente no cartão de crédito.
            </Typography>
            <Stack>
                <Typography variant='subtitle2'>Parcela {index} de 12</Typography>
                <Typography variant='subtitle2'>Período: {moment(start).format('DD/MM/YYYY')} até {moment(end).format('DD/MM/YYYY')}</Typography>
                <Typography variant='subtitle2'>Vencimento: {moment(start).format('DD/MM/YYYY')}</Typography>
            </Stack>
            <Formik
                initialValues={initialValues}
                onSubmit={(values: ICreditCard) => submit(values, changeCardAllCharges)}
                validationSchema={ValidatorCreditCard}
            >
                {(formikProps) => (
                    <>
                        <FormCard {...formikProps} handleBlurFields={handleBlurFields} handleChangeCvv={handleChangeCvv}>
                            <InputText
                                label="Valor da Parcela"
                                name={"parcel"}
                                type="text"
                                style={{ width: '100%' }}
                                value={toReal(amountMonth)}
                                touched={true}
                                disabled={true}
                            />
                        </FormCard>
                        {
                            !oldRecurrence ? (
                                <Stack direction={'row'}>
                                    <Checkbox
                                        defaultChecked
                                        value={changeCardAllCharges}
                                        checked={changeCardAllCharges}
                                        onChange={() => {
                                            setChangeCardAllCharges(!changeCardAllCharges)
                                        }}
                                    />
                                    <Typography variant='subtitle2' sx={{ padding: '5px 0px 5px' }}>Desejo salvar este cartão para pagar minhas próximas parcelas</Typography>
                                </Stack>
                            ) : null
                        }
                        <Button
                            variant="contained"
                            fullWidth
                            size="large"
                            color="primary"
                            sx={{ marginTop: '24px' }}
                            disabled={errors || loading}
                            onClick={() => formikProps.handleSubmit()}
                        >
                            {!loading ? 'PAGAR PARCELA' : <CircularProgress size={'20px'} />}
                        </Button>
                    </>
                )}
            </Formik>
        </CustomModal>
    )
};

export default PaymentModal;