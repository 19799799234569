import React from 'react';
import { Avatar, Box, Stack, Typography } from '@mui/material';
import PersonIcon from "@mui/icons-material/Person";

const InformationNewAccount = () => {
    return (
        <Box
            display={"flex"}
            alignItems={"center"}
            sx={{
              width: "100%",
              height: 104,
              backgroundColor: "#000000",
              opacity: "54%"
            }}
          >
            <Avatar
              variant="rounded"
              sx={{
                background: "#FFFFFF",
                marginRight: "16px",
                marginLeft: "16px"
              }}
            >
              <PersonIcon sx={{ color: "#10D878" }} />
            </Avatar>
            <Stack>
              <Typography
                sx={{
                  fontFamily: "Lato",
                  fontSize: "17px",
                  fontWeight: 400,
                  color: "#FFFFFF"
                }}
              >
                Sua conta allu foi criada!
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Lato",
                  fontSize: "15px",
                  fontWeight: 400,
                  color: "#FFFFFF"
                }}
              >
                Você receberá um e-mail com a sua senha provisória e informações
                sobre como acessar.
              </Typography>
            </Stack>
          </Box>
    )
};

export default InformationNewAccount;