import { Action } from "redux";
import { FastCheckoutActionTypes } from "./actionTypes";
import { ICreditCard, IDynamicKeyCheckout, IFinishedCheckout, PaymentType, TabsCheckoutKey } from "./Checkout";
import { IUserAddress } from "../graphql/types/users";
import { IPublicCoupon } from "../graphql/types/coupons";

export interface IFastCheckoutAction extends Action<FastCheckoutActionTypes> {
  [extraProp: string]: any;
}

export interface IPersonalDataFastCheckout {
  email: string;
  firstName: string;
  lastName: string;
  document?: string;
  birthdate?: Date;
  phone?: string;
  nestleCheck?: boolean;
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_term?: string;
  utm_content?: string;
}
export interface IFastCheckoutState {
  campaign?: string;
  origin?: string;
  currentTab: TabsCheckoutKey;
  personalData: IPersonalDataFastCheckout;
  paymentType?: PaymentType | null;
  deliveredAddress?: IUserAddress;
  personalDataAlreadySaved?: boolean;
  creditCard?: ICreditCard;
  amountMonth?: number;
  total?: number;
  hasDiscount?: boolean;
  coupon?: IPublicCoupon;
  couponPix?: IPublicCoupon;
  finishedCheckout?: IFinishedCheckout;
  newAccount?: boolean;
  nestleCheck?: boolean;
}

export const steps = [TabsCheckoutKey.PERSONAL_DATA, TabsCheckoutKey.ADDRESS, TabsCheckoutKey.PAYMENT, TabsCheckoutKey.SUMMARY]

const initialState = {
  currentTab: steps[0],
  hasDiscount: false,
  creditCard: { installments: 1, errors: false },
  paymentType: null,
} as IFastCheckoutState;

export const fastCheckoutReducer = (
  state: IFastCheckoutState = initialState,
  action: IFastCheckoutAction
): IFastCheckoutState => {
  switch (action.type) {
    case FastCheckoutActionTypes.SET_USER_PERSONAL_DATA:
      const { email, firstName, lastName, phone, birthdate, document, nestleCheck } = action.personalData;
      if (state.personalData && email === state.personalData.email && !birthdate && !document) {
        return {
          ...state,
          personalData: { ...state.personalData, email, firstName, lastName, phone, nestleCheck }
        };
      }
      return {
        ...state,
        personalData: { email, firstName, lastName, phone, birthdate, document, nestleCheck }
      };
    case FastCheckoutActionTypes.SET_CURRENT_TAB_FAST_CHECKOUT: {
      const { newTab } = action;
      return { ...state, currentTab: newTab };
    }
    case FastCheckoutActionTypes.SET_DELIVERED_ADDRESS_FAST_CHECKOUT: {
      const { address } = action;
      return { ...state, deliveredAddress: address };
    }
    case FastCheckoutActionTypes.SET_PERSONAL_DATA_ALREADY_SAVED: {
      const { value } = action;
      return { ...state, personalDataAlreadySaved: value };
    }
    case FastCheckoutActionTypes.SET_TYPE_PAYMENT_FAST_CHECKOUT: {
      const { value, total } = action;
      if (value === PaymentType.PIX) {
        return {
          ...state,
          creditCard: initialState.creditCard,
          amountMonth: initialState.amountMonth,
          total,
          paymentType: value
        };
      }
      return { ...state, paymentType: value };
    }
    case FastCheckoutActionTypes.SET_CREDIT_CARD_FAST_CHECKOUT: {
      const { data } = action;
      return { ...state, creditCard: { ...state.creditCard, ...data } };
    }
    case FastCheckoutActionTypes.SET_INSTALLMENTS_FAST_CHECKOUT: {
      const { installments, total, amountMonth } = action;
      return {
        ...state,
        creditCard: { ...state.creditCard, installments },
        total,
        amountMonth
      }
    }
    case FastCheckoutActionTypes.SET_HAS_DISCOUNT_FAST_CHECKOUT: {
      const { hasDiscount } = action;
      return { ...state, hasDiscount }
    }
    case FastCheckoutActionTypes.SET_COUPON_FAST_CHECKOUT: {
      const { coupon } = action;
      return { ...state, coupon };
    }
    case FastCheckoutActionTypes.SET_ORIGIN_FAST_CHECKOUT: {
      const { origin } = action;
      return { ...state, origin };
    }
    case FastCheckoutActionTypes.SET_COUPON_PIX_DEFAULT_FAST_CHECKOUT: {
      const { coupon } = action;
      return { ...state, couponPix: coupon };
    }
    case FastCheckoutActionTypes.SET_TOKEN_CREDIT_CARD_FAST_CHECKOUT: {
      const { cardInfo } = action;
      return { ...state, creditCard: { ...state.creditCard, cardInfo: { hash: cardInfo.token, issuerId: cardInfo.issuerId, paymentMethodId: cardInfo.paymentMethodId } } }
    }
    case FastCheckoutActionTypes.SET_FINISHED_PRODUCT_FAST_CHECKOUT: {
      const { product, total, linkPayment, installments, shortId, finishedMoment } = action;
      return { ...state, finishedCheckout: { product, total, linkPayment, blockCheckout: false, installments, shortId, finishedMoment } };
    }
    case FastCheckoutActionTypes.CLEAN_CREDIT_CARD_FAST_CHECKOUT: {
      return { ...state, creditCard: initialState.creditCard, paymentType: action.cleanTypePayment ? null : state.paymentType };
    }
    case FastCheckoutActionTypes.CLEAN_FAST_CHECKOUT: {
      return initialState;
    }
    case FastCheckoutActionTypes.SET_BLOCK_CHECKOUT_FAST_CHECKOUT: {
      return { ...state, finishedCheckout: { ...state.finishedCheckout, blockCheckout: true } };
    }
    case FastCheckoutActionTypes.SET_NEW_ACCOUNT_FAST_CHECKOUT: {
      return { ...state, newAccount: action.value }
    }
    case FastCheckoutActionTypes.SET_CAMPAIGN_FAST_CHECKOUT: {
      const { campaign } = action;
      return { ...state, campaign }
    }
    default:
      return state;
  }
};

export const SET_USER_PERSONAL_DATA = (personalData: IPersonalDataFastCheckout): IFastCheckoutAction => ({
  type: FastCheckoutActionTypes.SET_USER_PERSONAL_DATA,
  personalData
});

export const SET_CURRENT_TAB_FAST_CHECKOUT = (newTab: TabsCheckoutKey): IFastCheckoutAction => ({
  type: FastCheckoutActionTypes.SET_CURRENT_TAB_FAST_CHECKOUT,
  newTab
});

export const SET_DELIVERED_ADDRESS_FAST_CHECKOUT = (address: IUserAddress): IFastCheckoutAction => ({
  type: FastCheckoutActionTypes.SET_DELIVERED_ADDRESS_FAST_CHECKOUT,
  address
});

export const CLEAN_FAST_CHECKOUT = (): IFastCheckoutAction => ({
  type: FastCheckoutActionTypes.CLEAN_FAST_CHECKOUT
});

export const SET_PERSONAL_DATA_ALREADY_SAVED = (value: boolean): IFastCheckoutAction => ({
  type: FastCheckoutActionTypes.SET_PERSONAL_DATA_ALREADY_SAVED,
  value
});

export const SET_TYPE_PAYMENT_FAST_CHECKOUT = (value: PaymentType, total?: number): IFastCheckoutAction => ({
  type: FastCheckoutActionTypes.SET_TYPE_PAYMENT_FAST_CHECKOUT,
  value,
  total
});

export const SET_CREDIT_CARD_FAST_CHECKOUT = (data: IDynamicKeyCheckout): IFastCheckoutAction => ({
  type: FastCheckoutActionTypes.SET_CREDIT_CARD_FAST_CHECKOUT,
  data,
});

export const SET_INSTALLMENTS_FAST_CHECKOUT = (total: number, installments: number, amountMonth: number): IFastCheckoutAction => ({
  type: FastCheckoutActionTypes.SET_INSTALLMENTS_FAST_CHECKOUT,
  total,
  amountMonth,
  installments,
});

export const SET_HAS_DISCOUNT_FAST_CHECKOUT = (hasDiscount: boolean): IFastCheckoutAction => ({
  type: FastCheckoutActionTypes.SET_HAS_DISCOUNT_FAST_CHECKOUT,
  hasDiscount,
});

export const SET_COUPON_PIX_DEFAULT_FAST_CHECKOUT = (coupon: IPublicCoupon | null): IFastCheckoutAction => ({
  type: FastCheckoutActionTypes.SET_COUPON_PIX_DEFAULT_FAST_CHECKOUT,
  coupon
});

export const SET_COUPON_FAST_CHECKOUT = (coupon: IPublicCoupon | null): IFastCheckoutAction => ({
  type: FastCheckoutActionTypes.SET_COUPON_FAST_CHECKOUT,
  coupon
});

export const SET_ORIGIN_FAST_CHECKOUT = (origin: string | null): IFastCheckoutAction => ({
  type: FastCheckoutActionTypes.SET_ORIGIN_FAST_CHECKOUT,
  origin
});

export const SET_TOKEN_CREDIT_CARD_FAST_CHECKOUT = (cardInfo): IFastCheckoutAction => ({
  type: FastCheckoutActionTypes.SET_TOKEN_CREDIT_CARD_FAST_CHECKOUT,
  cardInfo
});

export const SET_FINISHED_PRODUCT_FAST_CHECKOUT = ({ product, total, linkPayment, installments, shortId, finishedMoment }: IFinishedCheckout): IFastCheckoutAction => ({
  type: FastCheckoutActionTypes.SET_FINISHED_PRODUCT_FAST_CHECKOUT,
  product,
  total,
  linkPayment,
  installments,
  shortId,
  finishedMoment
});

export const CLEAN_CREDIT_CARD_FAST_CHECKOUT = (cleanTypePayment?: boolean): IFastCheckoutAction => ({
  type: FastCheckoutActionTypes.CLEAN_CREDIT_CARD_FAST_CHECKOUT,
  cleanTypePayment,
});

export const SET_BLOCK_CHECKOUT_FAST_CHECKOUT = (): IFastCheckoutAction => ({
  type: FastCheckoutActionTypes.SET_BLOCK_CHECKOUT_FAST_CHECKOUT
});

export const SET_CAMPAIGN_FAST_CHECKOUT = (campaign: string | null): IFastCheckoutAction => ({
  type: FastCheckoutActionTypes.SET_CAMPAIGN_FAST_CHECKOUT,
  campaign
});

export const SET_NEW_ACCOUNT_FAST_CHECKOUT = (value: boolean): IFastCheckoutAction => ({
  type: FastCheckoutActionTypes.SET_NEW_ACCOUNT_FAST_CHECKOUT,
  value
});