import gql from "graphql-tag";

export interface IQueryBoletoExpiration {
    boletoExpiration: Number;
};

export const QUERY_BOLETO_EXPIRATION = gql`
    query boletoExpiration {
        boletoExpiration 
    }
  `;

export interface IHowItWorksData {
  howItWorks: IHowItWorks[];
  howItWorksVideo: string;
}

export interface IHowItWorks {
    title: string;
    description: string;
    icon: string;
};

export const QUERY_HOW_IT_WORKS = gql`
  query howItWorks {
    howItWorks {
      howItWorks{
        title
        description
        icon
      }
      howItWorksVideo
    }
  }
`;

export interface IQueryHowItWorks {
    howItWorks: IHowItWorksData;
};
