import { Action } from "redux";

import { IPublicUser } from "../graphql/types/users";
import { ProfileActionTypes } from "./actionTypes";

export interface IProfileAction extends Action<ProfileActionTypes> {
    [extraProp: string]: any;
}

export interface IDynamicKeyProfile {
    [key: string]: string | number | boolean;
}

export interface IProfileUserData extends IPublicUser {
    [key: string]: any;
}

export interface IProfileState {
    creditCard?: any;
}

const initialState = {
    creditCard: { installments: 1, errors: false },
} as IProfileState;

export const profileReducer = (
    state = initialState,
    action: IProfileAction
): IProfileState => {
    switch (action.type) {
        case ProfileActionTypes.SET_CREDIT_CARD_PROFILE: {
            const { data } = action;
            return { ...state, creditCard: { ...state.creditCard, ...data } };
        }
        case ProfileActionTypes.SET_CREDIT_CARD_TOKEN_PROFILE: {
            const { cardInfo } = action;
            return { ...state, creditCard: { ...state.creditCard, cardInfo: { hash: cardInfo.token, issuerId: cardInfo.issuerId, paymentMethodId: cardInfo.paymentMethodId } } }
        }
        case ProfileActionTypes.CLEAN_PROFILE: {
            return { ...state, creditCard: initialState.creditCard }
        }
        case ProfileActionTypes.CLEAN_CREDIT_CARD_PROFILE: {
            return { ...state, creditCard: initialState.creditCard };
        }
        case ProfileActionTypes.CLEAN_CREDIT_CARD_TOKEN_PROFILE: {
            return { ...state, creditCard: { ...state.creditCard, cardInfo: { } } }
        }
        default:
            return state;
    }
};

export const SET_CREDIT_CARD_PROFILE = (data: IDynamicKeyProfile): IProfileAction => ({
    type: ProfileActionTypes.SET_CREDIT_CARD_PROFILE,
    data,
});

export const SET_CREDIT_CARD_TOKEN_PROFILE = (cardInfo): IProfileAction => ({
    type: ProfileActionTypes.SET_CREDIT_CARD_TOKEN_PROFILE,
    cardInfo
});

export const CLEAN_PROFILE = (): IProfileAction => ({
    type: ProfileActionTypes.CLEAN_PROFILE
});

export const CLEAN_CREDIT_CARD_PROFILE = (): IProfileAction => ({
    type: ProfileActionTypes.CLEAN_CREDIT_CARD_PROFILE
});

export const CLEAN_CREDIT_CARD_TOKEN_PROFILE = (): IProfileAction => ({
    type: ProfileActionTypes.CLEAN_CREDIT_CARD_TOKEN_PROFILE
});
