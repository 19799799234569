import React from 'react';
import {
    InputLabel,
    FormControl,
    Select,
    SelectProps,
    FormHelperText,
  } from '@mui/material';
  import { SelectChangeEvent } from '@mui/material/Select';
  
  type optionsSelect = {
    value: string | number;
    title: string;
  };
  
  interface IProps extends SelectProps {
    value: string | number;
    setValue: (value: string) => void;
    label: string;
    options: optionsSelect[];
    errorMessage?: string;
    touched?: boolean;
    isRequired?: boolean;
    disabled?: boolean;
  }
  
  const MenuProps = {
    PaperProps: {
      style: {},
    },
  };
  
  const InputSelect = ({
    value,
    setValue,
    label,
    options,
    variant,
    touched,
    errorMessage,
    isRequired,
    disabled,
    id,
  }: IProps) => {
    const handleChange = (event: SelectChangeEvent) => {
      setValue(event.target.value);
    };
  
    return (
      <FormControl fullWidth error={touched && !!errorMessage}>
        <InputLabel>{label}</InputLabel>
        <Select
          native={true}
          value={value.toString()}
          onChange={handleChange}
          label={label}
          variant={variant || 'outlined'}
          MenuProps={MenuProps}
          required={isRequired || false}
          disabled={disabled}
          size={'small'}
          inputProps={{ id }}
        >
          {options.map((item, index) => (
            <option value={item.value} key={index} disabled={!item.value ? true : false}>
              {item.title}
            </option>
          ))}
        </Select>
        {touched && errorMessage && (
          <FormHelperText>{errorMessage}</FormHelperText>
        )}
      </FormControl>
    );
  };
  
  export default InputSelect;
  