import { Action } from "redux";
import { ITypePayment } from "../graphql/types/general";
import { ALLUGATOR_TYPE_PAYMENT } from "../boot/constants";
import { GeneralActionTypes } from "./actionTypes";

export interface IGeneralAction extends Action<GeneralActionTypes> {
  [extraProp: string]: any;
}

/**
 * Reducer
 */

export interface IGeneralState {
  loading: boolean;
  showCategories: boolean;
  orderConfirmed: boolean;
  missingPayment: boolean;
  missingOrderId: string;
  transactionId: string;
  typePayment?: ITypePayment;
}

const initialState: IGeneralState = {
  loading: false,
  showCategories: false,
  orderConfirmed: false,
  missingPayment: false,
  missingOrderId: "",
  transactionId: ""
};

const updateLocalStorage = (typePayment: ITypePayment) => {
  localStorage.setItem(ALLUGATOR_TYPE_PAYMENT, typePayment);
};

export const generalReducer = (
  state: IGeneralState = initialState,
  action: IGeneralAction
): IGeneralState => {
  switch (action.type) {
    case GeneralActionTypes.SET_LOADING: {
      return { ...state, loading: action.loadingState };
    }

    case GeneralActionTypes.SHOW_CATEGORIES_STATE: {
      return { ...state, showCategories: action.categoriesState };
    }

    case GeneralActionTypes.SET_ORDER_CONFIRMATION: {
      return { ...state, orderConfirmed: action.orderConfirmation };
    }

    case GeneralActionTypes.SET_MISSING_PAYMENT: {
      return { ...state, missingPayment: action.missingPayment };
    }

    case GeneralActionTypes.SET_MISSING_ORDER_ID: {
      return { ...state, missingOrderId: action.missingOrderId };
    }

    case GeneralActionTypes.SET_TRANSACTION_ID: {
      return { ...state, transactionId: action.transactionId };
    }

    case GeneralActionTypes.SET_TYPE_PAYMENT: {
      updateLocalStorage(action.typePayment);
      return { ...state, typePayment: action.typePayment };
    }

    case GeneralActionTypes.LOAD_TYPE_PAYMENT_FROM_LOCAL_STORAGE: {
      return { ...state, ...action.payload };
    }

    default:
      return state;
  }
};

/**
 * Actions
 */

export const SET_LOADING = (loadingState: boolean): IGeneralAction => ({
  type: GeneralActionTypes.SET_LOADING,
  loadingState
});

export const SHOW_CATEGORIES_STATE = (
  categoriesState: boolean
): IGeneralAction => ({
  type: GeneralActionTypes.SHOW_CATEGORIES_STATE,
  categoriesState
});

export const SET_ORDER_CONFIRMATION = (
  orderConfirmation: boolean
): IGeneralAction => ({
  type: GeneralActionTypes.SET_ORDER_CONFIRMATION,
  orderConfirmation
});

export const SET_MISSING_PAYMENT = (
  missingPayment: boolean
): IGeneralAction => ({
  type: GeneralActionTypes.SET_MISSING_PAYMENT,
  missingPayment
});

export const SET_MISSING_ORDER_ID = (
  missingOrderId: string
): IGeneralAction => ({
  type: GeneralActionTypes.SET_MISSING_ORDER_ID,
  missingOrderId
});

export const SET_TRANSACTION_ID = (transactionId: string): IGeneralAction => ({
  type: GeneralActionTypes.SET_TRANSACTION_ID,
  transactionId
});

export const SET_TYPE_PAYMENT = (typePayment: string): IGeneralAction => ({
  type: GeneralActionTypes.SET_TYPE_PAYMENT,
  typePayment
});

export const LOAD_TYPE_PAYMENT_FROM_LOCAL_STORAGE = (): IGeneralAction => {
  const oldTypePayment = localStorage.getItem(ALLUGATOR_TYPE_PAYMENT);
  return {
    type: GeneralActionTypes.LOAD_TYPE_PAYMENT_FROM_LOCAL_STORAGE,
    payload: {
      typePayment: oldTypePayment
    }
  };
};
