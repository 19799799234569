export default [
  {
    days: 3,
    discount: 0,
    realDiscount: 0,
  },
  {
    days: 7,
    discount: 45,
    realDiscount: 45,
  },
  {
    days: 15,
    discount: 50,
    realDiscount: 50,
  },
  {
    days: 30,
    discount: 70,
    realDiscount: 70,
  },
  {
    days: 60,
    discount: 70,
    realDiscount: 70,
  },
  {
    days: 90,
    discount: 0,
    realDiscount: 0,
  },
  {
    days: 180,
    discount: 25,
    realDiscount: 25,
  },
  {
    days: 365,
    discount: 50.65,
    realDiscount: 50.685,
  },
];
