import axios, { AxiosInstance } from "axios";
import { FileUploadError } from "./errors";
import { Document } from "../hooks/useUploadDocuments";
import { defineFilename } from "../helpers/defineFilename";


export type AnalysisType = 'identity_selfie' | 'identity_residence' | 'identity_selfie_residence';

export enum DocumentTypes {
  RG = 'RG',
  CNH = 'CNH',
  SELFIE = 'selfie',
  RESIDENCE = 'residence_proof'
}

export enum FileTypes {
  ID_FRONT = 'idFront',
  ID_BACK = 'idBack',
  SELFIE = 'selfie',
  DOC_FRONT_PERSON_CARD = 'docFrontPersonCard',
  DOC_BACK_PERSON_CARD = 'docBackPersonCard',
  SELFIE_PERSON_CARD = 'selfiePersonCard',
  DOC_FRONT_PERSON_RESIDENCE = 'docFrontPersonResidence',
  DOC_BACK_PERSON_RESIDENCE = 'docBackPersonResidence',
  RESIDENCE = 'residence',
  OTHER = 'other'
}

export type StartAnalysisParams = {
  analysisId: string;
  analysisType: AnalysisType;
  isThird: boolean;
  documents: Record<string, any>;
};

export type GetLastAnalysisParams = {
  id: string;
  cancelToken: any;
};

export type CreateOrUpdateOtherStageParams = {
  stageId?: string;
  analysisId: string;
  note: string;
  documentUrls: string[];
};

export type AddAnalysisAddressParams = {
  analysisId: string;
  reason?: string;
  billingAddress: {
    city: string;
    street: string;
    neighborhood: string;
    state: string;
    number: string;
    zipcode: string;
    complement?: string;
  };
  deliveryAddress: {
    city: string;
    street: string;
    neighborhood: string;
    state: string;
    number: string;
    zipcode: string;
    complement?: string;
  };
};

type ChangeStatusParams = {
  analysisId: string;
  status: string;
};

export class Risk {
  private readonly httpClient: AxiosInstance;
  private token = '';
  private expiresAt: Date;

  constructor() {
    this.httpClient = axios.create({
      baseURL: process.env.RAZZLE_BASE_URL_RISK,
    });
  }

  public async startAnalysis(params: StartAnalysisParams) {
    await this.auth();

    await this.httpClient.post('/analysis/start', {
      analysisId: params.analysisId,
      analysisType: params.analysisType,
      isThird: params.isThird,
      documents: params.documents,
    }, { headers: { Authorization: `Bearer ${this.token}` } });
  }

  public async findLastAnalysis(params: GetLastAnalysisParams) {
    const { data } = await this.httpClient.get(
      `/analysis/active/customer/${params.id}`,
      {
        cancelToken: params.cancelToken,
        headers: { Authorization: `Bearer ${this?.token}` }
      },
    );

    return data;
  }

  public async uploadDocuments(documents: Document[]) {
    try {
      await this.auth();

      const formData = new FormData();

      documents.forEach((data: Document) => {
        const fileName = defineFilename(data);
        formData.append('files', data.file, fileName);
      });

      const response = await this.httpClient.post('/documents/uploads', formData, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${this.token}`,
        }
      });

      return response.data.urls;
    } catch (e) {
      if (e.isAxiosError) {
        throw new FileUploadError(e.response.data);
      }
      throw e;
    }
  }

  public async createOrUpdateOtherStage({ analysisId, ...rest }: CreateOrUpdateOtherStageParams) {
    await this.auth();

    const { data } = await this.httpClient.post(
      `/analysis/createOrUpdateOtherDocumentsStage/${analysisId}`,
      {
        stageId: rest.stageId,
        note: rest.note,
        documents: rest.documentUrls,
      },
      { headers: { Authorization: `Bearer ${this.token}` } },
    );

    return data;
  }

  public async addAnalysisAddress({ analysisId, ...rest }: AddAnalysisAddressParams) {
    await this.auth();

    const { data } = await this.httpClient.post(`/analysis/${analysisId}/addresses`, rest, {
      headers: { Authorization: `Bearer ${this.token}` }
    });

    return data;
  }


  public async changeStatus({ analysisId, ...rest }: ChangeStatusParams) {
    await this.auth();

    const { data } = await this.httpClient.post(`/analysis/changeStatus/${analysisId}`, rest, {
      headers: { Authorization: `Bearer ${this.token}` }
    });

    return data;
  }

  private async auth() {
    if (this.token === '' || !this.expiresAt || this.expiresAt < new Date()) {
      const { data } = await axios.post(
        `https://auth.allugator.com/auth/realms/${process.env.RAZZLE_KEYCLOAK_REALM}/protocol/openid-connect/token`,
        new URLSearchParams({
          client_id: process.env.RAZZLE_KEYCLOAK_CLIENT,
          client_secret: process.env.RAZZLE_KEYCLOAK_SECRET,
          grant_type: 'client_credentials',
          scope: 'openid',
        }),
        { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } },
      );

      const newExpiresAt = new Date();
      newExpiresAt.setMinutes(newExpiresAt.getMinutes() + (data.expires_in / 60))

      this.expiresAt = newExpiresAt;
      this.token = data.access_token;
    }
  }
}

export default new Risk();