import { Box } from '@mui/system';
import { GridColDef } from '@mui/x-data-grid';
import React from 'react';
import { PagarmeLog } from '../../../../graphql/types/checkoutOrders';
import CustomTable from '../../../CustomTable';
import ContentEmpty from '../../../CustomTable/ContentEmpty';
import moment from 'moment';

interface IProps {
    detachedPayments: PagarmeLog[];
}

const DetachedPayments = ({ detachedPayments }: IProps) => {

    const formatData = () => {
        let id
        return detachedPayments.map(item => {
            id = id + 1
            if (item.result) {
                return {
                    id,
                    datePaid: `${moment(item.result.datePaid || item.updatedAt).format('DD/MM/YYYY')}`,
                    amount: item.result.paid_amount
                }
            }
        }).filter(elem => elem);
    };

    const columns: GridColDef[] = [
        { field: 'id', hide: true, sortable: false, },
        { field: 'datePaid', headerName: 'Data de Pagamento', sortable: false, flex: 1 },
        { field: 'amount', headerName: 'Valor', sortable: false, flex: 1 },
    ];

    return (
        <Box sx={{ maxHeight: '496px', overflow: 'auto' }}>
            <CustomTable columns={columns} rows={formatData()} autoHeight={true} ContentEmpty={ContentEmpty}/>
        </Box>
    )


};

export default DetachedPayments;