import { Box, Checkbox, FormControlLabel, FormGroup, Stack, Typography } from '@mui/material';
import { FormikProps } from 'formik';
import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { IFormDataSignUp } from '../..';
import InputSelect from '../../../../../components/InputSelect';
import TextFieldMask from '../../../../../components/Inputs/InputMask';
import InputText from '../../../../../components/Inputs/InputText';
import Config from '../../../../../config';
import listGenders from '../../../../../constants/genders';

const SignUpForm = ({
    handleBlur,
    handleChange,
    setFieldValue,
    values,
    touched,
    errors,
}: FormikProps<IFormDataSignUp>) => {
    return (
        <Box>
            <Stack spacing={'24px'}>
                <InputText
                    label="Nome"
                    type="text"
                    onBlur={handleBlur('firstName')}
                    setValue={handleChange('firstName')}
                    value={values.firstName || ''}
                    touched={touched.firstName}
                    errorMessage={errors.firstName}
                    isRequired={true}
                    name={"firstName"}
                />
                <InputText
                    label="Sobrenome"
                    type="text"
                    onBlur={handleBlur('lastName')}
                    setValue={handleChange('lastName')}
                    value={values.lastName || ''}
                    touched={touched.lastName}
                    errorMessage={errors.lastName}
                    isRequired={true}
                    name={"lastName"}
                />
                <InputSelect
                    value={values.gender || ''}
                    setValue={handleChange('gender')}
                    label={'Gênero'}
                    options={listGenders}
                    touched={touched.gender}
                    errorMessage={errors.gender}
                    required={true}
                />
                <InputText
                    label="Email"
                    type="text"
                    onBlur={handleBlur('email')}
                    setValue={handleChange('email')}
                    value={values.email || ''}
                    touched={touched.email}
                    errorMessage={errors.email}
                    isRequired={true}
                    name={"email"}
                />
                <TextFieldMask
                    mask="(99)99999-9999"
                    label="Telefone"
                    type="text"
                    onBlur={handleBlur('phone')}
                    setValue={handleChange('phone')}
                    value={values.phone || ''}
                    touched={touched.phone}
                    errorMessage={errors.phone}
                    isRequired={true}
                    name={"phone"}
                />
                <TextFieldMask
                    mask="999.999.999-99"
                    label="CPF"
                    type="text"
                    onBlur={handleBlur('document')}
                    setValue={handleChange('document')}
                    value={values.document || ''}
                    touched={touched.document}
                    errorMessage={errors.document}
                    isRequired={true}
                    name={"document"}
                />
                <InputText
                    label="Data de Nascimento"
                    type="date"
                    name={"birthday"}
                    onBlur={handleBlur('birthday')}
                    setValue={handleChange('birthday')}
                    value={values.birthday}
                    touched={touched.birthday}
                    errorMessage={errors.birthday}
                    shrink={true}
                />
                <InputText
                    label="Senha"
                    type="password"
                    onBlur={handleBlur('password')}
                    setValue={handleChange('password')}
                    value={values.password || ''}
                    touched={touched.password}
                    errorMessage={errors.password}
                    isRequired={true}
                    name={"password"}
                />
                <ReCAPTCHA
                    sitekey={Config.GoogleRecaptchaSiteKey}
                    ref={values.captchaRef}
                />
            </Stack>
            <Box sx={{ padding: '50px 0 16px 0' }}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                defaultChecked
                                value={values.receivePromotionalEmail}
                                checked={values.receivePromotionalEmail}
                                onChange={() => setFieldValue('receivePromotionalEmail', !values.receivePromotionalEmail)}
                            />
                        }
                        label={
                            <Typography fontFamily={'Chillax'} fontSize={'16px'}>
                                Quero receber novidades e dicas sobre produtos eletrônicos
                            </Typography>
                        }
                    />
                </FormGroup>
            </Box>
        </Box>
    )
};
export default SignUpForm;