import "react-credit-cards-2/dist/es/styles-compiled.css";
import * as React from 'react';
import { HelpOutline } from '@mui/icons-material';
import { Icon, Stack, Tooltip, Box, Typography } from '@mui/material';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { ActionCreator, Dispatch } from 'redux';
import toReal from '../../../../helpers/toReal';
import ValidatorCreditCard from '../../../../validators/validatorCreditCard';
import TextFieldMask from '../../../../components/Inputs/InputMask';
import InputText from '../../../../components/Inputs/InputText';
import { IAppState } from '../../../../store';
import { IFastCheckoutAction, SET_CREDIT_CARD_FAST_CHECKOUT, SET_INSTALLMENTS_FAST_CHECKOUT } from '../../../../actionReducers/FastCheckout';
import { ICreditCard, IDynamicKeyCheckout } from '../../../../actionReducers/Checkout';
import InputSelect from '../../../../components/InputSelect';
import { ICartItem } from "../../../../actionReducers/Cart";
import Cards, { Focused } from "react-credit-cards-2";

const initialValues: ICreditCard = {
  number: '',
  holderName: '',
  expirationDate: '',
  cvv: '',
  installments: 1,
};

interface IProps {
  items: ICartItem[]
  creditCard: ICreditCard;
  amount: number;
  setCreditCard: ActionCreator<IFastCheckoutAction>;
  setInstallments: ActionCreator<IFastCheckoutAction>;
}

const PaymentDataForm = ({ items, creditCard, amount, setCreditCard, setInstallments }: IProps) => {
  const [open, setOpen] = React.useState<boolean>(false);

  const [focused, setFocused] = React.useState<Focused>('')

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const calculateInstallmentsLite = (totalOnCard: number) => {
    let installments = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    const paymentMode = items[0].paymentMode

    if (paymentMode === 'quarterly') {
      installments = [1, 2, 3]
    } else if (paymentMode === 'semester') {
      installments = [1, 2, 3, 4, 5, 6]
    }

    return installments.map((installment) => {
      return ({
        title: `${installment}x ${toReal(totalOnCard / installment)} (sem juros)`,
        value: installment,
      });
    });
  };

  const handleInstallments = (value: any, setFieldValue: any) => {
    setFieldValue('installments', value);
    if (value === 1 || value === 2 || value === 3) {
      const amountMonth = amount / value;
      setInstallments(amount, value, amountMonth);
      return;
    }
    const totalWithInterest = amount;
    const totalMonth = amount / value;
    setInstallments(totalWithInterest, value, totalMonth);
  };

  const handleBlurFields = (field: string, value: string | number, errors: any) => {
    if (!value) return;
    const data = {};
    data[field] = value;
    data['errors'] = Object.keys(errors).length ? true : false;
    setCreditCard(data);
  };

  const handleChangeCvv = (value: any, errors: any) => {
    if (value && value.length >= 3) {
      setCreditCard({ cvv: value, errors: (Object.keys(errors).length === 1 && errors.cvv) || !Object.keys(errors).length ? false : true });
    } else {
      setCreditCard({ cvv: value, errors: true });
    }
  };

  const handleErrosForm = (error: boolean) => {
    setCreditCard({ errors: error });
  };

  const handleInputFocus = (evt) => {
    if (evt.target.name === 'cvv') {
      setFocused('cvc')
      return
    }
    setFocused(evt.target.name);
  }

  return (
    <Box>
      <Formik
        initialValues={creditCard || initialValues}
        onSubmit={() => { }}
        validateOnChange={true}
        validationSchema={ValidatorCreditCard}
      >
        {({
          touched,
          values,
          errors,
          handleBlur,
          handleChange,
          setFieldValue,
        }) => {

          if ((Object.keys(errors).length > 0 && !creditCard.errors) || (Object.keys(errors).length === 0 && creditCard.errors)) {
            handleErrosForm(Object.keys(errors).length ? true : false);
          }

          return (
            <Stack spacing={3}>
              <Typography fontFamily={'Chillax'} variant="h5" fontWeight={500}>
                Pagamento
              </Typography>
              <Box sx={{ '& *': { margin: '0' } }}>
                <Cards
                  number={values.number ?? ''}
                  expiry={values.expirationDate ?? ''}
                  cvc={values.cvv ?? ''}
                  name={values.holderName ?? ''}
                  focused={focused}
                  locale={{ valid: 'Validade' }}
                  placeholders={{ name: 'Nome no cartão' }}
                />
              </Box>
              <Stack spacing={2}>
                <TextFieldMask
                  mask={'9999 9999 9999 9999'}
                  label="Número do Cartão"
                  type="text"
                  autoComplete="disabled"
                  name={"number"}
                  onBlur={(e) => { handleBlur('number')(e), handleBlurFields('number', values.number, errors) }}
                  onFocus={handleInputFocus}
                  setValue={(e) => { handleChange('number')(e), handleBlurFields('number', e, errors) }}
                  value={values.number}
                  touched={touched.number || values.number ? true : false}
                  errorMessage={errors.number}
                  isRequired={true}
                />
                <InputText
                  label="Nome - Como no cartão"
                  type="text"
                  name="holderName"
                  onBlur={(e) => { handleBlur('holderName')(e), handleBlurFields('holderName', values.holderName, errors) }}
                  onFocus={handleInputFocus}
                  setValue={(e) => { handleChange('holderName')(e), handleBlurFields('holderName', e, errors) }}
                  value={values.holderName}
                  touched={touched.holderName}
                  errorMessage={errors.holderName}
                  autoComplete="disabled"
                  isRequired={true}
                  replace={/[0-9.]+/g}
                />
                <Stack
                  direction={'row'}
                  spacing={'16px'}
                >
                  <TextFieldMask
                    mask="99/99"
                    name={"expirationDate"}
                    autoComplete="disabled"
                    label="Validade"
                    type="text"
                    style={{ width: '50%' }}
                    onBlur={(e) => { handleBlur('expirationDate')(e), handleBlurFields('expirationDate', values.expirationDate, errors) }}
                    onFocus={handleInputFocus}
                    setValue={(e) => { handleChange('expirationDate')(e), handleBlurFields('expirationDate', e, errors) }}
                    value={values.expirationDate}
                    touched={touched.expirationDate}
                    errorMessage={errors.expirationDate}
                    isRequired={true}
                  />
                  <InputText
                    label="CVV"
                    name="cvv"
                    type="text"
                    style={{ width: '50%' }}
                    onBlur={(e) => { handleBlur('cvv')(e), handleBlurFields('cvv', values.cvv, errors) }}
                    onFocus={handleInputFocus}
                    setValue={(e) => { handleChange('cvv')(e), handleChangeCvv(e, errors) }}
                    value={values.cvv}
                    touched={touched.cvv}
                    errorMessage={errors.cvv}
                    isRequired={true}
                    maxLengthInput={4}
                    replace={/[^0-9.]+/g}
                    inputIcon={
                      <Tooltip sx={{ zIndex: 1 }} title={'O código de segurança poder ter 3 ou 4 dígitos.'} open={open} onClose={handleClose} onOpen={handleOpen} onClick={handleOpen}>
                        <Icon color={'action'}>
                          <HelpOutline />
                        </Icon>
                      </Tooltip>
                    }
                  />
                </Stack>
                <InputSelect
                  value={values.installments}
                  setValue={(value) => { handleInstallments(value, setFieldValue) }}
                  label={'Selecione o número de parcelas'}
                  options={calculateInstallmentsLite(amount)}
                  touched={touched.installments}
                  errorMessage={errors.installments}
                  onBlur={(e) => { handleBlur('installments')(e), handleBlurFields('installments', values.installments, errors) }}
                />
              </Stack>
            </Stack>
          )
        }}
      </Formik>
    </Box>
  );
};

export default connect((state: IAppState) => ({
  items: state.cart.items,
  creditCard: state.fastCheckout.creditCard,
}), (dispatch: Dispatch<IFastCheckoutAction>) => ({
  setCreditCard: (data: IDynamicKeyCheckout) => dispatch(SET_CREDIT_CARD_FAST_CHECKOUT(data)),
  setInstallments: (total: number, installments: number, amountMonth: number) => dispatch(SET_INSTALLMENTS_FAST_CHECKOUT(total, installments, amountMonth))
}))(PaymentDataForm);