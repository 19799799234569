import React from 'react';

import { BlackBox, BlackBoxClose, ModalCloseIcon } from './../styled';
import {  ModalContent, ModalWrapper } from './styled';
import { Icon } from './../UI/Icon';

type ModalProps = {
  display?: boolean;
  closeFn?: () => void;
};

const ModalSatisfactorySurvey: React.FC<ModalProps> = ({ children, display, closeFn }) => {
  const close = async () => {
    if (!closeFn) { return; }
    closeFn();
  };

  return (
    <ModalWrapper>
      {!!display && (
        <ModalContent>
          {closeFn && (
            <ModalCloseIcon onClick={close}>
              <Icon>close</Icon>
            </ModalCloseIcon>
          )}
          {children}
        </ModalContent>
      )}
      {!!display ? <BlackBox onClick={close} /> : <BlackBoxClose />}
    </ModalWrapper>
  );
};

export default ModalSatisfactorySurvey;