import styled from 'styled-components';

import { CommonDiv } from '../../../components/styled';
import { SuccessButton } from '../../../components/styled';
import { media, sizes } from '../../../helpers/style';
import { blue, darkBlue, gray1, gray3 } from '../../../style/colors';
import { CartCheckoutProductDetails } from '../../Cart/components/styled';

export const OrdersContentWrapper = styled(CommonDiv)`
  flex-direction: column;
  min-height: calc(100vh - 320px);
  max-width: ${sizes.big}px;

  ${
    media.small`
      width: calc(100% - 6em);
      padding: 0 3em;
    `
  }
`;

export const TermsWrapper = styled(CommonDiv)`
  flex-direction: column;
  min-height: calc(100vh - 320px);
  max-width: ${sizes.big}px;

  bdi {
    margin-left: 2em;
  }

  div {
    margin-left: 4em;
  }

  ${
    media.small`
      width: calc(100% - 6em);
      padding: 0 3em;
    `
  }
`;

export const TableWrapper = styled(CommonDiv)`
  flex-direction: column;
  min-height: calc(50vh - 320px);
  max-width: ${sizes.big}px;

  table {
    border: 1px solid black;
    border-collapse: collapse;
    text-align: center;
  }

  th {
    border: 1px solid black;
    border-collapse: collapse;
    text-align: center;
  }

  td {
    border: 1px solid black;
    border-collapse: collapse;
    text-align: center;
    padding: 0 10px;
  }

  ${
    media.small`
      width: calc(100% - 6em);
      padding: 0 3em;
    `
  }
`;

export const OrderCardWrapper = styled(CommonDiv)`
  align-items: flex-start;
  justify-content: flex-start;
  border-radius: 4em;
  box-shadow: 0px 0px 0px 1px ${gray3};
  margin-bottom: 5em;

  ${
    media.small`
      align-items: center;
      flex-direction: column;
    `
  }
`;

export const OrderProductImage = styled.img`
  width: 36em;
  height: 36em;
  padding: 4em;
`;

export const OrderContent = styled(CommonDiv)`
  flex-direction: column;
  justify-content: flex-start;
  padding: 5em;
  min-height: calc(44em - 10em);
  background: linear-gradient(90deg, #fff, ${gray1});
  border-radius: 4em;
  width: calc(100% - 10em);
`;

export const OrderContentTitle = styled.h1`
  margin: 0;
  padding: 0;
  font-size: 4em;
  font-weight: 700;
  width: 100%;
  margin-bottom: 0.75em;
  display: flex;

  div {
    flex: 1;
  }

  small {
    font-size: 12px;
    text-align: right;
    display: flex;
    align-items: center;
    font-weight: 500;
    justify-content: flex-end;
  }
`;

export const OrderProductDetails = styled(CartCheckoutProductDetails)`
  flex-direction: column;
`;

export const OrderCardFooter = styled(CommonDiv)`
  font-weight: 500;
  font-size: 2.33em;
  flex-grow: 1;
  align-items: flex-end;

  > span {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
  }

  a {
    box-shadow: unset;
    font-weight: 500;
    color: ${blue};
  }

  a:hover {
    box-shadow: unset;
    color: ${darkBlue};
    text-decoration: underline;
  }
`;

export const ThankYouModal = styled.div`
  width: 480px;
  background-color: white;
  position: fixed;
  top: 10%;
  z-index: 10;
  padding: 60px 0px;

  ${
    media.small`
      width: 100vw;
      top: 0;
      font-size: 6px;
      height: calc(100vh - 120px);
      display: flex;
      align-items: center;
    `
  }
`;

export const CloseButton = styled.div`
  user-select: none;
  cursor: pointer;
  padding: 10px 10px 0px 0px;
  position: absolute;
  top: 0;

  ${
    (props: { left?: boolean }) =>
      props.left
      ? `
        left: 12px;
      `
      : `
        right: 0;
      `
  }
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  height: fit-content;
`;

export const HeaderTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 24px;
  font-weight: 700;
  color: gray;
  margin: 30px 0px 10px;
`;

export const InfoText = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 18px;
  font-weight: 500;
  color: gray;
`;

export const ChatBot = styled.div`
  width: 70%;
  font-size: 2em;
  font-weight: 500;
  color: gray;
  margin-top: 20px;
  text-align: center;
`;

export const ModalCloseButton = styled(SuccessButton)`
  font-size: 18px;
  margin-top: 30px;
`;
