import verifyCpf from "../services/users/verifyCpf";

interface IProps {
    email: string; 
    document: string;
}

export const validationDocument = async ({ email, document }: IProps) => {
        if (document.length < 11) return;
        const result = await verifyCpf({ email, document });
        return result;
};