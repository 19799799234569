import { injectGlobal } from "styled-components";

export default () => injectGlobal`
  /* montserrat-regular - latin */
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-display: auto;
    font-weight: 400;
    src: url('/fonts/montserrat-v12-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Montserrat Regular'), local('Montserrat-Regular'),
        url('/fonts/montserrat-v12-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('/fonts/montserrat-v12-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
        url('/fonts/montserrat-v12-latin-regular.woff') format('woff'), /* Modern Browsers */
        url('/fonts/montserrat-v12-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
        url('/fonts/montserrat-v12-latin-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
  }
  /* montserrat-500 - latin */
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-display: auto;
    font-weight: 500;
    src: url('/fonts/montserrat-v12-latin-500.eot'); /* IE9 Compat Modes */
    src: local('Montserrat Medium'), local('Montserrat-Medium'),
        url('/fonts/montserrat-v12-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('/fonts/montserrat-v12-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
        url('/fonts/montserrat-v12-latin-500.woff') format('woff'), /* Modern Browsers */
        url('/fonts/montserrat-v12-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
        url('/fonts/montserrat-v12-latin-500.svg#Montserrat') format('svg'); /* Legacy iOS */
  }
  /* montserrat-600 - latin */
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-display: auto;
    font-weight: 600;
    src: url('/fonts/montserrat-v12-latin-600.eot'); /* IE9 Compat Modes */
    src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'),
        url('/fonts/montserrat-v12-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('/fonts/montserrat-v12-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
        url('/fonts/montserrat-v12-latin-600.woff') format('woff'), /* Modern Browsers */
        url('/fonts/montserrat-v12-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
        url('/fonts/montserrat-v12-latin-600.svg#Montserrat') format('svg'); /* Legacy iOS */
  }
  /* montserrat-700 - latin */
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-display: auto;
    font-weight: 700;
    src: url('/fonts/montserrat-v12-latin-700.eot'); /* IE9 Compat Modes */
    src: local('Montserrat Bold'), local('Montserrat-Bold'),
        url('/fonts/montserrat-v12-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('/fonts/montserrat-v12-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
        url('/fonts/montserrat-v12-latin-700.woff') format('woff'), /* Modern Browsers */
        url('/fonts/montserrat-v12-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
        url('/fonts/montserrat-v12-latin-700.svg#Montserrat') format('svg'); /* Legacy iOS */
  }

  @font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-display: auto;
    font-weight: 400;
    src: url('/fonts/MaterialIcons-Regular.eot'); /* For IE6-8 */
    src: local('Material Icons'),
      local('MaterialIcons-Regular'),
      url('/fonts/MaterialIcons-Regular.woff2') format('woff2'),
      url('/fonts/MaterialIcons-Regular.woff') format('woff'),
      url('/fonts/MaterialIcons-Regular.ttf') format('truetype');
  }

  .material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-display: auto;
    font-style: normal;
    font-size: 24px;  /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: 'liga';
  }

  /*Roboto*/
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-display: auto;
    font-weight: normal;
    src: url('/fonts/Roboto-Regular.ttf');
    src: local('Roboto Regular'), local('Roboto-Regular'),
        url('/fonts/Roboto-Regular.ttf') format('truetype'),
  }

  /* Roboto Medium*/
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-display: auto;
    font-weight: 500;
    src: url('/fonts/Roboto-Bold.ttf');
    src: local('Roboto Medium'), local('Roboto-Medium'),
        url('/fonts/Roboto-Bold.ttf') format('truetype'),
  }

  /*Roboto Light*/
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-display: auto;
    font-weight: 300;
    src: url('/fonts/Roboto-Light.ttf');
    src: local('Roboto Light'), local('Roboto-Light'),
        url('/fonts/Roboto-Light.ttf') format('truetype'), /* Super Modern Browsers */
  }

  /*Roboto Thin*/
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-display: auto;
    font-weight: 100;
    src: url('/fonts/Roboto-Thin.ttf');
    src: local('Roboto Thin'), local('Roboto-Thin'),
        url('/fonts/Roboto-Thin.ttf') format('truetype'), /* Super Modern Browsers */
  }

  /*Roboto Medium*/
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-display: auto;
    font-weight: 500;
    src: url('/fonts/Roboto-Medium.ttf'); /* IE9 Compat Modes */
    src: local('Roboto Medium'), local('Roboto-Medium'),
        url('/fonts/Roboto-Medium.ttf') format('truetype'), /* Safari, Android, iOS */
  }

  /*Gilroy ExtraBold*/
  @font-face {
    font-family: 'Gilroy';
    font-style: normal;
    font-display: auto;
    font-weight: 800;
    src: url('/fonts/Gilroy-ExtraBold.otf'); /* IE9 Compat Modes */
    src: local('Gilroy Bold'), local('Gilroy-Bold'),
        url('/fonts/Gilroy-ExtraBold.otf') format("opentype"), /* Safari, Android, iOS */
  }

  /*Gilroy Light*/
  @font-face {
    font-family: 'Gilroy';
    font-style: normal;
    font-display: auto;
    font-weight: 300;
    src: url('/fonts/Gilroy-Light.otf'); /* IE9 Compat Modes */
    src: local('Gilroy Bold'), local('Gilroy-Bold'),
        url('/fonts/Gilroy-Light.otf') format("opentype"), /* Safari, Android, iOS */
  }

  /*Chillax Regular*/
  @font-face {
    font-family: 'Chillax';
    font-style: normal;
    font-display: auto;
    font-weight: 400;
    src: url('/fonts/Chillax-Regular.otf'); /* IE9 Compat Modes */
    src: local('Chillax Regular'), local('Chillax-Regular'),
        url('/fonts/Chillax-Regular.otf') format('truetype'), /* Safari, Android, iOS */
  }
  
  /*Chillax Medium*/
  @font-face {
    font-family: 'Chillax';
    font-style: normal;
    font-display: auto;
    font-weight: 500;
    src: url('/fonts/Chillax-Medium.otf'); /* IE9 Compat Modes */
    src: local('Chillax Medium'), local('Chillax-Medium'),
        url('/fonts/Chillax-Medium.otf') format('truetype'), /* Safari, Android, iOS */
  }

  /*Chillax Semibold*/
  @font-face {
    font-family: 'Chillax';
    font-style: normal;
    font-display: auto;
    font-weight: 600;
    src: url('/fonts/Chillax-Semibold.otf'); /* IE9 Compat Modes */
    src: local('Chillax Semibold'), local('Chillax-Semibold'),
        url('/fonts/Chillax-Semibold.otf') format('truetype'), /* Safari, Android, iOS */
  }

  /*Denton Condensed Medium*/
  @font-face {
    font-family: 'Denton Medium';
    font-style: normal;
    font-display: auto;
    font-weight: 500;
    src: url('/fonts/DentonCondensedMedium.otf'); /* IE9 Compat Modes */
    src: local('Denton Condensed Medium'), local('Denton-Condensed-Medium'),
        url('/fonts/DentonCondensedMedium.otf') format('truetype'), /* Safari, Android, iOS */
  }

  /*Denton Condensed Bold*/
  @font-face {
    font-family: 'Denton Bold';
    font-style: normal;
    font-display: auto;
    font-weight: 700;
    src: url('/fonts/DentonCondensedBold.otf'); /* IE9 Compat Modes */
    src: local('Denton Condensed Bold'), local('Denton-Condensed-Bold'),
        url('/fonts/DentonCondensedBold.otf') format('truetype'), /* Safari, Android, iOS */
  }


  html {
    font-family: 'Roboto', 'Montserrat', Arial, sans-serif, 'Chillax';
  }

  .react-modal-document-preview-overlay {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .react-modal-document-preview-content {
    background: #fff;
    padding: 20px;
    position: relative;
    border-radius: 0.25rem;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 600px) {
      width: 80%;
    }

    .img-modal {
      width: 100%;
      max-height: 600px;
      margin-top: 30px;
    }
    
    .pdf-modal {
      margin-top: 30px;
      width: 320px;
      height: 400px;
    }
  }

  .react-modal-documents-explanation {
    max-width: 90%;
    max-height: 600px;
    background: #fff;
    position: relative;
    border-radius: 0.25rem;
    display: flex;

    h2 {
      color: #5F5F5F;
    }

    p {
      color: #585B60;
      strong {
        margin-left: 2px;
        color: #0066FF;
      }
      margin-bottom: 25px;
    }

    div {
      margin: 0;
    }

    :focus-visible {
      outline: none;
    }
  }

  .react-modal-thanks-documents {
    background-color: #fff;
    max-height: 246px;
    max-width: 543px;

    .react-modal-thanks-documents-container {
      padding: 0px 68px 16px;

      @media (max-width: 600px) {
        padding: 0px 32px 16px;
      }
      h2 {
        color: #282828;
        font-size: 18px;
      }
      p {
        color: #282828;
        font-size: 14px;
      }
    }
  }

  .react-modal-close {
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
    border: 0;
    background: transparent;
    transition: filter 0.2s;

    &:hover {
        filter: brightness(0.8);
    }
  }

  input[type="radio"] {
    appearance: none;
    background-color: #fff;
    margin: 0;
    font: inherit;
    color: currentColor;
    width: 1.25rem;
    height: 1.25rem;
    border: 0.15em solid #00000099;
    border-radius: 50%;
    transform: translateY(-0.075em);
    display: inline-grid;
    place-content: center;
    cursor: pointer;
    position: relative;

    &:checked {
      border: 0.15em solid #27AE60;
    }
  }

  input[type="radio"]::before {
    position: absolute;
    top: 15%;
    left: 15%;
    content: "";
    width: 0.754rem;
    height: 0.754rem;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em #27AE60;
  }

  input[type="radio"]:checked::before {
    transform: scale(.99);
  }
`;
