import React, { useEffect } from 'react';

import { BlackBox, BlackBoxClose, ModalCloseIcon, ModalContent, ModalWrapper } from './styled';
import { Icon } from './UI/Icon';

type ModalProps = {
  display?: boolean;
  closeFn?: () => void;
  contentStyle?: React.CSSProperties;
  closeIconStyle?: React.CSSProperties;
};

const Modal: React.FC<ModalProps> = ({ children, display, closeFn, contentStyle, closeIconStyle }) => {
  useEffect(() => {
    document.body.setAttribute('style', display ? 'overflow-y: hidden' : '');
  }, []);

  const close = async () => {
    if (!closeFn) { return; }
    closeFn();
  };

  return (
    <ModalWrapper>
      {!!display && (
        <ModalContent style={contentStyle || {}}>
          {closeFn && (
            <ModalCloseIcon onClick={close} style={closeIconStyle || {}}>
              <Icon>close</Icon>
            </ModalCloseIcon>
          )}
          {children}
        </ModalContent>
      )}

      {!!display ? <BlackBox onClick={close} /> : <BlackBoxClose />}
    </ModalWrapper>
  );
};
// public render() {
//   const { display, closeFn } = this.props;

//   return (
//     <ModalWrapper>
//       {
//         !!display
//           ? <ModalContent>
//             {
//               closeFn
//                 ? <ModalCloseIcon onClick={this.close}>
//                   <Icon>close</Icon>
//                 </ModalCloseIcon>
//                 : null
//             }
//             {this.props.children}
//           </ModalContent>
//           : null
//       }
//       {!!display ? <BlackBox onClick={this.close} /> : <BlackBoxClose />}
//     </ModalWrapper>
//   );
// }

export default Modal;
