import * as React from 'react';
import { Mutation, Query, QueryResult } from 'react-apollo';
import { RouteComponentProps } from 'react-router';
import { toast } from 'react-toastify';

import { MUTATION_RESET_PASSWORD } from '../../../graphql/mutations/users';
import { IQueryVerifyPasswordToken, QUERY_VERIFY_PASSWORD_TOKEN } from '../../../graphql/queries/users';
import ResetPasswordForm from './ResetPasswordForm';
import { Box, Stack, Typography } from '@mui/material';
import { ALLUGATOR_AUTH_TOKEN } from '../../../boot/constants';
import Cookies from '../../../helpers/Cookies';
import { MainLayoutWrapper } from './styled';

export class ResetPassword extends React.Component<RouteComponentProps<{ token: string }>> {
  public componentDidMount() {
    window.scrollTo(0, 0);
    if (!!Cookies.get(ALLUGATOR_AUTH_TOKEN)) {
      this.props.history.push('/');
    }
  }

  public renderForm = (token: string, userId: string, email: string) => {
    const { push } = this.props.history;

    return (
      <Mutation mutation={MUTATION_RESET_PASSWORD}>
        {
          (resetPassword) => (
            <ResetPasswordForm
              redirect={push}
              submit={resetPassword}
              userId={userId}
              newPasswordToken={token}
              email={email}
            />
          )
        }
      </Mutation>
    );
  }

  public render() {
    const { token } = this.props.match.params;
    return (
      <MainLayoutWrapper>
        <Box sx={{
          display: 'flex',
          backgroundColor: '#27AE60',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
            <Stack
              spacing={'40px'}
              sx={{
                height: {xs: '100%', sm: '70%', md: 'auto'},
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: '#F4F4EF',
                width: { md: '610px', sm: '70%', xs: '100%' },
                padding: { md: '8vh', xs: '8vh 19px' },
                borderRadius: { sm: '40px', xs: '0px' }
              }}>
              <Query query={QUERY_VERIFY_PASSWORD_TOKEN}
                variables={{ newPasswordToken: token }}
                fetchPolicy='cache-and-network'
              >
                {
                  (result: QueryResult<IQueryVerifyPasswordToken>) => {
                    const { data, error } = result;

                    if (error) {
                      error.graphQLErrors.map((e) => toast.error(e.message));
                      this.props.history.push('/');
                    }

                    if (data && data.verifyPasswordToken) {
                      const { email, id } = data.verifyPasswordToken;
                      return (
                        <>
                          <Typography fontSize={{ md: 60, xs: 37 }} fontWeight={700} fontFamily={'Denton Condensed'} color={'#27AE60'} lineHeight={{ md: '87px', xs: '49px' }}>
                            Redefina a sua senha
                          </Typography>
                          <Typography fontSize={{ md: 21, xs: 16 }} fontFamily={'Chillax'} color={'#2D2D38'}>
                            Digite uma nova senha para <strong>{email}</strong>.
                          </Typography>
                          {this.renderForm(token, id, email)}
                        </>
                      );
                    }

                    return null;
                  }
                }
              </Query>
            </Stack>
          </Box>
      </MainLayoutWrapper>
    );
  }
}

export default ResetPassword;
