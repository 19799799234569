import calculatePrice from "./priceCalculation";
import replaceToNumber from "./replaceToNumber";
import toReal from "./toReal";

const formatProductDataLayer = (product: any) => {
    const amount = toReal(calculatePrice(Number(product.prices.signatureLite), 365, 1, product.delivery));
    return {
        item_name: product.name,
        item_id: product._id,
        item_brand: product.brand,
        item_category: product.subcategory ? product.subcategory.name : '',
        item_variant: String(product.index),
        index: 1,
        quantity: product.quantity,
        price: Number(replaceToNumber(amount))
    };
};

export default formatProductDataLayer;
