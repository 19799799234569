import { Box, Stack, Typography } from '@mui/material';
import React, { ReactNode } from 'react';
import { IProduct } from '../../graphql/types/products';
import { getProductShortName } from '../../helpers/getProductShortName';
import getStorage from '../../helpers/getStorageProduct';
import { ImgProduct } from './styled';

interface IProps {
    product: IProduct;
    children?: ReactNode;
}

const ProductDetail = ({ product: { name, photos }, children }: IProps) => {
    return (
        <Stack
            sx={{
                padding: {
                    xs: '0px 24px 24px',
                    sm: '24px 24px 24px 0px',
                    md: '24px'
                },
                boxShadow: {
                    md: '0px 5px 8px rgba(120, 111, 111, 0.15)'
                },
                borderRadius: '8px',
                minHeight: { md: '205px' }
            }}>
            <Typography
                variant={'h6'}
                fontSize={'17px'}
                color={'#333333'}
                sx={{
                    paddingBottom: '12px',
                    display: {
                        xs: 'block',
                        md: 'none',
                    },
                }}>
                Produto
            </Typography>

            <Stack direction={'row'} >
                {photos && photos.length && (
                    <ImgProduct
                        src={photos[0]}
                        alt={name}
                    />
                )}
                <Stack marginLeft={'20px'}>
                    <Typography
                        variant={'h6'}
                        fontSize={'17px'}
                        color={'#333333'}
                        sx={{
                            paddingBottom: '12px',
                            display: {
                                xs: 'none',
                                sm: 'none',
                                md: 'block',
                            },
                        }}>
                        Produto
                    </Typography>
                    <Box>
                        <Typography variant={'subtitle2'} fontSize={'15px'} color={'#666666'}>
                            {getProductShortName(name) || null}
                        </Typography>
                        <Typography variant={'subtitle2'} fontSize={'15px'} color={'#666666'}>
                            {getStorage(name).label || null}
                        </Typography>
                    </Box>
                    {children}
                </Stack>
            </Stack>

        </Stack>
    )
};

export default ProductDetail;