import 'normalize.css';
import * as React from 'react';
import { ApolloProvider } from 'react-apollo';
import 'react-datepicker/dist/react-datepicker.css';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'universal-cookie';

import { TopProgressBar } from '../components/TopProgressBar';
import { Client } from '../config/graphql';
import YacareRouter from '../router';
import store, { persistor } from '../store';
import globalStyle from './globalStyle';

import { CssBaseline, ThemeProvider } from '@mui/material';
import { PersistGate } from 'redux-persist/integration/react';
import theme from '../style/material';

const Root = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <div>
        <CssBaseline />
        <ThemeProvider theme={theme}>
          <ToastContainer
            position='top-center'
            autoClose={3000}
            hideProgressBar
            closeOnClick
            closeButton={false}
            pauseOnHover
          />
          <TopProgressBar />
          <YacareRouter />
        </ThemeProvider>
      </div>
    </PersistGate>
  </Provider>
);

export default () => {
  globalStyle();

  return (
    <ApolloProvider client={Client(new Cookies())}>
      <Root />
    </ApolloProvider>
  );
};
