import * as Yup from 'yup';

const requiredMessages = {
  newPassword: 'A senha é obrigatória.',
};

const validMessages = {
  newPassword: 'Por favor, coloque uma senha com no mínimo 6 caractéres.',
};

export const ResetPasswordFormSchema = Yup.object().shape({
  newPassword: Yup
    .string()
    .min(6, validMessages.newPassword)
    .required(requiredMessages.newPassword),
});
