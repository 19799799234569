import { Action } from 'redux';
import { IProduct } from '../graphql/types/products';
import { ProductActionTypes } from './actionTypes';

export interface IProductAction extends Action<ProductActionTypes> {
    [extraProp: string]: any;
}

export interface IProductsData {
    name: string;
    availableStorages?: string[];
    availableStoragesPreowned?: string[];
    items: IProduct[];
    index?: number;
    brand: string;
}

export interface IProductState {
    products?: IProductsData[];
    preRelease?: boolean;
    showPreownedProducts?: boolean;
    promotionalPeriod?: boolean;
    preownedVersionChosen?: boolean;
}

const initialState = {
    preRelease: false,
    showPreownedProducts: false,
    promotionalPeriod: false,
    preownedVersionChosen: false,
} as IProductState;

/**Reducers**/

export const productReducer = (state: IProductState = initialState, action: IProductAction): IProductState => {
    switch (action.type) {
        case ProductActionTypes.SET_PRODUCTS: {
            return { ...state, products: action.products };
        }
        case ProductActionTypes.SET_PRE_RELEASE: {
            return { ...state, preRelease: !!action.preRelease };
        }
        case ProductActionTypes.SET_SHOW_PRE_OWNED_PRODUCTS: {
            return { ...state, showPreownedProducts: action.showPreownedProducts };
        }
        case ProductActionTypes.SET_PROMOTIONAL_PERIOD: {
            return { ...state, promotionalPeriod: action.promotionalPeriod };
        }
        case ProductActionTypes.SET_PRE_OWNED_VERSION_CHOSEN: {
                return { ...state, preownedVersionChosen: action.preownedVersionChosen, showPreownedProducts: action.preownedVersionChosen ? true : false };
        }
        default:
            return state;
    }
};

/**Actions**/

export const SET_PRODUCTS = (products: IProductsData[]) => ({
    type: ProductActionTypes.SET_PRODUCTS,
    products,
});


export const SET_PRE_RELEASE = (preRelease: boolean) => ({
    type: ProductActionTypes.SET_PRE_RELEASE,
    preRelease,
});

export const SET_SHOW_PRE_OWNED_PRODUCTS = (showPreownedProducts: boolean) => ({
    type: ProductActionTypes.SET_SHOW_PRE_OWNED_PRODUCTS,
    showPreownedProducts
});

export const SET_PROMOTIONAL_PERIOD = (promotionalPeriod: boolean) => ({
    type: ProductActionTypes.SET_PROMOTIONAL_PERIOD,
    promotionalPeriod
})

export const SET_PRE_OWNED_VERSION_CHOSEN = (preownedVersionChosen: boolean) => ({
    type: ProductActionTypes.SET_PRE_OWNED_VERSION_CHOSEN,
    preownedVersionChosen
})