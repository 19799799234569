import React, { useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import newAchievementPNG from '../../../assets/img/DocumentsV2/new_achievement.png';
import CustomButton from "../../../components/Rebranding/CustomButton";
import Footer from "../../HomeV2/components/Footer";
import NewNavbar from "../../HomeV2/components/NewNavbar";
import { Container } from "./styles";

interface ThanksProps extends RouteComponentProps<{}> { }

function Thanks({ history }: ThanksProps) {
  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <>
      <NewNavbar />

      <Container>
        <img src={newAchievementPNG} alt="Documentos enviados!" />

        <div>
          <h1>Documentos enviados!</h1>

          <h2>Agora é só relaxar e nós cuidaremos do resto</h2>

          <p>
            Não se preocupe, não vai demorar uma eternidade! Seus documentos serão
            analisados em <strong>até 3 dias úteis.</strong> Uma notificação será enviada por e-mail e
            WhatsApp sobre a situação dos documentos. Caso queira, você pode acompanhar
            o andamento na aba “Meus Pedidos”.
          </p>

          <CustomButton
            title="ver pedidos"
            onClick={() => history.push("/pedidos")}
          />
        </div>
      </Container>

      <Footer />
    </>
  );
};

export default Thanks;
