import styled from "styled-components";
import { Link } from "react-router-dom";

interface PropsNavbar {
  isFixedBar: boolean;
}

export interface IPropsSidebarContainer {
  isOpen: boolean;
}

export interface IPropsOptionMenu {
  missingPayment?: boolean;
}

interface PropsActions {
  loggedState: boolean;
  missingPayment?: boolean;
  isFixedBar: boolean;
  preRelease?: boolean;
}

export const SidebarMobileContainer = styled.div<IPropsSidebarContainer>`
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100vh;
  background-color: #2D2D38;
  display: flex;
  flex-direction: column;
  padding-top: 100px;
  padding-top: 6rem;
  left: 0;
  transition: all 0.5s ease-in-out;
  align-items: center;
  ${(props: IPropsSidebarContainer) =>
    props.isOpen
      ? `      
      opacity: 0.99;
      top: 0;
      display: flex;
    `
      : `
      display: none;
      top: -200%;
      height: 0px;
    `}
`;

export const ContainerOptions = styled.div`
  display: flex;
  flex-direction: column;
`;

export const OptionMenu = styled(Link) <IPropsOptionMenu>`
  ${(props: IPropsOptionMenu) =>
    props.missingPayment &&
    `
    cursor: not-allowed;
  `}
  text-decoration: none;
  color: #fff;
  font-size: 1.5rem;
  padding: 1rem 0 1rem 2rem;
  transition: color 0.2s ease-out;
  
  &:hover{
    color: #14d296;
  }
`;

export const ContainerCloseIcon = styled.div`
  position: absolute;
  top: 1.75rem;
  right: 1.25rem;
  cursor: pointer;
`;

export const LogoMobile = styled.img`
  position: absolute;
  top: 1.75rem;
  left: 1.75rem;
`;

export const FooterMenuMobile = styled.div`
  padding-left: 2rem;
  margin-top: 3rem;
  color: #fff;
  font-size: 0.8rem;
`;


export const Actions = styled.div<PropsActions>`
  display: flex;
  gap:20px;
  align-items: center;
  margin-right: 162px;
  align-items: center;
  @media (max-width: 900px) {
    width: 100%;
    display: flex;
    height: 100%;
    flex-direction: column;
    margin-right: 0px;
    margin-top: 40px;
    gap: 0;
  }
  .link_actions_pedidos {
      color: ${(props: PropsActions) => props.preRelease ? '#F4F4EF;' : '#FFFFFF'};
      width: 200px;
      height: 31px;
      font-family: 'Chillax';
      font-style: normal;
      font-weight: 500;
      font-size: 23px;
      line-height: 31px;
      }
  a {
    cursor: ${(props: PropsActions) => props.missingPayment ? 'not-allowed' : 'pointer'};
    text-decoration: none;
    font-weight: 700;
    &.link_actions {
      color: ${(props: PropsActions) => props.preRelease ? '#535353' : '#FFFFFF'};
      width: 157px;
      height: 31px;
      font-family: 'Chillax';
      font-style: normal;
      font-weight: 500;
      font-size: 23px;
      line-height: 31px;
      &.link_logout {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px 45px;
        gap: 14px;
        height: 42px;
        border-radius: 21px;
        border: 2px solid #F4F4EF;
        @media(max-width) {
          padding: 0px 45px;
          gap: 14px;
          width: 247px;
          height: 42px;
          border: 2px solid #F4F4EF;
          border-radius: 21px;
          color: #F4F4EF;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
        }
      }
      &.btn_produtos {
        color: #535353;
        background-color: #FFFFFF;
      }
      ${(props: PropsNavbar) => props.isFixedBar && `
        color: #FFFFFF;
      `};
    }
    @media(max-width: 900px) {
      width: 247px;
      height: 31px;
      margin-top: 25px;
      font-family: 'Chillax';
      font-style: normal;
      font-weight: 500;
      font-size: 23px;
      line-height: 31px;
      text-align: center;
      color: #F4F4EF;
    }
  }
`;

export const SideBarContainer = styled.article`
    font-family: 'Chillax';
    font-style: normal;
    z-index: 999;
    .link_actions_mobile {
      width: 247px;
      margin-bottom: 31px;
      &.link_central_ajuda {
        margin-top: 0px;
      }
    }
    .mobileMenu {
      height: 756px;
      width: 375px;
    }
    .burguerBtn {
      display: block;
      border: none;
      background: #2D2D38;
      width: 30px;
      margin-right: 27px;
    }
    section {
      margin-top: 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  
    .login-btn {
    background: transparent;
    color: #F4F4EF;
    border: 3px solid #F4F4EF;
    
  }

  .cadastro-btn {
    background: #27AE60;
    border-radius: 21px;
    border: none;
    margin-top: 31px;
  }

   

`;