import { Box, Grid, Link, Stack, Typography } from "@mui/material";
import { Formik } from "formik";
import moment from "moment";
import { parse } from "query-string";
import React, { useRef } from "react";
import { ReactCookieProps, withCookies } from "react-cookie";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { ActionCreator, AnyAction, Dispatch } from "redux";
import {
  ICheckoutAction,
  SET_NEW_ACCOUNT
} from "../../../actionReducers/Checkout";
import { IGeneralAction, SET_LOADING } from "../../../actionReducers/General";
import { IUserAction, LOG_USER } from "../../../actionReducers/User";
import { CAMPAIGN, MEDIUM, SOURCE } from "../../../boot/constants";
import CustomButton from "../../../components/Rebranding/CustomButton";
import { UserGender } from "../../../graphql/types/users";
import CPFValidation from "../../../helpers/CPFValidation";
import { googleTag } from "../../../helpers/analytics";
import MainLayout from "../../../layouts/Main";
import { create as UserApiCreate } from "../../../services/UserApi";
import { IAppState } from "../../../store";
import { validatorSignUpSchema } from "../../../validators/validatorSignUp";
import phones from "./../../../assets/img/SignUp/phones.png";
import SignUpForm from "./components/Form";
import { ContainerBackground } from "./styled";
import Config from "../../../config";
// import { useAnalytics } from "../../../hooks/useAnalytics";

export interface IFormDataSignUp {
  firstName: string;
  lastName: string;
  gender: string;
  email: string;
  phone: string;
  document: string;
  birthday: string;
  password: string;
  receivePromotionalEmail: boolean;
  captchaToken: string;
  captchaRef: any;
}

const initialValues: IFormDataSignUp = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  password: "",
  birthday: "",
  document: "",
  gender: "",
  receivePromotionalEmail: false,
  captchaToken: "",
  captchaRef: null
};

export interface ISignUpFormProps {
  loading: boolean;
  setLoading: ActionCreator<IGeneralAction>;
  logUser: ActionCreator<IUserAction>;
  setNewAccount: ActionCreator<ICheckoutAction>;
}

const NewSignUp = ({
  setLoading,
  logUser,
  setNewAccount,
  history,
  location,
  cookies,
  loading
}: ISignUpFormProps & ReactCookieProps & RouteComponentProps<{}>) => {
  const prevPath = "/";
  const qs = parse(location.search);
  // const analytics = useAnalytics();

  const captchaRef = useRef(null);

  initialValues.captchaRef = captchaRef;

  const redirect = (path?: string) => {
    if (qs.go) {
      return history.push(qs.go);
    }

    return history.push(path ? path : prevPath);
  };

  const handleSubmit = async (values: IFormDataSignUp) => {
    setLoading(true);

    values.captchaToken = values.captchaRef.current.getValue();
    values.captchaRef.current.reset();

    const gender = values.gender as UserGender;
    const phone = values.phone.replace(/\D+/g, "");
    const document = values.document.replace(/\D+/g, "");
    const birthday = moment(values.birthday).format("DD/MM/YYYY");
    const cleanBirthday = moment(
      moment(values.birthday, "YYYY-MM-DD")
    ).toDate();
    let utmSource = "";
    let utmMedium = "";
    let utmCampaign = "";

    let errorMessage = "";
    if (!Object.values(UserGender).includes(gender)) {
      errorMessage = "Gênero inválido, por favor verifique os dados.";
    } else if (document.length !== 11) {
      errorMessage = "Número de CPF incompleto, por favor verifique os dados.";
    } else if (!CPFValidation(document)) {
      errorMessage = "Número de CPF inválido, por favor verifique os dados.";
    } else if (!moment(cleanBirthday, "DD/MM/YYYY").isValid()) {
      errorMessage =
        "Data de nascimento inválida, por favor verifique os dados!";
    } else if (cleanBirthday > new Date()) {
      errorMessage =
        "Data de nascimento maior que hoje, por favor verifique os dados!";
    } else if (!values.captchaToken) {
      errorMessage =
        "Por favor, faça o captcha para completar seu cadastro no sistema.";
    }

    if (errorMessage) {
      setLoading(false);
      return toast.error(errorMessage);
    }

    if (cookies) {
      utmSource = cookies.get(SOURCE);
      utmMedium = cookies.get(MEDIUM);
      utmCampaign = cookies.get(CAMPAIGN);
    }

    try {
      const data = await UserApiCreate({
        ...values,
        gender,
        birthday,
        phone,
        document,
        utmSource,
        utmMedium,
        utmCampaign
      });

      setNewAccount(true);

      setLoading(false);

      if (data && data.token) {
        logUser(data.token);
        redirect();

        googleTag("page_view", {
          send_to: "AW-931911632",
          user_id: values.email
        });

        dataLayer.push(function(this: any) {
          this.reset();
        });
        dataLayer.push({
          event: "signup",
          userToken: data.token
        });
        // analytics.identify(data.token, {
        //   email: values.email
        // });
        // analytics.track("Sign Up");

        toast.success("Conta criada com sucesso.");
      } else return toast.error(data.message);
    } catch (e) {
      setLoading(false);

      const message = e.message
        ? e.message
        : "Não foi possível realizar seu cadastro neste momento. Tente novamente em alguns instantes.";
      return toast.error(message);
    }
  };

  return (
    <MainLayout searchPage={true}>
      <Grid container>
        <Grid
          item
          md={8}
          sx={{
            backgroundColor: "#27AE60",
            width: "100%",
            overflowY: "clip",
            marginTop: { xs: 5, md: 0 }
          }}
        >
          <Stack
            direction={{ xs: "row", md: "column" }}
            alignItems={{ xs: "center" }}
            sx={{
              padding: {
                xl: "120px 23% 80px 32%",
                lg: "120px 5% 80px 20%",
                md: "77px 4% 123px 14%",
                xs: "40px 9% 40px 5%"
              }
            }}
          >
            <Box sx={{ width: { sm: "100%", xs: "120%" }, zIndex: 0 }}>
              <Typography
                fontSize={{ lg: "65px", md: "49px", xs: "28px" }}
                fontFamily={"Denton Bold"}
                color={"#2D2D38"}
                lineHeight={{ lg: "87px", md: "65px", xs: "37px" }}
                fontWeight={700}
              >
                Se cadastrar
                <br />é fácil
                <span style={{ color: "#F4F4EF" }}>
                  . Difícil
                  <br />
                  vai ser escolher
                  <br />o modelo.
                </span>
              </Typography>
            </Box>
            <Box sx={{ width: "100%" }}>
              <ContainerBackground>
                <img
                  src={phones}
                  width={480}
                  height={841}
                  alt="Iphones"
                  className="phone"
                />
              </ContainerBackground>
            </Box>
          </Stack>
        </Grid>
        <Grid item md={4} sx={{ backgroundColor: "#F4F4EF", width: "100%" }}>
          <Box
            sx={{
              padding: {
                xl: "120px 30% 164px 18%",
                lg: "120px 20% 106px 10%",
                md: "120px 10% 120px 8%",
                xs: "48px 5% 72px 5%"
              }
            }}
          >
            <Formik
              initialValues={initialValues}
              onSubmit={(values: IFormDataSignUp) => handleSubmit(values)}
              validationSchema={validatorSignUpSchema}
              enableReinitialize={true}
            >
              {formikProps => (
                <Box>
                  <SignUpForm {...formikProps} />
                  <Box sx={{ paddingBottom: "24px" }}>
                    <Typography
                      fontFamily={"Chillax"}
                      variant={"caption"}
                      fontSize={"12px"}
                      color={"#2D2D38"}
                    >
                      Ao finalizar o pagamento, você estará de acordo com os
                      <Link
                        href={Config.termosDeUsoUrl}
                        target="_blank"
                        rel="noopener"
                        color={"#27AE60"}
                      >
                        &nbsp;Termos de Uso
                      </Link>
                      &nbsp; e
                      <Link
                        href={Config.avisoProtecaoDadosUrl}
                        target="_blank"
                        rel="noopener"
                      >
                        &nbsp;Aviso de Proteção de Dados
                      </Link>
                      &nbsp; da Allugator relativos à assinatura.
                    </Typography>
                  </Box>
                  <CustomButton
                    title={"cadastrar agora"}
                    style={{ width: "100%" }}
                    onClick={() => formikProps.handleSubmit()}
                    loading={loading}
                  />
                </Box>
              )}
            </Formik>
          </Box>
        </Grid>
      </Grid>
    </MainLayout>
  );
};

export default withRouter(
  connect(
    (state: IAppState) => ({
      loading: state.general.loading
    }),
    (dispatch: Dispatch<AnyAction>) => ({
      setLoading: (loadingState: boolean) =>
        dispatch(SET_LOADING(loadingState)),
      logUser: (token: string) => dispatch(LOG_USER(token)),
      setNewAccount: (newAccount: boolean) =>
        dispatch(SET_NEW_ACCOUNT(newAccount))
    })
  )(withCookies(NewSignUp))
);
