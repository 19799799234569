import { Box } from '@mui/system';
import { GridColDef } from '@mui/x-data-grid';
import moment from 'moment';
import React from 'react';
import { IExpectedPayments } from '../../../../graphql/types/checkoutOrders';
import toReal from '../../../../helpers/toReal';
import CustomTable from '../../../CustomTable';
import ContentEmpty from '../../../CustomTable/ContentEmpty';

interface IProps {
    payments: IExpectedPayments[];
    amountMonth: number;
}

const NextPayments = ({ payments, amountMonth }: IProps) => {

    const hideColumn = () => {
        if (window.innerWidth < 600) {
            return true;
        }
        return false;
    };

    const formatData = () => {
        return payments.map(item => {
            if (!item.expectedPayment) {
                return {
                    id: item.index,
                    parcel: item.payment && item.payment.type === 'entry' ? 'Entrada' : `${item.index} de 12`,
                    period: `${moment(item.start).format('DD/MM/YYYY')} até ${moment(item.end).format('DD/MM/YYYY')}`,
                    dueDate: moment(item.start).format('DD/MM/YYYY'),
                    status: item.expectedPayment && item.payment ? 'paid' : 'unpaid',
                    amount: toReal(amountMonth)
                }
            }
        }).filter(elem => elem);
    };


    const columns: GridColDef[] = [
        { field: 'id', hide: true, sortable: false, },
        { field: 'parcel', headerName: 'Parcela', sortable: false, flex: 1 },
        { field: 'period', headerName: 'Período', sortable: false, flex: 1, hide: hideColumn() },
        { field: 'dueDate', headerName: 'Vencimento', sortable: false, flex: 1 },
        { field: 'amount', headerName: 'Valor', sortable: false, flex: 1 },
    ];


    return (
        <Box sx={{ maxHeight: '496px', overflow: 'auto' }}>
            <CustomTable columns={columns} rows={formatData()} autoHeight={true} ContentEmpty={ContentEmpty}/>
        </Box>
    )


};

export default NextPayments;