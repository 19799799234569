import { Box, Button, CircularProgress, Stack, Typography } from '@mui/material';
import { GraphQLError } from 'graphql';
import React, { SetStateAction, useEffect, useState } from 'react';
import { WithApolloClient, withApollo } from 'react-apollo';
import { connect } from 'react-redux';
import { toast } from "react-toastify";
import { ActionCreator, AnyAction, Dispatch } from 'redux';

import { ICartAction, UPDATED_CARD } from '../../../actionReducers/Cart';
import { CLEAN_PROFILE, IProfileAction } from "../../../actionReducers/Profile";
import {
  IMutationRemoveCardPagarme,
  MUTATION_REMOVE_CARD_PAGARME,
} from '../../../graphql/mutations/users';
import { IUserCards } from '../../../graphql/types/users';
import { ProfileAddressBox } from '../components/styled';

interface IProfileCardsProps {
  removeSelectedCard: ActionCreator<ICartAction>;
  cleanProfile: ActionCreator<IProfileAction>;
  setShowFormCards: React.Dispatch<SetStateAction<boolean>>;
  card: IUserCards;
  refetch: (...args: any[]) => any;
}

const ProfileCards = (props: IProfileCardsProps & WithApolloClient<{}>) => {
  const { card, cleanProfile } = props;
  
  const [loading, setLoading] = useState(false);
  
  useEffect(() => {
    cleanProfile();
  }, []);

  // const setDefaultCardMP = async (cardId: string) => {
  //   const { client, refetch } = props;
  //   setLoading(true);

  //   try{
  //     await client.mutate<IMutationSetDefaultCardMP>({
  //       mutation: MUTATION_SET_DEFAULT_CARD,
  //       variables: { cardId }
  //     });
  //   } catch (e) {
  //     e.graphQLErrors.map((error: GraphQLError) => toast.error(error.message));
  //   }

  //   setLoading(false);
  //   await refetch();
  // }

  const removeCardPagarme = async (cardId: string) => {
    const { client, refetch } = props;
    setLoading(true);
    
    try{
      await client.mutate<IMutationRemoveCardPagarme>({
        mutation: MUTATION_REMOVE_CARD_PAGARME,
        variables: { cardId }
      });
    } catch (e) {
      e.graphQLErrors.map((error: GraphQLError) => toast.error(error.message));
    }

    setLoading(false);
    await refetch();
  }

  return (
    <>
      <ProfileAddressBox key={`cardBox-${card.id}`}>
        <Stack spacing={'28px'} sx={{ width: '100%', padding: '16px' }}>
          <Box>
            <Typography variant='h5'>{card.holderName}</Typography>
            <Typography variant='body2' color={'rgba(0, 0, 0, 0.6)'}>{'XXXX XXXX XXXX ' + card.lastDigits}</Typography>
            {
              card.expirationDate
                ? <Typography variant='body2' color={'rgba(0, 0, 0, 0.6)'}>{'Validade: ' + card.expirationDate}</Typography>
                : null
            }
          </Box>
          <Stack direction={'row'} spacing={'18px'}>
            {/* {
            card.isDefault
            ? <Button variant='text' disabled aria-disabled startIcon={<Check />}>Cartão Principal</Button>
            : <Button variant='outlined' color='info' onClick={() => setDefaultCardMP(card.id)} disabled={loading}>
                {loading ? <CircularProgress size={20} sx={{ color: '#D32F2F' }} /> : 'Tornar Principal'}
              </Button>
            } */}
            <Button
              variant='outlined'
              color='error'
              onClick={() => removeCardPagarme(card.id)}
              disabled={loading}
            >
              {loading ? <CircularProgress size={20} sx={{ color: '#D32F2F' }} /> : 'Excluir'}
            </Button>
          </Stack>
        </Stack>
      </ProfileAddressBox>
    </>
  );
}

export default connect(null, (dispatch: Dispatch<AnyAction>) => ({
  removeSelectedCard: () => dispatch(UPDATED_CARD()),
  cleanProfile: () => dispatch(CLEAN_PROFILE()),
}))(withApollo(ProfileCards));
