import React from 'react';
import CustomButton from '../../../components/Rebranding/CustomButton';
import { Container } from './styles';

interface DocumentInfosProps {
  onStart: () => void;
}

export default function DocumentInfos(props: DocumentInfosProps) {
  return (
    <Container>
      <div id="doc-info-header">
        <h1>Envio de documentos</h1>
        <h2>Chegou o momento! Antes de iniciar, que tal já ir separando os documentos?</h2>
      </div>

      <div id="doc-info-footer">
        <p>
        Ao clicar em 'vamos começar' você será direcionado para o nosso parceiro Clear Sale para fazer o processo de envio de documentação e selfie!
        </p>

        <CustomButton title="vamos começar" onClick={props.onStart} />
      </div>
    </Container>
  )
}