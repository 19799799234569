import * as React from 'react';
import { connect } from 'react-redux';

import { IAppState } from '../../store';
import { SizedTopProgressBarSnake, TopProgressBarSnake } from './styled';

class RawTopProgressBar extends React.Component<{ loadingState: boolean }> {
  public render() {
    if (this.props.loadingState) {
      return <TopProgressBarSnake />;
    }

    return null;
  }
}

export const TopProgressBar = connect((state: IAppState) => ({
  loadingState: state.general.loading,
}))(RawTopProgressBar);

class RawSizedTopProgressBar extends React.Component<{ loadingState: boolean }> {
  public render() {
    if (this.props.loadingState) {
      return <SizedTopProgressBarSnake />;
    }

    return null;
  }
}

export const SizedTopProgressBar = connect((state: IAppState) => ({
  loadingState: state.general.loading,
}))(RawSizedTopProgressBar);
