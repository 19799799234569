import { MonetizationOnOutlined } from '@mui/icons-material';
import { Alert, Box, Checkbox, FormControlLabel, Grid, Icon, Stack, Typography } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import { ActionCreator, Dispatch } from 'redux';
import { IDynamicKeyCheckout, PaymentType } from '../../../../actionReducers/Checkout';
import { ICheckoutRecurrenceAction, SET_TYPE_SINGLE_CARD, ICreditCardRecurrence, SET_CARD_MONTHLY, IPayment } from '../../../../actionReducers/Recurrence';
import { IAppState } from '../../../../store';
import FormCard from '../../../../components/FormCard';
import ValidatorCreditCard from '../../../../validators/validatorCreditCard';
import { Formik } from 'formik';
import toReal from '../../../../helpers/toReal';
import { ISubscriptionPayment } from '../../../../graphql/types/checkoutOrders';

interface IProps {
    singleCard: boolean;
    paymentEntry: IPayment;
    paymentRecurence: IPayment;
    subscriptionPayment: ISubscriptionPayment;
    setSingleCard: ActionCreator<ICheckoutRecurrenceAction>;
    setCardMonthly: ActionCreator<ICheckoutRecurrenceAction>;
    handleBlurFields: (field: string, value: string | number, errors: any) => void;
}

const initialValues: ICreditCardRecurrence = {
    number: '',
    holderName: '',
    expirationDate: '',
    cvv: '',
}

const RecurrencePayment = ({
    singleCard,
    paymentEntry: { type },
    paymentRecurence: { amount },
    subscriptionPayment: { entryFeeValue },
    setSingleCard,
    handleBlurFields,
    setCardMonthly }: IProps) => {

    const handleBlurForm = (field: string, value: string | number, errors: any) => {
        const data = handleBlurFields(field, value, errors);
        setCardMonthly(data)
    };

    const handleChangeCvv = (value: string, errors: any) => {
        const cardNumberElement = document.getElementById('card-number') as HTMLInputElement;
        const holderNameElement = document.getElementById('card-holder-name') as HTMLInputElement;
        const expirationDateElement = document.getElementById('card-expiration-date') as HTMLInputElement;
        
        setCardMonthly({ number: cardNumberElement?.value.replace(/\s/g, '') })
        setCardMonthly({ holderName: holderNameElement?.value })
        setCardMonthly({ expirationDate: expirationDateElement?.value.replace('/', '') })

        if (value && value.length >= 3) {
            setCardMonthly({ cvv: value, errors: Object.keys(errors).length === 1 && errors.cvv ? false : true });
        } else {
            setCardMonthly({ cvv: value, errors: true });
        }
    };

    return (
        <Grid item sm={12} width={'100%'}>
            <Stack spacing={'24px'}>
                <Box display={'flex'} justifyContent={'space-between'}>
                    <Grid display={'flex'}>
                        <Icon color={'action'}>
                            <MonetizationOnOutlined />
                        </Icon>
                        <Typography
                            variant="body1"
                            fontSize={'17px'}
                            marginLeft={'10px'}
                            color={'#30313F'}
                        >
                            PAGAMENTO MENSAL
                        </Typography>
                    </Grid>
                </Box>
                <Typography
                    variant="body1"
                    fontSize={'20px'}
                    marginLeft={'10px'}
                    color={'#30313F'}
                >
                    Valor: {toReal(amount)}/mês
                </Typography>
                <Typography
                    variant='subtitle1'
                    fontSize={'15px'}
                >
                    As mensalidades serão pagas via cartão de crédito, sem ocupar o seu limite. A cobrança mensal começa a valer no dia da entrega do aparelho.
                </Typography>
                <Alert severity="info" sx={{ color: '#30313F' }}>Este cartão deve estar em seu nome.</Alert>
                <Stack>
                    {
                        type === PaymentType.CREDIT_CARD
                            && entryFeeValue
                            ? (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            defaultChecked
                                            value={singleCard}
                                            checked={singleCard}
                                            onChange={() => setSingleCard(!singleCard)}
                                        />
                                    }
                                    label={
                                        <Typography variant="caption" fontSize={'13px'} color={'#666666'}>
                                            O cartão de crédito é o mesmo do pagamento da entrada
                                        </Typography>
                                    }
                                />
                            ) : null
                    }
                    {
                        !singleCard || type === PaymentType.BOLETO ? (
                            <Formik
                                initialValues={initialValues}
                                onSubmit={() => { }}
                                validateOnChange={true}
                                validationSchema={ValidatorCreditCard}>
                                {(formikProps) => (
                                    <FormCard
                                        {...formikProps}
                                        handleBlurFields={(field: string, value: string | number, errors: any) => handleBlurForm(field, value, errors)}
                                        handleChangeCvv={(value: string, errors: any) => handleChangeCvv(value, errors)}
                                    />
                                )}
                            </Formik>
                        ) : null
                    }
                </Stack>
            </Stack>
        </Grid>
    )
};

export default connect((state: IAppState) => ({
    singleCard: state.checkoutRecurrence.singleCard,
    paymentEntry: state.checkoutRecurrence.paymentEntry,
    paymentRecurence: state.checkoutRecurrence.paymentRecurrence,
    subscriptionPayment: state.checkoutRecurrence.order.subscriptionPayment,
}), (dispatch: Dispatch<ICheckoutRecurrenceAction>) => ({
    setSingleCard: (value: boolean) => dispatch(SET_TYPE_SINGLE_CARD(value)),
    setCardMonthly: (card: IDynamicKeyCheckout) => dispatch(SET_CARD_MONTHLY(card))
})
)(RecurrencePayment);