import { Formik } from "formik";
import { GraphQLError } from "graphql";
import { parse } from "query-string";
import React, { useEffect, useRef, useState } from "react";
import { MutationFn, WithApolloClient, withApollo } from "react-apollo";
import ReCAPTCHA from "react-google-recaptcha";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { ActionCreator, AnyAction, Dispatch } from "redux";
import {
  ICartAction,
  REMOVE_COUPON_FROM_CART
} from "../../actionReducers/Cart";
import { IGeneralAction, SET_LOADING } from "../../actionReducers/General";
import { IUserAction, LOG_USER } from "../../actionReducers/User";
import endIcon from "../../assets/img/Frame.svg";
import greenArrow from "../../assets/img/Home/greenarrow.png";
import InputText from "../../components/Inputs/InputText";
import CustomButton from "../../components/Rebranding/CustomButton";
import Config from "../../config";
import {
  IMutationUserLogin,
  MUTATION_USER_LOGIN
} from "../../graphql/mutations/users";
import {
  IQueryCurrentUser,
  QUERY_CURRENT_USER
} from "../../graphql/queries/users";
import { googleTag } from "../../helpers/analytics";
import AuthLayout from "../../layouts/Auth";
import { IAppState } from "../../store";
import { LoginFormSchema } from "../Auth/Login/LoginValidation";
import Footer from "../HomeV2/components/Footer";
import NavBar from "../HomeV2/components/NavBar";
import * as S from "./style";
// import { useAnalytics } from "../../hooks/useAnalytics";

interface ILoginUser {
  email: string;
  password: string;
  captchaToken: string;
}
const initialValues: ILoginUser = {
  email: "",
  password: "",
  captchaToken: ""
};
export interface ILoginFormProps
  extends WithApolloClient<{}>,
    RouteComponentProps<{}> {
  setLoading: ActionCreator<IGeneralAction>;
  logUser: ActionCreator<IUserAction>;
  submit: MutationFn<IMutationUserLogin>;
  removeCoupon: ActionCreator<ICartAction>;
  loading: boolean;
}

const NewLogin = ({
  client,
  logUser,
  removeCoupon,
  setLoading,
  loading,
  history
}: ILoginFormProps) => {
  const prevPath = "/";
  const qs = parse(location.search);
  // const analytics = useAnalytics();

  const captchaRef = useRef(null);

  const handleSubmit = async (values: ILoginUser) => {
    try {
      setLoading(true);

      values.captchaToken = captchaRef.current.getValue();
      captchaRef.current.reset();

      if (!values.captchaToken) {
        setLoading(false);
        return toast.error("Por favor, faça o captcha para logar no sistema.");
      }

      const data = await client.mutate<IMutationUserLogin>({
        mutation: MUTATION_USER_LOGIN,
        variables: values
      });

      if (data) {
        const token = data.data.login;
        logUser(token);

        googleTag("page_view", {
          send_to: "AW-931911632",
          user_id: values.email
        });
        const queryCurrentUser = await client.query<IQueryCurrentUser>({
          query: QUERY_CURRENT_USER,
          fetchPolicy: "no-cache"
        });
        dataLayer.push(function(this: any) {
          this.reset();
        });
        dataLayer.push({
          event: "login",
          userId: queryCurrentUser.data.currentUser.id
        });
        // analytics.identify(queryCurrentUser.data.currentUser.id, {
        //   email: values.email
        // });
        // analytics.track("Login");

        redirect();
        toast.success("Login realizado com sucesso.");

        removeCoupon();
      }
    } catch (e) {
      e.graphQLErrors.map((error: GraphQLError) => toast.error(error.message));
    }

    setLoading(false);
  };

  const redirect = (path?: string) => {
    if (qs.go && !path) {
      return history.push(qs.go);
    } else if (qs.go) {
      return history.push(`/cadastrar?go=${qs.go}`);
    }

    return history.push(path ? path : prevPath);
  };

  const [isMobile, setIsMobile] = useState<boolean>(() => {
    let sizeScreen: number = window.screen.width;
    if (sizeScreen < 1250) {
      return true;
    } else {
      return false;
    }
  });

  useEffect(() => {
    window.addEventListener(
      "resize",
      function() {
        if (window.innerWidth <= 1250) {
          setIsMobile(true);
        } else {
          setIsMobile(false);
        }
      },
      { passive: true }
    );
  }, []);

  return (
    <AuthLayout>
      <S.MainContainer>
        <NavBar />
        <S.SecondContainer>
          <S.ThirdContainer>
            <h2>Hey! Faça o seu login</h2>
            <Formik
              initialValues={initialValues}
              onSubmit={values => handleSubmit(values)}
              validationSchema={LoginFormSchema}
            >
              {({ values, handleChange, handleSubmit, touched, errors }) => (
                <>
                  <div className="form-data">
                    <InputText
                      className="login-ipt"
                      type="email"
                      label="Endereço de email"
                      value={values.email}
                      setValue={handleChange("email")}
                      name="email"
                      touched={touched.email}
                      errorMessage={errors.email}
                    />
                    <InputText
                      className="password-ipt"
                      type="password"
                      label="Senha"
                      value={values.password}
                      setValue={handleChange("password")}
                      name="password"
                      touched={touched.password}
                      submitByEnter={() => handleSubmit()}
                      errorMessage={errors.password}
                    />
                  </div>
                  <div>
                    <ReCAPTCHA
                      sitekey={Config.GoogleRecaptchaSiteKey}
                      ref={captchaRef}
                    />
                  </div>
                  <div>
                    {!isMobile ? (
                      <CustomButton
                        title="Entrar"
                        endIcon={<img src={endIcon} />}
                        onClick={() => handleSubmit()}
                        loading={loading}
                        style={{
                          backgroundColor: "#0099DE",
                          color: "#F4F4EF",
                          width: "250px"
                        }}
                      />
                    ) : (
                      <CustomButton
                        title="Entrar"
                        endIcon={<img src={endIcon} />}
                        onClick={() => handleSubmit()}
                        loading={loading}
                        style={{
                          backgroundColor: "#0099DE",
                          color: "#F4F4EF",
                          width: "333px"
                        }}
                      />
                    )}
                  </div>
                </>
              )}
            </Formik>
            <S.FourthContainer>
              <a
                onClick={() => {
                  history.push("/esqueci-minha-senha");
                }}
                style={{ cursor: "pointer" }}
              >
                Esqueci minha senha
              </a>
              <p>
                Ao entrar, você concorda com os{" "}
                <a
                  className="word-color"
                  href={Config.termosDeUsoUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Termos de Uso
                </a>{" "}
                e a{" "}
                <a
                  className="word-color"
                  href={Config.avisoProtecaoDadosUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Aviso de Proteção de Dados
                </a>{" "}
                da allu, que contém todas as informações significativas sobre a
                sua assinatura.
              </p>
            </S.FourthContainer>
          </S.ThirdContainer>
          <S.FifthContainer>
            <p className="allu-lover">
              Você ainda
              <br />
              não é um allu.lover?
            </p>
            <p className="allu-cadastro">
              Faça agora o seu
              <br />
              cadastro, é rapidinho.
            </p>
            <CustomButton
              title="quero ser allu"
              endIcon={<img width="28px" height="28px" src={greenArrow} />}
              style={{
                backgroundColor: "#2D2D38",
                color: "#27AE60",
                width: "250px",
                height: "42px",
                fontSize: "23px",
                lineHeight: "31px",
                padding: "0px"
              }}
              onClick={() => redirect("/cadastrar")}
            />
          </S.FifthContainer>
        </S.SecondContainer>
        <Footer />
      </S.MainContainer>
    </AuthLayout>
  );
};

export default withRouter(
  connect(
    (state: IAppState) => ({
      logged: state.user.logged
    }),
    (dispatch: Dispatch<AnyAction>) => ({
      setLoading: (loadingState: boolean) =>
        dispatch(SET_LOADING(loadingState)),
      logUser: (token: string) => dispatch(LOG_USER(token)),
      removeCoupon: () => dispatch(REMOVE_COUPON_FROM_CART())
    })
  )(withApollo(NewLogin))
);
