import axios from "axios";
import { useEffect, useReducer } from "react";
import { toast } from "react-toastify";
import risk from "../services/risk";

export enum DocumentsOptions {
  // para essa opção teremos o description q é o conteúdo do email enviado
  OTHERS = 'Outros',
  PERSONAL_DOCUMENTS = 'Documentos pessoais',
  SELFIE = 'Selfie',
  PROOF_ADDRESS = 'Comprovante de residência',
  RESPONSIBLE_CARD = 'Documentos - Responsável pelo cartão',
  RESPONSIBLE_VOUCHER = 'Documentos - Responsável pelo comprovante ',
  DOCUMENT_DISCREPANCY = 'Divergência de documento',
  ADDRESS_DIVERGENCE = 'Divergência de endereço',
  '2ND_SUBSCRIBER' = '2º assinante',
}

export type Document = {
  id: string;
  document: DocumentsOptions;
  description: string | null;
};

export type Customer = {
  name: string;
  email: string;
  phone: string;
  document: string;
  birthDate: string;
  mongodbReference: string;
};

export enum AnalysisStatus {
  PENDING_DOCUMENTATION = 'pending_documentation',
  RESCUE_ANALYSIS = 'rescue_analysis',
  ULTIMATUM_RESCUE = 'ultimatum_rescue',
  APPROVED = 'approved',
  PENDING_ANALYSIS = 'pending_analysis',
  DISAPPROVED = 'disapproved',
  FRAUD = 'fraud',
  CANCELED = 'canceled',
  NEW_REQUEST = 'new_request',
  IN_RECAP = 'in_recap',
  ULTIMATUM = 'ultimatum',
  TERM_OF_RESPONSIBILITY_PENDING = 'term_of_responsibility_pending',
}

export type Analysis = {
  id: string;
  lastUpdate: string;
  status: AnalysisStatus;
  customer: Customer;
  documents: Array<Document>
}

function reducer(state: Analysis, action: any) {
  switch (action.type) {
    case 'set_analysis':
      return action.payload;
    default:
      return state;
  }
}

export default function useLastAnalysis(userId?: string) {
  const [state, dispatch] = useReducer(reducer, {});

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    async function findLastAnalysisByCustomerId(id: string) {
      try {
        if (Object.keys(state).length > 0) {
          return;
        }

        const analysis = await risk.findLastAnalysis({ id, cancelToken: source.token })

        dispatch({ type: 'set_analysis', payload: analysis });

      } catch (e) {
        if (e.response.status != 404) {
          toast.error('Não foi possível recuperar os dados.');
        }
      }
    }

    if (userId) {
      findLastAnalysisByCustomerId(userId);
    }

    return () => {
      source.cancel('canceled');
    }
  }, [userId]);

  return state;
}