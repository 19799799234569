import * as React from 'react';
import { withApollo, WithApolloClient } from 'react-apollo';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IAppState } from '../../store';

type MainLayoutProps = {
    internal?: boolean;
    darkMode?: boolean;
    searchPage?: boolean;
    hideSearch?: boolean;
    logged: boolean;
    cartItems: number;
} & RouteComponentProps<{}>;

const MainLayoutHome: React.FC<WithApolloClient<MainLayoutProps>> = ({
    children,
}) => {

    return (
        <>
            {/* <Query query={QUERY_BANNER}>
                {({ data, error, loading }: QueryResult<IQueryBanner>) => {
                    if (!loading && !error && data && data.bannerValues && data.bannerValues.banner) {
                        return (
                            <ContentBannerHome>
                                <LinkBar text={data.bannerValues.bannerText} />
                            </ContentBannerHome>
                        )
                    }
                    return null;
                }}
            </Query> */}
            {children}
        </>
    );
};

const matStateToProps = (state: IAppState) => ({
    logged: state.user.logged,
    cartItems: state.cart.items.length,
});

export default connect(matStateToProps)(withRouter(withApollo(MainLayoutHome)));
