import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import { IAddress } from '../form';

const SummaryAddress = ({
  city,
  code,
  street,
  complement,
  neighborhood,
  number,
  state,
}: IAddress) => {
  return (
    <Box>
      <Stack>
        <Typography variant="caption" fontSize={13} color="#666">
          {street},&nbsp;{number}
        </Typography>

        <Typography variant="caption" fontSize={13} color="#666">
          {complement}
        </Typography>

        <Typography variant="caption" fontSize={13} color="#666">
          {neighborhood}{', '}{city}
        </Typography>

        <Typography variant="caption" fontSize={13} color="#666">
          {state}
        </Typography>
        
        <Typography variant="caption" fontSize={13} color="#666">
          {code}
        </Typography>
      </Stack>
    </Box>
  );
};

export default SummaryAddress;
