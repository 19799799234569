import { UserGender } from "../graphql/types/users";

const listGenders = [
    { value: '', title: '' },
    { value: UserGender.FEMALE, title: 'Feminino' },
    { value: UserGender.MALE, title: 'Masculino' },
    { value: UserGender.NOT_BINARY, title: 'Não-binário' },
    { value: UserGender.NOT_ANSWER, title: 'Prefiro não responder' },
    { value: UserGender.OTHER, title: 'Outro' }
];

export default listGenders;