import gql from 'graphql-tag';
import { ICampaign } from '../types/campaign';

export const QUERY_VERIFY_CAMPAIGN = gql`
  query verifyCampaign (
    $id: ID!,
    $product: String!,
    $sellerId: String,
    $coupon: String,
  ){
    verifyCampaign (
        id: $id,
        product: $product,
        sellerId: $sellerId,
        coupon: $coupon
    ){
      id
      shortId
      origin
      paymentModeAvailability
      defaultPaymentMode
      product {
        _id
        name
        slug
        photos
        description
        technicalDetails
        quantity
        delivery
        fakePrice
        createdAt
        priority
        marketPrice
        uniquePeriod
        brand
        launchDate
        deadline
        prices{
          signatureLite
        }
        category {
          _id
          name
        }
        subcategory {
          _id
          name
          game
        }
        quarterlyRate
        semesterRate
      }
      coupon {
        slug
        amount
        type
        lite
        billet
        flags {
          memberGetMember
          disabled
        }
      }
    }
  }
`;

export interface IQueryVerifyCampaign {
  verifyCampaign: ICampaign;
}