import React from 'react';
import { Stack, Typography } from '@mui/material';
import { IAddress } from '../../../Address/form';

const AddressInformation = ({
    city,
    street,
    complement,
    neighborhood,
    number,
    state,
}: IAddress) => {
    return (
        <Stack spacing={'12px'}>
            <Typography fontFamily={'Chillax'} fontSize={21} fontWeight={500} color={'#007D34'} >
                Informações de entrega
            </Typography>
            <Stack spacing={'4px'} sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
                <Typography fontFamily={'Chillax'} fontSize={16} fontWeight={400}>
                    {street}, {number}, {neighborhood}
                </Typography>
                <Typography fontFamily={'Chillax'} fontSize={16} fontWeight={400}>
                    {complement}
                </Typography>
                <Typography fontFamily={'Chillax'} fontSize={16} fontWeight={400}>
                    {city}, {state}
                </Typography>
            </Stack>
        </Stack>
    );
};

export default AddressInformation;
