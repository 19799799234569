import * as yup from 'yup';
import getAddressByCEP from '../services/getByCep';

export const validatorAddressSchema = yup.object().shape({
    code: yup
        .string()
        .nullable(true)
        .required('Necessário informar CEP')
        .min(8, 'Formato inválido para CEP')
        .max(8, 'Formato inválido para CEP')
        ,
    street: yup.string().nullable(true).required('Necessário informar endereço'),
    number: yup.string().nullable(true).required('Necessário informar número de residência'),
    neighborhood: yup.string().nullable(true).required('Necessário informar bairro'),
    city: yup.string().nullable(true).required('Necessário informar cidade'),
    state: yup
        .string()
        .nullable(true)
        .required('Necessário informar estado')
        .min(2, 'Estado inválido')
        .max(2, 'Estado inválido'),
}).test('Test code', 'CEP inválido', async (values) => {
    if (!values || !values.code || values.code.length < 8) return true;
    const result = await getAddressByCEP(values.code);
    if (result) return true;
    return false;
});
