import React from "react";
import { Stack, Typography } from "@mui/material";
import { IAddress } from "../../../Address/form";

const AddressInformation = ({
  city,
  street,
  complement,
  neighborhood,
  number,
  state
}: IAddress) => {
  return (
    <Stack spacing={"12px"}>
      <Typography
        sx={{
          fontFamily: "Lato",
          fontWeight: "600",
          fontSize: "17px",
          lineHeight: "23.35px",
          letterSpacing: "0.15px",
          color: "#000000"
        }}
      >
        Informações de entrega
      </Typography>
      <Stack
        spacing={"4px"}
        sx={{
          fontFamily: "Lato",
          fontWeight: "500",
          fontSize: "15px",
          lineHeight: "23.35px",
          letterSpacing: "0.1px",
          color: "#000000",
          opacity: "60%"
        }}
      >
        <Typography
          sx={{
            fontFamily: "Lato",
            fontWeight: "500",
            fontSize: "15px",
            lineHeight: "23.35px",
            letterSpacing: "0.1px",
            color: "#000000",
            opacity: "60%"
          }}
        >
          {street}, {number}, {neighborhood}
        </Typography>
        <Typography
          sx={{
            fontFamily: "Lato",
            fontWeight: "500",
            fontSize: "15px",
            lineHeight: "23.35px",
            letterSpacing: "0.1px",
            color: "#000000",
            opacity: "60%"
          }}
        >
          {complement}
        </Typography>
        <Typography
          sx={{
            fontFamily: "Lato",
            fontWeight: "500",
            fontSize: "15px",
            lineHeight: "23.35px",
            letterSpacing: "0.1px",
            color: "#000000",
            opacity: "60%"
          }}
        >
          {city}, {state}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default AddressInformation;
