import * as React from 'react';
import { Query, QueryResult, withApollo, WithApolloClient } from 'react-apollo';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IMutationEndOfSession, MUTATION_END_OF_SESSION } from '../../graphql/mutations/users';
import { IQueryCurrentUser, QUERY_CURRENT_USER } from '../../graphql/queries/users';
import { acSiteTracking } from '../../helpers/analytics';
import Footer from '../../routes/HomeV2/components/Footer';
import { IAppState } from '../../store';
// import Header from "./components/NewHeader";
import NewNavBar from "./../../routes/HomeV2/components/NewNavbar";
import { MainLayoutWrapper } from './styled';

type MainLayoutProps = {
  internal?: boolean;
  darkMode?: boolean;
  searchPage?: boolean;
  hideSearch?: boolean;
  logged: boolean;
  cartItems: number;
} & RouteComponentProps<{}>;

const TWO_MINUTES_IN_MILLISECONDS = 120000;

const MainLayout: React.FC<WithApolloClient<MainLayoutProps>> = ({
  children,
  logged,
  cartItems,
  client,
  history
}) => {
  // const [firstName, setFirstName] = React.useState('');

  React.useEffect(() => {
    window.scrollTo(0, 0);
    const intervalID = setInterval(async () => {
      if (logged) {
        const { data: { endOfSession } } = await client.mutate<IMutationEndOfSession>({
          mutation: MUTATION_END_OF_SESSION,
          variables: { cartItems },
        });

        console.log('End of Session Result', endOfSession);
      }
    }, TWO_MINUTES_IN_MILLISECONDS);

    return () => {
      clearInterval(intervalID);
    };
  }, [cartItems]);

  const redirect = (path?: string) => {
    return window.location.href = path;
  };

  return (
    <MainLayoutWrapper>
      <Query query={QUERY_CURRENT_USER}>
        {({ data, error, loading }: QueryResult<IQueryCurrentUser>) => {
          if (!loading && !error && data && data.currentUser) {
            acSiteTracking('setEmail', data.currentUser.email);
            // setFirstName(data.currentUser.personalData.firstName);
          }
          return null;
        }}
      </Query>

      {history && history.location ? (
        <>
          {/* {!history.location.pathname.includes('finalizar-') && (
            <Query query={QUERY_BANNER}>
              {({ data, error, loading }: QueryResult<IQueryBanner>) => {
                if (!loading && !error && data && data.bannerValues && data.bannerValues.banner) {
                  return <LinkBar text={data.bannerValues.bannerText} />;
                }
                return null;
              }}
            </Query>
          )} */}

          {history.location.pathname !== '/finalizar-aluguel' && <NewNavBar redirect={redirect} />}
        </>
      ) : null}

      {children}

      {history && history.location && (history.location.pathname !== '/finalizar-aluguel' && history.location.pathname !== '/cadastrar') ? (
        <Footer />
      ) : null}

    </MainLayoutWrapper>
  );
};

const matStateToProps = (state: IAppState) => ({
  logged: state.user.logged,
  cartItems: state.cart.items.length,
});

export default connect(matStateToProps)(withRouter(withApollo(MainLayout)));
