import Close from '@mui/icons-material/Close';
import Help from '@mui/icons-material/Help';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../../assets/img/DocumentsV2/allu_logo_black.svg';
import { Container, DropdownArea } from './styles';

const StyledButton = styled(Button)({
  textTransform: 'capitalize',
  fontWeight: 500,
  fontSize: '1rem',
  fontFamily: 'Roboto',
  '&:hover': {
    backgroundColor: 'transparent',
  }
});

const Sections = [
  () => (
    <div>
      <h4>O que preciso enviar?</h4>
      <p>
        Os documentos necessários são:
      </p>
      <ul>
        <li>Um documento de identificação que seja CNH, RG ou RNE (para CNH e RG a validade necessária é de até 10 anos, e para RNE é de 5 anos).</li>
        <li>Uma foto sua segurando o documento de identificação escolhido.</li>
        <li>Um comprovante de residência com emissão de até 3 meses e que seja: conta de água, luz, telefone, internet, contrato de aluguel, conta de gás ou IPTU.</li>
      </ul>
    </div>
  ),
  () => (
    <div>
      <h4>Quando preciso enviar documentos de terceiros?</h4>
      <p>
        Caso você tenha feito o pagamento com o cartão de crédito de uma outra pessoa ou enviou um comprovante de residência em nome de um terceiro, é preciso enviar o documento dessas pessoas também.
      </p>
      <p>
        Será necessário enviar:
      </p>
      <ul>
        <li>Uma foto da CNH, RG ou RNE do responsável pelo cartão ou comprovante de residência.</li>
      </ul>
    </div>
  ),
  () => (
    <div>
      <h4>Quanto tempo tenho para enviar todos os documentos?</h4>
      <p>
        O prazo para envio de todos documentos é de 2 dias úteis após o recebimento do pagamento.
      </p>
    </div>
  ),
  () => (
    <div>
      <h4>Se eu tiver outras dúvidas como posso entrar em contato?</h4>
      <p>
        Você pode falar com um de nossos atendente através do nosso chat, clicando no ícone do balão, no canto inferior da tela.

        Nossos horários de funcionamento são de segunda a sexta, das 09hrs às 18hrs, horário de Brasília.
      </p>
    </div>
  ),
]

export default function Header() {
  const [showDropdown, setShowDropdown] = useState(false);
  const [sectionToShow, setSectionToShow] = useState(0);

  const Section = useMemo(() => Sections[sectionToShow], [sectionToShow]);

  const sectionHandler = (current: number) => () => {
    setSectionToShow(current);
  }

  return (
    <Container>
      <Link to="/">
        <img src={logo} alt="Allugator" width={60} />
      </Link>

      <DropdownArea>
        <StyledButton
          color='info'
          startIcon={<Help />}
          size='medium'
          disableTouchRipple={true}
          onClick={() => setShowDropdown(!showDropdown)}
        >
          Ajuda
        </StyledButton>

        <div id='help-dropdown' className={showDropdown ? 'open' : 'close'}>
          <Section />

          <div id='dropdown-menu'>
            <button onClick={() => setShowDropdown(false)}>
              <Close color="action" />
            </button>

            <h4>Dúvidas Frequentes</h4>

            <ul>
              <li className={sectionToShow === 0 ? 'active' : 'deactive'} onClick={sectionHandler(0)}>
                O que preciso enviar?
              </li>
              <li className={sectionToShow === 1 ? 'active' : 'deactive'} onClick={sectionHandler(1)}>
                Quando preciso enviar documentos de terceiros?
              </li>
              <li className={sectionToShow === 2 ? 'active' : 'deactive'} onClick={sectionHandler(2)}>
                Quanto tempo tenho para enviar todos os documentos?
              </li>
              <li className={sectionToShow === 3 ? 'active' : 'deactive'} onClick={sectionHandler(3)}>
                Se eu tiver outras dúvidas como posso entrar em contato?
              </li>
            </ul>
          </div>
        </div>
      </DropdownArea>

      <div id='help-mobile' className={showDropdown ? 'open' : 'close'}>

        <div id="help-mobile-body">
          <div id="help-mobile-title">
            <h3>Dúvidas Frequentes</h3>

            <button onClick={() => setShowDropdown(false)}>
              <Close color="action" />
            </button>
          </div>
          {Sections.map((Section) => <Section />)}
        </div>
      </div>
    </Container>
  )
}