const getStorage = (name: string) => {
    const labelRegx = name.match(/[0-9]+(GB|TB)/g)!;
    if(labelRegx)
    {
      const label = labelRegx[0];
      const storage = Number(label.replace(/GB|TB/gi, ''));
      return { label, storage };
    }
    else
      return {label:'' ,storage: 0 };
  };
  
  export default getStorage;
  