export const ALLUGATOR_PREVIOUS_PAGE = "allugator-previous-page";
export const ALLUGATOR_AUTH_TOKEN = "v2-allugator-token";
export const NEW_TOKEN_HEADER = "x-allugator-new-token";
export const ALLUGATOR_CART_TOKEN = "v2-allugator-cart";
export const ALLUGATOR_TYPE_PAYMENT = "type-payment";
export const ALLUGATOR_REFERRAL = "allugator-referral";
export const MELIUZ = "meliuzCookie";
export const SOURCE = "utm_source";
export const MEDIUM = "utm_medium";
export const CAMPAIGN = "utm_campaign";
export const TERM = "utm_term";
export const CONTENT = "utm_content";
export const ORIGIN_FAST_CHECKOUT = "origin_fast_checkout";
export const VENDOR_FAST_CHECKOUT = "vendor_fast_checkout";
export const PAYMENTMODE_FAST_CHECKOUT = "paymentmode_fast_checkout";
export const EXTERNAL_VENDOR_FAST_CHECKOUT = "external_vendor_fast_checkout";
export const EXTERNAL_CHANNEL_FAST_CHECKOUT = "external_channel_fast_checkout";
export const EXTERNAL_LEAD_ID_FAST_CHECKOUT = "external_lead_id_fast_checkout";
export const UPGRADE_ID_FAST_CHECKOUT = 'upgrade_id_fast_checkout'
export const NOTICE_FAST_CHECKOUT = 'notice_fast_checkout'
export const INTER_BANK_CLICK_REF = 'interClickRef';