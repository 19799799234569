import { AccountCircleOutlined } from '@mui/icons-material';
import { Box, Button, CircularProgress, Divider, Link, Stack, Typography } from '@mui/material';
import { Formik } from 'formik';
import { GraphQLError } from 'graphql';
import React, { Dispatch, Fragment, SetStateAction, useEffect, useState } from 'react';
import { withApollo, WithApolloClient } from 'react-apollo';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { IMutationVerifyPersonalDataWithOrder, MUTATION_VERIFY_PERSONAL_DATA_WITH_ORDER } from '../../../graphql/mutations/users';
import { ICheckoutOrder } from '../../../graphql/types/checkoutOrders';
import { IAppState } from '../../../store';
import { validatorPersonalDataRecurrence } from '../../../validators/validatorPersonalDataRecurrence';
import PersonalDataForm from './form';
import DataUserSummary from './summary';

export interface IFormikPersonalDataRecurrenceFields {
    document: string;
    phone?: string;
    email?: string;
    cnpj?: string;
    fantasyName?: string;
};

interface IProps {
    loading: boolean;
    personalData: IFormikPersonalDataRecurrenceFields;
    setPersonalData: Dispatch<SetStateAction<IFormikPersonalDataRecurrenceFields>>
    shortId: string;
    order: ICheckoutOrder
}

const PersonalData = ({ loading, personalData, setPersonalData, client, shortId, order }: IProps & WithApolloClient<{}>) => {

    const [edit, setEdit] = useState<boolean>(order.company ? false : true);
    const [activeAllFields, setActiveAllFields] = useState<boolean>(false);

    useEffect(()=> {
      if (order.company && order.user) {
        submit({
            document: order.user.personalData?.document,
            email: order.user.email,
        });
      }
    },[])

    const submit = async (values: IFormikPersonalDataRecurrenceFields) => {
        try {
            await client.mutate<IMutationVerifyPersonalDataWithOrder>({ 
                mutation: MUTATION_VERIFY_PERSONAL_DATA_WITH_ORDER,
                variables: { email: values.email, document: values.document, shortId } 
            });
            setPersonalData({
                ...values,
                cnpj: order.company?.cnpj,
                fantasyName: order.company?.fantasyName
            });
            setActiveAllFields(true);
            setEdit(false);
        } catch (err) {
            err.graphQLErrors.map((error: GraphQLError) => toast.error(error.message || 'Erro ao alterar dados do usuário.'));
            return;
        }
    };

    return (
        <Box sx={{ paddingBottom: '24px' }}>
            {loading ? (
                <Box width={'100%'} sx={{ display: 'flex', justifyContent: 'center', padding: '30px' }}>
                    <CircularProgress />
                </Box>
            ) : (
                <Fragment>
                    <Stack spacing={'20px'}>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            spacing="10px"
                        >
                            <Stack direction="row" alignItems="center" spacing="10px" flexGrow={1}>
                                <AccountCircleOutlined sx={{ opacity: 0.5 }} />
                                <Typography variant="body1" fontSize={17}>
                                    DADOS PESSOAIS
                                </Typography>
                            </Stack>
                            {!order?.company && (
                                <Link
                                    variant="caption"
                                    color="#000000"
                                    fontSize={13}
                                    onClick={() => setEdit(true)}
                                    sx={{ cursor: 'pointer' }}
                                >
                                    Editar
                                </Link>
                            )}

                        </Stack>
                        {!edit ? (
                            loading ? (
                                <Box width={'100%'} sx={{ display: 'flex', justifyContent: 'center', padding: '30px' }}>
                                    <CircularProgress />
                                </Box>
                            ) : (
                                <DataUserSummary {...personalData} />
                            )
                        ) : (
                            <Formik
                                initialValues={personalData}
                                onSubmit={(values: IFormikPersonalDataRecurrenceFields) => submit(values)}
                                validationSchema={validatorPersonalDataRecurrence}
                            >
                                {(formikProps) => (
                                    <Stack spacing={'24px'}>
                                        <PersonalDataForm {...formikProps} submitByEnter={() => formikProps.handleSubmit()} />
                                        <Stack direction="row" spacing={'24px'}>
                                            {activeAllFields ? (
                                                <Button
                                                    variant="outlined"
                                                    color="secondary"
                                                    size="large"
                                                    sx={{ width: '100%' }}
                                                    onClick={() => setEdit(false)}
                                                >
                                                    CANCELAR
                                                </Button>
                                            ) : null}
                                            <Button
                                                variant="contained"
                                                size="large"
                                                color="primary"
                                                sx={{ width: '100%' }}
                                                onClick={() => formikProps.handleSubmit()}
                                            >
                                                SALVAR
                                            </Button>
                                        </Stack>
                                    </Stack>
                                )}
                            </Formik>
                        )}
                    </Stack>
                    <Divider sx={{ paddingTop: '24px' }} />
                </Fragment>
            )}
        </Box>
    );
};

export default connect((state: IAppState) => ({
    loading: state.general.loading,
    order: state.checkoutRecurrence.order
}))(withApollo(PersonalData));
