import * as React from 'react';
import Thanks from '..';
import { ThanksButton } from '../styled';

const ThanksReview: React.FC = () => (
  <Thanks>
    <h1>Uhuuu!!!</h1>
    <p>Obrigado por <span>Allugar</span> com a gente.</p>
    <p>Seu pagamento está sendo processado e isso pode demorar até 72 horas, mas não se preocupe.</p>
    <p>Em caso de dúvidas, fique à vontade para entrar em contato com a gente pelo Chat.</p>
    <ThanksButton to='/'>Voltar ao início</ThanksButton>
  </Thanks>
);

export default ThanksReview;
