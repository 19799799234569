import React from 'react';
import {
  TextField,
  FormHelperText,
  FormControl,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { VisibilityOff, Visibility } from '@mui/icons-material';
import { useState } from 'react';

interface IInputProps {
  value?: string;
  placeholder?: string;
  label?: string;
  setValue?: (value: string) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  name?: string;
  isRequired?: boolean;
  type?: string;
  disabled?: boolean;
  readOnly?: boolean;
  errorMessage?: string;
  touched?: boolean;
  shrink?: boolean;
  submitByEnter?: () => void;
  maxDate?: string;
  minDate?: string;
  maxLengthInput?: number;
  inputIcon?: JSX.Element;
  replace?: RegExp;
  style?: object;
  id?: string;
  autoComplete?: string;
  className?: string;
}

const InputText = ({
  value,
  label,
  setValue,
  className,
  isRequired,
  readOnly,
  type,
  disabled,
  onBlur,
  onFocus,
  errorMessage,
  touched,
  shrink,
  submitByEnter,
  maxDate,
  minDate,
  maxLengthInput,
  inputIcon,
  replace,
  name,
  style,
  placeholder,
  id,
  autoComplete = 'on'
}: IInputProps) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <FormControl sx={style} error={touched && !!errorMessage} variant="standard">
      <TextField
        id={id}
        variant={'outlined'}
        name={name}
        label={label}
        value={value}
        className={className}
        size={'small'}
        placeholder={placeholder}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          setValue(
            replace
              ? event.target.value.replace(replace, '')
              : event.target.value
          )
        }
        required={isRequired || false}
        onBlur={onBlur}
        onFocus={onFocus}
        type={!type || showPassword ? 'text' : type}
        disabled={disabled || false}
        error={touched && !!errorMessage}
        InputProps={{
          endAdornment:
            (type === 'password' && (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            )) ||
            inputIcon,
        }}
        InputLabelProps={{ shrink }}
        inputProps={{
          min: minDate,
          max: maxDate,
          maxLength: maxLengthInput,
          readOnly: readOnly
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && submitByEnter) submitByEnter();
        }}
        autoComplete={autoComplete}
      />
      {touched && errorMessage && (
        <FormHelperText>{errorMessage}</FormHelperText>
      )}
    </FormControl>
  );
};

export default InputText;
