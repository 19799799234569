import { ApolloQueryResult } from 'apollo-client';
import * as React from 'react';
import { Mutation, MutationFn } from 'react-apollo';

import { MUTATION_EDIT_SHIPPING_ADDRESS } from '../../../graphql/mutations/checkoutOrders';
import { IMutationEditAddress } from '../../../graphql/mutations/users';
import { IQueryMyOrders } from '../../../graphql/queries/checkoutOrders';
import AddAddressForm from './AddAddressForm';
import { Information, Wrapper } from './styled';

interface IProps {
  orderId: string;

  goBack: () => void;
  refetchOrders: () => Promise<ApolloQueryResult<IQueryMyOrders>>;
}

class EditShipping extends React.Component<IProps> {
  public render() {
    const {
      refetchOrders,
      goBack,
      orderId,
    } = this.props;

    return (
      <Wrapper>
        <Information style={{ marginBottom: 12 }}>
          Novo endereço de frete
        </Information>

        <Mutation mutation={MUTATION_EDIT_SHIPPING_ADDRESS}>
          {
            (editAddress: MutationFn<IMutationEditAddress>) => (
              <AddAddressForm
                edit={editAddress}
                orderId={orderId}
                refetch={() => {
                  refetchOrders().then(() => goBack());
                }}
              />
            )
          }
        </Mutation>
      </Wrapper>
    );
  }
}

export default EditShipping;
