import { Field, FieldProps, FormikErrors, FormikTouched } from 'formik';
import * as React from 'react';
import MaskedInput from 'react-text-mask';

import { IValues } from './AddShippingForm';
import { FormHeader, InputWrapper } from './styled';

interface IProps {
  touched: FormikTouched<IValues>;
  errors: FormikErrors<IValues>;
}

const PersonalDataForm: React.SFC<IProps> = (props) => {
  const { errors, touched } = props;

  return (
    <div>
      <FormHeader>
        Dados pessoais
      </FormHeader>

      <Field
        name='fullName'
        render={({ field }: FieldProps<IValues>) => (
          <InputWrapper>
          <label>Seu nome completo</label>
            <input
              {...field}
              type='text'
              placeholder='Nome completo'
              autoComplete='fullName'
            />
            {
              touched.fullName && !!errors.fullName
              && <div>{ errors.fullName }</div>
            }
          </InputWrapper>
        )}
      />

      <Field
        name='email'
        render={({ field }: FieldProps<IValues>) => (
          <InputWrapper>
          <label>Seu email</label>
            <input
              {...field}
              type='email'
              placeholder='Email'
              autoComplete='email'
            />
            {
              touched.email && !!errors.email
              && <div>{ errors.email }</div>
            }
          </InputWrapper>
        )}
      />

      <Field
        name='document'
        render={({ field }: FieldProps<IValues>) => (
          <InputWrapper>
          <label>Seu CPF</label>
            <MaskedInput
              {...field}
              type='text'
              placeholder='000.000.000-00'
              autoComplete='document'
              guide={false}
              mask={[
                /[0-9]/, /[0-9]/, /[0-9]/, '.',
                /[0-9]/, /[0-9]/, /[0-9]/, '.',
                /[0-9]/, /[0-9]/, /[0-9]/, '-',
                /[0-9]/, /[0-9]/,
              ]}
            />
            {
              touched.document && !!errors.document
              && <div>{ errors.document }</div>
            }
          </InputWrapper>
        )}
      />

      <Field
        name='phone'
        render={({ field }: FieldProps<IValues>) => (
          <InputWrapper>
          <label>Seu celular</label>
            <MaskedInput
              {...field}
              type='text'
              placeholder='(XX) 00000-0000'
              autoComplete='phone'
              guide={false}
              mask={[
                '(', /[0-9]/, /[0-9]/, ')', ' ',
                /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/,
                '-', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/,
              ]}
            />
            {
              touched.phone && !!errors.phone
              && <div>{ errors.phone }</div>
            }
          </InputWrapper>
        )}
      />

      <Field
        name='birthDate'
        render={({ field }: FieldProps<IValues>) => (
          <InputWrapper>
          <label>Sua data de nascimento</label>
            <MaskedInput
              {...field}
              type='text'
              placeholder='00/00/0000'
              autoComplete='birthDate'
              guide={false}
              mask={[
                /[0-3]/, /[0-9]/, '/',
                /[0-1]/, /[0-9]/, '/',
                /[1-2]/, /[0-9]/, /[0-9]/, /[0-9]/,
              ]}
            />
            {
              touched.birthDate && !!errors.birthDate
              && <div>{ errors.birthDate }</div>
            }
          </InputWrapper>
        )}
      />
    </div>
  );
};

export default PersonalDataForm;
