import styled from 'styled-components';

export const ImgProduct = styled.img`
        height: 157px;
        width: 116px;
    @media (max-width: 900px) {
        width: 80px;
        height: 108px;
    }
`;
