import React from 'react';
import { Step, StepLabel, Stepper, Typography } from '@mui/material';
import { Container } from './styled';

interface IProps {
  steps: string[];
  activeStep: number;
}

const ProgressSteps = ({ steps, activeStep }: IProps) => {
  return (
    <Container>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>
              <Typography variant="subtitle2" fontSize={15}>
                {label}
              </Typography>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Container>
  );
};

export default ProgressSteps;
