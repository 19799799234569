import { Field, FieldProps, FormikErrors, FormikTouched } from 'formik';
import * as React from 'react';
import MaskedInput from 'react-text-mask';

import { CommonDiv } from '../../../components/styled';
import { IValues } from './AddShippingForm';
import { FormHeader, InputWrapper } from './styled';

interface IProps {
  touched: FormikTouched<IValues>;
  errors: FormikErrors<IValues>;
}

const BillingForm: React.SFC<IProps> = (props) => {
  const { errors, touched } = props;

  return (
    <div>
      <FormHeader>
        Endereço de cobrança
      </FormHeader>

      <Field
        name='street'
        render={({ field }: FieldProps<IValues>) => (
          <InputWrapper>
          <label>Endereço</label>
            <input
              {...field}
              type='text'
              placeholder='Endereço'
              autoComplete='street'
            />
            {
              touched.street && !!errors.street
              && <div>{ errors.street }</div>
            }
          </InputWrapper>
        )}
      />

      <CommonDiv style={{ justifyContent: 'space-between' }}>
        <Field
          name='number'
          render={({ field }: FieldProps<IValues>) => (
            <InputWrapper style={{
              width: '48%',
            }}>
              <label>Número</label>
              <input
                {...field}
                type='text'
                placeholder='Número'
                autoComplete='address-number'
              />
              {
                touched.number && !!errors.number
                && <div>{ errors.number }</div>
              }
            </InputWrapper>
          )}
        />

        <Field
          name='complement'
          render={({ field }: FieldProps<IValues>) => (
            <InputWrapper style={{
              width: '48%',
            }}>
              <label>Complemento</label>
              <input
                {...field}
                type='text'
                placeholder='Complemento'
                autoComplete='address-complement'
              />
            </InputWrapper>
          )}
        />
      </CommonDiv>

      <CommonDiv style={{ justifyContent: 'space-between' }}>
        <Field
          name='neighborhood'
          render={({ field }: FieldProps<IValues>) => (
            <InputWrapper style={{
              width: '48%',
            }}>
              <label>Bairro</label>
              <input
                {...field}
                type='text'
                placeholder='Bairro'
                autoComplete='address-neighborhood'
              />
              {
                touched.neighborhood && !!errors.neighborhood
                && <div>{ errors.neighborhood }</div>
              }
            </InputWrapper>
          )}
        />

        <Field
          name='code'
          render={({ field }: FieldProps<IValues>) => (
            <InputWrapper style={{
              width: '48%',
            }}>
              <label>CEP</label>
              <MaskedInput
                {...field}
                type='text'
                placeholder='00000-000'
                mask={[
                  /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/,
                  '-', /[0-9]/, /[0-9]/, /[0-9]/,
                ]}
                autoComplete='zip-code'
                guide={false}
              />
              {
                touched.code && !!errors.code
                && <div>{ errors.code }</div>
              }
            </InputWrapper>
          )}
        />
      </CommonDiv>

      <Field
        name='city'
        render={({ field }: FieldProps<IValues>) => (
          <InputWrapper>
            <label>Cidade</label>
            <input
              { ...field }
              type='text'
              placeholder='Cidade'
              autoComplete='city'
            />
            {
              touched.city && !!errors.city
              && <div>{ errors.city }</div>
            }
          </InputWrapper>
        )}
      />

      <Field
        name='state'
        render={({ field }: FieldProps<IValues>) => (
          <InputWrapper>
            <label>Estado</label>
            <select
              { ...field }
              placeholder='Estado'
              style={{
                background: 'none',
                boxShadow: '0 0 0 1px #dee2e6',
                outline: 'none',
                border: 'none',
                width: '100%',
              }}
            >
              <option value='AC'>Acre</option>
              <option value='AL'>Alagoas</option>
              <option value='AP'>Amapá</option>
              <option value='AM'>Amazonas</option>
              <option value='BA'>Bahia</option>
              <option value='CE'>Ceará</option>
              <option value='DF'>Distrito Federal</option>
              <option value='ES'>Espírito Santo</option>
              <option value='GO'>Goiás</option>
              <option value='MA'>Maranhão</option>
              <option value='MT'>Mato Grosso</option>
              <option value='MS'>Mato Grosso do Sul</option>
              <option value='MG'>Minas Gerais</option>
              <option value='PA'>Pará</option>
              <option value='PB'>Paraíba</option>
              <option value='PR'>Paraná</option>
              <option value='PE'>Pernambuco</option>
              <option value='PI'>Piauí</option>
              <option value='RJ'>Rio de Janeiro</option>
              <option value='RN'>Rio Grande do Norte</option>
              <option value='RS'>Rio Grande do Sul</option>
              <option value='RO'>Rondônia</option>
              <option value='RR'>Roraima</option>
              <option value='SC'>Santa Catarina</option>
              <option value='SP'>São Paulo</option>
              <option value='SE'>Sergipe</option>
              <option value='TO'>Tocantins</option>
            </select>
            {
              touched.state && !!errors.state
              && <div>{ errors.state }</div>
            }
          </InputWrapper>
        )}
      />
    </div>
  );
};

export default BillingForm;
