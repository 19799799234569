import styled from "styled-components";

export const AnimationContainer = styled.div`
        display: flex;
        align-itens: center;
        justify-content:center;
        height: 100vh;
        width: 100%;
        padding-bottom: 100px;
        background-color: rgb(45, 45, 56);
        img {
            width: 70%;
        }
`;