import styled, { keyframes } from 'styled-components';


const showUp = keyframes`
  0% {
    background-color: transparent;
  }

  100% {
    background-color: rgba(0, 0, 0, 0.3);
  }
`;

const showUpChild = keyframes`
  0% {
    height: 0;
  }

  100% {
    height: 90vh;
  }
`

export const Container = styled.div`
  display: flex;
  padding: 1.563rem 7.5rem;
  justify-content: space-between;
  align-items: center;
  background-color: #F8F8F8;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 
    0px 1px 1px rgba(0, 0, 0, 0.14), 
    0px 1px 3px rgba(0, 0, 0, 0.12);
  margin-bottom: 3.125rem;
  height: 4.688rem;

  div#help-mobile {
    display: none;
    font-family: Lato;
    transition: display 2s;
    animation: ${showUp} .5s linear;

    &.open {
      display: none;
    }
  }

  @media (max-width: 600px) {
    padding: 1.563rem 0.75rem;

    div#help-mobile {
      position: fixed;
      height: 100vh;
      width: 100vw;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 1;
      bottom: 0;
      left: 0;

      div#help-mobile-body {
        position: fixed;
        overflow-y: scroll;
        left: 0;
        bottom: 0;
        display: flex;
        height: 90vh;
        background-color: white;
        flex-direction: column;
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        padding: 1.5rem;
        padding-bottom: 5rem;
        animation: ${showUpChild} .25s linear;

        div#help-mobile-title {
          display: flex;  
          justify-content: space-between;
          
          button {
            border: none;
            outline: none;
            background: none;
            cursor: pointer;
            padding: 0.5rem;
          }

          h3 {
            font-size: 1rem;
            margin-bottom: 0;
          }
        }
      }

    
      &.open {
        display: flex;
      }

      &.close {
        display: none;
      }

      h4 {
        padding: 0.25rem 0.5rem;
        font-size: 0.875rem;
        background-color: #0000000A;
        font-weight: 600;
      }

      h4 + p {
        margin-top: -0.5rem;
      }

      p, li {
        font-size: 0.875rem;
        color: #575863;
      }

      ul {
        margin-top: -0.5rem;
      }

      li {
        margin-left: -1rem;
      }
    }
  }
`;

export const DropdownArea = styled.div`
  font-family: 'Lato';
  font-size: .75rem;
  position: relative;

  div#help-dropdown {
    position: absolute;
    top: 3.15rem;
    right: 0;
    z-index: 1;
    display: flex;
    align-items: flex-start;
    padding: 1.875rem 1.5rem;
    background: #FFFFFF;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 
      0px 2px 2px rgba(0, 0, 0, 0.14), 
      0px 1px 5px rgba(0, 0, 0, 0.12);
    border-radius: 24px;
    transition: display 2s ease-in-out;

    &.open {
      display: flex;
    }

    &.close {
      display: none;
    }


    div {
      width: 15rem;
      height: 20rem;

      h3 {
        font-weight: 600;
        margin-bottom: 1rem;
      }
      
      ul li {
        font-weight: 400;
      } 

      &:not(#dropdown-menu) {
        ul {
          margin-top: -0.5rem;
        }

        li {
          margin-left: -1rem;
        }
      }

      &:first-child {
        padding-right: 16px;
        border-right: 1px solid rgba(0, 0, 0, .12);
      }

      &:last-child {
        position: relative;
        padding-left: 16px;

        button {
          position: absolute;
          top: -15px;
          right: -5px;
          background: none;
          outline: none;
          border: none;
          cursor: pointer;
        }

        ul {
          list-style-type: none;
          margin: 0;
          padding: 0;

          li {
            padding: 4px 8px;
            cursor: pointer;
            transition: background .25s linear;

            &:hover, &.deactive:hover {
              background: rgba(0, 0, 0, 0.04);
            }

            &.active {
              background: rgba(0, 0, 0, 0.04);
            }

            &.deactive {
              background: transparent;
            }
          }
        }
      } 
    }
  }

  @media (max-width: 600px) {
    div#help-dropdown {
      &.open {
        display: none;
      }
    }
  }
`;

