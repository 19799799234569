import { Stack, Typography } from "@mui/material";
import React from 'react';
import formatCPF from '../../../../helpers/formatDocument';
import { TextDescription } from './styled';

interface IProps {
    firstName: string;
    lastName: string;
    document: string;
    email: string;
}

const PersonalData = ({ firstName, lastName, document, email }: IProps) => {
    return (
        <Stack
            spacing={'12px'}
            sx={{
                padding: {
                    xs: '24px',
                    sm: '24px 0px 24px 24px',
                    md: '24px'
                },
                boxShadow: {
                    md: '0px 5px 8px rgba(120, 111, 111, 0.15)'
                },
                borderRadius: '8px',
                minHeight: { md: '205px' }
            }}
        >
            <Typography variant='h6'>Dados Pessoais</Typography>
            <Stack spacing={'4px'} sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>
                <Typography variant='subtitle2' fontSize={'15px'}>Nome:<TextDescription>&nbsp;{firstName.trim()}&nbsp;{lastName.trim()}</TextDescription></Typography>
                <Typography variant='subtitle2' fontSize={'15px'}>CPF:<TextDescription>&nbsp;{formatCPF(document)}</TextDescription></Typography>
                <Typography variant='subtitle2' fontSize={'15px'}>E-mail:<TextDescription>&nbsp;{email}</TextDescription></Typography>
            </Stack>
        </Stack>
    )
};

export default PersonalData;