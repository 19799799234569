import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  background: #F8F8F8;
  margin-top: 3.125rem;
  color: #FFFFFF;
  font-size: 1rem;
  padding: 20px 0;
  justify-content: space-around;

  a {
    font-family: 'Chillax';
    text-decoration: none;
    color: #0288D1;
    font-weight: 500;
  }

  b {
    font-family: 'Chillax';
    font-weight: 500;
    color: #575863;
  }

  @media (max-width: 600px) {
    justify-content: center;
    align-items: center;
    row-gap: 1rem;
    flex-direction: column;
    width: 100vw;
    font-size: .75rem;
    text-align: center;
    
    b {
      width: 50%;
    }
  }
`;