export default (cpf: string) =>  {
  const checkIfAllDigitsAreTheSame:string[] = cpf.split('');
  let Soma;
  let Resto;
  Soma = 0;
  
  if (checkIfAllDigitsAreTheSame.every((digit) => digit === checkIfAllDigitsAreTheSame[0])) return false;

  for (let i = 1; i <= 9; i++) { Soma = Soma + parseInt(cpf.substring(i - 1, i), 10) * (11 - i); }
  Resto = (Soma * 10) % 11;

  if ((Resto === 10) || (Resto === 11)) {  Resto = 0; }
  if (Resto !== parseInt(cpf.substring(9, 10), 10) ) { return false; }

  Soma = 0;
  for (let i = 1; i <= 10; i++) { Soma = Soma + parseInt(cpf.substring(i - 1, i), 10) * (12 - i); }
  Resto = (Soma * 10) % 11;

  if ((Resto === 10) || (Resto === 11)) {  Resto = 0; }
  if (Resto !== parseInt(cpf.substring(10, 11), 10) ) { return false; }
  return true;
};
