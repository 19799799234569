import { AnalyticsBrowser } from "@orbite/analytics-js";
import { useMemo } from "react";

export function useAnalytics() {
  const analytics = useMemo(
    () =>
      AnalyticsBrowser.load({
        writeKey: "",
        cdnSettings: {
          integrations: {
            Orbite: {
              type: "destination",
              apiHost: "orbite-api-cjttw7kbaq-rj.a.run.app",
              apiKey: process.env.RAZZLE_ORBITE_APIKEY
            }
          }
        }
      }),
    []
  );

  return analytics;
}
