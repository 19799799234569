import React from 'react';
import { Fab, Link, Tooltip } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const WhatsappBalloon = () => {
  return (
    <Tooltip title="Abrir o WhatsApp" arrow>
      <Link href='https://api.whatsapp.com/send?phone=553191095737' target='_blank'>
        <Fab
          sx={{
            position: 'fixed',
            bottom: 20,
            right: 20,
            zIndex: 1000,
            color: 'white',
            backgroundColor: '#229d53',
          }}
        >
          <WhatsAppIcon />
        </Fab>
      </Link>
    </Tooltip>
  );
};

export default WhatsappBalloon;
