import { Stack, Typography } from '@mui/material';
import { FormikProps } from 'formik';
import React from 'react';
import TextFieldMask from '../../../../components/Inputs/InputMask';
import InputText from '../../../../components/Inputs/InputText';
import { IFormikPersonalDataRecurrenceFields } from '../index';

interface IProps extends FormikProps<IFormikPersonalDataRecurrenceFields> {
  submitByEnter?: () => void;
}

const PersonalDataForm = ({
  handleBlur,
  handleChange,
  values,
  touched,
  errors,
  submitByEnter,
}: IProps) => {

  return (
    <>
      <Stack spacing={'20px'}>
        <Typography variant='subtitle1' fontSize={'16px'}>
          Digite seu CPF e E-mail para confirmar seus dados
        </Typography>
        <TextFieldMask
          mask="999.999.999-99"
          label="CPF"
          type="text"
          name={"document"}
          onBlur={handleBlur('document')}
          setValue={handleChange('document')}
          value={values.document}
          touched={touched.document}
          errorMessage={errors.document}
          disabled={false}
          submitByEnter={submitByEnter}
        />
        <InputText
          label="E-mail"
          type="text"
          name={"email"}
          onBlur={handleBlur('email')}
          setValue={handleChange('email')}
          value={values.email || ""}
          touched={touched.email}
          errorMessage={errors.email}
          disabled={false}
        />
      </Stack>
    </>
  );
};

export default PersonalDataForm;
