import { FormikProps, withFormik } from 'formik';
import * as moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { ActionCreator, Dispatch } from 'redux';
import * as Yup from 'yup';

import { GraphQLError } from 'graphql';
import { MutationFn } from 'react-apollo';
import { toast } from 'react-toastify';
import { IGeneralAction, SET_LOADING } from '../../../actionReducers/General';
import { SizedTopProgressBarSnake } from '../../../components/TopProgressBar/styled';
import { FormikFixedForm } from '../../../components/styled';
import Config from '../../../config';
import { IMutationAddShippingToOrder } from '../../../graphql/mutations/checkoutOrders';
import { IPublicUser, IUserAddress } from '../../../graphql/types/users';
import CPFValidation from '../../../helpers/CPFValidation';
import { googleTag } from '../../../helpers/analytics';
import { IAppState } from '../../../store';
import BillingForm from './BillingForm';
import CardForm from './CardForm';
import PersonalDataForm from './PersonalDataForm';
import { DeliveryButton } from './styled';

export interface IValues {
  // Personal Data
  fullName: string;
  email: string;
  document: string;
  birthDate: string;
  phone: string;

  // Billing Address
  code: string;
  street: string;
  number: string;
  complement: string;
  neighborhood: string;
  city: string;
  state: string;

  // Card Data
  cardName: string;
  cardNumber: string;
  cardExpiration: string;
  cvv: string;
}

const initialValues: IValues = {
  fullName: '',
  email: '',
  document: '',
  birthDate: '',
  phone: '',

  code: '',
  street: '',
  number: '',
  complement: '',
  neighborhood: '',
  city: '',
  state: 'MG',

  cardName: '',
  cardNumber: '',
  cardExpiration: '',
  cvv: '',
};

interface IProps {
  orderId: string;
  selectedAddress: number;
  address: IUserAddress;
  loading: boolean;
  user: IPublicUser;

  submit: MutationFn<IMutationAddShippingToOrder>;
  setLoading: ActionCreator<IGeneralAction>;
  refetch: () => void;
}

class AddShippingForm extends React.Component<IProps & FormikProps<IValues>> {
  public componentDidMount() {
    const script = document.createElement('script');
    script.setAttribute('src', 'https://assets.pagar.me/pagarme-js/4.5/pagarme.min.js');
    script.setAttribute('async', 'async');
    document.body.appendChild(script);
  }

  public render() {
    const { errors, touched, loading } = this.props;

    return (
      <FormikFixedForm>
        <PersonalDataForm errors={errors} touched={touched} />
        <CardForm errors={errors} touched={touched} />
        <BillingForm errors={errors} touched={touched} />

        <DeliveryButton type='submit'>
          Pagar
        </DeliveryButton>

        {
          loading
          ? <SizedTopProgressBarSnake />
          : null
        }
      </FormikFixedForm>
    );
  }
}

const requiredMessages = {
  fullName: 'Seu nome é um campo obrigatório.',
  email: 'O email é um campo obrigatório.',
  document: 'Documento é um campo obrigatório.',
  birthDate: 'Data de nascimento do titular do cartão é um campo obrigatório.',
  phone: 'Telefone é um campo obrigatório.',

  code: 'O cep é um campo obrigatório.',
  street: 'O endereço é um campo obrigatório.',
  number: 'O número é um campo obrigatório.',
  neighborhood: 'O bairro é um campo obrigatório.',
  city: 'A cidade é um campo obrigatório.',
  state: 'O estado é um campo obrigatório.',

  cardName: 'Nome como no cartão e um dado obrigatório.',
  cardNumber: 'Número do cartão é um campo obrigatório.',
  cardExpiration: 'Data de validade do cartão é um campo obrigatório.',
  cvv: 'CVV obrigatório.',
};

const validMessages = {
  email: 'Email inválido.',
  birthDate: 'Data de nascimento inválida.',
  phone: 'Número de celular inválido.',

  cardNumber: 'Número de cartão inválido.',
  cardExpiration: 'A validade do cartão tem que estar no formato MM/YY.',
};

export const validationSchema = Yup.object().shape({
  fullName: Yup.string().required(requiredMessages.fullName),
  email: Yup.string().required(requiredMessages.fullName)
    .email(validMessages.email),
  document: Yup.string().required(requiredMessages.document),
  birthDate: Yup.string().min(10, validMessages.birthDate).max(10, validMessages.birthDate)
    .required(requiredMessages.birthDate),
  phone: Yup.string().min(11, validMessages.phone).max(15, validMessages.phone)
    .required(requiredMessages.phone),

  code: Yup.string().required(requiredMessages.code),
  street: Yup.string().required(requiredMessages.street),
  number: Yup.string().required(requiredMessages.number),
  neighborhood: Yup.string().required(requiredMessages.neighborhood),
  city: Yup.string().required(requiredMessages.city),
  state: Yup.string().required(requiredMessages.state),

  cardName: Yup.string().required(requiredMessages.cardName),
  cardNumber: Yup.string().required(requiredMessages.cardNumber)
    .min(16, validMessages.cardNumber).max(19, validMessages.cardNumber),
  cardExpiration: Yup.string().min(5, validMessages.cardExpiration).max(5, validMessages.cardExpiration)
    .required(requiredMessages.cardExpiration),
  cvv: Yup.string().min(3).max(4).required(requiredMessages.cvv),
});

export default connect((state: IAppState) => ({
  loading: state.general.loading,
}), (dispatch: Dispatch<IGeneralAction>) => ({
  setLoading: (loadingState: boolean) => dispatch(SET_LOADING(loadingState)),
}))(
  withFormik<IProps, IValues>({
    validationSchema,
    mapPropsToValues({ user, address }: IProps) {
      let data: IValues = {
        ...initialValues,
        email: user.email,
        complement: address.complement,
        state: address.state,
        street: address.street,
        number: address.number,
        city: address.city,
        neighborhood: address.neighborhood,
        code: String(address.code),
      };

      const {
        personalData,
        phone = '',
      } = user;

      if (phone) {
        data = {
          ...data,
          phone,
        };
      }

      if (personalData) {
        const {
          birthday,
          document = '',
          firstName,
          lastName,
        } = personalData;

        data = {
          ...data,
          fullName: `${firstName} ${lastName}`,
          document,
          birthDate: birthday ? moment.tz(birthday, 'UTC').format('DD/MM/YYYY') : '',
        };
      }

      return data;
    },
    handleSubmit(values, { setFieldError, props }) {
      const {
        setLoading,
        selectedAddress,
        orderId,
        submit,
        refetch,
      } = props;

      const {
        fullName,
        email,
        document,
        birthDate,
        phone: rawPhone,

        code,
        street,
        number: num,
        neighborhood,
        city,
        state,

        cardName,
        cardNumber: rawCardNumber,
        cardExpiration,
        cvv,
      } = values;

      setLoading(true);

      const cleanDocument = document.replace(/\D+/g, '');

      /** Validate Document & BirthDate */
      if (!CPFValidation(cleanDocument)) {
        setFieldError('document', 'Documento inválido.');
        setLoading(false);
        return;
      }

      const cardNumber = rawCardNumber.replace(/ /g, '');
      const expiration = cardExpiration.split('/');
      const phone = rawPhone.replace(/\D+/g, '');
      const pagarMeBirthDate = birthDate.split('/').reverse().join('-');

      const card = {
        card_number: cardNumber,
        card_cvv: cvv,
        card_holder_name: cardName,
        card_expiration_date: expiration.join(''),
      };

      const { card: cardValidation } = pagarme.validate({ card });

      const errors: string[] = [];
      for (const validationKey in cardValidation) {
        if (validationKey === 'brand') { continue; }

        const validationStatus = cardValidation[validationKey];

        if (!validationStatus) {
          switch (validationKey) {
            case 'card_number': {
              errors.push('Número do cartão inválido.');
              break;
            }
            case 'card_holder_name': {
              errors.push('Nome do titular inválido.');
              break;
            }
            case 'card_expiration_month': {
              errors.push('Mês de expiração inválido.');
              break;
            }
            case 'card_expiration_year': {
              errors.push('Ano de expiração inválido.');
              break;
            }
            case 'card_expiration_date': {
              errors.push('Data de expiração inválido.');
              break;
            }
            case 'card_cvv': {
              errors.push('CVV (Código de segurança) inválido.');
              break;
            }
            default: {
              break;
            }
          }
        }
      }

      if (errors.length > 0) {
        errors.forEach((error) => toast.error(error));
        return;
      }

      pagarme.client
        .connect({ encryption_key: Config.PagarmeV3Key })
        .then((client: any) => client.security.encrypt(card))
        .then(async (hash: string) => {
          const variables = {
            orderId,
            cardHash: hash,
            customer: {
              fullName,
              email,
              document: cleanDocument,
              birthDate: pagarMeBirthDate,
              phone: `+55${phone}`,
            },
            billingAddress: {
              code: code.replace('-', ''),
              street,
              city,
              state,
              number: num,
              neighborhood,
            },
            address: selectedAddress,
          };

          try {
            const result = await submit({ variables });

            if (result && result.data) {
              googleTag('purchase', {
                items: [{
                  id: `frete ${orderId}`,
                  name: `frete ${orderId}`,
                  price: 22,
                  quantity: 1,
                }],
                currency: 'BRL',
                value: 22,
                transaction_id: `frete ${orderId}`,
                shipping: 22,
              });

              googleTag('conversion', {
                send_to: 'AW-931911632/IyOzCKr0h4YBENCvr7wD',
                value: 22,
                currency: 'BRL',
                transaction_id: `frete ${orderId}`,
              });

              setLoading(false);
              refetch();
              toast.success('Endereço adicionado ao pedido com sucesso.');
            } else {
              throw new Error('Erro com pagamento. Código: XDKE3');
            }
          } catch (e) {
            if (e.graphQLErrors) {
              e.graphQLErrors.map((error: GraphQLError) => toast.error(error.message));
              setLoading(false);
            } else {
              throw e;
            }
          }
        }).catch((error: any) => {
          toast.error(error.message || error);
          setLoading(false);
        });
    },
  })(AddShippingForm),
);
