import * as React from "react";
import axios from "axios";
import Dialog from "../../../../components/CustomModal";
import { Divider, Grid, Stack, Typography } from "@mui/material";
import { CreditCardOutlined } from "@mui/icons-material";
import { Charge } from "../../../../graphql/types/checkoutOrders";

import Cards from 'react-credit-cards-2';
import 'react-credit-cards-2/dist/es/styles-compiled.css'

import { Formik } from 'formik';
import ValidatorCreditCard from '../../../../validators/validatorCreditCard';
import TextFieldMask from '../../../../components/Inputs/InputMask';
import InputText from '../../../../components/Inputs/InputText';
import { Info } from './styled';
import { IQueryOrderByShortId } from '../../../../graphql/queries/checkoutOrders';
import moment from 'moment';
import toReal from '../../../../helpers/toReal';
import { RedirectButton } from '../styled';
import { gray15, greenAllu } from '../../../../style/colors';
import { orderTransactions } from '../../../../helpers/charges';
import { uppercaseFirstLetter } from '../../../../helpers/uppercasefirstLetter';
import { toast } from 'react-toastify';
import config from '../../../../config';
import { ALLUGATOR_AUTH_TOKEN } from '../../../../boot/constants';
import { LoadingButton } from '@mui/lab';

enum State {
  IDLE = 'idle',
  DONE = 'done',
}

export const RetryDialog = (props: {
  open: boolean;
  handleClose: () => void;
  charge: Charge;
  isMobile: boolean;
  order: IQueryOrderByShortId['searchOrderById'];
}) => {
  const [isLoading, setLoading] = React.useState<boolean>(false)
  const [state, setState] = React.useState<State>(State.IDLE)
  const [changeCard, setChangeCard] = React.useState(false)
  const initialValues = {
    number: '',
    expiry: '',
    cvc: '',
    name: '',
    focus: 'number'
  }
  
  const lastTransaction = React.useMemo(() => {
    const orderedTransactions = orderTransactions(props.charge.transactions || [])
    return orderedTransactions[orderTransactions.length - 1]
  }, [props.charge])

  const handleSubmit = (values) => {
    handleChangeCard(values)
    setState(State.DONE)
  }

  const handleClose = () => {
    setLoading(false)
    setChangeCard(false)
    setState(State.IDLE)
    props.handleClose()
  }

  const handleRetry = async () => {
    setLoading(true)
    await axios.post(`${config.YacareURL}/recurrence/charges/${props.charge.id}/retry`, {}, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem(ALLUGATOR_AUTH_TOKEN) || ''}`
      }
    })
      .then((_) => {
        setState(State.DONE)
      })
      .catch((_) => toast.error('Erro ao retentar cobrança'));
    setLoading(false)
  }
  
  const handleChangeCard = async (values) => {
    setLoading(true)

    const card = {
      number: values.number.replace(/\s/g, ""),
      holder_name: values.name,
      exp_month: values.expiry.substring(0,2),
      exp_year: values.expiry.substring(2,4),
      cvv: values.cvc
    };
    
    const { data } = await axios.post(
      `${config.PagarmeV5URL}/tokens?appId=${config.PagarmeV5Key}`,
      { type: "card", card }
    );
    await axios.post(`${config.YacareURL}/recurrence/charges/${props.charge.id}/card`, {
      token: data.id,
      orderId: props.order._id,
    }, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem(ALLUGATOR_AUTH_TOKEN) || ''}`
      }
    })
      .then((_) => {
        setState(State.DONE)
      })
      .catch((_) => toast.error('Erro ao tentar trocar cartão da cobrança'));
    setLoading(false)
  }

  return (
    <Dialog open={props.open} handleClose={handleClose}>
      {state !== State.DONE && (
        <>
          {((props.isMobile && !changeCard) || !props.isMobile) && (
            <Stack>
              <Info style={{ fontWeight: 600, fontSize: '1.3125rem', marginBottom: '12px' }}>Fazer pagamento</Info>
              <Info>Pedido: {props.order.shortId}</Info>
              <Info>Parcela {props.charge.installment} de {props.order.subscription.cycleQuantity}</Info>
              <Info>Vence em {moment(props.charge.dueDate).format('DD/MM/AA')}</Info>
              <Info>{toReal(props.charge.totalValue / 100)}</Info>
              <Divider style={{ marginTop: '12px', marginBottom: '12px' }} />
            </Stack>
          )}
          {!changeCard && (
            <Grid container style={{ padding: '12px', backgroundColor: gray15 }}>
              <Grid item xs={12} display='flex' justifyContent='space-between'>
                <Info bold>Cartão atual</Info>
                <RedirectButton onClick={() => setChangeCard(true)}>Trocar cartão</RedirectButton>
              </Grid>
              {lastTransaction && (
                <Grid container direction='row' style={{ marginTop: '8px', marginBottom: '20px' }}>
                  <Grid item><CreditCardOutlined color='info' /></Grid>
                  <Grid item style={{ marginLeft: '8px' }}>
                    <Grid item xs={12}><Info small style={{ fontWeight: 400 }}>{uppercaseFirstLetter(lastTransaction?.cardBrand || '-')} final <strong>{lastTransaction?.cardLastDigits || '-'}</strong></Info></Grid>
                    <Grid item xs={12}><Info small style={{ fontWeight: 400 }}>{lastTransaction.cardHolderName || '-'}</Info></Grid>
                    <Grid item xs={12}><Info small style={{ fontWeight: 400 }}>Expira em {lastTransaction.cardExpirationDate}</Info></Grid>
                  </Grid>
                </Grid>
              )}
              <Grid xs={12}>
                <LoadingButton
                  size="large"
                  variant="contained"
                  loading={isLoading}
                  onClick={() => handleRetry()}
                  style={{ width: '100%' }}
                >
                  Pagar
                </LoadingButton>
              </Grid>
            </Grid>
          )}
          {changeCard && (
            <>
              <Stack style={{ marginBottom: '12px' }}>
                <Info style={{ fontWeight: 600, fontSize: '1.3125rem', marginBottom: '12px' }}>Trocar cartão</Info>
                <Info>Insira os dados do novo cartão</Info>
              </Stack>
              <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validateOnChange={true}
                validationSchema={ValidatorCreditCard}
              >
                {({
                  touched,
                  values,
                  errors,
                  handleBlur,
                  handleChange,
                  setFieldValue,
                }) => (
                    <Stack spacing={'16px'}>
                      <Cards
                        preview
                        number={values.number}
                        expiry={values.expiry}
                        cvc={values.cvc}
                        name={values.name}
                        focused={values.focus}
                        placeholders={{ name: 'seu nome' }}
                        locale={{ valid: 'vencimento' }}
                      />
                      <TextFieldMask
                        mask={'9999 9999 9999 9999'}
                        label="Número do Cartão"
                        type="text"
                        autoComplete="disabled"
                        name={"number"}
                        onBlur={(e) => { setFieldValue('focus', 'number'), handleBlur('number')(e) }}
                        setValue={(e) => { handleChange('number')(e), setFieldValue('focus', 'number') }}
                        value={values.number}
                        touched={touched.number || values.number ? true : false}
                        errorMessage={errors.number as string}
                        isRequired={true}
                      />
                      <InputText
                        label="Nome no cartão"
                        type="text"
                        name={"name"}
                        onBlur={(e) => { setFieldValue('focus', 'name'), handleBlur('name')(e) }}
                        setValue={(e) => { handleChange('name')(e), setFieldValue('focus', 'name') }}
                        value={values.name}
                        touched={touched.name as boolean}
                        errorMessage={errors.name as string}
                        autoComplete="disabled"
                        isRequired={true}
                        replace={/[0-9.]+/g}
                      />
                      <Stack
                        direction={'row'}
                        spacing={'16px'}
                        alignItems="center"
                      >
                        <TextFieldMask
                          mask="99/99"
                          name={"expiry"}
                          autoComplete="disabled"
                          label="Validade"
                          type="text"
                          style={{ width: '50%' }}
                          onBlur={(e) => { setFieldValue('focus', 'expiry'), handleBlur('expiry')(e) }}
                          setValue={(e) => { handleChange('expiry')(e), setFieldValue('focus', 'expiry') }}
                          value={values.expiry}
                          touched={values.expiry || touched.expiry ? true : false}
                          errorMessage={errors.expiry as string}
                          isRequired={true}
                        />
                        <InputText
                          label="cvv"
                          name={"cvc"}
                          type="text"
                          style={{ width: '50%' }}
                          onBlur={(e) => { setFieldValue('focus', 'cvc'), handleBlur('cvc')(e) }}
                          setValue={(e) => { handleChange('cvc')(e), setFieldValue('focus', 'cvc') }}
                          value={values.cvc}
                          touched={touched.cvc as boolean}
                          errorMessage={errors.cvc as string}
                          isRequired={true}
                          maxLengthInput={4}
                          replace={/[^0-9.]+/g}
                        />
                      </Stack>
                      <Stack
                        direction={'row'}
                        spacing={'16px'}
                        alignItems="center"
                      >
                        <LoadingButton
                          variant="outlined"
                          sx={{ color: greenAllu, }}
                          loading={isLoading}
                          onClick={() => {
                            handleClose()
                            setChangeCard(false)
                          }}
                          style={{ width: '50%' }}
                        >
                          Cancelar
                        </LoadingButton>
                        <LoadingButton
                          variant="contained"
                          loading={isLoading}
                          onClick={() => handleChangeCard(values)}
                          style={{ width: '50%' }}
                        >
                          Pagar
                        </LoadingButton>
                      </Stack>
                    </Stack>
                  )
                }
              </Formik>
            </>
          )}
        </>
      )}
      {state === State.DONE && (
        <>
          <Stack spacing={"8px"} sx={{ textAlign: "center" }}>
            <Typography variant="h5" color={greenAllu}>
              Seu pagamento está sendo processado!
            </Typography>
            <Typography variant="subtitle2">
              O processamento pode demorar alguns minutos. Quando o processo for finalizado consulte o histórico de faturas da sua assinatura para verificar o pagamento.
            </Typography>
          </Stack>
        </>
      )}
    </Dialog>
  );
};
