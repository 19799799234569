import styled from 'styled-components';
import {
  CommonAnchor,
  CommonDiv,
  CommonInput,
  CommonMaskedInput,
  PrimaryButton,
  RawLink
} from '../../components/styled';
import { media } from '../../helpers/style';
import {
  black,
  facebookBlue,
  facebookBlueHover,
  googleRed,
  googleRedHover,
  gray3,
  gray5,
  gray6,
  gray7,
  gray9,
  green1,
  green2,
  white
} from '../../style/colors';


export const AuthWrapper = styled(CommonDiv)`
  width: calc(100% - 5em);
  max-width: 70em;
  flex-direction: column;
  justify-content: flex-start;
  padding: 9.6em 3em;
`;

export const AuthTitle = styled.h1`
  padding: 0;
  margin: 0;
  font-size: 5em;
  font-weight: 700;
`;

export const AuthSubtitle = styled.h2`
  padding: 0;
  margin: 1em 0;
  font-size: 3em;
  line-height: 1.3em;
  font-weight: 400;
  text-align: center;
`;

export const AuthSocialButton = styled(PrimaryButton)`
  padding: 0.5em 0;
  font-size: 2.4em;
  width: 100%;
  margin-top: 1em;
  transition: background-color 0.2s;
  border-radius: 6px;
`;

export const AuthFacebookButton = styled(AuthSocialButton)`
  margin-top: 1.5em;
  background-color: ${facebookBlue};

  &:hover, &:active {
    background-color: ${facebookBlueHover};
  }
`;

export const AuthGoogleButton = styled(AuthSocialButton)`
  background-color: ${googleRed};

  &:hover, &:active {
    background-color: ${googleRedHover};
  }
`;

export const AuthSubmit = styled(AuthSocialButton)`
  font-size: 3em;
  margin-top: 0.5em;
  padding: 0.5em 0;
  background-color: ${green1};

  &:hover, &:active {
    background-color: ${green2};
  }
`;

interface IAuthSignUpTextProps {
  white?: boolean;
}

export const AuthSignUpText = styled.p`
  margin-top: 1em;
  line-height: 1.5em;
  align-self: center;
  text-align: center;
  width: 90%;
  font-size: 2em;
  color: ${gray5};

  a {
    position: relative;
    color: ${(props: IAuthSignUpTextProps) => props.white ? white : black};
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }
`;

/** Form */
export const AuthForm = styled(CommonDiv)``;

interface IAuthInputProps {
  notValid?: boolean;
}

export const AuthInput = styled(CommonInput)`
  padding: 1em 1.1em;
  width: 100%;
  ${(props: IAuthInputProps) => props.notValid && 'box-shadow: 0px 0px 0px 2px red;'}
  ${(props: IAuthInputProps) => props.notValid && '&:hover { box-shadow: 0px 0px 0px 2px red; }'}
  &:focus {
    box-shadow: 0px 0px 0px 2px #80bdff;
    ${(props: IAuthInputProps) => props.notValid && 'box-shadow: 0px 0px 0px 2px red;'}
  }

  &::placeholder {
    color: gray;
  }
`;

export const AuthMaskedInput = styled(CommonMaskedInput)`
  padding: 1em 1.1em;
  ${(props: IAuthInputProps) => props.notValid && 'box-shadow: 0px 0px 0px 2px red;'}
  ${(props: IAuthInputProps) => props.notValid && '&:hover { box-shadow: 0px 0px 0px 2px red; }'}

  &:focus {
    box-shadow: 0px 0px 0px 2px #80bdff;
    ${(props: IAuthInputProps) => props.notValid && 'box-shadow: 0px 0px 0px 2px red;'}
  }

  &::placeholder {
    color: gray;
  }
`;

export const AuthLoginForgotPassword = styled(CommonDiv)`
  font-weight: 500;
  flex-grow: 1;
  justify-content: flex-end;

  a {
    color: ${gray6};
    text-decoration: none;
    transition: color 0.2s;
  }

  a:hover {
    color: ${gray9};
    text-decoration: underline;
  }
`;

export const AuthShowPasswordButton = styled(CommonAnchor)`
  z-index: 1;
  font-size: 2em;
  position: absolute;
  align-self: flex-end;
  box-shadow: none;
  background: transparent;
  top: 4.5em;
  right: 1.5em;
  color: ${gray5};

  &:hover {
    box-shadow: none;
    color: ${gray7};
  }
`;

export const AuthForgotPasswordLink = styled(RawLink)`
  align-self: flex-end;
  font-weight: 500;
  color: ${gray5};
  transition: color 0.2s;
  font-size: 2.5em;
  &:hover {
    color: ${gray5};
    text-decoration: underline;
  }
`;

export const PromotionalEmailCheckbox = styled(CommonDiv)`
  font-size: 13px;
  font-weight: 800;
  color: black;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  width: calc(100% + 10px);
  user-select: none;
  cursor: pointer;

  input {
    color: green;
    background-color: green;
    margin-right: 5px;
    cursor: pointer;
  }

  ${media.small`
      margin-top: unset;
    `
  }
`;

export const AddPhotoButton = styled.label`
  cursor: pointer;
  text-align: center;
  width: 60%;
  border-radius: 3em;
  border: none;
  color: white;
  background-color: ${green1};
  transition: background-color 0.2s;
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: 600;

  &:hover {
    background-color: ${green2};
  }
`;

export const ImageWrapper = styled.div`
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 12px;
  margin-top: 10px;
`;

export const ImgPreview = styled.img`
  width: 230px;
  border: none;
  ${media.small`
      width: 120px;
    `
  }
`;

export const RemovePhotoButton = styled.button`
  width: 60%;
  border-radius: 3em;
  border: none;
  color: white;
  background-color: ${green1};
  transition: background-color 0.2s;
  font-size: 14px;
  margin-top: 25px;
  font-weight: 600;

  &:hover {
    background-color: ${green2};
  }
`;

export const InputLabelPDF = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
  font-weight: bold;
`;

export const AuthSelectProducts = styled.select`
  width: calc(100% - 2.2em);
  font-size: 2.5em;
  padding: 0.8em 1.1em;
  border-radius: 6px;
  border: none;
  outline: none;
  box-shadow: 0 0 0 1px ${gray3};
  transition: box-shadow 0.2s;
  background-color: transparent;

  &:focus, &:hover {
    outline: none;
    box-shadow: 0 0 0 2px ${gray3};
  }
  border: 1px solid #dee2e6;
`;

export const SearchPageWrapper = styled(CommonDiv)`
  justify-content: flex-start;
`;

export const ButtonAuthProductCompany = styled.button`
  border: none;
  border-radius: 14px;
  line-height: 1em;
  font-weight: 700;
  color: ${white};
  width: 100%;
  transition: background-color 0.2s;
  font-size: 3em;
  margin-top: 0.5em;
  padding: 0.5em 0;
  background-color: ${green1};

  &:hover, &:active {
    background-color: ${green2};
  }
  &:hover {
    cursor: pointer;
  }
`;

export const AddProductButton = styled.button`
  border: none;
  width: 90%;
  border-radius: 20px;
  line-height: 20px;
  font-weight: 700;
  color: ${white};
  transition: background-color 0.2s;
  font-size: 3em;
  margin-top: 0.5em;
  padding: 0.5em 0;
  background-color: ${green1};

  &:hover, &:active {
    background-color: ${green2};
  }
  &:hover {
    cursor: pointer;
  }
`;
