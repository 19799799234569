import moment from "moment";

const validateCardExpiration = (date: string) => {
    if (date && date.length === 4) {
        const receivedMonth = parseInt(date!.substring(0, 2));
        const receivedYear = parseInt(date!.substring(2, 4));
        if (receivedMonth < 1 || receivedMonth > 12) return false;
        if (receivedYear < 22) return false;
        const receivedDate = new Date(
          parseInt(`20${receivedYear}`),
          receivedMonth - 1,
          1
        );
        const today = new Date();
        const year = today.getFullYear();
        const month = today.getMonth();
        const dateToCompare = new Date(year, month, 1);
        if (!moment(receivedDate).isSameOrAfter(moment(dateToCompare))) {
          return false;
        }
      }
      return true;
};

export default validateCardExpiration;