import * as React from 'react';

export interface IconProps {
  style?: React.CSSProperties;
}

export const Icon: React.SFC<IconProps> = (props) => (
  <i className='material-icons' style={props.style || {}}>
    { props.children }
  </i>
);

export default Icon;
