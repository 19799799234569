import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: #2E2F39;
  
  div#doc-info-header {
    h1 {
      font-family: 'Denton Condensed Test';
      font-size: 3rem;
      letter-spacing: -0.05rem;
      color: #27AE60;
      text-align: center;
      margin-bottom: 1.5rem;
    }
  
    h2 {
      font-family: 'Chillax';
      font-weight: 400;
      font-size: 1.75rem;
      text-align: center;
      color: #2E2F39;
      line-height: 2.313rem;
    }
  }

  div#doc-info-body {
    font-family: 'Chillax';
    margin: 5rem 0;
    display: flex;
    column-gap: 3.75rem;
    justify-content: center;
    
    div {
      max-width: 23.438rem;

      h3 {
        font-size: 1.313rem;
        font-weight: 500;
      }

      ul {
        margin: 1rem 0;
        margin-left: 1.25rem;

        li {
          margin-left: -2.5rem; 
          line-height: 1.313rem;
        }
      }

      &:last-child {
        h3 {
          margin-bottom: 1rem;
        }

        span {
          font-family: 'Chillax';
          font-weight: 400;
          font-size: .75rem;
          color: #007D34;
        }
      }
    }
  }

  div#doc-info-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 2.813rem;
    margin-bottom: 100px;

    p {
      width: 34.375rem;
      text-align: center;
    }
  }

  @media (max-width: 600px) {
    width: 100%;
    align-items: center;

    div#doc-info-header {
      justify-content: center;
      
      h1 {
        font-size: 2.313rem;
      }
    
      h2 {
        width: 80%;
        margin: 0 auto;
        font-size: 1.313rem;
      }
    }

    div#doc-info-body {
      margin: 2.5rem auto;
      flex-direction: column;
      align-items: center;

      div {
        width: 90%;
       

        h3 {
          font-size: 1.313rem;
        }

        ul {
          width: 80%;
          margin: 0 auto;
          margin-bottom: 0.625rem;

          li {
            font-size: 1rem;
            line-height: 1.313rem;
          }
        }

        &:last-child {
          h3 {
            
          }

          span {
            font-size: 0.75rem;
            line-height: 1.031rem;
          }

          ul {
            margin-top: 0.625rem;
          }
        }
      }
    }

    div#doc-info-footer {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 0;
      margin-bottom: 4rem;

      p {
        width: 80%;
        font-size: 1rem;
        text-align: center;
        margin-bottom: 2.5rem;
      }
    }
  }
`;