import React from 'react';
import { Mutation, MutationFn } from 'react-apollo';

import {
  IMutationSendPhoneNumberVerificationCode,
  MUTATION_SEND_PHONE_NUMBER_VERIFICATION_CODE,
} from '../../graphql/mutations/users';
import Loader from '../LoaderBuffer';
import Modal from '../Modal';
import CheckPhoneNumberForm from './components/CheckPhoneNumberForm';

import {
  ErrorMessage,
  LoaderWrapper,
  ModalContent,
  ModalSubtitle,
  ModalTitle,
  ModalWrapper,
} from './styled';

type PhoneNumberVerificationModalProps = {
  display: boolean;
  phoneNumber: string;
  closeModal: () => void;
  checkPhoneNumber?: boolean;
  userCurrentId: string
};

const PhoneNumberVerificationModal: React.FC<PhoneNumberVerificationModalProps> = (
  { display, closeModal, phoneNumber, checkPhoneNumber, userCurrentId },
) => (
  <Modal closeFn={closeModal} display={display}>
    <ModalWrapper>
      <Mutation mutation={MUTATION_SEND_PHONE_NUMBER_VERIFICATION_CODE}>
        {(sendPhoneNumberVerificationCode: MutationFn<IMutationSendPhoneNumberVerificationCode>, result) => {
          const { called, error, loading } = result;

          if (!called || error) {
            return checkPhoneNumber ? (
              <ModalContent>
                <ModalTitle>Confirmar celular</ModalTitle>

                <ModalSubtitle>Este é o número do seu celular?</ModalSubtitle>

                <CheckPhoneNumberForm
                  phoneNumber={phoneNumber}
                  sendPhoneNumberVerificationCode={sendPhoneNumberVerificationCode}
                  userCurrentId={userCurrentId}
                />

                {error && error.graphQLErrors.map((graphQLError, index) => (
                  <ErrorMessage key={index}>{graphQLError.message}</ErrorMessage>
                ))}
              </ModalContent>
            ) : (
              <ModalContent>
                <ModalTitle>Confirmar celular</ModalTitle>
                <ModalSubtitle>Clique no botão abaixo para confirmar seu celular.</ModalSubtitle>
                <ModalContent>
                  <CheckPhoneNumberForm
                    phoneNumber={phoneNumber}
                    sendPhoneNumberVerificationCode={sendPhoneNumberVerificationCode}
                    userCurrentId={userCurrentId}
                  />
                  {error && error.graphQLErrors.map((graphQLError, index) => (
                    <ErrorMessage key={index}>{graphQLError.message}</ErrorMessage>
                  ))}
                </ModalContent>
              </ModalContent>
            );
          }

          if (loading) {
            return (
              <LoaderWrapper>
                <Loader />
              </LoaderWrapper>
            );
          }

          closeModal();
          return null;
        }}
      </Mutation>
    </ModalWrapper>
  </Modal>
);

export default PhoneNumberVerificationModal;
