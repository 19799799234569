import {
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import { PaymentMode } from '../../../../actionReducers/Checkout';
import toReal from '../../../../helpers/toReal';

interface IProps {
  paymentMode: 'yearly' | 'semester' | 'quarterly',
  setPaymentMode: (data: PaymentMode ) => void;
  paymentModeValues: { yearlyValue: number, semesterValue: number, quarterlyValue: number }
  changeSubscriptionPlan: any
};

const tabStyle = {
  border: '1px solid',
  borderColor: '#828392',
  borderRadius: '8px',
  alignItems: 'flex-start',
  marginRight: '8px',
  color: '#828392',
  '&.Mui-selected': {
    color: '#828392',
    border: '3px solid',
    borderColor: '#27AE60',
  }
};

const TitlelabelStyle = {
  textTransform: 'none',
  fontFamily: 'Chillax', 
  fontWeight: 500, 
  fontSize: '16px',
  color: '#000000',
  lineHeight: '19.2px', 
  marginBottom: '12px'
};

const ValuelabelStyle = {
  fontFamily: 'Chillax', 
  fontWeight: 500, 
  fontSize: '14px',
  color: '#666666',
  lineHeight: '16.8px',
};


const ChooseSubscriptionPlan = ({ paymentMode, setPaymentMode, paymentModeValues, changeSubscriptionPlan }: IProps) => {
  return (
    <Stack spacing={'20px'}>
      <Stack spacing={'8px'}>
        <Typography fontFamily={'Chillax'} variant="h5" fontWeight={500}>
          Escolha seu plano de assinatura:
        </Typography>
        <Stack>
          <Tabs
            value={paymentMode}
            onChange={(_event, value) => { setPaymentMode(value), changeSubscriptionPlan(value) }}
            textColor="inherit"
            TabIndicatorProps={{
              hidden: true,
            }}
          >
            <Tab sx={tabStyle} value={PaymentMode.YEARLY} label={
              <>
                <Typography sx={TitlelabelStyle}>12 meses</Typography>
                <Typography sx={ValuelabelStyle}>{`${toReal(paymentModeValues.yearlyValue)}`}</Typography>
                <Typography sx={ValuelabelStyle}>12x {`${toReal(paymentModeValues.yearlyValue / 12)}`}</Typography>
              </>
            }
              wrapped />
            <Tab sx={tabStyle} value={PaymentMode.SEMESTER} label={
              <>
                <Typography sx={TitlelabelStyle}>6 meses</Typography>
                <Typography sx={ValuelabelStyle}>{`${toReal(paymentModeValues.semesterValue)}`}</Typography>
                <Typography sx={ValuelabelStyle}>{`6x ${toReal(+paymentModeValues.semesterValue / 6)}`}</Typography>
              </>
            } wrapped />
            <Tab sx={tabStyle} value={PaymentMode.QUARTERLY} label={
              <>
                <Typography sx={TitlelabelStyle}>3 meses</Typography>
                <Typography sx={ValuelabelStyle}>{`${toReal(paymentModeValues.quarterlyValue)}`}</Typography>
                <Typography sx={ValuelabelStyle}>{`3x ${toReal(+paymentModeValues.quarterlyValue / 3)}`}</Typography>
              </>
            } wrapped />
          </Tabs>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default connect(() => ({
}))(ChooseSubscriptionPlan);
