const formatCPF = (cpf: string): string => {
    if (!cpf) return '';
    return cpf.replace(
        /(\d{3})(\d{3})(\d{3})(\d{2})/,
        function (_, arg1: string, arg2: string, arg3: string, arg4: string) {
            return arg1 + '.' + arg2 + '.' + arg3 + '-' + arg4;
        }
    );
};

export function formatCNPJ(cnpj: string): string {
    const cnpjClean = cnpj?.replace(/\D/g, '');
  
    return cnpjClean?.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.$2.$3/$4-$5');
  }

export default formatCPF;