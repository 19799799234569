import * as React from 'react';
import { Helmet } from 'react-helmet';
import Protect from '../../components/Protect';
import MainLayout from '../../layouts/Main';

import thanks from '../../assets/img/Thanks/thanks.png';
import { ThanksImage, ThanksText, ThankWrapper } from './styled';

const Thanks: React.FC = ({ children }) => {
  return (
    <Protect>
      <MainLayout hideSearch={true} internal={false}>
        <Helmet title='Obrigado - Allugator'
          meta={[
            { property: 'og:title', content: 'Obrigado - Allugator' },
            { property: 'og:site_name', content: 'Allugator' },
            { property: 'og:image', content: 'https://images.allugator.com/prototype/facebookgraph.png' },
            { property: 'og:image:width', content: '800' },
            { property: 'og:image:height', content: '600' },
          ]}
        />
        <ThankWrapper>
          <ThanksText>
            {children}
          </ThanksText>
          <ThanksImage>
            <img src={thanks} alt='Banner Allugator' />
          </ThanksImage>
        </ThankWrapper>
      </MainLayout>
    </Protect>
  );
};

export default Thanks;
