import { Suggest } from 'react-geosuggest';
import { ALLOWED_CITIES } from '../config/allowedCities';

interface IMapsInput {
  type: string;
  field: string;
  label: string;
}

const mapsInputList: IMapsInput[] = [
  {
    type: 'street_number',
    field: 'number',
    label: 'Número',
  },
  {
    type: 'route',
    field: 'street',
    label: 'Rua',
  },
  {
    type: 'sublocality_level_1',
    field: 'neighborhood',
    label: 'Bairro',
  },
  {
    type: 'administrative_area_level_2',
    field: 'city',
    label: 'Cidade',
  },
  {
    type: 'administrative_area_level_1',
    field: 'state',
    label: 'Estado',
  },
  {
    type: 'postal_code',
    field: 'code',
    label: 'CEP',
  },
];

const gmapsInputValidator = (values: any): string => {
  const absentValues: string[] = [];
  mapsInputList.forEach((input) => {
    const value = (values as any)[input.field];

    if (!value) {
      return absentValues.push(input.label);
    }
  });

  if (values.gmapsInput === '') {
    return 'Campo obrigatório.';
  } else if (absentValues.length === mapsInputList.length) {
    return 'Endereço não encontrado.';
  } else if (absentValues.length > 0) {
    return `Elementos ausentes no endereço: ${absentValues.join(', ')}.`;
  } else if (!ALLOWED_CITIES.includes(values.city)) {
    return `Cidade fora da nossa área de entrega: ${values.city}.`;
  }

  return null;
};

const gmapsInputValidatorLite = (values: any): string => {
  const absentValues: string[] = [];
  mapsInputList.forEach((input) => {
    const value = (values as any)[input.field];

    if (!value) {
      return absentValues.push(input.label);
    }
  });

  if (values.gmapsInput === '') {
    return 'Campo obrigatório.';
  } else if (absentValues.length === mapsInputList.length) {
    return 'Endereço não encontrado.';
  } else if (absentValues.length > 0) {
    return `Elementos ausentes no endereço: ${absentValues.join(', ')}.`;
  }

  return null;
};

const getGMapsValuesFromSuggestion = (suggest: Suggest) => {
  const { address_components } = suggest.gmaps;

  const addressValues: { [key: string]: string } = {};

  mapsInputList.forEach(({ type, field }) => {
    const address = address_components.find((component) => component.types.includes(type));

    if (address) {
      addressValues[field] =
        type === 'administrative_area_level_1'
        ? address.short_name
        : address.long_name;
    }
  });

  return addressValues;
};

export {
  IMapsInput,
  mapsInputList,
  gmapsInputValidator,
  gmapsInputValidatorLite,
  getGMapsValuesFromSuggestion,
};
