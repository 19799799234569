import React from 'react';
import { darken } from '@mui/system'
import { Box, Grid, Typography, Button, Container } from '@mui/material';
import liveloLogo from '../../../../assets/img/banners/livelo-logo.svg';
import logo from '../../../../assets/img/Home/allu_new_logo.png';

const REGULATION_LIVELO = process.env.RAZZLE_LIVELO_REGULATION;
const LIVELO_HEADER_TEXT = process.env.RAZZLE_LIVELO_HEADER_TEXT;

interface IHeaderProps {
  liveloOrigin?: boolean;
}

const Header = ({ liveloOrigin }: IHeaderProps) => {
  return (
    <Grid
      container
      sx={{
        background: '#2D2D38',
        width: '100%',
        justifyContent: 'center',
        padding: '16px',
      }}
    >
      {liveloOrigin && (
        <Container maxWidth='xl'>
          <Box
            display='flex'
            justifyContent='center'
            sx={{ justifyContent: { xs: 'center', lg: 'space-between' } }}
            alignItems='center'
            flexWrap='wrap'
            padding={2}
            gap={2}
          >
            <Box>
              <img src={liveloLogo} height={80} width='100%' />
            </Box>
            <Typography color='white' textAlign='center' maxWidth={1000} fontFamily='Chillax'>
              {LIVELO_HEADER_TEXT}
            </Typography>
            <Button
              sx={{
                textTransform: 'capitalize',
                color: 'rgb(0,0,0)',
                background: '#ffffff',
                fontFamily: 'Arial',
                fontWeight: 'bold',
                width: '138px',
                fontSize: '1rem',
                height: '40px',
                '&:hover': {
                  background: darken('#ffffff', 0.3),
                },
              }}
              href={REGULATION_LIVELO}
              target='_blank'
            >
              Regulamento
            </Button>
          </Box>
        </Container>
      )}
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <img src={logo} alt='logo allu' style={{ cursor: 'pointer' }} onClick={() => window.open("https://allugator.com", "_blank")} />
      </Grid>
    </Grid>
  );
};

export default Header;
