import { Box } from '@mui/material';
import React, { ReactNode } from 'react';
import { TabsCheckoutKey } from '../../../../actionReducers/Checkout';

interface IProps {
    children?: ReactNode;
    index: TabsCheckoutKey;
    value: TabsCheckoutKey;
}

const TabPanel = ({ children, value, index, ...other }: IProps) => {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box minWidth='100%'>{ children }</Box>
            )}
        </div>
    );
}

export default TabPanel;