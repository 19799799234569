import { Box } from '@mui/material';
import { DataGrid, GridColDef, GridRowParams } from '@mui/x-data-grid';
import React from 'react';

interface IProps {
    pageSize?: number;
    rowsPerPageOptions?: number[];
    checkboxSelection?: boolean;
    hideFooter?: boolean;
    columns: GridColDef[];
    rows: object[];
    autoHeight?: boolean;
    onClick?: (data: GridRowParams) => void;
    ContentEmpty?: React.JSXElementConstructor<any>;
}

const CustomTable = ({
    pageSize = 100,
    hideFooter = true,
    checkboxSelection = false,
    rowsPerPageOptions = [pageSize],
    autoHeight = false,
    rows,
    columns,
    onClick,
    ContentEmpty
}: IProps) => {

    return (
        <Box style={{ height: '100%', width: '100%' }}>
            <DataGrid
                rows={rows}
                columns={columns}
                pageSize={pageSize}
                rowsPerPageOptions={rowsPerPageOptions}
                checkboxSelection={checkboxSelection}
                hideFooter={hideFooter}
                autoPageSize={true}
                disableColumnSelector={true}
                disableColumnMenu={true}
                disableColumnFilter={true}
                autoHeight={autoHeight}
                disableSelectionOnClick={onClick ? false : true}
                onRowClick={onClick}
                components={{
                    NoRowsOverlay: ContentEmpty
                }}
                sx={{
                    border: 'none',
                    fontSize: { xs: '13px', sm: '15px' },
                    ".MuiDataGrid-row.Mui-selected": {
                        backgroundColor: 'rgba(20, 201, 130, 0.04)',
                    },
                    '.MuiDataGrid-columnSeparator': {
                        display: 'none',
                    },
                    '.MuiDataGrid-columnHeaderTitleContainer': {
                        justifyContent: 'flex-start',
                    },
                    '.MuiDataGrid-columnHeaders': {
                        border: 'none',
                    },
                    '.MuiDataGrid-columnHeaderTitleContainerContent': {
                        color: '#000000',
                        fontWeight: 'bold'
                    },
                    '.MuiDataGrid-cell': {
                        justifyContent: 'flex-start',
                        border: 'none',
                        color: 'rgba(0, 0, 0, 0.87)',
                        cursor: onClick ? 'pointer' : 'default',
                    },
                    '.MuiDataGrid-cellContent': {
                        whiteSpace: 'normal',
                    }
                }}
            />
        </Box>
    )
};

export default CustomTable;