import axios from "axios";
import config from "../config";

const getAddressByCEP = async (code: string) => {
  try {
    if (!config.ViaCepService) return;

    const { data } = await axios.get(
      `${config.ViaCepService}/ws/${code}/json/`
    );
    if (data.erro) return false;
    return {
      city: data.localidade,
      neighborhood: data.bairro,
      complement: data.complemento,
      state: data.uf,
      street: data.logradouro,
      code,
    };
  } catch (err) {
    throw new Error('Erro ao buscar CEP.');
  }
};

export default getAddressByCEP;
