import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { ALLUGATOR_AUTH_TOKEN } from '../../boot/constants';
import Cookies from '../../helpers/Cookies';

export const AuthLayoutWrapper = styled.div`
    
`;

export class AuthLayout extends React.Component<RouteComponentProps<{}>> {
  public componentDidMount() {
    window.scrollTo(0, 0);
    if (!!Cookies.get(ALLUGATOR_AUTH_TOKEN)) {
      this.props.history.push('/');
    }
  }
  public render() {
    const { children } = this.props;

    return (
      <AuthLayoutWrapper>
        {children}
      </AuthLayoutWrapper>
    );
  }
}
export default withRouter(AuthLayout);
