import styled, { keyframes } from 'styled-components';
import { black } from '../style/colors';

const stretchDelay = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

interface ISpinner {
  color?: string;
  width?: string;
  height?: string;
}

const Loader = styled.div`
  display: inline-block;
  width: ${(props: ISpinner) => props.width ? props.width : "64px"};
  height: ${(props: ISpinner) => props.height ? props.height : "64px"};

  &:after {
    content: " ";
    display: block;
    width: ${(props: ISpinner) => props.width ? props.width : "46px"};
    height: ${(props: ISpinner) => props.height ? props.height : "46px"};
    margin: 1px;
    border-radius: 50%;
    border: 5px solid ${(props: ISpinner) => props.color ? props.color : black};
    border-color:
      ${(props: ISpinner) => props.color ? props.color : black}
      transparent
      ${(props: ISpinner) => props.color ? props.color : black}
      transparent;

    animation: ${stretchDelay} 1.2s linear infinite;
  }
`;

export default Loader;
