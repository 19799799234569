import * as React from "react";
import { CookiesProvider } from "react-cookie";
import { hydrate } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import Root from "./boot/Root";
// import CookieConsent from './routes/Terms/CookiesConsent';
import Modal from "react-modal";
// import Analytics from "./components/Analytics";

Modal.setAppElement("#allugator");

const ClientRoot = () => (
  <CookiesProvider>
    <BrowserRouter>
      <>
        <Root />
        {/* <CookieConsent /> */}
        {/* <Analytics /> */}
        {/* <AnalyticsBrowser /> */}
      </>
    </BrowserRouter>
  </CookiesProvider>
);

hydrate(<ClientRoot />, document.getElementById("allugator"));

if ((module as any).hot!) {
  (module as any).hot.accept();
}
