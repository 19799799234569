import { Action } from 'redux';

import { SearchActionTypes } from './actionTypes';

export interface ISearchAction extends Action<SearchActionTypes> {
  [extraProp: string]: any;
}

/**
 * Reducer
 */

export interface ISearchState {
  term: string;
}

const initialState: ISearchState = {
  term: '',
};

export const searchReducer = (state: ISearchState = initialState, action: ISearchAction): ISearchState => {
  switch (action.type) {
    case SearchActionTypes.SET_TERM: {
      return { ...state, term: action.term };
    }

    default:
      return state;
  }
};

/**
 * Actions
 */

export const SET_TERM = (term: string): ISearchAction => ({
  type: SearchActionTypes.SET_TERM,
  term,
});
