import React, { useEffect, useRef } from "react";
import { Checkbox, FormControlLabel, Stack, Typography } from "@mui/material";
import { FormikErrors, FormikProps } from "formik";
import InputText from "../../../../components/Inputs/InputText";
import TextFieldMask from "../../../../components/Inputs/InputMask";
import { IFormikPersonalDataFastCheckout } from "../PersonalDataFastCheckout";
import { connect } from "react-redux";
import { IAppState } from "../../../../store";
import { IInvalidDocument } from "../..";
import Cookies from "../../../../helpers/Cookies";
import { googleTag } from "../../../../helpers/analytics";
import { EXTERNAL_LEAD_ID_FAST_CHECKOUT } from "../../../../boot/constants";

interface IProps {
  personalDataAlreadySaved?: boolean;
  invalidDocument: IInvalidDocument;
  cart?: any;
  valueForDataLayer?: number;
}

const PersonalDataForm = ({
  invalidDocument,
  personalDataAlreadySaved,
  values,
  errors,
  handleChange,
  handleBlur,
  setErrors,
  setFieldValue,
  cart,
  touched
}: IProps & FormikProps<IFormikPersonalDataFastCheckout>) => {
  const formRef = useRef(null);

  useEffect(() => {
    if (invalidDocument && invalidDocument.status) {
      setErrors({ document: invalidDocument.message });
      formRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [invalidDocument]);
  const origin = Cookies.get("origin_fast_checkout") ?? '';
  const lead_id = Cookies.get(EXTERNAL_LEAD_ID_FAST_CHECKOUT) ?? '';

  useEffect(() => {
    googleTag("checkout_stage", {
      send_to: "G-MFSWHTC64V",
      partner: origin,
      product: cart?.slug,
      lead_id: lead_id,
    });
    dataLayer.push({
      event: "checkout_stage",
      product: cart?.slug,
      origin
    });
  }, []);

  const returnErrorMessage = (
    name: string,
    errors: FormikErrors<IFormikPersonalDataFastCheckout>
  ) => (touched[name] ? errors[name] : "");

  return (
    <Stack spacing={"20px"} ref={formRef}>
      <Typography fontFamily={"Chillax"} variant="h5" fontWeight={500}>
        Dados Pessoais
      </Typography>
      <InputText
        label="Nome Completo"
        name={"name"}
        type="text"
        onBlur={handleBlur("name")}
        setValue={handleChange("name")}
        value={values.name}
        touched={true}
        errorMessage={returnErrorMessage("name", errors)}
        isRequired={true}
        replace={/[0-9.]+/g}
      />
      <InputText
        label="E-mail"
        type="text"
        name={"email"}
        onBlur={handleBlur("email")}
        setValue={handleChange("email")}
        value={values.email}
        touched={true}
        errorMessage={returnErrorMessage("email", errors)}
        id="form-checkout__cardholderEmail"
        disabled={personalDataAlreadySaved}
      />
      {!personalDataAlreadySaved ? (
        <InputText
          label="Confirmar e-mail"
          type="text"
          name={"confirmEmail"}
          onBlur={handleBlur("confirmEmail")}
          setValue={handleChange("confirmEmail")}
          value={values.confirmEmail}
          touched={true}
          errorMessage={returnErrorMessage("confirmEmail", errors)}
          id="form-checkout__cardholderConfirmEmail"
        />
      ) : null}
      <TextFieldMask
        mask="999.999.999-99"
        label="CPF"
        type="text"
        name={"document"}
        onBlur={handleBlur("document")}
        setValue={handleChange("document")}
        value={values.document}
        touched={true}
        errorMessage={returnErrorMessage("document", errors)}
        disabled={personalDataAlreadySaved}
      />
      <InputText
        label="Data de Nascimento"
        type="date"
        name={"birthdate"}
        onBlur={handleBlur("birthdate")}
        setValue={handleChange("birthdate")}
        value={values.birthdate}
        touched={true}
        errorMessage={returnErrorMessage("birthdate", errors)}
        shrink={true}
      />
      <TextFieldMask
        mask="(99)99999-9999"
        label="Celular"
        type="text"
        name={"phone"}
        onBlur={handleBlur("phone")}
        setValue={handleChange("phone")}
        value={values.phone}
        touched={true}
        errorMessage={returnErrorMessage("phone", errors)}
        isRequired={true}
      />
      {String(origin).toLowerCase() === 'nestle' ?
          (
            <FormControlLabel
              control={
                <Checkbox
                  size={'small'}
                  value={values.nestleCheck}
                  onChange={() => setFieldValue('nestleCheck', !values.nestleCheck)}
                />
              }
              label={
                <Typography fontFamily={'Chillax'} fontSize={'12px'}>
                  Eu concordo em compartilhar minhas informações com a Nestlé para receber e-mails com ofertas personalizadas.
                </Typography>
              }
            />
          ) : null}
    </Stack>
  );
};

export default connect(
  (state: IAppState) => ({
    personalDataAlreadySaved: state.fastCheckout.personalDataAlreadySaved
  }),
  null
)(PersonalDataForm);
