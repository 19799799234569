import { HelpOutline, MonetizationOnOutlined } from '@mui/icons-material';
import { Box, Grid, Icon, Stack, Tooltip, Typography } from '@mui/material';
import { Formik } from 'formik';
import React from 'react';
import { connect } from 'react-redux';
import { IDynamicKeyCheckout, PaymentType } from '../../../../actionReducers/Checkout';
import { ICheckoutRecurrenceAction, ICreditCardRecurrence, IPayment, SET_CARD_ENTRY } from '../../../../actionReducers/Recurrence';
import InputText from '../../../../components/Inputs/InputText';
import { IAppState } from '../../../../store';
import FormCard from '../../../../components/FormCard';
import TopBar from '../TopBar';
import InfoOtherPayment from './InfoOtherPayment';
import ValidatorCreditCard from '../../../../validators/validatorCreditCard';
import { ActionCreator, Dispatch } from 'redux';
import toReal from '../../../../helpers/toReal';
import { ISubscriptionPayment } from '../../../../graphql/types/checkoutOrders';

interface IProps {
    paymentEntry: IPayment;
    singleCard: boolean;
    paymentRecurence: IPayment;
    subscriptionPayment: ISubscriptionPayment;
    handleBlurFields: (field: string, value: string | number, errors: any) => void;
    setCardEntry: ActionCreator<ICheckoutRecurrenceAction>;
}

const initialValues: ICreditCardRecurrence = {
    number: '',
    holderName: '',
    expirationDate: '',
    cvv: '',
}

const EntryPayment = ({
    paymentEntry: { type, amount },
    paymentRecurence: { amount: amountMonthly },
    subscriptionPayment: { entryFeeValue },
    singleCard,
    handleBlurFields,
    setCardEntry }: IProps) => {

    const handleBlurForm = (field: string, value: string | number, errors: any) => {
        const data = handleBlurFields(field, value, errors);
        setCardEntry(data);
    };

    const handleChangeCvv = (value: string, errors: any) => {
        if (value && value.length >= 3) {
            setCardEntry({ cvv: value, errors: Object.keys(errors).length === 1 && errors.cvv ? false : true });
        } else {
            setCardEntry({ cvv: value, errors: true });
        }
    };

    return (
        <Stack>
            <Box display={'flex'} justifyContent={'space-between'}>
                <Grid display={'flex'}>
                    <Icon color={'action'}>
                        <MonetizationOnOutlined />
                    </Icon>
                    <Typography
                        variant="body1"
                        fontSize={17}
                        marginLeft={'10px'}
                        color={'#30313F'}
                    >
                        PAGAMENTO DA ENTRADA
                    </Typography>
                </Grid>
            </Box>
            <Stack spacing={'24px'}>
                <Typography variant='subtitle1' fontSize={'15px'}>Como você prefere pagar a sua entrada?</Typography>
                <TopBar />
            </Stack>
            {
                type === PaymentType.CREDIT_CARD ? (
                    <Formik
                        initialValues={initialValues}
                        onSubmit={() => { }}
                        validationSchema={ValidatorCreditCard}>
                        {(formikProps) => (
                            <FormCard
                                {...formikProps}
                                handleBlurFields={(field: string, value: string | number, errors: any) => handleBlurForm(field, value, errors)}
                                handleChangeCvv={(value: string, errors: any) => handleChangeCvv(value, errors)}
                            >
                                <InputText
                                    label="Valor de entrada"
                                    name={"entry"}
                                    type="text"
                                    style={{ width: '100%' }}
                                    value={toReal(amount)}
                                    touched={true}
                                    disabled={true}
                                    inputIcon={
                                        singleCard ? (
                                            <Tooltip title={`${toReal(entryFeeValue)} (Taxa de adesão) + ${toReal(amountMonthly)} (1ª parcela da assinatura)`} >
                                                <Icon color={'action'}>
                                                    <HelpOutline />
                                                </Icon>
                                            </Tooltip>
                                        ) : null
                                    }
                                />
                            </FormCard>
                        )}
                    </Formik>
                ) : <InfoOtherPayment />
            }
        </Stack >
    )
};

export default connect((state: IAppState) => ({
    paymentEntry: state.checkoutRecurrence.paymentEntry,
    singleCard: state.checkoutRecurrence.singleCard,
    paymentRecurence: state.checkoutRecurrence.paymentRecurrence,
    subscriptionPayment: state.checkoutRecurrence.order.subscriptionPayment,
}), (dispatch: Dispatch<ICheckoutRecurrenceAction>) => ({
    setCardEntry: (card: IDynamicKeyCheckout) => dispatch(SET_CARD_ENTRY(card))
}))(EntryPayment);