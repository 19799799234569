import styled from "styled-components";
export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: #27AE60;
  justify-content: center;
    @media(max-width: 1250px) {
    background: #F4F4EF;
    min-width: 375px;
    }
`;
export const SecondContainer = styled.div`
  display: flex;
  width: 80%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 128px 98px;
  gap: 24px;
  background: #F4F4EF;
  border-radius: 40px;
  margin-top: 80px;
  margin-bottom: 80px;
  margin-left: 144px;
  margin-right: 144px;
  
  @media(max-width:1250px) {
    display:flex;
    flex-direction: column;
    padding: 0px;
    width:100%;
    margin-top:0px;
    margin-left: 0px;
    margin-right: 0px;
  }
`;
export const ThirdContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 40px;
  width: 466px;
  margin-right: 24px;
  @media(max-width:1250px) {
    width: 336px;
    margin-right:0px;
  }
  h2 {
    font-family: 'Denton Condensed Test';
    font-weight: 700;
    font-size: 65px;
    line-height: 87px;
    color: #27AE60;
    width: 432px;
    @media(max-width:1250px) {
      font-family: 'Denton bold';
      font-size: 37px;
      line-height: 49px;
      width: 336px;
      text-align: left;
    }
  }
  p {
    font-family: 'Chillax';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    width: 354px;
    color: #2D2D38;
  }
  .form-data {
    display: flex;
    flex-direction: column;
    /* height: 136px; */
    .login-ipt {
      width: 368px;
      background: #F4F4EF;
      border-radius: 40px;
      @media(max-width:1250px) {
        width: 336px;
      }
    }
    .password-ipt {
      width: 368px;
      margin-top: 24px;
      background: #F4F4EF;
      border-radius: 40px;
      @media(max-width:1250px) {
        width: 333px;
      }
    }
    @media(max-width:1250px) {
        width: 336px;
      }
  }
`;
export const FifthContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width:466px;
  @media(max-width:1250px) {
    width: 336px;
  }
  .sade-face {
    margin-bottom:40px;
    
  }
  .allu-lover {
    font-family: 'Chillax';
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 37px;
    color: #000000;
    text-align: center;
    margin-top:40px;
    margin-bottom:0px;
    @media(max-width: 1250px) {
      font-size:16px;
      line-height: 21px;  
    }
  }
  .allu-cadastro {
    margin-top:8px;
    font-family: 'Chillax';
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 37px;
    text-align: center;
    @media(max-width: 1250px) {
      font-size:16px;
      line-height: 21px;  
    }
    
  }
`;
export const FourthContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media(max-width:1250px) {
    width:336px;
    align-items:center;
   
  }
  .word-color {
    color: #27AE60;
    font-family: Chillax;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0px;
    text-decoration: none;
    width:56px;
  }
  
  a {
    font-family: 'Chillax';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    align-items: center;
    text-decoration-line: underline;
    color: #27AE60; 
    @media(max-width:1250px) {
      margin-bottom: 24px;
    }
  }
  p {
    font-family: 'Chillax';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #2D2D38;
    width: 354px;
    padding:0px;
    @media(max-width:1250px) {
      width: 333px;
      text-align: center;
    }
    
  }
    
`;