import { CreditCard } from '@mui/icons-material';
import { Grid } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import { ActionCreator, Dispatch } from 'redux';
import { PaymentType } from '../../../../actionReducers/Checkout';
import { ICheckoutRecurrenceAction, SET_TYPE_PAYMENT_ENTRY } from '../../../../actionReducers/Recurrence';
import boleto from '../../../../assets/img/Checkout/barCode.svg';
import boletoCurrent from '../../../../assets/img/Checkout/barCodeCurrent.svg';
import BarPayments from '../../../../components/Pages/Checkout/BarPayments';
import { IAppState } from '../../../../store';

interface IProps {
    paymentType: string;
    chosenPaymentType: ActionCreator<ICheckoutRecurrenceAction>;
}

const TopBar = ({ paymentType, chosenPaymentType }: IProps) => {
    return (
        <Grid container sm={12} width={'100%'}>
            <Grid item xs={6}>
                <BarPayments title={'Cartão de Crédito'} current={paymentType === PaymentType.CREDIT_CARD} onClick={() => {chosenPaymentType(PaymentType.CREDIT_CARD)}}>
                    <CreditCard sx={{ width: '22px', height: '16px', fill: paymentType === PaymentType.CREDIT_CARD ? '#10D878' : 'rgba(0, 0, 0, 0.38)' }} />
                </BarPayments>
            </Grid>
            <Grid item xs={6}>
                <BarPayments title={'Boleto'} current={paymentType === PaymentType.BOLETO} onClick={() => {chosenPaymentType(PaymentType.BOLETO)}}>
                    {
                        paymentType === PaymentType.BOLETO
                            ? <img src={boletoCurrent} alt='icone boleto escolhido' style={{ width: '22px', height: '16px' }} />
                            : <img src={boleto} alt='icone boleto' style={{ width: '22px', height: '16px' }} />
                    }
                </BarPayments>
            </Grid>
        </Grid>
    )
};

export default connect((state: IAppState) => ({
    paymentType: state.checkoutRecurrence.paymentEntry.type,
}), (dispatch: Dispatch<ICheckoutRecurrenceAction>) => ({
    chosenPaymentType: (value: PaymentType) => dispatch(SET_TYPE_PAYMENT_ENTRY(value))
}))(TopBar);