import { HelpOutline } from '@mui/icons-material';
import { Box, Icon, Stack, Tooltip } from '@mui/material';
import { Formik } from 'formik';
import * as React from 'react';
import Cards, { Focused } from "react-credit-cards-2";
import "react-credit-cards-2/dist/es/styles-compiled.css";
import { connect } from 'react-redux';
import { ActionCreator, AnyAction, Dispatch } from 'redux';

import { ICreditCard } from "../../../actionReducers/Checkout";
import { IDynamicKeyProfile, IProfileAction, SET_CREDIT_CARD_PROFILE } from "../../../actionReducers/Profile";
import TextFieldMask from "../../../components/Inputs/InputMask";
import InputText from "../../../components/Inputs/InputText";
import { IAppState } from "../../../store";
import ValidatorCreditCard from "../../../validators/validatorCreditCard";

const initialValues: ICreditCard = {
  number: '',
  holderName: '',
  expirationDate: '',
  cvv: '',
  installments: 1,
};

interface IProps {
  creditCard: ICreditCard;
  setCreditCard: ActionCreator<IProfileAction>;
}

const ProfileAddCardsFormFields = ({ creditCard, setCreditCard }: IProps) => {

  const [open, setOpen] = React.useState<boolean>(false);
  const [focused, setFocused] = React.useState<Focused>('')

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleBlurFields = (field: string, value: string | number, errors: any) => {
    if (!value) return;
    const data = {};
    data[field] = value;
    data['errors'] = Object.keys(errors).length ? true : false;
    setCreditCard(data);
  };

  const handleChangeCvv = (value: any, errors: any) => {
    if (value && value.length >= 3) {
      setCreditCard({ cvv: value, errors: (Object.keys(errors).length === 1 && errors.cvv) || !Object.keys(errors).length ? false : true });
    } else {
      setCreditCard({ cvv: value, errors: true });
    }
  };

  const handleErrosForm = (error: boolean) => {
    setCreditCard({ errors: error });
  };

  const handleInputFocus = (evt) => {
    if(evt.target.name === 'cvv') {
      setFocused('cvc')
      return
    }
    setFocused(evt.target.name);
  }

  return (
    <Box>
      <Formik
        initialValues={creditCard || initialValues}
        onSubmit={() => { }}
        validateOnChange={true}
        validationSchema={ValidatorCreditCard}
      >
        {({
          touched,
          values,
          errors,
          handleBlur,
          handleChange,
        }) => {

          if ((Object.keys(errors).length > 0 && !creditCard.errors) || (Object.keys(errors).length === 0 && creditCard.errors)) {
            handleErrosForm(Object.keys(errors).length ? true : false);
          }

          return (
            <Stack spacing={3}>
              <Box sx={{ '& *': { margin: '0' } }}>
                <Cards
                  number={values.number ?? ''}
                  expiry={values.expirationDate ?? ''}
                  cvc={values.cvv ?? ''}
                  name={values.holderName ?? ''}
                  focused={focused}
                  locale={{ valid: 'Validade' }}
                  placeholders={{ name: 'Nome no cartão' }}
                />
              </Box>
              <Stack spacing={2}>
                <TextFieldMask
                  mask={'9999 9999 9999 9999'}
                  label="Número do Cartão"
                  type="text"
                  autoComplete="disabled"
                  name="number"
                  id="number"
                  onBlur={(e) => { handleBlur('number')(e), handleBlurFields('number', values.number, errors) }}
                  onFocus={handleInputFocus}
                  setValue={(e) => { handleChange('number')(e), handleBlurFields('number', e, errors) }}
                  value={values.number}
                  touched={touched.number || values.number ? true : false}
                  errorMessage={errors.number}
                  isRequired={true}
                />
                <InputText
                  label="Nome - Como no cartão"
                  type="text"
                  name="holderName"
                  onBlur={(e) => { handleBlur('holderName')(e), handleBlurFields('holderName', values.holderName, errors) }}
                  onFocus={handleInputFocus}
                  setValue={(e) => { handleChange('holderName')(e), handleBlurFields('holderName', e, errors) }}
                  value={values.holderName}
                  touched={touched.holderName}
                  errorMessage={errors.holderName}
                  autoComplete="disabled"
                  isRequired={true}
                  replace={/[0-9.]+/g}
                />
                <Stack
                  direction={'row'}
                  spacing={'16px'}
                >
                  <TextFieldMask
                    mask="99/99"
                    name="expirationDate"
                    autoComplete="disabled"
                    label="Validade"
                    type="text"
                    style={{ width: '50%' }}
                    onBlur={(e) => { handleBlur('expirationDate')(e), handleBlurFields('expirationDate', values.expirationDate, errors) }}
                    onFocus={handleInputFocus}
                    setValue={(e) => { handleChange('expirationDate')(e), handleBlurFields('expirationDate', e, errors) }}
                    value={values.expirationDate}
                    touched={touched.expirationDate}
                    errorMessage={errors.expirationDate}
                    isRequired={true}
                  />
                  <InputText
                    label="CVV"
                    name="cvv"
                    type="text"
                    style={{ width: '50%' }}
                    onBlur={(e) => { handleBlur('cvv')(e), handleBlurFields('cvv', values.cvv, errors) }}
                    onFocus={handleInputFocus}
                    setValue={(e) => { handleChange('cvv')(e), handleChangeCvv(e, errors) }}
                    value={values.cvv}
                    touched={touched.cvv}
                    errorMessage={errors.cvv}
                    isRequired={true}
                    maxLengthInput={4}
                    replace={/[^0-9.]+/g}
                    inputIcon={
                      <Tooltip sx={{ zIndex: 1 }} title={'O código de segurança poder ter 3 ou 4 dígitos.'} open={open} onClose={handleClose} onOpen={handleOpen} onClick={handleOpen}>
                        <Icon color={'action'}>
                          <HelpOutline />
                        </Icon>
                      </Tooltip>
                    }
                  />
                </Stack>
              </Stack>
            </Stack>
          )
        }}
      </Formik>
    </Box>
  );
};

export default connect((state: IAppState) => ({
  creditCard: state.profile.creditCard,
}), (dispatch: Dispatch<AnyAction>) => ({
  setCreditCard: (data: IDynamicKeyProfile) => dispatch(SET_CREDIT_CARD_PROFILE(data)),
}))(ProfileAddCardsFormFields);