import Axios from "axios";
import Config from "../../config";

const generateTokenCard = async (card: any) => {
    try {
        const { data } = await Axios.post(`${Config.PagarmeV5URL}/tokens?appId=${Config.PagarmeV5Key}`, {
            type: "card",
            card,
        });
        return data.id;
    } catch (err) {
        return false;
    }
};

export default generateTokenCard;