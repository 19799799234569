import * as Yup from 'yup';

const requiredMessages = {
  email: 'O email é obrigatório.',
  password: 'A senha é obrigatória.',
};

const validMessages = {
  email: 'Por favor, coloque um email válido.',
};

export const LoginFormSchema = Yup.object().shape({
  email: Yup.string().email(validMessages.email).required(requiredMessages.email),
  password: Yup.string().required(requiredMessages.password),
});
