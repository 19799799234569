import React from 'react';
import { Tabs, Tab, Box, Typography, TabProps } from '@mui/material';
import { Check } from '@mui/icons-material'
import { TabsCheckoutKey, TabsCheckoutValues } from '../../../../actionReducers/Checkout';
import { connect } from 'react-redux';
import { styled } from '@mui/material/styles';
import { IAppState } from '../../../../store';
import { ActionCreator, Dispatch } from 'redux';
import useMediaQuery from '@mui/material/useMediaQuery';
import { IFastCheckoutAction, SET_CURRENT_TAB_FAST_CHECKOUT, steps } from '../../../../actionReducers/FastCheckout';

const CustomTabs = styled(Tabs)({
  alignItems: 'flex-start',
  '& .MuiTabs-indicator': {
    backgroundColor: 'transparent',
  },
});

const CustomTab = styled((props: TabProps & { overpast: boolean }) => <Tab disableRipple {...props} />)(
  ({ overpast }) => ({
    paddingTop: 0,
    paddingBottom: 0,
    justifyContent: 'flex-start',
    color: overpast && '#27AE60'
  }),
);

interface IProps {
  currentTab: TabsCheckoutKey;
  changeTab: ActionCreator<IFastCheckoutAction>;
}

const TabMenu = ({ currentTab, changeTab }: IProps) => {
  const handleChange = (_, newTab: TabsCheckoutKey) => {
    changeTab(newTab);
  };

  const overpastSteps = steps.slice(0, steps.findIndex((value) => value === currentTab))

  const active = (tab, isOverpast) => currentTab === tab || isOverpast ? '#27AE60' : '#C1C1BD'

  const activeTextColor = (tab, isOverpast) => currentTab === tab || isOverpast ? '#27AE60' : 'white'

  const isOverpast = (tab) => overpastSteps.findIndex((value) => value === tab) >= 0

  const matches = useMediaQuery('(max-width:500px)');

  const iconPosition = matches ? 'top' : 'start'

  return (
    <Box maxWidth={900} margin="0 auto" py={4} bgcolor="white">
      <CustomTabs
        value={currentTab}
        onChange={handleChange}
        variant="fullWidth"
      >
        <CustomTab
          overpast={isOverpast(TabsCheckoutKey.PERSONAL_DATA)}
          icon={
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height={40}
              width={40}
              
              color={activeTextColor(TabsCheckoutKey.PERSONAL_DATA, isOverpast(TabsCheckoutKey.PERSONAL_DATA))}
              bgcolor={() => active(TabsCheckoutKey.PERSONAL_DATA, isOverpast(TabsCheckoutKey.PERSONAL_DATA))} borderRadius="50%">
              {isOverpast(TabsCheckoutKey.PERSONAL_DATA) ?
                <Check sx={{ color: "white" }} /> :
                <Typography fontSize={23} color="white" fontWeight={500} fontFamily='Chillax'>1</Typography>
              }
            </Box>
          }
          value={TabsCheckoutKey.PERSONAL_DATA}
          iconPosition={iconPosition}
          label={TabsCheckoutValues.PERSONAL_DATA}
        />
        <CustomTab
          overpast={isOverpast(TabsCheckoutKey.ADDRESS)}
          icon={
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height={40}
              width={40}
              bgcolor={() => active(TabsCheckoutKey.ADDRESS, isOverpast(TabsCheckoutKey.ADDRESS))} borderRadius="50%">
              {isOverpast(TabsCheckoutKey.ADDRESS) ?
                <Check sx={{ color: "white" }} /> :
                <Typography fontSize={23} color="white" fontWeight={500} fontFamily='Chillax'>2</Typography>
              }
            </Box>
          }
          iconPosition={iconPosition}
          value={TabsCheckoutKey.ADDRESS}
          label={TabsCheckoutValues.ADDRESS}
          disabled={currentTab === TabsCheckoutKey.PERSONAL_DATA}
        />
        <CustomTab
          overpast={isOverpast(TabsCheckoutKey.PAYMENT)}
          icon={
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height={40}
              width={40}
              bgcolor={() => active(TabsCheckoutKey.PAYMENT, isOverpast(TabsCheckoutKey.PAYMENT))} borderRadius="50%">
              {isOverpast(TabsCheckoutKey.PAYMENT) ?
                <Check sx={{ color: "white" }} /> :
                <Typography fontSize={23} color="white" fontWeight={500} fontFamily='Chillax'>3</Typography>
              }
            </Box>
          }
          iconPosition={iconPosition}
          value={TabsCheckoutKey.PAYMENT}
          label={TabsCheckoutValues.PAYMENT}
          disabled={currentTab === TabsCheckoutKey.PERSONAL_DATA || currentTab === TabsCheckoutKey.ADDRESS}
        />
        <CustomTab
          overpast={isOverpast(TabsCheckoutKey.SUMMARY)}
          icon={
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height={40}
              width={40}
              bgcolor={() => active(TabsCheckoutKey.SUMMARY, isOverpast(TabsCheckoutKey.SUMMARY))} borderRadius="50%">
              {isOverpast(TabsCheckoutKey.SUMMARY) ?
                <Check sx={{ color: "white" }} /> :
                <Typography fontSize={23} color="white" fontWeight={500} fontFamily='Chillax'>4</Typography>
              }
            </Box>
          }
          iconPosition={iconPosition}
          value={TabsCheckoutKey.SUMMARY}
          label={TabsCheckoutValues.SUMMARY}
          disabled={currentTab === TabsCheckoutKey.PERSONAL_DATA || currentTab === TabsCheckoutKey.ADDRESS || currentTab === TabsCheckoutKey.PAYMENT}
        />
      </CustomTabs>
    </Box>
  );
};

export default connect((state: IAppState) => ({
  currentTab: state.fastCheckout.currentTab,
}), (dispatch: Dispatch<IFastCheckoutAction>) => ({
  changeTab: (value: TabsCheckoutKey) => dispatch(SET_CURRENT_TAB_FAST_CHECKOUT(value))
}))(TabMenu);