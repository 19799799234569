import React from 'react';
import { ICreditCard } from '../../../../actionReducers/Checkout';
import { IExpectedPayments } from '../../../../graphql/types/checkoutOrders';
import FeedbackModal from '../FeedbackModal';
import PaymentModal from '../PaymentModal';

export enum ModalType {
    PAYMENT = 'payment',
    FEEDBACK = 'feedback'
};

interface IProps {
    currentCardPayment: IExpectedPayments;
    open: boolean,
    handleClose: () => void;
    amountMonth: number;
    submit: (value: ICreditCard, changeCardAllCharges?: boolean) => void;
    modalType: ModalType;
    loading: boolean;
    oldRecurrence: boolean;
}

const TimelineModal = ({ currentCardPayment, open, handleClose, amountMonth, submit, modalType, loading, oldRecurrence }: IProps) => {
    if (modalType === ModalType.PAYMENT) {
        return (
            <PaymentModal
                currentCardPayment={currentCardPayment}
                open={open}
                handleClose={handleClose}
                amountMonth={amountMonth}
                submit={submit}
                loading={loading}
                oldRecurrence={oldRecurrence}
            />

        )
    } else return <FeedbackModal open={open} handleClose={handleClose} />
};

export default TimelineModal;