import { Action } from 'redux';

import { SidebarActionTypes } from './actionTypes';

export interface ISidebarAction extends Action<SidebarActionTypes> {
  [extraProp: string]: any;
}

/**
 * Reducer
 */
export interface ISidebarState {
  open: boolean;
}

const initialState: ISidebarState = {
  open: false,
};

export const sidebarReducer = (state: ISidebarState = initialState, action: ISidebarAction): ISidebarState => {
  switch (action.type) {
    case SidebarActionTypes.TOGGLE_SIDEBAR: {
      return { ...state, open: !state.open };
    }

    default:
      return state;
  }
};

/**
 * Actions
 */
export const TOGGLE_SIDEBAR = (): ISidebarAction => ({
  type: SidebarActionTypes.TOGGLE_SIDEBAR,
});
