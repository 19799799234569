import styled from "styled-components";
interface PropsNavbar {
  isFixedBar: boolean;
}
interface PropsActions {
  loggedState: boolean;
  missingPayment?: boolean;
  isFixedBar: boolean;
  preRelease?: boolean;
}





export const MenuMobile = styled.nav<PropsActions>`
  .burguerBtn {
      display: block;
      border: none;
      background: #2D2D38;
      width: 30px;
      margin-right: 27px;
    }
`;

 




export const Actions = styled.div<PropsActions>`
${(props: PropsNavbar) => props.isFixedBar && `
        color: #FFFFFF;`
};
  a {
    cursor: ${(props: PropsActions) => props.missingPayment ? 'not-allowed' : 'pointer'};
    text-decoration: none;
    font-weight: 700;
    &.link_actions {
      color: ${(props: PropsActions) => props.preRelease ? '#535353' : '#FFFFFF'};
      width: 157px;
      height: 31px;
      font-family: 'Chillax';
      font-style: normal;
      font-weight: 500;
      font-size: 23px;
      line-height: 31px;
        &.link_produtos {
          width: 160px;
          font-size: 21px;
        }
        &.link_central_ajuda {
          width: 200px;
          font-size: 21px;
          margin-left: 0px;
        }
        &.link_pedidos {
            width: 200px;
            font-size: 21px;
        }
        &.link_editarConta {
            width: 200px;
            font-size: 21px;
        }
        &.link_sair {
            width: 123px;
            font-size: 21px;
            border-radius: 21px;
            border: 2px solid #F4F4EF;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }
    }
  }
`;