import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ICreditCard, PaymentType, TabsCheckoutKey } from '../../../../actionReducers/Checkout';
import { IProduct } from '../../../../graphql/types/products';
import { IAppState } from '../../../../store';
import CustomButton from '../../../../components/Rebranding/CustomButton';

interface IProps {
  total: number;
  subTotal: number;
  hasDiscount: boolean;
  paymentType: PaymentType;
  selectedProduct: IProduct;
  currentTab: TabsCheckoutKey;
  disabled: boolean;
  creditCard: ICreditCard;
  amountMonth: number;
  loading: boolean;
  nextStep: () => void;
  liveloOrigin?: boolean;
  liveloScore?: number;
}

const NextStepButton = ({ selectedProduct, currentTab, disabled, loading, nextStep }: IProps) => {
  const [titleButton, setTitleButton] = useState<string>('ir para endereço');

  useEffect(() => {
    if (currentTab) handleTitleButton();
  }, [currentTab]);

  const handleTitleButton = () => {
    switch (currentTab) {
      case TabsCheckoutKey.PERSONAL_DATA:
        setTitleButton('ir para endereço');
        break;
      case TabsCheckoutKey.ADDRESS:
        setTitleButton('ir para pagamento');
        break;
      case TabsCheckoutKey.PAYMENT:
        setTitleButton('revisar pedido');
        break;
      case TabsCheckoutKey.SUMMARY:
        setTitleButton('finalizar');
        break;
    }
  };

  return (
    <>
      {
        selectedProduct &&
        <CustomButton
          variant="contained"
          size="large"
          color="primary"
          disabled={disabled || loading}
          onClick={nextStep}
          title={titleButton}
          style={{ width: '100%' }}
        />
      }
    </>
  )
};

export default connect((state: IAppState) => ({
  total: state.cart.total,
  subTotal: state.cart.subtotal,
  items: state.cart.items,
  paymentType: state.fastCheckout.paymentType,
  hasDiscount: state.fastCheckout.hasDiscount,
  currentTab: state.fastCheckout.currentTab,
  creditCard: state.fastCheckout.creditCard,
  amountMonth: state.fastCheckout.amountMonth,
}))(NextStepButton);