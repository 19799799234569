import * as Yup from 'yup';

const validation = Yup.object({
  phoneNumber: Yup
    .string()
    .matches(/^(\([1-9][1-9]\) 9[1-9][0-9]{3}-[0-9]{4})$/, 'Celular inválido.')
    .required('Campo obrigatório.'),
});

export default validation;
