import { Button } from '@mui/material';
import {
  Field,
  FieldProps,
  FormikProps,
  withFormik,
} from 'formik';
import * as React from 'react';
import { MutationFn } from 'react-apollo';
import { toast } from 'react-toastify';

import { IMutationSendPhoneNumberVerificationCode } from '../../../../graphql/mutations/users';
import formatPhoneNumber from '../../../../helpers/formatPhoneNumber';
import updateUser from '../../../../services/users/updateUser';
import {
  FormikFixedForm,
  InputWrapper,
} from '../../../styled';
import {
  ModalSubtitle,
  PhoneNumberError,
  PhoneNumberInput,
} from '../../styled';
import validationSchema from './validation';

interface IFields {
  phoneNumber: string;
}

interface IProps {
  phoneNumber: string;
  userCurrentId: string;
  sendPhoneNumberVerificationCode: MutationFn<IMutationSendPhoneNumberVerificationCode>;
}

const CheckPhoneNumberForm = (props: IProps & FormikProps<IFields>) => {
  const {
    errors,
    touched,
  } = props;

  return (
    <FormikFixedForm style={{ flex: 1 }}>
      <Field
        name='phoneNumber'
        render={({ field }: FieldProps<IFields>) => (
          <InputWrapper>
            <PhoneNumberInput
              {...field}
              placeholder='Digite seu celular...'
              guide={false}
              mask={[
                '(', /[0-9]/, /[0-9]/, ')', ' ',
                /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/,
                '-', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/,
              ]}
            />

            <PhoneNumberError>
              {errors.phoneNumber && touched.phoneNumber ? errors.phoneNumber : ''}
            </PhoneNumberError>

            <ModalSubtitle>
              O número acima será utilizado pela nossa equipe para entrar em contato com você nos próximos passos do seu pedido
            </ModalSubtitle>
          </InputWrapper>
        )}
      />
      <Button
        variant="contained"
        fullWidth
        size="large"
        color="primary"
        type='submit'
      >
        Confirmar
      </Button>
    </FormikFixedForm>
  );
};

export default withFormik<IProps, IFields>({
  async handleSubmit(values, { props }) {
    const {
      userCurrentId,
      sendPhoneNumberVerificationCode,
    } = props;

    try {
      const phoneNumberDigits = values.phoneNumber.replace(/\D+/g, '');
      await updateUser({
        id: userCurrentId,
        values: {
            phone: phoneNumberDigits,
        }
      });
      await sendPhoneNumberVerificationCode();
      toast.success('Número confirmado com sucesso');
    } catch (error) {
      toast.error('Erro ao confirmar seu número.');
    }
  },
  mapPropsToValues(props) {
    const { phoneNumber } = props;

    return {
      phoneNumber: phoneNumber ? formatPhoneNumber(phoneNumber) : '',
    };
  },
  validationSchema,
})(CheckPhoneNumberForm);
