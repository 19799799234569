import { MonetizationOnOutlined } from '@mui/icons-material';
import { Box, Grid, Icon, MenuItem, Select, Stack, Typography } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import { PaymentType } from '../../../actionReducers/Checkout';
import { ISubscriptionPayment } from '../../../graphql/types/checkoutOrders';
import { ICompany } from "../../../graphql/types/company";
import { IUserAddress } from '../../../graphql/types/users';
import { validateDeliveryAddressStepCheckout, validatePersonalDataRecurrence } from '../../../helpers/validateDataUser';
import { IAppState } from '../../../store';
import { IFormikPersonalDataRecurrenceFields } from '../PersonalData';
import EntryPayment from './Entry';
import RecurrencePayment from './Recurrence';

interface IProps {
    dataPersonal: IFormikPersonalDataRecurrenceFields;
    deliveredAddress: IUserAddress;
    singleCard: boolean;
    paymentEntry: PaymentType;
    subscriptionPayment: ISubscriptionPayment;
    paymentDay: number | string;
    company: ICompany;
    handlePaymentDay: React.Dispatch<React.SetStateAction<number>>;
}

const paymentDayOptions = [1, 5, 10, 15, 20, 25];

const handleBlurFields = (field: string, value: string | number, errors: any) => {
    if (!value) return;
    const data = {};
    data[field] = value;
    data['errors'] = Object.keys(errors).length ? true : false;
    return data;
};

const PaymentStep = ({
    deliveredAddress,
    dataPersonal,
    subscriptionPayment: { entryFeeValue },
    handlePaymentDay,
    paymentDay,
    company
}: IProps) => {
    const canPay = company
        ? true
        : deliveredAddress && dataPersonal && validatePersonalDataRecurrence(dataPersonal) && validateDeliveryAddressStepCheckout(deliveredAddress)

    return (
        <Grid
            container
            padding={'24px'}
            borderRadius={'8px'}
            boxShadow={'0px 5px 8px rgba(120, 111, 111, 0.15)'}
        >
            <Grid item sm={12} width={'100%'}>
                <Stack spacing={'24px'}>
                    {canPay ? (
                        <React.Fragment>
                            {
                                entryFeeValue
                                    ? <EntryPayment handleBlurFields={handleBlurFields} />
                                    : null
                            }
                            <RecurrencePayment handleBlurFields={handleBlurFields} />
                            <Typography
                                variant='subtitle1'
                                fontSize={'15px'}
                                marginBottom={2}
                            >
                                Escolha o melhor dia para a cobrança mensal (começa a valer a partir da segunda parcela da sua assinatura):
                            </Typography>
                            <Select
                                value={paymentDay}
                                onChange={(event) => handlePaymentDay(event.target.value as number)}
                                displayEmpty
                                size="small"
                                renderValue={selected => selected ? selected : "Selecione o dia para cobrança"}
                            >
                                {paymentDayOptions.map(day => (
                                    <MenuItem key={day} value={day}>
                                        {day}
                                    </MenuItem>
                                ))}
                            </Select>
                        </React.Fragment>
                    ) : (
                        <Stack spacing={'24px'}>
                            <Box display={'flex'} justifyContent={'space-between'}>
                                <Grid display={'flex'}>
                                    <Icon color={'action'}>
                                        <MonetizationOnOutlined />
                                    </Icon>
                                    <Typography
                                        variant="body1"
                                        fontSize={17}
                                        marginLeft={'10px'}
                                        color={'#30313F'}
                                    >
                                        DADOS DE PAGAMENTO 
                                    </Typography>
                                </Grid>
                            </Box>
                            <Typography variant={'caption'} fontSize={13} color={'#666666'}>
                                Aguardando o preenchimento dos dados Dados de endereço
                            </Typography>
                        </Stack>
                    )}
                </Stack>
            </Grid>
        </Grid>
    );
};

export default connect((state: IAppState) => ({
    singleCard: state.checkoutRecurrence.singleCard,
    paymentEntry: state.checkoutRecurrence.paymentEntry.type,
    subscriptionPayment: state.checkoutRecurrence.order.subscriptionPayment,
    company: state.checkoutRecurrence.order.company
}))(PaymentStep);
