import { InMemoryCache } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import { ApolloLink, NextLink, Operation } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { HttpLink } from 'apollo-link-http';
import fetch from 'cross-fetch';

import Cookies from 'universal-cookie';
import Config from '.';
import { ALLUGATOR_AUTH_TOKEN, NEW_TOKEN_HEADER } from '../boot/constants';

const isBrowser = typeof window !== 'undefined';

const Link = new HttpLink({
  uri: Config.YacareGraphQL,
  fetch,
});

const Authentication = (CookiesWrapper: Cookies) => setContext(() => ({
  headers: {
    Authorization: `Bearer ${CookiesWrapper.get(ALLUGATOR_AUTH_TOKEN) || ''}`,
  },
}));

const ReAuthenticate = (CookiesWrapper: Cookies) => new ApolloLink((operation: Operation, forward: NextLink) =>
  forward(operation).map((response) => {
    const context = operation.getContext();
    const responseHeaders = context.response.headers;
    const newToken = responseHeaders.get(NEW_TOKEN_HEADER);

    if (newToken && newToken !== '') {
      CookiesWrapper.set(ALLUGATOR_AUTH_TOKEN, responseHeaders.get(NEW_TOKEN_HEADER), { path: '/' });
    }

    return response;
  }));

export const Client = (CookiesWrapper: Cookies) => new ApolloClient({
  connectToDevTools: isBrowser,
  ssrMode: !isBrowser,
  link: ReAuthenticate(CookiesWrapper).concat(Authentication(CookiesWrapper).concat(Link)),
  cache: isBrowser
    ? new InMemoryCache({addTypename: false}).restore((window as any).__APOLLO_STATE__)
    : new InMemoryCache({addTypename: false}),
  ssrForceFetchDelay: 100,
});
