import { Box } from '@mui/material';
import styled from 'styled-components';

export const Container = styled(Box)`
  margin: 0 auto;
  padding: 20px 0px 40px;
  max-width: 410px;

  .MuiStepLabel-labelContainer {
    max-width: 107px;
  }
  .MuiStepIcon-root {
    color: rgba(0, 0, 0, 0.38);
  }
  .Mui-active {
    color: #000;
  }
  .Mui-completed {
    color: #2e7d32;
  }
`;
