import { createTheme, responsiveFontSizes } from '@mui/material';

let theme = createTheme({
  typography: {
    
    fontFamily: ['lato', 'sans-serif', 'Arial'].join(','),
    h1: {
      fontFamily: 'sans-serif',
    },
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#27AE60',
      contrastText: "#fff"
    },
    secondary: {
      main: '#10D878',
    },
  },
});

theme = responsiveFontSizes(theme);

export default theme;
