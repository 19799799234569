import styled from 'styled-components';

import {
  blue,
  green1,
  green2,
  red,
  white,
} from '../../style/colors';
import { CommonButton, CommonMaskedInput, FieldError } from '../styled';

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 20px;
  min-height: inherit;
  justify-content: center;
  text-align: center;
`;

export const ModalTitle = styled.h1`
  font-size: 18px;
  margin-top: 30px;
`;

export const ModalSubtitle = styled.p`
  font-size: 16px;
`;

export const ModalText = styled.p`
  font-size: 14px;
`;

export const ModalAnchorButton = styled.button`
  cursor: pointer;
  border: none;
  background: transparent;
  color: ${blue};
  text-decoration: underline;
  outline: none;
  font-size: 14px;
`;

export const ModalButton = styled(CommonButton)`
  margin-top: auto;
  font-size: 16px;
  padding: 10px 8px;
  width: 100%;
  background: ${green1};
  color: ${white};
  box-shadow: unset;

  &:hover:enabled {
    background-color: ${green2};
    color: ${white};
    box-shadow: unset;
  }
`;

export const ModalRoundButton = styled(ModalButton)`
  width: 200px;
  height: 200px;
  border-radius: 100%;
  margin: auto;
`;

export const ModalContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const LoaderWrapper = styled.div`
  align-self: center;
`;

export const ErrorWrapper = styled.div`
  margin:auto;
`;

export const ErrorMessage = styled(ModalText)`
  color: ${red}
`;

export const PhoneNumberInput = styled(CommonMaskedInput)`
  text-align: center;
  font-size: 24px;
  width: 100%;
`;

export const PhoneNumberError = styled(FieldError)`
  min-height: 16px;
  align-self: center;
`;
