import React from 'react';
import { Button, Card, Stack, Typography } from '@mui/material';
import { connect } from 'react-redux';
import { IAppState } from '../../../../store';
import { PaymentType } from '../../../../actionReducers/Checkout';

interface IProps {
    paymentType: PaymentType;
    linkPayment: string;
    paymentExpired?: Number;
}

const TrackOrder = ({ paymentType, linkPayment, paymentExpired }: IProps) => {

    return (
        <Card
            sx={{
                boxShadow: '0px 5px 8px rgba(120, 111, 111, 0.15)',
                borderRadius: '8px',
                padding: '24px',
            }}
        >
            <Stack spacing={'12px'} sx={{ paddingBottom: '20px' }}>
                {
                    paymentType === PaymentType.BOLETO ? (
                        <>
                            <Typography variant="h6" fontSize={21}>
                                Boleto Bancário
                            </Typography>
                            <Typography variant="subtitle1" fontSize={15}>
                                Pague o seu boleto de entrada aqui:
                            </Typography>
                            <Button
                                variant="contained"
                                size="large"
                                color="primary"
                                onClick={() =>
                                    window.open(linkPayment, '_blank')
                                }
                            >
                                VISUALIZAR BOLETO
                            </Button>
                        </>
                    ) : (
                        <Typography variant="h6" fontSize={21}>
                            Acompanhe seu pedido através da nossa plataforma
                        </Typography>
                    )
                }
            </Stack>
            <Stack spacing={'12px'}>
                {
                    paymentType === PaymentType.BOLETO ? (
                        <Typography variant="caption" fontSize={15}>
                            O pagamento deve ser efetuado em até {paymentExpired || 1} dias úteis.
                        </Typography>
                    ) : (
                        <Typography variant="caption" fontSize={15}>
                            Os dados do pedido serão enviados para o seu e-mail.
                        </Typography>
                    )
                }

            </Stack>
        </Card>
    );
};

export default connect((state: IAppState) => ({
    paymentType: state.checkoutRecurrence.paymentEntry.type,
    linkPayment: state.checkoutRecurrence.paymentEntry.linkPayment
}))(TrackOrder);
