import { CheckCircle } from '@mui/icons-material';
import { Box, Stack, Typography } from "@mui/material";
import React from 'react';
import CustomModal from "../../../CustomModal";

interface IProps {
    open: boolean;
    handleClose: () => void;
}

const FeedbackModal = ({ open, handleClose }: IProps) => {
    return (
        <CustomModal open={open} handleClose={handleClose} width={526} paddingBase={48}>
            <Box sx={{ width: '100%', textAlign: 'center' }}>
                <CheckCircle sx={{ fill: '#00BFA5', marginBottom: '35px', width: '36px', height: '36px' }} />
                <Stack spacing={'8px'} sx={{ textAlign: 'center' }}>
                    <Typography variant='h5' color={'#169E7E'}>Seu pagamento está sendo processado!</Typography>
                    <Typography variant='subtitle2' color={'#666666'}>Aguarde alguns instantes para efetuação do pagamento.</Typography>
                </Stack>
            </Box>
        </CustomModal>
    )
};

export default FeedbackModal;