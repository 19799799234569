import { Action } from 'redux';
import { DocumentsActionTypes } from './actionTypes';

export interface IDocumentAction extends Action<DocumentsActionTypes> {
  [extraProp: string]: any;
}

export interface IDocumentState {
  answer: string;
  isAdmin: boolean;
};

const initialState = {
  answer: '',
  isAdmin: null,
}

export const documentReducer = (
  state: IDocumentState = initialState,
  action: IDocumentAction
): IDocumentState => {
  switch (action.type) {
    case DocumentsActionTypes.SET_DIFFERENT_ADDRESS_ANSWER: {
      return { ...state, answer: action.answer };
    }
    case DocumentsActionTypes.SET_IS_ADMIN: {
      return { ...state, isAdmin: action.isAdmin };
    }
    default:
      return state;
  }
};

export const SET_DIFFERENT_ADDRESS_ANSWER = (answer: string): IDocumentAction => ({
  type: DocumentsActionTypes.SET_DIFFERENT_ADDRESS_ANSWER,
  answer
});

export const SET_IS_ADMIN = (isAdmin: boolean): IDocumentAction => ({
  type: DocumentsActionTypes.SET_IS_ADMIN,
  isAdmin
});