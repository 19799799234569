import Axios, { AxiosResponse } from "axios";
import Config from "../config";
import convertObjectToQueryString from "../helpers/convertObjectToQueryString";
import { ICreateDeal, ICreatedUser, IDealData, IDealDataFormatHubspot, ILoginFacebookUser, ILoginGoogleUser, IPersonalDataDeal } from "../types/users";

export const getIdLastDocumentByToken = async (userToken: string) => {
  try {
    const lastDocument: AxiosResponse = await Axios.get(
      `${Config.UserUrlApi}/documentsUser/getLastByToken`,
      {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      }
    );
    let docId: string;
    if (lastDocument.status === 200 && lastDocument.data && lastDocument.data._id) {
      docId = lastDocument.data._id;
    }
    return docId;
  } catch (error) {
    //
  }
};

export const getLastDocumentByToken = async (userToken: string) => {
  try {
    const lastDocument: AxiosResponse = await Axios.get(
      `${Config.UserUrlApi}/documentsUser/getLastByToken`,
      {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      }
    );
    if (lastDocument.status === 200 && lastDocument.data && lastDocument.data._id) {
      return lastDocument.data;
    }
    throw new Error("Erro ao buscar documento");
  } catch (error) {
    //
  }
};

export const getStatusIntegrationById = async (docId: string, userToken: string) => {
  try {
    const response = await Axios.get(`${Config.UserUrlApi}/documentsUser/getStatusIntegrationById/${docId}`,
      {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      }
    );
    if (response.status === 200 && response.data && response.data.statusIntegration) {
      return response.data;
    }
  } catch (error) {
    //
  }
};

export const create = async (payload: ICreatedUser) => {
  try {
    const { captchaRef, ...payloadFinal } = payload;
    const response = await Axios.post(`${Config.UserUrlApi}/users/create`, payloadFinal);
    return response.data;
  } catch (err) {
    if (err && err.response && err.response.data && err.response.data.message) {
      throw new Error(err.response.data.message);
    }
  }
};

export const loginFacebook = async (params: ILoginFacebookUser) => {
  try {
    const response = await Axios.get(`${Config.UserUrlApi}/users/loginFacebook${convertObjectToQueryString(params)}`);
    return response.data;
  } catch (err) {
    if (err && err.response && err.response.data && err.response.data.message) {
      throw new Error(err.response.data.message);
    }
  }
};

export const loginGoogle = async (params: ILoginGoogleUser) => {
  try {
    const response = await Axios.get(`${Config.UserUrlApi}/users/loginGoogle${convertObjectToQueryString(params)}`);
    return response.data;
  } catch (err) {
    if (err && err.response && err.response.data && err.response.data.message) {
      throw new Error(err.response.data.message);
    }
  }
};

export const createDealHubspotFromData = async (payload: IDealData) => {
  const response = await Axios.post(`${Config.UserUrlApi}/users/hubspotDealsFromData`, payload);
  return response.data;
};

export const createDealHubspot = async (payload: ICreateDeal, userToken: string) => {
  try {
    const response = await Axios.post(`${Config.UserUrlApi}/users/hubspotDeals`, payload,
      {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      }
    );
    return response.data;
  } catch (err) {
    if (err && err.response && err.response.data && err.response.data.message) {
      throw new Error(err.response.data.message);
    }
  }
};

export const removeDealHubspot = async (pipeline: string, userToken: string) => {
  try {
    const response = await Axios.delete(`${Config.UserUrlApi}/users/hubspotDeals/${pipeline}`, {
      headers: {
        Authorization: `Bearer ${userToken}`
      },
    });
    return response.data;
  } catch (err) {
    if (err && err.response && err.response.data && err.response.data.message) {
      throw new Error(err.response.data.message);
    }
  }
};

export const updateDealHubspot = async (pipeline: string, dealName: string, userToken: string, contactData?: IPersonalDataDeal, dealData?: IDealDataFormatHubspot, campaign?: string) => {
  const response = await Axios.patch(`${Config.UserUrlApi}/users/hubspotDeals`, {
    pipeline,
    dealName,
    contactData,
    dealData,
    campaign
  },
    {
      headers: {
        Authorization: `Bearer ${userToken}`
      }
    });
  return response;
};

export const getDealHubspot = async (pipeline: string, userToken: string) => {
  const response = await Axios.get(`${Config.UserUrlApi}/users/hubspotDeals/${pipeline}`,
    {
      headers: {
        Authorization: `Bearer ${userToken}`
      }
    });
  return response.data;
};