import { Alert, Button, Stack, Typography } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { IAppState } from "../../../../../store";
import { RouteComponentProps, withRouter } from "react-router-dom";
import moment from "moment";
import { withApollo, WithApolloClient } from "react-apollo";
import InformationFastOrder from "../InformationFastOrdem";
import InformationNewAccount from "../InformationNewAccount";

interface IProps extends RouteComponentProps<{}>, WithApolloClient<{}> {
  linkPayment: string;
  finishedMoment: Date;
  newAccount: boolean;
}

const PaymentPix = ({
  linkPayment,
  finishedMoment,
  history,
  newAccount
}: IProps) => {
  const paymentExpired = 1;

  const openLinkPayment = () => {
    window.open(linkPayment, '_blank').focus();
  }

  return (
    <Stack
      spacing={"24px"}
      sx={{
        alignItems: {
          xs: "center",
          sm: "center",
          md: "flex-start"
        }
      }}
    >
      <Alert
        severity="info"
        sx={{
          width: "320px",
          fontFamily: "Lato",
          fontSize: 15,
          fontWeight: 400,
          display: "flex"
        }}
      >
        Vencimento:&nbsp;
        {moment(finishedMoment)
          .add(paymentExpired, "days")
          .locale("pt-br")
          .format("DD [de] MMMM [às] HH:mm")}
      </Alert>
      <>
        <Stack
          spacing={"24px"}
          sx={{
            flexDirection: "column",
            alignItems: { xs: "center", sm: "center", md: "flex-start" }
          }}
        >
          <Button
            variant="contained"
            size="large"
            color="primary"
            sx={{ fontFamily: "Roboto", fontSize: "15px", fontWeight: 500 }}
            onClick={() => openLinkPayment()}
          >
            Pagar com Picpay
          </Button>
          <Typography
            fontFamily={"Lato"}
            fontSize={17}
            fontWeight={600}
            color={"#000000"}
            width={"100%"}
          >
            Agora é só fazer o pagamento via Picpay antes do vencimento para
            garantir o seu pedido!
          </Typography>
          {newAccount ? <InformationNewAccount /> : null}
          <InformationFastOrder />
        </Stack>
      </>
      <Stack spacing={"12px"}>
        <Typography
          sx={{
            fontFamily: "Lato",
            fontWeight: "500",
            fontSize: "15px",
            lineHeight: "23.55px",
            letterSpacing: "0.1px",
            color: "#000000"
          }}
        >
          Informações importantes sobre o pagamento:
        </Typography>
        <Stack spacing={"4px"}>
          <Typography
            sx={{
              fontFamily: "Lato",
              fontWeight: "400",
              fontSize: "12px",
              lineHeight: "19.92px",
              letterSpacing: "0.4px",
              color: "#000000",
              display: "list-item",
              listStylePosition: "inside"
            }}
          >
            Após finalizar o pedido, realize o pagamento em até 24 horas. Caso
            contrário, o pedido será cancelado e um novo deverá ser feito
          </Typography>
          <Typography
            sx={{
              fontFamily: "Lato",
              fontWeight: "400",
              fontSize: "12px",
              lineHeight: "19.92px",
              letterSpacing: "0.4px",
              color: "#000000",
              display: "list-item",
              listStylePosition: "inside"
            }}
          >
            Você pode consultar o link de pagamento em
            <span
              style={{ color: "#10D878", cursor: "pointer" }}
              onClick={() => history.push("/login?go=/pedidos/agendados")}
            >
              &nbsp;Meus Pedidos&nbsp;
            </span>
            durante esse período
          </Typography>
        </Stack>
      </Stack>
      <Stack spacing={"12px"}>
        <Typography
          sx={{
            fontFamily: "Lato",
            fontWeight: "400",
            fontSize: "12px",
            lineHeight: "19.92px",
            letterSpacing: "0.4px",
            color: "#000000",
            display: "list-item",
            listStylePosition: "inside"
          }}
        >
          Ao fazer o pagamento, você pode acompanhar o status da sua
          assinatura na página
          <span
            style={{ color: "#10D878", cursor: "pointer" }}
            onClick={() => history.push("/pedidos/agendados")}
          >
            &nbsp;Meus Pedidos&nbsp;
          </span>
        </Typography>
      </Stack>
    </Stack>
  );
};

export default withApollo(
  withRouter(
    connect((state: IAppState) => ({
      newAccount: state.fastCheckout.newAccount,
      linkPayment: state.fastCheckout.finishedCheckout.linkPayment,
      finishedMoment: state.fastCheckout.finishedCheckout.finishedMoment
    }))(PaymentPix)
  )
);
