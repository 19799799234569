import * as Yup from 'yup';

const requiredMessages = {
  email: 'O email é obrigatório.',
};

const validMessages = {
  email: 'Por favor, coloque um email válido.',
};

export const ForgotPasswordFormSchema = Yup.object().shape({
  email: Yup.string().email(validMessages.email).required(requiredMessages.email),
});
