import React, { useEffect, useState } from "react";
import { Stack, Typography } from "@mui/material";
import { connect } from "react-redux";
import { PaymentType } from "../../../../../actionReducers/Checkout";
import { IProduct } from "../../../../../graphql/types/products";
import { getProductShortName } from "../../../../../helpers/getProductShortName";
import getStorage from "../../../../../helpers/getStorageProduct";
import toReal from "../../../../../helpers/toReal";
import { IAppState } from "../../../../../store";

interface IProps {
  amountTotal: number;
  amountMonth: number;
  paymentType: PaymentType;
  installments: number;
  order: string;
  product: IProduct;
  origin?: string;
}

const InformationOrder = ({
  amountTotal,
  paymentType,
  amountMonth,
  installments,
  order,
  origin,
  product: { name }
}: IProps) => {
  const [liveloScore, setLiveloScore] = useState<number>(0);

  const { RAZZLE_PARIDADE_LIVELO } = process.env;

  const calculateLiveloScore = (value: number) => {
    const totalScore = Math.floor(value * Number(RAZZLE_PARIDADE_LIVELO));
    setLiveloScore(totalScore);
  };

  useEffect(() => {
    calculateLiveloScore(amountTotal);
  }, [amountTotal]);

  return (
    <Stack spacing={"24px"} display={'flex'} alignSelf={{ xs: 'flex-start' }}>
      <Stack>
        <Typography
          sx={{
            opacity: "87%",
            fontFamily: "Lato",
            fontWeight: "600",
            fontSize: "17px",
            lineHeight: "26.35px",
            letterSpacing: "0.15px",
            color: "#000000"
          }}
        >
          Pedido:&nbsp;
          <span
            style={{
              opacity: "87%",
              fontFamily: "Lato",
              fontWeight: "400",
              fontSize: "17px",
              lineHeight: "26.35px",
              letterSpacing: "0.15px",
              color: "#000000"
            }}
          >
            {order}
          </span>
        </Typography>
        <Typography
          sx={{
            opacity: "87%",
            fontFamily: "Lato",
            fontWeight: "600",
            fontSize: "17px",
            lineHeight: "26.35px",
            letterSpacing: "0.15px",
            color: "#000000"
          }}
        >
          Total do pedido:&nbsp;
          <span
            style={{
              opacity: "87%",
              fontFamily: "Lato",
              fontWeight: "400",
              fontSize: "17px",
              lineHeight: "26.35px",
              letterSpacing: "0.15px",
              color: "#000000"
            }}
          >
            {paymentType === PaymentType.PIX
              ? toReal(amountTotal)
              : `${toReal(amountTotal)} ${installments > 1
                ? `(${installments}x de ${toReal(amountMonth)})`
                : ""
              }`}
          </span>
        </Typography>
        {origin === 'livelo' && (
          <Typography
            sx={{
              opacity: "87%",
              fontFamily: "Lato",
              fontWeight: "600",
              fontSize: "17px",
              lineHeight: "26.35px",
              letterSpacing: "0.15px",
              color: "#000000"
            }}
          >
           {`Pontos ganhos: ${liveloScore}`}
          </Typography>
        )}
      </Stack>
      {name ? (
        <Stack>
          <Typography
            sx={{
              opacity: "87%",
              fontFamily: "Lato",
              fontWeight: "600",
              fontSize: "17px",
              lineHeight: "26.35px",
              letterSpacing: "0.15px",
              color: "#000000"
            }}
          >
            {getProductShortName(name)}
          </Typography>
          <Typography
            sx={{
              opacity: "60%",
              fontFamily: "Lato",
              fontWeight: "500",
              fontSize: "15px",
              lineHeight: "23.55px",
              letterSpacing: "0.1px",
              color: "#000000"
            }}
          >
            {getStorage(name).label}
          </Typography>
        </Stack>
      ) : null}
    </Stack>
  );
};

export default connect((state: IAppState) => ({
  paymentType: state.fastCheckout.paymentType,
  amountMonth: state.fastCheckout.amountMonth,
  amountTotal: state.fastCheckout.finishedCheckout.total,
  order: state.fastCheckout.finishedCheckout.shortId,
  installments: state.fastCheckout.finishedCheckout.installments,
  product: state.fastCheckout.finishedCheckout.product
}))(InformationOrder);
