import { Box } from '@mui/material';
import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { Mutation, QueryResult } from 'react-apollo';

import { MUTATION_ADD_CARD_PAGARME } from '../../../graphql/mutations/users';
import { IQueryCurrentUser } from "../../../graphql/queries/users";
import ProfileAddCardsForm from './ProfileAddCardsForm';

interface IProfileAddCardsSectionProps {
  queryResult: QueryResult<IQueryCurrentUser>;
  refetch: (...args: any[]) => any;
  showFormCards: boolean;
  setShowFormCards: Dispatch<SetStateAction<boolean>>;
}

const ProfileAddCardsSection = ({ queryResult, refetch, showFormCards, setShowFormCards }: IProfileAddCardsSectionProps) => {

  useEffect(() => {
    // inicializa o pagarme v3
    if (typeof pagarme === 'undefined') {
      const script = document.createElement('script');
      script.setAttribute('src', 'https://assets.pagar.me/pagarme-js/4.5/pagarme.min.js');
      script.setAttribute('async', 'async');
      document.body.appendChild(script);
    }
  }, [])

  return (
    <Box>
      {
        showFormCards ? (
          <Mutation mutation={MUTATION_ADD_CARD_PAGARME}>
            {(addCardPagarme) => <ProfileAddCardsForm queryResult={queryResult} submit={addCardPagarme} refetch={refetch} setShowFormCards={setShowFormCards} />}
          </Mutation>
        ) : null
      }
    </Box>
  );
}

export default ProfileAddCardsSection;
