import React, { useRef } from 'react';
import { Box, CircularProgress, Link, Stack, Typography } from '@mui/material';
import { FormikProps } from 'formik';
import { useState } from 'react';
import InputText from '../../Inputs/InputText';
import { brazilianStates } from '../../../helpers/brazilianState';
import getAddressByCEP from '../../../services/getByCep';
import TextFieldMask from '../../Inputs/InputMask';
import InputSelect from '../../InputSelect';

export interface IAddress {
  city: string;
  code: string;
  street: string;
  complement?: string;
  neighborhood: string;
  number: string;
  state: string;
}

interface IProps extends FormikProps<IAddress> {
  scrollActive?: boolean;
}

const Address = ({
  handleBlur,
  handleChange,
  values,
  touched,
  setFieldValue,
  errors,
  scrollActive,
  setErrors
}: IProps) => {
  const endFormRef = useRef(null);

  const [existCep, setExistCep] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const scrollToAddress = () => {
    endFormRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleChangeCode = async (field: string, code: string) => {
    try {
      if (code.length < 8) {
        cleanAddressFields();
        return;
      }
      setLoading(true);

      const result = await getAddressByCEP(code);
      if (result) {
        setFieldValue('street', result.street);
        setFieldValue('neighborhood', result.neighborhood);
        setFieldValue('complement', result.complement);
        setFieldValue('city', result.city);
        setFieldValue('state', result.state);
        handleBlur(field);
        setExistCep(true);
        if (scrollActive) scrollToAddress();

      } else {
        cleanAddressFields();
      }
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
    }
  };

  const cleanAddressFields = () => {
    setErrors({ code: 'CEP inválido' });
    setFieldValue('street', '');
    setFieldValue('neighborhood', '');
    setFieldValue('number', '');
    setFieldValue('complement', '');
    setFieldValue('city', '');
    setFieldValue('state', '');
    setExistCep(false);
  };

  return (
    <Box>
      <Stack spacing={'24px'}>
        <Stack>
          <TextFieldMask
            mask="99999-999"
            name={"code"}
            label="CEP"
            type="text"
            onBlur={handleBlur('code')}
            setValue={(e) => { handleChange('code')(e), handleChangeCode('code', e) }}
            value={values.code}
            touched={touched.code}
            errorMessage={errors.code}
            isRequired={true}
            inputIcon={loading ? <CircularProgress sx={{ color: 'rgba(0, 0, 0, 0.6)' }} size={'20px'} /> : null}
          />
          <Typography
            variant="caption"
            fontSize={13}
            sx={{ padding: '3px 14px 0px', opacity: '0.6' }}
          >
            Não sabe o seu CEP?&nbsp;
            <Link
              href="https://buscacepinter.correios.com.br/app/endereco/index.php"
              target={'_blank'}
            >
              Clique aqui
            </Link>
          </Typography>
        </Stack>
        {
          existCep || (values.state && values.state.length) ? (
            <>
              <InputText
                label="Endereço"
                type="text"
                onBlur={handleBlur('street')}
                setValue={handleChange('street')}
                value={values.street || ''}
                touched={touched.street}
                errorMessage={errors.street}
                isRequired={true}
                name={"street"}
              />
              <InputText
                label="Número"
                type="text"
                name={"number"}
                onBlur={handleBlur('number')}
                setValue={handleChange('number')}
                value={values.number || ''}
                touched={touched.number}
                errorMessage={errors.number}
                isRequired={true}
              />
              <Stack>
                <InputText
                  label="Complemento (Opcional)"
                  type="text"
                  name={"complement"}
                  maxLengthInput={55}
                  onBlur={handleBlur('complement')}
                  setValue={handleChange('complement')}
                  value={values.complement || ''}
                  touched={touched.complement}
                  errorMessage={errors.complement}
                />
                <Typography
                  variant="caption"
                  fontSize={13}
                  sx={{ padding: '3px 14px 0px', opacity: '0.6' }}
                >
                  (Apartamento, Bloco, Andar, etc)
                </Typography>
              </Stack>
              <Box ref={endFormRef}>
                <InputText
                  label="Bairro"
                  type="text"
                  name={"neighborhood"}
                  onBlur={handleBlur('neighborhood')}
                  setValue={handleChange('neighborhood')}
                  value={values.neighborhood || ''}
                  touched={touched.neighborhood}
                  errorMessage={errors.neighborhood}
                  isRequired={true}
                  style={{ width: '100%' }}
                />
              </Box>
              <InputText
                label="Cidade"
                type="text"
                name={"city"}
                onBlur={handleBlur('city')}
                setValue={handleChange('city')}
                value={values.city || ''}
                touched={touched.city}
                errorMessage={errors.city}
                isRequired={true}
                disabled={true}
              />
              <InputSelect
                value={values.state || ''}
                setValue={handleChange('state')}
                label={'Estado'}
                options={brazilianStates()}
                touched={touched.state}
                errorMessage={errors.state}
                required={true}
                disabled={true}
              />
            </>
          ) : null
        }
      </Stack>
    </Box>
  );
};

export default Address;
