import styled, { keyframes } from 'styled-components';

import { green1, green2 } from '../../style/colors';
import { CommonDiv } from '../styled';

const animation = keyframes`
  from { left: -200px; width: 30%; }
  50% { width: 30%; }
  70% { width: 70%; }
  80% { left: 50%; background-color: ${green2} }
  95% { left: 120%; background-color: ${green2} }
  to { left: 100%; background-color: ${green2} }
`;

export const TopProgressBarSnake = styled(CommonDiv)`
  position: fixed;
  top: 0;
  left: 0;
  transition: background-color 0.2s, width 0.3s;
  background-color: ${green1};
  height: 0.25em;
  width: 30%;
  z-index: 99;
  animation: ${animation} 2s ease-in-out infinite;
`;

const animationSoft = keyframes`
  from { width: 0%; }
  15% { width: 25%; }
  50% { width: 65%; }
  65% { width: 80%; }
  to { width: 100%; }
`;

export const SizedTopProgressBarSnake = styled(TopProgressBarSnake)`
  position: absolute;
  animation: ${animationSoft} 5s ease-in-out forwards;
  height: 5px;
`;
