import React, { Dispatch, SetStateAction } from 'react';
import { Mutation } from 'react-apollo';
import { MUTATION_ADD_ADDRESS } from '../../../graphql/mutations/users';
import ProfileAddAddressForm from './ProfileAddAddressForm';
import { Box } from '@mui/material';

interface IProfileAddAddressSectionProps {
  refetch: (...args: any[]) => any;
  showFormAddress: boolean;
  setShowFormAddress: Dispatch<SetStateAction<boolean>>;
}

const ProfileAddAddressSection = ({ refetch, showFormAddress, setShowFormAddress }: IProfileAddAddressSectionProps) => {

  return (
    <Box>
      {
        showFormAddress ? (
          <Mutation mutation={MUTATION_ADD_ADDRESS}>
            {(addAddress) => <ProfileAddAddressForm submit={addAddress} refetch={refetch} setShowFormAddress={setShowFormAddress} />}
          </Mutation>
        ) : null
      }
    </Box>
  );
}

export default ProfileAddAddressSection;
