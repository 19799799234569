import styled from "styled-components";
export const MainContainer = styled.footer`
  display: flex;
  flex-direction: column;
  background: #2D2D38;
  align-items: flex-start;
  padding: 48px 144px;
  width: 100%;
  @media(max-width: 1250px) {
    padding: 19px 19px;
    height: 100%;
  }
`;
export const LogoSection = styled.section`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-end;
  justify-content: space-between;
  @media(max-width: 1250px) {
    align-items: center;
    justify-content: space-between;
  }
`;
export const MenuContainer = styled.section`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-top: 49px;
  @media(max-width: 1250px) {
    flex-wrap: wrap;
  }
`;
export const OptionsContainer= styled.article`
  display: flex;
  flex-direction: column;
  @media(max-width: 1250px) {
    padding: 10px;
    margin-bottom: 64px;
    :nth-child(4) {
      margin-right: 30px;
    }
  }
`;
export const Title = styled.div`
  font-family: 'Chillax';
  font-weight: 600;
  font-size: 21px;
  line-height: 28px;
  margin-bottom: 12px;
  color: #F4F4EF;
  @media(max-width: 1250px) {
    font-size: 16px;
    line-height: 21px;
  }
`;
export const Options = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-weight: 400;
  font-size: 16px;
  font-family: "Chillax";
  line-height: 21px;
  color: #F4F4EF;
  a, a:hover, a:visited {
    font-family: "Chillax";
    text-decoration: none;
    color: #F4F4EF;
    & + a {
      margin-top: 1.125rem;
    }
  }
  @media(max-width: 1250px) {
    font-size: 16px;
    line-height: 21px;
  }
`;
export const Images = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4rem;
  @media (max-width: 1250px){
    gap: 2rem;
  }
`;
export const RowImages = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  .img_cards {
    width: 300px;
  }
  .logo_mercadopago {
    width: 183px;
  }
  
  
  @media (max-width: 1280px){
    display: grid;
    justify-content: start;
    .logo_mercadopago {
      max-width: 300px;
    }
    .img_cards {
      max-width: 250px;
    }
  }
`;
export const ExtendedLogoContainer = styled.div`
  margin-top: 128px;
  .logoextended {
    width: 144px;
    height: 35px;
  }
  @media(max-width: 1250px) {
    margin-top: 64px;
    flex-direction: column;
  }
`;
export const AddressContainer = styled.div`
  width: 392px;
  font-weight: 400;
  font-size: 21px;
  font-family: "Chillax";
  line-height: 28px;
  color: #F4F4EF;
  @media(max-width: 1250px) {
    width: 100%;
    font-size: 16px;
    line-height: 21px;
  }
`;
export const MidiaContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 24px;
  span {
    font-family: "Chillax";
    font-weight: 400;
    font-size: 21px;
    line-height: 28px;
    color: #F4F4EF;
  }
  @media(max-width: 1250px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 24px;
  }
`;
export const SocialLinks = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  
  @media (max-width: 1250px){
    justify-content: flex-start;
    margin-top: 24px;
  }
`;
export const CopyRight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 128px;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #F3F3F3;
  @media(max-width: 1250px) {
    margin-top: 64px;
    font-size: 12px;
    line-height: 21px;
  } 
`;
export const WhatsappContact = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: end;
  margin-top: 18px;
`