import gql from 'graphql-tag';
import { ILocalization, IPublicUser, IUserAccounts } from '../types/users';

export const QUERY_CURRENT_USER = gql`
  query currentUser {
    currentUser {
      id
      personalData {
        firstName
        lastName
        document
        birthday
        gender
      }
      email
      phone
      addresses {
        identifier
        street
        code
        complement
        city
        neighborhood
        state
        number
        lat
        lng
        placeId
      }
      cards {
        id
        brand
        expirationDate
        holderName
        lastDigits
        isDefault
        transactionGateway
        updatedAt
      }
      boletos {
        url
      }
      flags {
        hasVerifiedEmail
        hasVerifiedPhoneNumber
      }
      documents {
        idFront
        idBack
        selfie
        residence
        others
        lastStepComplete
        documentsToReUpload
        documentStatus
        statusIntegration
      }
    }
  }
`;

export interface IQueryCurrentUser {
  currentUser: IPublicUser;
}

export const QUERY_USER_ACCOUNTS = gql`
  query userAccounts($email: String!) {
    userAccounts(email: $email) {
      email
      googleData
      facebookData
    }
  }
`;

export interface IQueryUserAccounts {
  userAccounts: IUserAccounts;
}

export const QUERY_VERIFY_EMAIL = gql`
  query verifyEmail(
    $emailVerificationToken: String!
  ) {
    verifyEmail(
      emailVerificationToken: $emailVerificationToken,
    )
  }
`;

export interface IQueryVerifyEmail {
  verifyEmail: boolean;
}

export const QUERY_VERIFY_PASSWORD_TOKEN = gql`
  query verifyPasswordToken(
    $newPasswordToken: String!
  ) {
    verifyPasswordToken(
      newPasswordToken: $newPasswordToken,
    ) {
      id
      email
    }
  }
`;

export interface IQueryVerifyPasswordToken {
  verifyPasswordToken: IPublicUser;
}

export const QUERY_SIGNEDURL = gql`
  query signedUrl($url: String, $bucketName: String) {
    signedUrl(url: $url, bucketName: $bucketName)
  }
`;

export interface IQuerySignedUrl {
  signedUrl: string;
}

export const QUERY_LOCALIZATION = gql`
  query verifyCep($code: String){
    verifyCep(code: $code){
      zipcode
      street
      neighborhood
      city
      state
    }
  }
`;

export interface IQueryLocalization {
  verifyCep: ILocalization;
}
