import * as yup from 'yup';

export const validatorChangePasswordSchema = yup.object().shape({
    oldPassword: yup
        .string()
        .min(6, 'Por favor, coloque uma senha com no mínimo 6 caractéres.')
        .required('A senha atual é obrigatória.'),
    newPassword: yup
        .string()
        .min(6, 'Por favor, coloque uma senha com no mínimo 6 caractéres.')
        .required('A nova senha é obrigatória.'),
});
