import * as React from 'react';
import Geosuggest, { GeosuggestProps } from 'react-geosuggest';

import { GeosuggestWrapper } from '../../components/styled';
import config from '../../config';
import { ALLOWED_CITIES } from '../../config/allowedCities';

interface IState {
  gmapsLoaded: boolean;
  value: string | number | readonly string[];
}

class GeosuggestInput extends React.Component<GeosuggestProps, IState> {
  private inputRef: Geosuggest;

  constructor(props: any) {
    super(props);

    this.state = {
      gmapsLoaded: false,
      value: this.props.value,
    };
  }

  public componentDidUpdate() {
    if (this.props.value !== this.state.value) {
      if (this.props.value === '') {
        this.inputRef.clear();
      }

      this.setState({ value: this.props.value });
    }
  }

  public componentDidMount() {
    const isInjected = document.getElementById('gmaps-api');

    if (isInjected) {
      this.setState({ gmapsLoaded: true });
    } else if (!isInjected && !this.state.gmapsLoaded) {
      const script = document.createElement('script');
      script.setAttribute('id', 'gmaps-api');
      script.setAttribute(
        'src',
        `https://maps.googleapis.com/maps/api/js?key=${config.GmapsKey}&libraries=places`,
      );
      script.onload = () => this.setState({ gmapsLoaded: true });
      document.body.appendChild(script);
    }
  }

  public blockSuggest = (suggest: google.maps.places.AutocompletePrediction) => {
    const term = new RegExp(ALLOWED_CITIES.join('|'));

    if (suggest.description.match(term)) {
      return false;
    }

    return true;
  }

  public render() {
    return (
      this.state.gmapsLoaded
        ? (
          <GeosuggestWrapper>
            <Geosuggest
              {...this.props}
              skipSuggest={this.blockSuggest}
              initialValue={(this.props.value as string)}
              ref={(ref) => this.inputRef = ref}
            />
          </GeosuggestWrapper>
        )
        : this.props.children || null
    );
  }
}

export default GeosuggestInput;
