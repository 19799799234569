import { Box, Button, Stack, Typography } from '@mui/material';
import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { MutationFn, QueryResult, WithApolloClient, withApollo } from 'react-apollo';
import { connect } from "react-redux";
import { toast } from 'react-toastify';

import { AnyAction } from "redux";
import { ICreditCard } from "../../../actionReducers/Checkout";
import { SET_CREDIT_CARD_TOKEN_PROFILE } from "../../../actionReducers/Profile";
import CustomButton from '../../../components/Rebranding/CustomButton';
import { FormikFixedForm } from '../../../components/styled';
import { IMutationAddCardPagarme } from '../../../graphql/mutations/users';
import { IQueryCurrentUser } from "../../../graphql/queries/users";
import { IAppState } from "../../../store";
import ProfileAddCardPagarme from "./ProfileAddCardPagarme";
import ProfileAddCardsFormFields from "./ProfileAddCardsFormFields";

interface IProfileCardFormProps {
  queryResult: QueryResult<IQueryCurrentUser>;
  creditCard?: ICreditCard;
  cardInfo?: any;
  submit: MutationFn<IMutationAddCardPagarme>;
  refetch: (...args: any[]) => any;
  setShowFormCards?: Dispatch<SetStateAction<boolean>>;
}

type Props = IProfileCardFormProps & WithApolloClient<{}>;

const ProfileAddCardForm = (props: Props) => {

  const {
    setShowFormCards,
  } = props;

  useEffect(() => {
    // inicializa o pagarme v3
    if (typeof pagarme === 'undefined') {
      const script = document.createElement('script');
      script.setAttribute('src', 'https://assets.pagar.me/pagarme-js/4.5/pagarme.min.js');
      script.setAttribute('async', 'async');
      document.body.appendChild(script);
    }
  }, [])

  const addCancel = () => {
    setShowFormCards(false);
  };
  
  const handleSubmit = async () => {
    const { cardInfo, submit, refetch, setShowFormCards } = props;

    try {
      await submit({ variables: { token: cardInfo.hash} });
      toast.success('Sucesso ao adicionar um novo cartão!');
    } catch (e) {
      toast.error('Erro ao criar seu cartão.');
    }

    await refetch();

    setShowFormCards(false);

    window.scrollBy(0, -400);
  };

  return (
    <Box sx={{ width: '100%', marginBottom: '79px' }}>
      <FormikFixedForm>
        <Stack spacing={'24px'}>
          <Stack spacing={'24px'} sx={{ marginTop: '43px' }}>
            <Typography fontFamily={'Chillax'} fontWeight={500} fontSize={'21px'} sx={{ color: '#27AE60' }}>Cartão</Typography>
          </Stack>

          <ProfileAddCardsFormFields {...props} />
          <ProfileAddCardPagarme {...props} />

          <Stack spacing={'30px'} sx={{ width: '100%' }}>
            <CustomButton
              sx={{ width: '100%' }}
              onClick={() => handleSubmit() }
              title={'Salvar cartão'}
            />
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <Button
                variant="text"
                sx={{ color: '#D32F2F', }}
                onClick={() => addCancel()}
              >
                CANCELAR
              </Button>
            </Box>
          </Stack>
        </Stack>
      </FormikFixedForm>
    </Box>

  );
};

export default connect((state: IAppState) => ({
  creditCard: state.profile.creditCard,
  cardInfo: state.profile.creditCard.cardInfo
}), (dispatch: Dispatch<AnyAction>) => ({
  setTokenCreditCard: (cardInfo) => dispatch(SET_CREDIT_CARD_TOKEN_PROFILE(cardInfo)),
}))(withApollo(ProfileAddCardForm));