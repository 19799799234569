export const gradientProductBox = `
  linear-gradient(-135deg, transparent 50%, rgba(51,255,153,0.4)),
  linear-gradient(135deg, transparent 50%, rgba(51,187,255,0.8)),
  linear-gradient(-45deg, transparent 50%, rgba(204,238,255,0.1)),
  linear-gradient(45deg, transparent 50%, rgba(217,234,242,0.1))
`;

export const gradientAddressBox = `
  linear-gradient(-135deg, transparent 0%, rgba(51,255,153,0.8)),
  linear-gradient(135deg, transparent 0%, rgba(51,187,255,0.8)),
  linear-gradient(-45deg, transparent 0%, rgba(0,68,102,0.3)),
  linear-gradient(45deg, transparent 0%, rgba(25,59,77,0.3)),
  #6f42c1
`;

export const gradientPurply = `
  linear-gradient(-165deg, #4d2d89, transparent), #6f42c1
`;

export const gradientDarkGreen = `
  linear-gradient(-165deg, #001212, transparent), #00191a
`;

export const gradientGray = `
  linear-gradient(-135deg, transparent 0%, rgba(143,156,163,0.8)),
  linear-gradient(135deg, transparent 0%, rgba(143,160,163,0.8)),
  #e9ecef
`;

export const gradientDarkGray = `
  linear-gradient(-135deg, transparent 0%, rgba(143,156,163,0.8)),
  linear-gradient(135deg, transparent 0%, rgba(143,160,163,0.8)),
  #ced4da
`;

export const gradientCartItem = `
  linear-gradient(90deg, #fff 132px, #f8f9fa)
`;
