import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10rem;
  padding-bottom: 10rem;
  column-gap: 3.75rem;

  h1 {
    font-family: Denton Condensed Test;
    font-size: 3.0625rem;
    font-style: normal;
    font-weight: 500;
    margin: 0px;
    margin-bottom: 2rem;
    letter-spacing: -1px;
    color: #27AE60;
  }

  h2 {
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 400;
    margin: 0;
    color: #2E2F39;
    margin-bottom: 1rem;
    line-height: 2.3125rem;
  }

  h2, p {
    font-family: Chillax;
  }

  p {
    max-width: 37.875rem;
    margin-bottom: 2.5rem;
    line-height: 1.3125rem;
  }

  @media (max-width: 680px) {
    flex-direction: column;
    padding: 0 5%;
    padding-top: 20%;
    padding-bottom: 30%;
    column-gap: 0rem;
    row-gap: 2.63rem;
    
    img {
      width: 80%;
      height: auto;
    }

    p {
      width: 90%;
      font-size: 1rem;
    }

    h2 {
      font-size: 1.75rem;
    }
  }
`;
