export default (cnpj: string) => {
  const rawCNPJ = cnpj.replace(/[^\d]+/g, '');
  if (rawCNPJ === '') { return false; }

  if (rawCNPJ.length !== 14) {
    return false;
  }

  if (rawCNPJ === '00000000000000' ||
    rawCNPJ === '11111111111111' ||
    rawCNPJ === '22222222222222' ||
    rawCNPJ === '33333333333333' ||
    rawCNPJ === '44444444444444' ||
    rawCNPJ === '55555555555555' ||
    rawCNPJ === '66666666666666' ||
    rawCNPJ === '77777777777777' ||
    rawCNPJ === '88888888888888' ||
    rawCNPJ === '99999999999999') {
    return false;
  }

  let tamanho = rawCNPJ.length - 2;
  let numeros = rawCNPJ.substring(0, tamanho);
  const digitos = rawCNPJ.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += Number(numeros.charAt(tamanho - i)) * pos--;
    if (pos < 2) {
      pos = 9;
    }
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
  if (resultado !== Number(digitos.charAt(0))) {
    return false;
  }

  tamanho = tamanho + 1;
  numeros = rawCNPJ.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += Number(numeros.charAt(tamanho - i)) * pos--;
    if (pos < 2) {
      pos = 9;
    }
  }
  resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
  if (resultado !== Number(digitos.charAt(1))) {
    return false;
  }

  return true;

};
