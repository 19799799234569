
import styled from 'styled-components';
import { media } from '../../helpers/style';
import { gray4, white } from '../../style/colors';

export const CommonDiv = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const ModalWrapper = styled(CommonDiv)``;

export const ModalContent = styled(CommonDiv)`
  z-index: 101;
  position: fixed;
  background-color: ${white};
  top: calc(50% - 240px);
  left: calc(50% - 200px);
  width: 480px;
  min-height: 20px;
  min-width: 480px;
  box-shadow: 0 0 10px ${gray4};
  border-radius: 6px;
  font-size: 15px;

  ${media.small`
      width: 60vw;
      left: unset;
      max-height: 50vh;
      border-radius: unset;
      top: 5vh;
      height: fit-content;
      left: 5vw;
      min-height: 20px;
      min-width: 350px;
    `
  }
`;