import React from 'react';
import { Link, Stack, Typography } from '@mui/material';
import { IAppState } from '../../../../store';
import { connect } from 'react-redux';
import { IPayment } from '../../../../actionReducers/Recurrence';
import { PaymentType } from '../../../../actionReducers/Checkout';

interface IProps {
    paymentEntry: IPayment;
    openChat: () => void;
}

const BannerEndCheckoutRecurrence = ({ paymentEntry: { type }, openChat }: IProps) => {
    return (
        <Stack
            spacing={'16px'}
            sx={{
                padding: '24px',
                background: 'linear-gradient(90.81deg, #18BAD0 9.48%, #3658D0 100.51%)',
            }}
            alignItems={'center'}
        >
            <Typography
                variant="h5"
                fontSize={25}
                color={'#FFFFFF'}
                textAlign={'center'}
                maxWidth={'355px'}
            >
                Seu pedido foi emitido!
            </Typography>
            <Typography
                variant="body2"
                fontSize={15}
                color={'#FFFFFF'}
                textAlign={'center'}
                maxWidth={'355px'}
            >
                {type === PaymentType.BOLETO ? `Faça a emissão do seu boleto no link abaixo. ` : null}
                Os próximos passos da sua assinatura iniciam após a confirmação do pagamento. Fique de olho no seu e-mail para mais informações.
            </Typography>
            <Link
                fontSize={12}
                color={'#FFFFFF'}
                textAlign={'center'}
                maxWidth={'355px'}
                onClick={openChat}
                sx={{cursor: 'pointer'}}
            >
                Dúvidas? Fale com nosso suporte aqui
            </Link>
        </Stack>
    );
};

export default connect((state: IAppState) => ({
    paymentEntry: state.checkoutRecurrence.paymentEntry
}))(BannerEndCheckoutRecurrence);
