import { ApolloQueryResult } from "apollo-client";
import axios from "axios";
import { FormikProps, withFormik } from "formik";
import { GraphQLError } from "graphql";
import React from "react";
import { withApollo, WithApolloClient } from "react-apollo";
import { toast } from "react-toastify";
import * as Yup from "yup";
import Config from "../../../config";
import { IQueryMyOrders } from "../../../graphql/queries/checkoutOrders";
import {
  IMutationChangeCardRecurrence,
  MUTATION_CHANGE_CARD_RECURRENCE
} from "./../../../graphql/mutations/checkoutOrders";
import CardForm from "./CardForm";
import {
  ButtonSubmit,
  ContainerCard,
  ContentCheckbox,
  FormikForm,
  WarningSave
} from "./styled";

interface IProps {
  orderId: String;
  goBack: () => void;
  refetchOrders: () => Promise<ApolloQueryResult<IQueryMyOrders>>;
}
interface IValues {
  cardName: string;
  cardNumber: string;
  cardExpiration: string;
  cvv: string;
  allOrdersByUser: boolean;
}

const ChangeCard: React.FC<IProps &
  FormikProps<IValues> &
  WithApolloClient<{}>> = props => {
  const { errors, touched, setFieldValue, values } = props;

  return (
    <ContainerCard>
      <FormikForm>
        <CardForm errors={errors} touched={touched} />
        <img
          alt="formas de pagamento: elo, visa, mastercard, jcb, aura, hipercard, american express"
          width="100%"
          src="https://images.allugator.com/base/formas-pagto-ead-checkout.png"
        />
        <ContentCheckbox>
          <input
            type="checkbox"
            checked={values.allOrdersByUser}
            disabled={false}
            onChange={() =>
              setFieldValue("allOrdersByUser", !values.allOrdersByUser)
            }
          />
          <span
            onClick={() =>
              setFieldValue("allOrdersByUser", !values.allOrdersByUser)
            }
          >
            Usar este novo cartão para todos os meus pedidos.
          </span>
        </ContentCheckbox>
        <ButtonSubmit type="submit">Salvar</ButtonSubmit>
        <WarningSave>
          Ao clicar em “Salvar” você alterará o cartão a ser utilizado nas
          próximas cobranças&nbsp;
          {values.allOrdersByUser
            ? "de todos os pedidos recorrentes deste usuário."
            : "deste pedido."}
        </WarningSave>
      </FormikForm>
    </ContainerCard>
  );
};

const requiredMessages = {
  cardName: "Nome como no cartão e um dado obrigatório.",
  cardNumber: "Número do cartão é um campo obrigatório.",
  cardExpiration: "Data de validade do cartão é um campo obrigatório.",
  cvv: "CVV obrigatório."
};

const validMessages = {
  cardNumber: "Número de cartão inválido.",
  cardExpiration: "A validade do cartão tem que estar no formato MM/YY.",
  cvv: "O CVV precisa ter pelo menos 3 dígitos."
};

export const validationSchema = Yup.object().shape({
  cardName: Yup.string().required(requiredMessages.cardName),
  cardNumber: Yup.string()
    .required(requiredMessages.cardNumber)
    .min(16, validMessages.cardNumber)
    .max(19, validMessages.cardNumber),
  cardExpiration: Yup.string()
    .min(5, validMessages.cardExpiration)
    .max(5, validMessages.cardExpiration)
    .required(requiredMessages.cardExpiration),
  cvv: Yup.string()
    .min(3, validMessages.cvv)
    .max(4, validMessages.cvv)
    .required(requiredMessages.cvv)
});

const ChangeCardForm = withFormik<WithApolloClient<{}> & IProps, IValues>({
  validationSchema,
  mapPropsToValues() {
    return {
      cardName: "",
      cardNumber: "",
      cardExpiration: "",
      cvv: "",
      allOrdersByUser: false
    };
  },
  async handleSubmit(values, { props }) {
    const {
      cardName,
      cardNumber,
      cardExpiration,
      cvv,
      allOrdersByUser
    } = values;

    const { client, goBack, refetchOrders, orderId } = props;

    try {
      const card = {
        number: cardNumber.replace(/\s/g, ""),
        holder_name: cardName,
        exp_month: cardExpiration.split("/")[0],
        exp_year: cardExpiration.split("/")[1],
        cvv
      };

      const { data } = await axios.post(
        `${Config.PagarmeV5URL}/tokens?appId=${Config.PagarmeV5Key}`,
        {
          type: "card",
          card
        }
      );

      const {
        data: { changeCardRecurrence }
      } = await client.mutate<IMutationChangeCardRecurrence>({
        mutation: MUTATION_CHANGE_CARD_RECURRENCE,
        variables: {
          order: orderId,
          token: data.id,
          allOrdersByUser
        }
      });

      if (changeCardRecurrence) {
        toast.success("Cartão atualizado com sucesso.");
        refetchOrders().then(() => goBack());
      }
    } catch (error) {
      error.graphQLErrors.forEach((error: GraphQLError) => {
        toast.error(
          error.message || "Ocorreu um erro ao salvar o novo cartão."
        );
      });
    }
  }
})(ChangeCard);

export default withApollo(ChangeCardForm);
