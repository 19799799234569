import * as React from 'react';
import { ReactCookieProps, withCookies } from 'react-cookie';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { RouteComponentProps , withRouter } from 'react-router';

import { ALLUGATOR_AUTH_TOKEN } from '../boot/constants';
import { IAppState } from '../store';

class Protect extends React.Component<{ loggedState: boolean } & ReactCookieProps & RouteComponentProps<{}>> {

  public render() {
    const { loggedState, cookies,history } = this.props;
    let path = `/login?go=${history.location.pathname}`;
    return (
      loggedState || cookies.get(ALLUGATOR_AUTH_TOKEN)
        ? this.props.children
        : <Redirect to={path}/>
    );
  }
}

export default connect((state: IAppState) => ({
  loggedState: state.user.logged,
}))(withCookies(withRouter(Protect)));
