import styled from 'styled-components';
import { media } from '../../helpers/style';
import { white } from '../../style/colors';
import { mainFontSize } from '../../style/constants';


export const MainLayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'Roboto', sans-serif ;
  font-size: ${mainFontSize}px;
  .container{
    background-color: ${white};
    max-width: 1400px;
    width: 100%;
    padding: 0 10px;
    margin: auto;
  }
`;


export const ContentBannerHome = styled.div`
  margin-bottom: 36px;
  ${media.small`
    margin-bottom: 0px;
  `}
`;