import moment from 'moment';
import { ICartItem } from '../actionReducers/Cart';
import { IProduct } from '../graphql/types/products';
import calculatePrice from './priceCalculation';


export const getPriceByPlan = (product: IProduct, plan: string, preowned?: boolean) => {
  if (!product) {
    return 0;
  }
  
  if(preowned) return product.prices.preowned || product.prices.signatureLite;

  switch (plan) {
    case 'keep':
      return product.prices.signatureKeep || product.prices.signatureLite;
    case 'switch':
      return product.prices.signatureSwitch || product.prices.signatureLite;
    default:
      return product.prices.signatureLite;
  }
};

function calculateSubtotal(items: ICartItem[], preowned: boolean = false) {
  return items.reduce((acc, i) => {
    i.startDate = moment(i.startDate);
    i.endDate = moment(i.endDate);
    const days = Math.round(i.endDate.diff(i.startDate, 'day', true));

    const priceOnPlan = getPriceByPlan(i.product, i.plan, preowned)
    if (i.product && i.product.category.name === 'Assinatura') {
      return acc + calculatePrice(priceOnPlan, days, i.quantity, i.product.delivery, { semesterRate: i.product.semesterRate, quarterlyRate: i.product.quarterlyRate }, i.paymentMode);
    }

    return acc + calculatePrice(priceOnPlan, days, i.quantity, undefined, { semesterRate: i.product.semesterRate, quarterlyRate: i.product.quarterlyRate }, i.paymentMode);
  }, 0);
}

export default calculateSubtotal;
