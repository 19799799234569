import * as React from 'react';
import { QueryResult } from 'react-apollo';

interface IProps {
  loading: JSX.Element | JSX.Element[];
  error: JSX.Element | JSX.Element[];
  result: QueryResult<any>;
  render: (result: QueryResult<any>) => JSX.Element | JSX.Element[];
}

class QueryRenderer extends React.Component<IProps> {
  public render() {
    const {
      loading,
      error,
      result,
      render,
    } = this.props;

    if (result.loading) {
      return loading;
    }

    if (result.error || !result.data) {
      return error;
    }

    if (result.data) {
      return render(result);
    }
  }
}

export default QueryRenderer;
