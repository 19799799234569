import * as yup from 'yup';

import cardValidator from 'card-validator'

import validateCardExpiration from '../helpers/validateCardExpiration';

const ValidatorCreditCard = yup.object().shape({
  number: yup
    .string()
    .nullable(true)
    .required('Necessário informar o número do cartão')
    .test('credit-card-number', 'Número de cartão inválido', function (value) {
      const validation = cardValidator.number(value)

      return validation.isValid
    }),
  holderName: yup.string().nullable(true).required('Necessário informar o nome'),
  expirationDate: yup
    .string()
    .required('Necessário informar a data de expiração')
    .test('Teste data', 'Data inválida.', (date) => {
      return validateCardExpiration(date);
    })
    .min(4, 'Data inválida. Formato deve ser MM/AA.')
    .required('Necessário informar a data de expiração'),
  cvv: yup
    .string()
    .nullable(true)
    .required('Necessário informar o código de segurança (cvv)')
    .test('credit-card-cvv', 'CVV inválido', function (value) {
      const card = cardValidator.number(this.parent.number)?.card

      const validation = cardValidator.cvv(value, card?.code?.size ?? 3)

      return validation.isValid
    }),
});

export default ValidatorCreditCard;
