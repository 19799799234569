import { GridColDef, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid';
import moment from 'moment';
import React from 'react';
import { IExpectedPayments } from '../../../../graphql/types/checkoutOrders';
import toReal from '../../../../helpers/toReal';
import CustomTable from '../../../CustomTable';
import ContentEmpty from '../../../CustomTable/ContentEmpty';
import LabelPayment from '../../../Labels/StatusPayment';

interface IProps {
    payments: IExpectedPayments[];
    handleChosenPayment: (data: GridRowParams) => void;
    handleDefineStatus: (data: IExpectedPayments) => void;
    amountMonth: number;
}

const ChargesTable = ({ payments, handleChosenPayment, handleDefineStatus, amountMonth }: IProps) => {

    const isMobile = () => {
        if (window.innerWidth < 600) {
            return true;
        }
        return false;
    };

    const handleStyle = (width?: number) => {
        if (isMobile()) return { width: width || 100 };
        return { flex: 1 };
    };

    const formatData = () => {
        return payments.map(item => {
            if (item.expectedPayment) {
                return {
                    id: item.index,
                    parcel: item.payment && item.payment.type === 'entry' ? 'Entrada' : `${item.index} de 12`,
                    period: item.start && item.end ? `${moment(item.start).format('DD/MM/YYYY')} até ${moment(item.end).format('DD/MM/YYYY')}` : '-',
                    dueDate: item.start ? moment(item.start).format('DD/MM/YYYY') : '-',
                    status: handleDefineStatus(item),
                    amount: item.payment ? toReal(item.payment.referenceValue) : toReal(amountMonth)
                }
            }
        }).filter(elem => elem);
    };

    const columns: GridColDef[] = [
        { field: 'id', hide: true, sortable: false },
        { field: 'parcel', headerName: 'Parcela', sortable: false, ...handleStyle(63) },
        { field: 'period', headerName: 'Período', sortable: false, width: 150, hide: isMobile() },
        { field: 'dueDate', headerName: 'Vencimento', sortable: false, flex: 1 },
        {
            field: 'status',
            headerName: 'Status',
            sortable: false,
            width: isMobile() ? 80 : 120,
            renderCell: (params: GridRenderCellParams) => <LabelPayment type={params.value} />
        },
        { field: 'amount', headerName: 'Valor', sortable: false, flex: 1 },
    ];


    return <CustomTable columns={columns} rows={formatData()} onClick={handleChosenPayment} ContentEmpty={ContentEmpty}/>;
};

export default ChargesTable;