import { Stack, Typography } from "@mui/material";
import { Formik } from "formik";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import Address from "../../../../components/Address/form";
import { validatorAddressSchema } from "../../../../validators/validatorAddress";
import { IAppState } from "../../../../store";
import { IUserAddress } from "../../../../graphql/types/users";
import { IProduct } from "../../../../graphql/types/products";
import Cookies from "../../../../helpers/Cookies";
import { googleTag } from "../../../../helpers/analytics";
import { EXTERNAL_LEAD_ID_FAST_CHECKOUT } from "../../../../boot/constants";

const initialValues: IUserAddress = {
  city: "",
  code: "",
  street: "",
  complement: "",
  neighborhood: "",
  number: "",
  state: ""
};

interface IProps {
  product: IProduct;
  total?: number;
  deliveredAddress: IUserAddress;
  setAddressData: (data: IUserAddress) => void;
}

const DeliveredAddress = ({
  product,
  deliveredAddress,
  setAddressData
}: IProps) => {
  const initialFormValues = (): IUserAddress => {
    if (deliveredAddress) {
      return {
        city: deliveredAddress.city || "",
        code: deliveredAddress.code || "",
        street: deliveredAddress.street || "",
        complement: deliveredAddress.complement || "",
        neighborhood: deliveredAddress.neighborhood || "",
        number: deliveredAddress.number || "",
        state: deliveredAddress.state || ""
      };
    }
    return initialValues;
  };
  const origin = Cookies.get("origin_fast_checkout");
  const lead_id = Cookies.get(EXTERNAL_LEAD_ID_FAST_CHECKOUT) ?? '';

  useEffect(() => {
    googleTag("address_stage", {
      send_to: "G-MFSWHTC64V",
      partner: origin,
      product: product?.slug,
      lead_id
    });
    dataLayer.push({
      event: "address_stage",
      product: product?.slug,
      origin
    });
  }, []);

  return (
    <Stack spacing={"20px"}>
      <Stack spacing={"8px"}>
        <Typography fontFamily={"Chillax"} fontSize={21} fontWeight={500}>
          Endereço de Entrega
        </Typography>
        <Typography fontFamily={"Chillax"} fontSize={16}>
          Digite o CEP para encontrarmos o seu endereço:
        </Typography>
      </Stack>
      <Formik
        initialValues={initialFormValues()}
        onSubmit={() => {}}
        validationSchema={validatorAddressSchema}
        enableReinitialize={true}
      >
        {formikProps => {
          setAddressData(formikProps.values);
          return <Address {...formikProps} scrollActive={true} />;
        }}
      </Formik>
    </Stack>
  );
};

export default connect((state: IAppState) => ({
  deliveredAddress: state.fastCheckout.deliveredAddress,
  total: state.cart.total
}))(DeliveredAddress);
