import { Stack } from '@mui/material';
import { FormikProps, withFormik } from 'formik';
import React, { SetStateAction } from 'react';
import { MutationFn, withApollo, WithApolloClient } from 'react-apollo';
import { toast } from 'react-toastify';
import InputText from '../../../components/Inputs/InputText';
import CustomButton from '../../../components/Rebranding/CustomButton';
import { IMutationRequestNewPassword } from '../../../graphql/mutations/users';
import { ForgotPasswordFormSchema } from './ForgotPasswordValidation';
import arrowIcon from '../../../assets/img/Frame.svg';
import { IRequestedNewPassword } from '.';
import { IAppState } from '../../../store';
import { ActionCreator, Dispatch } from 'redux';
import { IGeneralAction, SET_LOADING } from '../../../actionReducers/General';
import { connect } from 'react-redux';

interface IForgotPasswordFields {
  email: string;
}

const initialValues: IForgotPasswordFields = {
  email: '',
};

interface IForgotPasswordProps {
  setRequestedPassword: React.Dispatch<SetStateAction<IRequestedNewPassword>>;
  submit: MutationFn<IMutationRequestNewPassword>;
  setLoading: ActionCreator<IGeneralAction>;
  loading: boolean;
}

type Props = IForgotPasswordProps & FormikProps<IForgotPasswordFields>;

const ForgotPasswordRawForm: React.FC<Props> = (props: Props) => {
  const { loading, touched, errors, values, handleChange, handleBlur, handleSubmit } = props;

  return (
    <Stack spacing={'40px'} >
      <InputText
        label="E-mail"
        type="text"
        name={"email"}
        onBlur={handleBlur('email')}
        setValue={handleChange('email')}
        value={values.email}
        touched={touched.email}
        errorMessage={errors.email}
        submitByEnter={() => handleSubmit()}
      />
      <CustomButton
        variant="contained"
        fullWidth
        size="large"
        color="primary"
        onClick={() => handleSubmit()}
        title={'continuar'}
        loading={loading}
        disabled={loading}
        style={{
          background: '#0099DE',
          color: '#F4F4EF',
          '&:hover': {
            backgroundColor: '#006b9b',
          }
        }}
        endIcon={<img src={arrowIcon} />}
      />
    </Stack >
  );
};

export const ForgotPasswordForm = withFormik<WithApolloClient<{}> & IForgotPasswordProps, IForgotPasswordFields>({
  mapPropsToValues() {
    return initialValues;
  },
  validationSchema: ForgotPasswordFormSchema,
  async handleSubmit(values: IForgotPasswordFields, { props }) {
    const { submit, setRequestedPassword, setLoading } = props;
    try {
      setLoading(true);
      await submit({ variables: values });
      toast.success('Se esse e-mail estiver cadastrado você receberá um e-mail com novas instruções.');
      setLoading(false);
      setRequestedPassword({ status: true, email: values.email });
    } catch (e) {
      setLoading(false);
      setRequestedPassword({ status: false });
      toast.error('Erro ao solicitar troca de senha, tente novamente.')
    }
  },
})(ForgotPasswordRawForm);

export default connect((state: IAppState) => ({
  loading: state.general.loading
}), (dispatch: Dispatch<IGeneralAction>) => ({
  setLoading: (value: boolean) => dispatch(SET_LOADING(value)),
}))(withApollo(ForgotPasswordForm));
