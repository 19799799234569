import { ICartItem } from '../../actionReducers/Cart';

export enum UserRoles {
  ADMIN = 'admin',
  STAFF = 'staff',
  PREMIUM = 'premium',
  REGULAR = 'regular',
}

export interface IUserFlags {
  deleted: boolean;
  blocked: boolean;
  safeToken: boolean;
  profileCompleted: boolean;
  hasVerifiedEmail: boolean;
  hasVerifiedPhoneNumber: boolean;
}

export enum UserGender {
  MALE = 'male',
  FEMALE = 'female',
  OTHER = 'other',
  NOT_BINARY = 'not_binary',
  NOT_ANSWER = 'not_answer',
}

export enum StatusIntegration {
  approved = 'Aprovado',
  reproved = 'Reprovado',
  analysis = 'Em análise',
  waiting_resend = 'Aguardando reenvio',
  verification_expired = 'Verificação expirada',
  error_send = 'Erro ao enviar',
}

export enum LastStepComplete {
  documents_user = "Person Documents",
  documents_card_owner = "Documents Credit Card Owner",
  documents_residence = "Documents Residence",
  documents_request_separated = "Documents Request Separated",
}

export enum DocumentStatuses {
  approved = 'aprovado',
  refused = 'reprovado',
  doubleCheck = 'double check',
  analisys = 'em analise',
  revision = 'revisao',
  fraud = 'fraude'
}

export interface IFacebookData {
  _id: string;
  id: string;
  firstName: string;
  lastName: string;
  token: string;
  imageUrl: string;
}

export interface IGoogleData {
  _id: string;
  id: string;
  fullName: string;
  familyName: string;
  givenName: string;
  imageUrl: string;
  token: string;
}

export interface IPersonalData {
  firstName: string;
  lastName: string;
  document?: string;
  birthday?: Date;
  gender?: UserGender;
}

export interface IUserAddress {
  identifier?: string;
  code: string;
  state: string;
  city: string;
  neighborhood: string;
  street: string;
  number: string;
  complement?: string;
  lat?: number;
  lng?: number;
  placeId?: string;
}

export interface IUserCards {
  id: string;
  brand: string;
  expirationDate: string;
  holderName: string;
  lastDigits: string;
  isDefault: boolean;
  transactionGateway: string;
  updatedAt: Date;
}

export interface IUserBoleto {
  url: string;
  items?: ICartItem[];
}

export interface IDocuments {
  idFront?: string;
  idBack?: string;
  selfie?: string;
  residence?: string;
  docFrontPersonCard?: string;
  others?: [string];
  lastStepComplete?: string;
  documentsToReUpload?: [string];
  documentStatus?: DocumentStatuses;
  statusIntegration: StatusIntegration;
}

export interface IPublicUser {
  id: string;
  email?: string;
  phone?: string;
  role: UserRoles;
  flags?: IUserFlags;
  googlePicture?: string;
  facebookPicture?: string;
  personalData?: IPersonalData;
  addresses?: IUserAddress[];
  cards?: IUserCards[];
  prime?: boolean;
  boletos?: IUserBoleto[];
  documents?: IDocuments;
}

export interface IUserAccounts {
  id: string;
  email?: string;
  facebookData?: IFacebookData;
  googleData?: IGoogleData;
  personalData?: IPersonalData;
}

export interface ILocalization {
  zipcode: string;
  street: string;
  neighborhood: string;
  city: string;
  state: string;
}

export interface IDataPersonalUser {
  firstName: string;
  lastName: string;
}

export interface IDataUser {
  email: string;
  personalData: IDataPersonalUser;
}

export interface ICreatedOrUpdateUser {
  action: string;
  token: string;
}