import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import BannerEndCheckoutRecurrence from '../../../components/Pages/EndCheckoutRecurrence/Banner';
import TrackOrder from '../../../components/Pages/EndCheckoutRecurrence/Track';
import ProgressSteps from '../../../components/ProgressSteps';
import Header from '../../../components/Pages/Checkout/Header';
import ProductFooter from '../../../components/Footer';
import CardProductRecurrence from '../../../components/Pages/EndCheckoutRecurrence/CardProduct';
import { withApollo, WithApolloClient } from 'react-apollo';
import { IQueryBoletoExpiration, QUERY_BOLETO_EXPIRATION } from '../../../graphql/queries/config';

const EndCheckoutRecurrence = ({ client }: WithApolloClient<{}>) => {

    const [paymentExpired, setPaymentExpired] = useState<Number>();

    useEffect(() => {
        boletoExpiration();
    }, []);

    const boletoExpiration = async () => {
        const {data: {boletoExpiration}} = await client.query<IQueryBoletoExpiration>({
            query: QUERY_BOLETO_EXPIRATION,
            fetchPolicy: 'network-only'
        });
        setPaymentExpired(boletoExpiration)
    };

    const openChat = () => {
        const node = document.getElementById('blip-chat-open-iframe')
        node.click()
    };  

    const progressSteps = [
        'Dados pessoais e de pagamento',
        'Aguardando pagamento',
    ];

    return (
        <React.Fragment>
            <Header />
            <Grid
                container
                columns={6}
                width={{
                    sm: '732px',
                }}
                columnSpacing={{
                    sm: '32px',
                }}
                rowSpacing={{
                    sm: '0px',
                    xs: '32px',
                }}
                margin={{
                    sm: '0 auto',
                }}
                marginBottom={{
                    md: '195px'
                }}
                padding={{
                    sm: '0px',
                    xs: '20px'
                }}
            >
                <ProgressSteps steps={progressSteps} activeStep={1} />
                <Grid item xs={6} sm={6} md={6}>
                    <BannerEndCheckoutRecurrence openChat={openChat} />
                </Grid>
                <Grid item xs={6} sm={6} md={3}>
                    <CardProductRecurrence/>
                </Grid>
                <Grid item xs={6} sm={6} md={3}>
                    <TrackOrder paymentExpired={paymentExpired} />
                </Grid>
            </Grid>
            <ProductFooter />
        </React.Fragment>
    );
};

export default withApollo(EndCheckoutRecurrence);