import moment from 'moment';
import * as yup from 'yup';
import { validatorEmail } from './validatorEmail';

export const validatorSignUpSchema = validatorEmail.concat(yup.object().shape({
  firstName: yup
    .string()
    .required('O nome é obrigatório.'),
  lastName: yup
    .string()
    .required('O sobrenome é obrigatório.'),
  password: yup
    .string()
    .min(6, 'Por favor, coloque uma senha com no mínimo 6 caractéres.')
    .required('A senha é obrigatória.'),
  phone: yup
    .string()
    .matches(/^([1-9][1-9]9[1-9][0-9]{3}[0-9]{4})$/, 'Por favor, coloque um número de celular válido.')
    .required('O número de celular é obrigatório.'),
  birthday: yup
    .string()
    .required('A data de nascimento é obrigatório')
    .test('birthday', 'Necessário ter pelo menos 18 anos', async (value) => moment(value).isBefore(moment().subtract('y', 18))),
  document: yup.string().required('O CPF é obrigatório.').min(11, 'Por favor informe seu CPF completo'),
  gender: yup.string().required('O gênero é obrigatório.')
}));
