import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { media } from '../../helpers/style';

export const ThankWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  ${media.medium`
    height: 65vh;
  `}

  ${media.small`
    margin-bottom: 50px;
  `}
`;

export const ThanksText = styled.div`
  width: 50%;
  text-align: center;
  padding-left: calc(5% - 2px);
  color: #131D27;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h1{
    font-family: 'Gilroy', 'Roboto', sans-serif;
    font-weight: bold;
    font-size: 55px;
    margin-bottom: 10px;
  }
  p{
    font-family: 'Roboto', sans-serif;
    font-size: 25px;
    margin-bottom: 5px;
    span{
      color: #2E7D32;
      font-size: 29px;
      font-weight: 500;
    }
  }

  ${media.medium`
    h1{
      font-size: 45px;
    }
    p{
      font-size: 25px;
      span{
        font-size: 30px;
      }
    }
  `}
  ${media.small`
    width: 100%;
    h1{
      font-size: 45px;
    }
    p{
      font-size: 20px;
      span{
        font-size: 25px;
      }
    }
  `}
`;

export const ThanksButton = styled(Link)`
  text-decoration: none;
  font-family: 'Gilroy', 'Roboto', sans-serif;
  position: relative;
  font-weight: bold;
  width: 50%;
  min-width: 300px;
  height: 46px;
  padding: 10px 20px;
  background:  #0FCF58;
  border-radius: 23px;
  font-size: 20px;
  letter-spacing: .8px;
  color: #fff;
  box-sizing: border-box;
  display: flex;
  align-items:center;
  justify-content:center;
  margin-top: 30px;
  transition: box-shadow .5s, color .5s, background-color .5s;

  &:hover{
    color: #fff;
    box-shadow: 0px 10px 20px -5px #0FCF58;
  }
`;

export const ThanksImage = styled.div`
  margin-right: 0px;
  width: 50%;
  display: flex;
  justify-content: flex-end;
  img{
    width: 65%;
    margin-right: 0px;
    max-width: 645px;
  }
  ${media.medium`
    img{
      width: 80%;
    }
  `}
  ${media.small`
    display:none;
  `}
`;

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 20px;
  min-height: inherit;
  justify-content: center;
  text-align: center;
`;
