import React from 'react';
import { Chip } from "@mui/material";
import { PaymentStatusTypes } from "../../../graphql/types/general";

interface IProps {
    type: PaymentStatusTypes;
}

const LabelPayment = ({ type }: IProps) => {

    const configLabel = {
        paid: { label: 'Pago', textColor: '#2E7D32', backgroundColor: 'rgba(46, 125, 50, 0.12)' },
        processing: { label: 'Processando', textColor: '#ED6C02', backgroundColor: 'rgba(237, 108, 2, 0.12)' },
        unpaid: { label: 'Não pago', textColor: '#D32F2F', backgroundColor: 'rgba(244, 67, 54, 0.12)' }
    }

    return (
        <Chip
            label={configLabel[type] && configLabel[type].label || ''}
            sx={{
                color: configLabel[type].textColor,
                backgroundColor: configLabel[type].backgroundColor,
            }}
        />
    )
};

export default LabelPayment;
