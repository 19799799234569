import { FormikBag, FormikProps, withFormik } from 'formik';
import React, { Dispatch, SetStateAction } from 'react';
import { MutationFn, withApollo, WithApolloClient } from 'react-apollo';
import { toast } from 'react-toastify';
import {
  FormikFixedForm,
} from '../../../components/styled';
import { IMutationAddAddress, IMutationEditAddress } from '../../../graphql/mutations/users';
import { IUserAddress } from '../../../graphql/types/users';
import { Box, Button, Stack, Typography } from '@mui/material';
import Address from '../../../components/Address/form';
import CustomButton from '../../../components/Rebranding/CustomButton';
import { validatorAddressSchema } from '../../../validators/validatorAddress';
import replaceMask from '../../../helpers/replaceMask';

interface IProfileAddressValues {
  street: string;
  code: string;
  complement: string;
  city: string;
  state: string;
  neighborhood: string;
  number: string;
  gmapsInput?: string;
  lat?: number;
  lng?: number;
  placeId?: string;
  checkNotAddress?: boolean;
  localizationChosen?: boolean;
  codeValid?: boolean;
}

const initialValues: IProfileAddressValues = {
  street: '',
  code: '',
  complement: '',
  city: '',
  neighborhood: '',
  state: '',
  number: '',
  lat: 0,
  lng: 0,
  gmapsInput: '',
  placeId: '',
  checkNotAddress: false,
  localizationChosen: false,
  codeValid: true,
};

interface IProfileAddressFormProps {
  address?: IUserAddress;
  submit: MutationFn<IMutationAddAddress | IMutationEditAddress>;
  toggleEdit?: () => void;
  refetch: (...args: any[]) => any;
  callback?: () => void;
  setShowFormAddress?: Dispatch<SetStateAction<boolean>>;
}

type Props = IProfileAddressFormProps & WithApolloClient<{}> & FormikProps<IProfileAddressValues>;

const ProfileAddAddressForm: React.FC<Props> = (props: Props) => {

  const {
    setShowFormAddress,
    isSubmitting
  } = props;

  const editCancel = () => {
    setShowFormAddress(false);
    if (props.toggleEdit) {
      props.toggleEdit();
    }
  };

  return (
    <Box sx={{ width: '100%', marginBottom: '79px' }}>
      <FormikFixedForm>
        {
          <Stack spacing={'24px'}>
            <Stack spacing={'24px'} sx={{ marginTop: '43px' }}>
              <Typography fontFamily={'Chillax'} fontWeight={500} fontSize={'21px'} sx={{ color: '#27AE60' }}>Endereço</Typography>
              <Typography fontFamily={'Chillax'} fontSize={'16px'}>Digite o CEP para encontrarmos o seu endereço:</Typography>
            </Stack>
            <Address {...props} />
            <Stack spacing={'30px'} sx={{ width: '100%' }}>
              <CustomButton
                sx={{ width: '100%' }}
                onClick={() => props.handleSubmit()}
                title={'salvar endereço'}
                loading={isSubmitting}
              />
              <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <Button
                  variant="text"
                  sx={{ color: '#D32F2F', }}
                  onClick={() => editCancel()}
                >
                  CANCELAR
                </Button>
              </Box>
            </Stack>
          </Stack>
        }
      </FormikFixedForm>
    </Box>

  );
}

export default withFormik<IProfileAddressFormProps, IProfileAddressValues>({
  validationSchema: validatorAddressSchema,
  mapPropsToValues(props: IProfileAddressFormProps) {
    const { address } = props;
    if (address) {
      return {
        ...address,
        complement: address.complement || '',
        code: replaceMask(String(address.code)),
        codeValid: true,
      };
    }

    return initialValues;
  },

  async handleSubmit(
    values: IProfileAddressValues,
    { props, resetForm, setStatus }: FormikBag<IProfileAddressFormProps, IProfileAddressValues>,
  ) {
    const { submit, refetch, toggleEdit, callback, setShowFormAddress } = props;

    const code = values.code.replace(/\D+/g, '');
    const identifier = toggleEdit ? props.address.identifier || `${props.address.street} - ${props.address.number}` : `${values.street} - ${values.number}`;

    try {
      await submit({ variables: { ...values, code, identifier } });

      if (toggleEdit) {
        toast.success('Sucesso ao editar seu endereço!');
      } else {
        toast.success('Sucesso ao adicionar um novo endereço!');
      }
    } catch (e) {
      if (toggleEdit) {
        toast.error('Erro ao editar seu endereço.');
      } else {
        toast.error('Erro ao criar seu endereço.');
      }
    }
    setStatus(true);
    await refetch();

    if (toggleEdit) {
      toggleEdit();
    } else {
      setShowFormAddress(false);
    }
    if (callback) { callback(); }
    resetForm();
    window.scrollBy(0, -400);
  },
  validate() {
    const errors: any = {};

    return errors;
  },
})(withApollo(ProfileAddAddressForm));
