import allowedDays from '../config/allowedDays';

type IRates = {
  semesterRate: number,
  quarterlyRate: number,
};

// @ts-ignore
const calculatePrice = (price: number, days: number, quantity: number, delivery?: number, rates?: IRates, paymentMode?: string) => {
  const daysToPay = days;

  const foundDate = allowedDays.find((day) => day.days === daysToPay);

  const bonus = 1 - ((foundDate ? foundDate.realDiscount : 0) / 100);
  let rawPrice = (Math.ceil(price * bonus * 100000) / 100000) * daysToPay * quantity;

  if (paymentMode === 'quarterly' || paymentMode === 'semester') {
    rawPrice = (Math.ceil(price * bonus * 100000) / 100000) * 365 * quantity;
  }

  const roundValue = Math.round(rawPrice * 100) / 100;

  return handlePaymentMode(roundValue, paymentMode, rates);
};

const handlePaymentMode = (price: number, paymentMode?: string, rates?: IRates) => {

  if (paymentMode === 'quarterly' && rates?.quarterlyRate) {

    const spread = (100 + Number(rates.quarterlyRate));
    return price * (spread / 100) / 4;
  }

  if (paymentMode === 'semester' && rates?.semesterRate) {
    const spread = (100 + Number(rates.semesterRate));
    return price * (spread / 100) / 2;
  }

  return price;
};

export default calculatePrice;
