import api from "../api";
import config from '../../config';

interface IProps {
  email: string;
  document: string;
}

export default async function verifyCpf({ email, document }: IProps) {
  try {
    const { data } = await api.post(`${config.YacareURL}/verifyPersonalData`, { email, cpf: document });
    return data;
  } catch (err: any) {
    if (err && err.response && err.response.data && err.response.data) {
      throw new Error(err.response.data);
    }
  }
};
