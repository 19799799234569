import { ALLUGATOR_AUTH_TOKEN } from "../../boot/constants";
import Cookies from "../../helpers/Cookies";
import axios from 'axios';

export default async function updateUser({ id, values }: any) {
  try {
    const { data } = await axios.patch(`${process.env.RAZZLE_USER_API_URL}/users/update/${id}`,
      values,
      { headers: { Authorization: `Bearer ${Cookies.get(ALLUGATOR_AUTH_TOKEN)}` } }
    );

    return data;
  } catch (err: any) {
    if (err && err.response && err.response.data && err.response.data.message) {
      throw new Error(err.response.data.message);
    }
  }
};
