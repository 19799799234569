import { applyMiddleware, combineReducers, compose, createStore, Middleware } from 'redux';
import { logger } from 'redux-logger';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { cartReducer, ICartState } from './actionReducers/Cart';
import { checkoutReducer, ICheckoutState } from './actionReducers/Checkout';
import { documentReducer, IDocumentState } from './actionReducers/Documents';
import { fastCheckoutReducer, IFastCheckoutState } from './actionReducers/FastCheckout';
import { generalReducer, IGeneralState } from './actionReducers/General';
import { IProductState, productReducer } from './actionReducers/Product';
import { IProfileState, profileReducer } from './actionReducers/Profile';
import { checkoutRecurrenceReducer, ICheckoutRecurrenceState } from './actionReducers/Recurrence';
import { ISearchState, searchReducer } from './actionReducers/Search';
import { ISidebarState, sidebarReducer } from './actionReducers/Sidebar';
import { IUserState, userReducer } from './actionReducers/User';
import Config from './config';

export interface IAppState {
  cart: ICartState;
  user: IUserState;
  general: IGeneralState;
  search: ISearchState;
  sidebar: ISidebarState;
  product: IProductState;
  profile: IProfileState;
  checkout: ICheckoutState;
  fastCheckout: IFastCheckoutState;
  checkoutRecurrence: ICheckoutRecurrenceState;
  document: IDocumentState;
}

const reducers = combineReducers({
  cart: cartReducer,
  general: generalReducer,
  search: searchReducer,
  user: userReducer,
  sidebar: sidebarReducer,
  product: productReducer,
  profile: profileReducer,
  checkout: checkoutReducer,
  checkoutRecurrence: checkoutRecurrenceReducer,
  document: documentReducer,
  fastCheckout: fastCheckoutReducer
});

const rootPersistConfig = {
  version: 1,
  key: 'root:allugator',
  storage: storage,
  whitelist: ['product', 'profile', 'checkout', 'checkoutRecurrence', 'document', 'fastCheckout']
};

const persistedReducer = persistReducer(rootPersistConfig, reducers);

const middlewares: Middleware[] = [];

if (Config.Env === 'development') {
  middlewares.push(logger);
}

const store = createStore(
  persistedReducer,
  compose(applyMiddleware(...middlewares)),
);

export const persistor = persistStore(store);

export default store;
