import * as React from 'react';
import { Icon } from '../../../components/UI/Icon';
import {
  ChatBot,
  CloseButton,
  Content,
  HeaderTitle,
  InfoText,
  ModalCloseButton,
  ThankYouModal as MainDiv,
} from './styled';

interface IThankYouProps {
  close: any;
}

export class ThankYouModal extends React.Component<IThankYouProps> {
  public render() {
    return (
      <MainDiv>
        <CloseButton onClick={this.props.close}>
          <Icon>close</Icon>
        </CloseButton>

        <Content>
          <img
            alt='presente'
            src='http://icons.iconarchive.com/icons/paomedia/small-n-flat/1024/gift-icon.png'
            width={80}
            height={80}
          />

          <HeaderTitle>Parabéns!</HeaderTitle>
          <InfoText>Seu pedido foi confirmado e deu tudo certo!</InfoText>
          <InfoText>Agora você já faz parte da revolução.</InfoText>

          <ChatBot>
            <ModalCloseButton onClick={this.props.close}>Continuar</ModalCloseButton>
          </ChatBot>

        </Content>

      </MainDiv>
    );
  }
}

export default ThankYouModal;
