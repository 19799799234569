import styled from 'styled-components';

import {
  CommonButton,
  CommonDiv,
  CommonInput,
  CommonLink,
  FormikFixedForm,
  InputWrapper,
  PrimaryButton,
} from '../../../components/styled';
import { media, sizes } from '../../../helpers/style';
import {
  black,
  gray2,
  gray4,
  gray5,
  gray6,
  green1,
  white,
} from '../../../style/colors';
import {
  gradientCartItem,
  gradientDarkGray,
  gradientDarkGreen,
  gradientGray,
} from '../../../style/gradients';
import {
  ProfileAddAddressSectionWrapper,
  ProfileAddressBox,
  ProfileAddressesSectionWrapper,
  ProfileSectionIcon,
} from '../../Profile/components/styled';

export const CartHeader = styled(CommonDiv)`
  margin-top: 20px;
  padding: 4em 0 1.5em;
  font-size: 6em;
  font-weight: 700;
  background: ${gradientDarkGreen};
  color: ${white};
  line-height: 1;
  text-align: center;

  ${media.small`
      padding: 2em 0 1em;
    `
  }
`;

export const CartContentWrapper = styled(CommonDiv)`
  align-items: flex-start;
  padding-top: 10em;
  max-width: ${sizes.big}px;
  min-height: calc(100vh - 440px);


  ${media.small`
      flex-direction: column;
      padding: 10em 3em 0;
      width: calc(100% - 6em);
    `
  }
`;

export const CartContentItems = styled(CommonDiv)`
  flex-direction: column;
  flex-grow: 1;
`;

export const CartIsEmptyMessage = styled.h1`
  margin: 0;
  padding: 0;
  font-size: 6em;
  font-weight: 700;
  margin-bottom: 1em;
  text-align: center;
`;

export const CartContentInfo = styled(CommonDiv)`
  flex-direction: column;
  flex-shrink: 0;
  width: calc(80em - 10em);
  padding-left: 10em;

  ${media.small`
      width: 100%;
      padding: 3em 0 0;
    `
  }
`;

export const CartContentEmptyAddress = styled.a`
  background: ${gradientGray};
  color: rgba(255, 255, 255, 0.8);
  transition: 0.2s;
  padding: 30px;
  margin-bottom: 30px;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 24px;
  width: calc(100% - 60px);
  border-radius: 24px;

  &:hover {
    cursor: pointer;
    background: ${gradientDarkGray};
    color: ${white};
  }

  > span {
    width: 300px;
    border-right: 1px solid ${gray4};
  }

  ${media.small`
      margin-left: 6px;
    `
  }
`;

export const CartContentCouponWrapper = styled(InputWrapper)`
  position: relative;
`;

export const CartContentCouponInput = styled(CommonInput)`
  font-size: 14px;
  padding: 15px 24px;
  width: calc(100% - 48px);
  border-radius: 6px;
`;

export const CartContentCouponButton = styled(PrimaryButton)`
  position: absolute;
  font-size: 14px;
  padding: 10px 18px;
  line-height: 12px;
  top: 35px;
  right: 3px;
  border-radius: 6px;
`;

export const CartContentInfoPrices = styled(CommonDiv)`
  flex-direction: column;
  margin: 4em 0;
`;

export const CartContentInfoPrice = styled(CommonDiv)`
  padding: 12px 0;
  font-size: 14px;
  justify-content: flex-end;
  color: ${(props: { discount?: boolean }) => props.discount ? green1 : black};
  font-weight: 500;

  > span:first-child {
    font-weight: 400;
    flex-grow: 1;
    text-align: left;
    color: ${gray5};
  }
`;

export const CartCallToAction = styled(CommonLink)`
  font-size: 3em;
  padding: 12px 30px;
  width: fit-content;
  border-radius: 6px;
  text-align: center;
`;

/** Shipping Address Section */
export const NoDeliveryButton = styled(PrimaryButton)`
  font-size: 14px;
  margin-top: 1em;
  width: 100%;
`;

export const ShippingAddressesWrapper = styled(ProfileAddressesSectionWrapper)``;

export const ShippingAddressWrapper = styled(ProfileAddAddressSectionWrapper)`
  margin-bottom: 10em;
`;

export const ShippingAddressBoxWrapper = styled(ProfileAddressBox)`
  margin: 0 0 5em;
`;

/** Cart Item */
export const CartDisplayItemWrapper = styled(CommonDiv)`
  justify-content: flex-start;
  margin-bottom: 4em;
  border-radius: 6px;
  background: ${gradientCartItem};
  box-shadow: 0 0 0 1px #dee2e6;

  > img {
    width: 78px;
    height: 78px;
    margin: 12px;

    ${media.small`
        margin: 6px;
      `
  }
  }

  ${media.small`
      padding: 12px 6px;
      width: calc(100% - 12px);
      flex-direction: column;
    `
  }
`;

export const CartDisplayItemInfo = styled(CommonDiv)`
  flex-direction: column;
  flex-grow: 1;
  padding: 30px;

  ${media.small`
      padding: 6px;
      margin-bottom: 6px;
    `
  }
`;

export const CartDisplayItemTitle = styled(CommonDiv)`
  justify-content: flex-start;
  font-size: 3em;
  font-weight: 700;
  margin-bottom: 6px;

  ${media.small`
      justify-content: center;
    `
  }
`;

export const CartDisplayItemTechDetails = styled(CommonDiv)`
  justify-content: flex-start;
  font-size: 2em;
  margin-top: 0.5em;
  margin-bottom: 2em;
  font-weight: 500;
  color: ${gray5};

  ${media.small`
      justify-content: center;
    `
  }
`;

export const CartDisplayItemDetails = styled(CommonDiv)`
  justify-content: flex-start;
  font-weight: 500;
  font-size: 2.33em;

  > span {
    font-weight: 700;
  }

  ${media.small`
      justify-content: center;
    `
  }
`;

export const CartDisplayItemOptions = styled(CommonDiv)`
  width: unset;
`;

export const RemoveButton = styled(CommonButton)`
  padding: unset;
  width: 6em;
  height: 6em;
  border-radius: 50%;
  background-color: ${gray4};
  color: ${white};
  margin-right: 1em;
  box-shadow: none;

  &:hover {
    color: ${white};
    box-shadow: none;
  }
`;

export const ChangeButton = styled(CommonLink)`
  background: transparent;
  padding: 0.7em 1.6em;
  font-size: 2.33em;
`;

export const CartDisplayItemPrice = styled(CommonDiv)`
  font-weight: 700;
  font-size: 3em;
  margin: 0 30px;
  width: fit-content;
  white-space: nowrap;

  ${media.small`
      margin-top: 1em;
    `
  }
`;

/** Checkout */
export const CartCheckoutSection = styled(ShippingAddressWrapper)`
  margin: unset;
  padding: 10em 5em 5em;

  ${media.small`
      padding: 10em 2em 5em;
      width: calc(100% - 4em);
    `
  }
`;

export const CartCheckoutIcon = styled(ProfileSectionIcon)`
  ${media.small`
      display: none;
    `
  }
`;

export const CheckoutFixedForm = styled(FormikFixedForm)`
  align-items: center;
  width: 70%;
`;

export const CartCheckoutSectionTitle = styled(CommonDiv)`
  position: absolute;
  top: 9px;
  left: 30px;
  justify-content: flex-start;
  font-weight: 700;
  font-size: 3em;
  ${media.small`
      width: calc(100% - 4em);
    `
  }
`;

export const CartCheckoutProduct = styled(CommonDiv)`
  flex-direction: column;
  margin-bottom: 3em;
  padding-bottom: 3em;
  border-bottom: 1px dashed ${gray2};
`;

export const CartCheckoutProductTitle = styled(CommonDiv)`
  justify-content: flex-start;
  font-size: 2em;
  font-weight: 500;
  margin-bottom: 6px;
  color: ${gray6};
`;

export const CartCheckoutProductDetails = styled(CommonDiv)`
  font-size: 2.33em;
  font-weight: 500;
  justify-content: flex-start;

  small {
    flex-grow: 1;
    color: ${gray5};
    margin-left: 6px;
  }
`;

export const CartCheckoutPagSeguroImg = styled.img`
  height: 6em;
  margin: 12px 0;
`;

export const CouponMessage = styled.small`
  text-align: center;
  color: darkgreen;
  font-size: 0.9rem;
  margin-top: 20px;
`;
