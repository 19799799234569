import ApolloClient from "apollo-client";
import { useEffect, useReducer } from "react";
import { IQueryCurrentUser, QUERY_CURRENT_USER } from "../graphql/queries/users";
import { IPublicUser } from "../graphql/types/users";
import { useCallback } from "react";

function reducer(state: IPublicUser, action: any) {
  switch (action.type) {
    case 'set_user':
      return action.payload;
    default:
      return state;
  }
}

export default function useUser(client: ApolloClient<any>) {
  // Tive que tomar essa abordagem por conta da versão do react-redux.
  // Por estar na versão 5.* ele não possui hooks.
  const [state, dispatch] = useReducer(reducer, {});

  useEffect(() => {
    queryCurrentUser();
  }, []);

  const queryCurrentUser = useCallback(async () => {
    const result = await client.query<IQueryCurrentUser>({
      query: QUERY_CURRENT_USER,
    });

    if (result
      && result.data
      && result.data.currentUser
      && Object.keys(result.data.currentUser).length) {
      dispatch({ type: 'set_user', payload: result.data.currentUser });
    }
  }, []);

  return { user: state, refetchUser: queryCurrentUser };
}