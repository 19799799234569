import { Box, Divider, Stack, Typography } from '@mui/material';
import React, { ReactNode } from 'react';

interface IProps {
    title: string;
    children: ReactNode;
    current: boolean;
    onClick: () => void;
}

const BarPayments = ({ title, children, current, onClick }: IProps) => {
    return (
        <div onClick={onClick} style={{
            cursor: 'pointer',
        }}>
            <Stack
                spacing={'8px'}
                justifyContent={'center'}
                sx={{ padding: '0 7px' }}
            >
                <Box display={'flex'} justifyContent={'center'}>
                    {children}
                </Box>
                <Divider variant="fullWidth" color={current ? '#10D878' : '#A1A1A1'} />
                <Typography variant="body2"
                    fontSize={'15px'}
                    letterSpacing={'0.25px'}
                    color={current ? '#10D878' : 'rgba(0, 0, 0, 0.38)'}
                    textAlign={'center'}
                >
                    {title}
                </Typography>
            </Stack>
        </div >
    )
};

export default BarPayments;