export const mountName = (name: string): { firstName: string; lastName: string } => {
  if(name){
    const names = name
    .trim()
      .replace(/[0-9.]+/g, '')
      .split(' ');
      const firstName = names[0].trim();
      names.shift();
      const lastName = names.join(' ').trim();
      return { firstName, lastName };
    }
    return { firstName: undefined, lastName: undefined};
  };