import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { IExpectedPayments } from '../../graphql/types/checkoutOrders';
import { PaymentStatusTypes } from '../../graphql/types/general';
import toReal from '../../helpers/toReal';
import LabelPayment from '../Labels/StatusPayment';
interface IProps {
    currentCardPayment: IExpectedPayments;
    type: PaymentStatusTypes;
    amountMonth: number;
    openModalPayment?: () => void;
}

const PaymentDetail = ({ currentCardPayment: { start, end, index, payment }, type, amountMonth, openModalPayment }: IProps) => {

    const configCard = {
        processing: { backgroundColor: 'rgba(237, 108, 2, 0.04)', borderColor: '#ED6C02' },
        paid: { backgroundColor: 'rgba(46, 125, 50, 0.04)', borderColor: '#4CAF50' },
        unpaid: { backgroundColor: 'rgba(211, 47, 47, 0.04)', borderColor: '#D32F2F' },
    };

    const parseCardData = (cardNumber: string) => {
        return cardNumber.substring(cardNumber.length - 4);
    };

    return (
        <Stack spacing={'24px'} sx={{
            padding: '24px',
            border: `2px solid ${configCard[type].borderColor}`,
            borderRadius: '8px',
            boxShadow: '0px 5px 8px rgba(120, 111, 111, 0.15)',
            backgroundColor: configCard[type].backgroundColor,
        }}>
            <Box>
                <LabelPayment type={type} />
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant='subtitle1' fontSize={'17px'}>{payment && payment.type === 'entry' ? 'Entrada' : `Parcela ${index} de 12`}</Typography>
                <Typography variant='subtitle1' fontSize={'17px'} fontWeight={700}>
                    {payment && payment.referenceValue ? toReal(payment.referenceValue) : toReal(amountMonth)}
                </Typography>
            </Box>

            <Divider />

            <Stack spacing={'4px'} sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
                {
                    start && end ? (
                        <Typography variant='subtitle2' fontSize={'15px'}>
                            Período: {moment(start).format('DD/MM/YYYY')} até {moment(end).format('DD/MM/YYYY')}
                        </Typography>
                    ) : null
                }
                {start ? <Typography variant='subtitle2' fontSize={'15px'}>Vencimento: {moment(start).format('DD/MM/YYYY')}</Typography> : null}
                {
                    payment && payment.data && payment.data.result && type !== PaymentStatusTypes.UNPAID ? (
                        <Typography variant='subtitle2' fontSize={'15px'}>
                            Data do pagamento: {moment(payment.data.result.datePaid || payment.data.updatedAt).format('DD/MM/YYYY')}
                        </Typography>
                    ) : null
                }
                {
                    payment && payment.data && payment.data.result && payment.data.result.card && type !== PaymentStatusTypes.UNPAID ? (
                        <Typography variant='subtitle2' fontSize={'15px'}>
                            Mensalidade paga com cartão final&nbsp;
                            <span style={{ color: 'rgba(0, 0, 0, 0.87)' }}>
                                {parseCardData(payment.data.result.card.card_number)}
                            </span>
                        </Typography>
                    ) : null
                }
            </Stack>
            <Button
                variant="contained"
                fullWidth
                size="large"
                color="primary"
                sx={{ marginTop: '24px' }}
                disabled={type !== PaymentStatusTypes.UNPAID}
                onClick={openModalPayment}
            >
                PAGAR PARCELA
            </Button>

        </Stack>
    )
};

export default PaymentDetail;