import Config from '../config';

export const googleTag = (event: string, data?: any) => {
  if (!gtag || Config.Env !== 'production') { return; }
  gtag('event', event, data);
};

export const acSiteTracking = (action: 'setEmail', data: string) => {
  try {
    if (typeof prismGlobalObject === 'undefined' || !pgo || !window || Config.Env !== 'production') {
      return;
    }
    prismGlobalObject.visitor.url = window.location.href;

    pgo(action, data);
    pgo('process');
  } catch (error) {
    return;
  }
};
