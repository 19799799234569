import React from 'react';
import { Stack, Typography } from '@mui/material';

const OtherPayment = () => {
    return (
        <Stack spacing={'8px'}>
            <Typography
                fontFamily={'Chillax'}
                fontSize={16}
                fontWeight={500}
            >
                Informações importantes sobre o pagamento:
            </Typography>
            <Stack>
                <Typography
                    fontFamily={'Chillax'}
                    fontSize={12}
                    fontWeight={400}
                    sx={{ display: 'list-item', listStylePosition: 'inside' }} >
                    Após finalizar o pedido, realize o pagamento em até 24 horas. Caso contrário, o pedido será cancelado e um novo deverá ser feito;
                </Typography>
                <Typography fontFamily={'Chillax'}
                    fontSize={12}
                    fontWeight={400}
                    sx={{ display: 'list-item', listStylePosition: 'inside' }}>
                    Você pode consultar o QR Code em Meus Pedidos durante esse período.
                </Typography>
            </Stack>
        </Stack>
    )
};

export default OtherPayment;