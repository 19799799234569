import gql from 'graphql-tag';
import { ICheckoutOrder, IPaymentBoletoResult, IPaymentPixResult, IPaymentTwoCardResult } from '../types/checkoutOrders';

export interface IMutationPagarMePayment {
  pagarMePayment: string;
}

export const MUTATION_CHECKOUT = gql`
  mutation checkout(
    $items: [CartItem]!,
    $cardInfo: CardInfo,
    $customer: Customer!,
    $billingAddress: AddressInput!,
    $shippingAddress: AddressInput!,
    $selectedAddress: Int,
    $coupon: String,
    $installments: Int!,
    $goPhone: Boolean,
    $referral: String,
    $plan: String,
    $missingAmount: Float,
    $missingOrderId: String,
    $paymentMethod: PaymentMethods,
    $gtmCookie: String,
    $preownedProduct: Boolean,
  ) {
    checkout(
      items: $items,
      cardInfo: $cardInfo,
      customer: $customer,
      shippingAddress: $shippingAddress,
      billingAddress: $billingAddress,
      selectedAddress: $selectedAddress,
      coupon: $coupon,
      installments: $installments,
      goPhone: $goPhone,
      referral: $referral,
      plan: $plan,
      missingAmount: $missingAmount,
      missingOrderId: $missingOrderId,
      paymentMethod: $paymentMethod,
      gtmCookie: $gtmCookie,
      preownedProduct: $preownedProduct,
    ) {
      orderId,
      boletoUrl,
      qrcode,
      shortId,
      message
    }
  }
`;

export const MUTATION_FAST_CHECKOUT = gql`
  mutation fastCheckout(
    $items: [CartItem]!,
    $cardInfo: CardInfo,
    $customer: Customer!,
    $billingAddress: AddressInput!,
    $shippingAddress: AddressInput!,
    $selectedAddress: Int,
    $coupon: String,
    $installments: Int!,
    $goPhone: Boolean,
    $referral: String,
    $plan: String,
    $utm: UtmInput,
    $missingAmount: Float,
    $missingOrderId: String,
    $paymentMethod: PaymentMethods,
    $gtmCookie: String,
    $preownedProduct: Boolean,
    $userEmail: String!,
    $origin: String,
    $salesFunnel: String,
    $campaign: ID,
    $externalChannel: String,
    $externalVendor: String,
    $vendor: String,
    $paymentMode: String,
    $leadId: String,
    $upgradeId: String,
    $notice: String,
  ) {
    fastCheckout(
      items: $items,
      cardInfo: $cardInfo,
      customer: $customer,
      shippingAddress: $shippingAddress,
      billingAddress: $billingAddress,
      selectedAddress: $selectedAddress,
      coupon: $coupon,
      installments: $installments,
      goPhone: $goPhone,
      referral: $referral,
      plan: $plan,
      missingAmount: $missingAmount,
      missingOrderId: $missingOrderId,
      paymentMethod: $paymentMethod,
      gtmCookie: $gtmCookie,
      preownedProduct: $preownedProduct,
      userEmail: $userEmail,
      origin: $origin,
      salesFunnel: $salesFunnel,
      campaign: $campaign,
      externalChannel: $externalChannel,
      externalVendor: $externalVendor,
      vendor: $vendor,
      paymentMode: $paymentMode,
      leadId: $leadId,
      upgradeId: $upgradeId,
      notice: $notice,
      utm: $utm,
    ) {
      orderId,
      boletoUrl,
      qrcode,
      shortId,
      message
    }
  }
`;

export const MUTATION_FAST_CHECKOUT_PICPAY = gql`
  mutation fastCheckoutPicpay(
    $items: [CartItem]!,
    $cardInfo: CardInfo,
    $customer: Customer!,
    $billingAddress: AddressInput!,
    $shippingAddress: AddressInput!,
    $selectedAddress: Int,
    $coupon: String,
    $installments: Int!,
    $goPhone: Boolean,
    $referral: String,
    $plan: String,
    $missingAmount: Float,
    $missingOrderId: String,
    $paymentMethod: PaymentMethods,
    $gtmCookie: String,
    $preownedProduct: Boolean,
    $userEmail: String!,
    $origin: String,
    $salesFunnel: String,
    $campaign: ID,
    $externalChannel: String,
    $externalVendor: String,
    $vendor: String,
    $paymentMode: String,
    $leadId: String,
  ) {
    fastCheckoutPicpay(
      items: $items,
      cardInfo: $cardInfo,
      customer: $customer,
      shippingAddress: $shippingAddress,
      billingAddress: $billingAddress,
      selectedAddress: $selectedAddress,
      coupon: $coupon,
      installments: $installments,
      goPhone: $goPhone,
      referral: $referral,
      plan: $plan,
      missingAmount: $missingAmount,
      missingOrderId: $missingOrderId,
      paymentMethod: $paymentMethod,
      gtmCookie: $gtmCookie,
      preownedProduct: $preownedProduct,
      userEmail: $userEmail,
      origin: $origin,
      salesFunnel: $salesFunnel,
      campaign: $campaign,
      externalChannel: $externalChannel,
      externalVendor: $externalVendor,
      vendor: $vendor,
      paymentMode: $paymentMode,
      leadId: $leadId,
    ) {
      orderId,
      paymentUrl,
      shortId
    }
  }
`;

export interface IMutationPagarMeBoleto {
  pagarMeBoleto: IPaymentBoletoResult;
}

export const MUTATION_PAGAR_ME_BOLETO = gql`
  mutation pagarMeBoleto(
    $items: [CartItem]!,
    $selectedAddress: Int,
    $customer: Customer!,
    $shippingAddress: AddressInput!,
    $coupon: String,
    $goPhone: Boolean,
    $meliuz: String,
    $plan: String,
    $missingAmount: Float,
    $missingOrderId: String,
    $referral: String,

  ) {
    pagarMeBoleto(
      items: $items,
      selectedAddress: $selectedAddress,
      customer: $customer,
      coupon: $coupon,
      goPhone: $goPhone,
      meliuz: $meliuz,
      plan: $plan,
      missingAmount: $missingAmount,
      missingOrderId: $missingOrderId,
      referral: $referral,
      shippingAddress: $shippingAddress,
    ) {      
      orderId,
      boletoUrl,
    }
  }
`;

export interface IMutationAddShippingToOrder {
  addShippingToOrder: ICheckoutOrder;
}

export const MUTATION_ADD_SHIPPING_TO_ORDER = gql`
  mutation addShippingToOrder(
    $orderId: String!,
    $address: Int!,
    $customer: Customer!,
    $billingAddress: AddressInput!,
    $cardHash: String!,
  ) {
    addShippingToOrder(
      orderId: $orderId,
      address: $address,
      customer: $customer,
      billingAddress: $billingAddress,
      cardHash: $cardHash,
    ) {
      _id
    }
  }
`;

export interface IMutationEditShippingAddress {
  editShippingAddress: boolean;
}

export const MUTATION_EDIT_SHIPPING_ADDRESS = gql`
  mutation editShippingAddress(
    $orderId: String!,
    $address: AddressInput!,
  ) {
    editShippingAddress(
      orderId: $orderId,
      address: $address,
    )
  }
`;

export type MudationPagarMePix = {
  pagarMePix: IPaymentPixResult;
};

export const MUTATION_PAGARME_PIX = gql`
  mutation pagarMePix(
    $items: [CartItem]!,
    $selectedAddress: Int,
    $customer: Customer!,
    $shippingAddress: AddressInput!,
    $coupon: String,
    $meliuz: String,
    $referral: String
    $gtmCookie: String,
  ) {
    pagarMePix(
      items: $items,
      selectedAddress: $selectedAddress,
      customer: $customer,
      coupon: $coupon,
      meliuz: $meliuz,
      referral: $referral,
      shippingAddress: $shippingAddress
      gtmCookie: $gtmCookie,
    ) {
      orderId,
      qrCode,
      shortId,
    }
  }
`;

export interface IMutationPagarMePaymentBoleto {
  pagarMePaymentBoleto: string;
}

export const MUTATION_PAGAR_ME_PAYMENT_BOLETO = gql`
  mutation pagarMePaymentBoleto(
    $items: [CartItem]!,
    $selectedAddress: Int,
    $billingAddress: AddressInput!,
    $cardInfo: CardInfo!,
    $customer: Customer!,
    $coupon: String,
    $installments: Int!,
    $goPhone: Boolean,
    $meliuz: String,
    $cardAmount: Float,
    $boletoAmount: Float,
    $referral: String,
  ) {
    pagarMePaymentBoleto(
      items: $items,
      selectedAddress: $selectedAddress,
      billingAddress: $billingAddress,
      cardInfo: $cardInfo,
      customer: $customer,
      coupon: $coupon,
      installments: $installments,
      goPhone: $goPhone,
      meliuz: $meliuz,
      cardAmount: $cardAmount,
      boletoAmount: $boletoAmount,
      referral: $referral,
    )
  }
`;

export interface IMutationPagarMePaymentTwoCards {
  pagarMePaymentTwoCards: IPaymentTwoCardResult;
}

export const MUTATION_PAGAR_ME_PAYMENT_TWOCARDS = gql`
  mutation pagarMePaymentTwoCards(
    $items: [CartItem]!,
    $selectedAddress: Int,
    $billingAddress: AddressInput!,
    $shippingAddress: AddressInput!,
    $cardInfo: CardInfo!,
    $customer: Customer!,
    $coupon: String,
    $installments: Int!,
    $goPhone: Boolean,
    $meliuz: String,
    $cardAmount: Float,
    $installmentsSecondCard:Int,
    $secondCardInfo: CardInfo,
    $missingAmount: Float,
    $missingOrderId: String,
    $referral: String,
    $gtmCookie: String,
  ) {
    pagarMePaymentTwoCards(
      items: $items,
      selectedAddress: $selectedAddress,
      billingAddress: $billingAddress,
      shippingAddress: $shippingAddress,
      cardInfo: $cardInfo,
      customer: $customer,
      coupon: $coupon,
      installments: $installments,
      goPhone: $goPhone,
      meliuz: $meliuz,
      cardAmount: $cardAmount,
      installmentsSecondCard: $installmentsSecondCard,
      secondCardInfo: $secondCardInfo,
      missingAmount: $missingAmount,
      missingOrderId: $missingOrderId,
      referral: $referral,
      gtmCookie: $gtmCookie,
    ){
      paid,
      orderId,
      secondValue,
      transactionId,
      shortId,
    }
  }
`;

export interface IMutationRefundTransactionLite {
  refundTransactionLite: string;
}

export const MUTATION_REFUND_TRANSACTION_LITE = gql`
  mutation refundTransactionLite(
    $missingOrderId: String,
    $transactionId: String,
  ) {
    refundTransactionLite(
      missingOrderId: $missingOrderId,
      transactionId: $transactionId,
    )
  }
`;

export interface IMutationChangeProposal {
  changeProposal: boolean;
}

export const MUTATION_CHANGE_PROPOSAL = gql`
  mutation changeProposal(
    $proposalId: ID!,
    $contactVendor: Boolean,
  ){
    changeProposal(
      proposalId: $proposalId,
      contactVendor: $contactVendor,
    )
  }
`;

export interface IMutationChangeCardRecurrence {
  changeCardRecurrence: boolean;
}

export const MUTATION_CHANGE_CARD_RECURRENCE = gql`
  mutation changeCardRecurrence(
    $order: ID!,
    $token: String!
    $allOrdersByUser: Boolean,
  ){
    changeCardRecurrence(
      order: $order,
      token: $token
      allOrdersByUser: $allOrdersByUser,
    )
  }
`;

export interface IMutationCreateOrderRecurrence {
  createOrderExternal: boolean;
}

export const MUTATION_CREATE_ORDER_RECURRENCE = gql`
  mutation createOrderExternal(
    $orderId: ID!,
    $tokenCard: String!
    $changeCardAllCharges: Boolean
    $tokenChangeCard: String
  ){
    createOrderExternal(
      orderId: $orderId,
      tokenCard: $tokenCard
      changeCardAllCharges: $changeCardAllCharges
      tokenChangeCard: $tokenChangeCard
    )
  }
`;