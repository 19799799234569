import React from "react";
import { FormControl, FormHelperText, TextField } from "@mui/material";
import InputMask from "react-input-mask";
import replaceMask from "../../../helpers/replaceMask";

interface IProps {
  value: string;
  setValue: (value: string) => void;
  mask: string;
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  name: string;
  isRequired?: boolean;
  label?: string;
  type?: string;
  disabled?: boolean;
  errorMessage?: string;
  touched?: boolean;
  style?: object;
  submitByEnter?: () => void;
  id?: string;
  autoComplete?: string;
  inputIcon?: JSX.Element;

}

const TextFieldMask = ({
  value,
  setValue,
  label,
  mask,
  isRequired,
  type,
  disabled,
  onBlur,
  errorMessage,
  touched,
  style,
  submitByEnter,
  name,
  id,
  onFocus,
  autoComplete='on',
  inputIcon
}: IProps) => {
  return (
    <FormControl
      sx={style}
      error={touched && !!errorMessage}
      variant="standard"
    >
      <InputMask
        id={id}
        mask={mask}
        value={value}
        disabled={disabled || false}
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setValue(replaceMask(e.target.value));
        }}
      >
        {() => (
          <TextField
            id={id}
            name={name}
            label={label}
            type={type || "text"}
            size={'small'}
            required={isRequired || false}
            error={touched && !!errorMessage}
            disabled={disabled || false}
            onKeyDown={e => {
              if (e.key === "Enter" && submitByEnter) submitByEnter();
            }}
            autoComplete={autoComplete}
            InputProps={{
              endAdornment: inputIcon
          }}
          />
        )}
      </InputMask>
      {touched && errorMessage && (
        <FormHelperText>{errorMessage}</FormHelperText>
      )}
    </FormControl>
  );
};

export default TextFieldMask;
