import React from "react";
import { WithApolloClient, withApollo, } from "react-apollo";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ActionCreator, AnyAction, Dispatch } from "redux";
import { ICheckoutAction, SET_DELIVERED_ADDRESS, SET_USER_DATA } from "../../actionReducers/Checkout";
import { IDocumentAction, SET_IS_ADMIN } from "../../actionReducers/Documents";
import Protect from "../../components/Protect";
import { IPublicUser, IUserAddress } from "../../graphql/types/users";
import useLastAnalysis from "../../hooks/useLastAnalysis";
import useUser from "../../hooks/useUser";
import MainLayoutHome from "../../layouts/MainLayoutHome";
import { IAppState } from "../../store";
import DocumentInfos from './DocumentInfos';
import Footer from "./Footer";
import Header from "./Header";
import { Container } from "./styles";
import { Box } from "@mui/material";

interface DocumentsV2Props extends RouteComponentProps<{}> {
  setUserCurrent: ActionCreator<ICheckoutAction>;
  setDeliveredAddress: ActionCreator<ICheckoutAction>;
  setIsAdmin: ActionCreator<IDocumentAction>;
  userCurrent: IPublicUser;
  isAdmin: boolean;
}

function DocumentsV2({ client }: WithApolloClient<DocumentsV2Props>) {
  const { user } = useUser(client);
  const analysis = useLastAnalysis(user.id);

  const handleStart = () => {
    const absoluteUrl = new URL(analysis?.bioLink);
    window.location.href = absoluteUrl.href;
  }
  

  return (
    <Protect>
        <MainLayoutHome>
          <Box display="flex" flexDirection="column" justifyContent="space-between" minHeight="100vh">
            <Header />
            <Container>
              <DocumentInfos onStart={handleStart} />
            </Container>
            <Footer />
          </Box>

        </MainLayoutHome>
    </Protect>
  );
};

export default connect((state: IAppState) => ({
  userCurrent: state.checkout.currentUser,
  isAdmin: state.document.isAdmin
}), (dispatch: Dispatch<AnyAction>) => ({
  setUserCurrent: (userCurrent: IPublicUser) => dispatch(SET_USER_DATA(userCurrent)),
  setDeliveredAddress: (value: IUserAddress) => dispatch(SET_DELIVERED_ADDRESS(value)),
  setIsAdmin: (value: boolean) => dispatch(SET_IS_ADMIN(value))
}))(withRouter(withApollo(DocumentsV2)));
