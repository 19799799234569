import React, { ReactNode } from 'react';
import { Box, Modal } from '@mui/material';
import { Close } from '@mui/icons-material';
interface IProps {
    children: ReactNode;
    open: boolean;
    handleClose: () => void;
    width?: number;
    paddingBase?: number
}

const CustomModal = (
    { children,
        open,
        handleClose,
        width = 410,
        paddingBase = 24
    }: IProps) => {
    const paddingHeader = paddingBase / 2;
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box>
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: {
                        xs: '90%',
                        sm: width
                    },
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    borderRadius: '8px',
                    padding: `0px 0px ${paddingBase}px ${paddingBase}px`,
                }}>
                    <Box sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        padding: `${paddingHeader}px ${paddingHeader}px 0px 0px`,
                        cursor: 'pointer',
                    }}>
                        <Close onClick={handleClose} />
                    </Box>
                    <Box sx={{ margin: `0px ${paddingBase}px 0px 0px` }}>
                        {children}
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
};

export default CustomModal;