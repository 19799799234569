import styled from "styled-components";
interface PropsNavbar {
  isFixedBar: boolean;
}
interface PropsActions {
  loggedState: boolean;
  missingPayment?: boolean;
  isFixedBar: boolean;
  preRelease?: boolean;
}
export const NavContent = styled.nav`
  background: #2D2D38;
  display: flex;
  flex-direction: row;
  width: 100%;
  background: #2D2D38;
  align-items: center;
  justify-content: space-between;
  height: 90px;
  font-family: Chillax;
  position: sticky;
  z-index: 2;
  transition: background-color ease-in 0.3s, opacity ease-in 0.3s;
  top: 0;
  
  .alluLogo {
    margin-left: 162px;
    width: 117px;
    height: 56px;
    @media(max-width: 900px) {
      margin-left: 19px;
      width: 83px;
      height: 40px;
    }
  }
  span {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 162px;
    .login-btn {
      border: 2px solid #F4F4EF;
      background: #2D2D38;
      width: 205px;
      margin-right: 20px;
      font-family: 'Chillax';
      font-style: normal;
      font-weight: 500;
      font-size: 23px;
      line-height: 31px;
      text-align: center;
      color: #F4F4EF;
    }
  }
  button {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 34px 0px 45px;
    gap: 14px;
    height: 42px;
    background: #27AE60;
    border-radius: 21px;
    font-family: 'Chillax';
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
    line-height: 31px;
    color: #2D2D38;
  }
  .login-btn {
    background: transparent;
    color: #F4F4EF;
    border: 3px solid #F4F4EF;
  }

  .cadastro-btn {
    background: #27AE60;
    border-radius: 21px;
    border: none;
    color: #2D2D38;

  }
  .burguerBtn {
    display: none;
  }
  @media(max-width: 900px) {
    display: flex;
    height: 56px;
    width: 100%;
    span {
      display: none;
    }
    .burguerBtn {
      display: block;
      border: none;
      background-color: #2D2D38;
    }
  }
`;
export const Actions = styled.div<PropsActions>`
  display: flex;
  gap:20px;
  align-items: center;
  margin-right: 162px;
  align-items: center;
  @media (max-width: 900px) {
    width: 100%;
    display: flex;
    height: 100%;
    flex-direction: column;
    margin-right: 0px;
    margin-top: 40px;
    gap: 0;
  }
  .link_actions_pedidos {
      color: ${(props: PropsActions) => props.preRelease ? '#F4F4EF;' : '#FFFFFF'};
      width: 200px;
      height: 31px;
      font-family: 'Chillax';
      font-style: normal;
      font-weight: 500;
      font-size: 23px;
      line-height: 31px;
      }
  a {
    cursor: ${(props: PropsActions) => props.missingPayment ? 'not-allowed' : 'pointer'};
    text-decoration: none;
    font-weight: 700;
    &.link_actions {
      color: ${(props: PropsActions) => props.preRelease ? '#535353' : '#FFFFFF'};
      width: 157px;
      height: 31px;
      font-family: 'Chillax';
      font-style: normal;
      font-weight: 500;
      font-size: 23px;
      line-height: 31px;
      &.link_logout {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px 45px;
        gap: 14px;
        height: 42px;
        border-radius: 21px;
        border: 2px solid #F4F4EF;
        @media(max-width) {
          padding: 0px 45px;
          gap: 14px;
          width: 247px;
          height: 42px;
          border: 2px solid #F4F4EF;
          border-radius: 21px;
          color: #F4F4EF;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
        }
      }
      &.btn_produtos {
        color: #535353;
        background-color: #FFFFFF;
      }
      ${(props: PropsNavbar) => props.isFixedBar && `
        color: #FFFFFF;
      `};
    }
    @media(max-width: 900px) {
      width: 247px;
      height: 31px;
      margin-top: 25px;
      font-family: 'Chillax';
      font-style: normal;
      font-weight: 500;
      font-size: 23px;
      line-height: 31px;
      text-align: center;
      color: #F4F4EF;
    }
  }
`;

interface PropsNavbarMobile {
  isOpen: boolean;
}

export const SideBarContainer = styled.article<PropsNavbarMobile>`
    display: flex;
    flex-direction: column;
    width: 100%;
    background: #2D2D38;
    align-items: center;
    position: 'fixed';
    flex-direction: column;

    height: ${(props: PropsNavbarMobile) => props.isOpen ? '100vh' : '100%'};
    z-index: 999;
    left: 0;
    transition: all 0.5s ease-in-out;

    ${(props: PropsNavbarMobile) =>
    props.isOpen
      ? `      
      opacity: 0.99;
      top: 0;
      display: flex;
    `
      : `
      display: none;
      top: -200%;
      height: 0px;
    `}

    .link_actions_mobile {
      width: 247px;
      margin-bottom: 31px;
    }
    .mobileMenu {
      height: 756px;
      width: 375px;
    }
    .burguerBtn {
      display: block;
      border: none;
      background: #2D2D38;
      width: 30px;
      margin-right: 27px;
    }
    section {
      margin-top: 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  
    .login-btn {
    background: transparent;
    color: #F4F4EF;
    border: 3px solid #F4F4EF;
  }

  .cadastro-btn {
    background: #27AE60;
    border-radius: 21px;
    border: none;
    margin-top: 31px;
    color: #2D2D38;

  }

   
    button {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 14px;
      width: 277px;
      height: 42px;
      background: #27AE60;
      border-radius: 21px;
      font-weight: 500;
      font-size: 23px;
      line-height: 31px;
      
    }
`;