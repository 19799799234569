import gql from 'graphql-tag';

import { ICouponProducts, IMGMCoupon, IPublicCoupon } from '../types/coupons';

export const QUERY_CHECK_COUPON = gql`
  query checkCoupon($slug: String!, $billet: Boolean) {
    checkCoupon(slug: $slug, billet: $billet) {
      slug
      amount
      type
      lite
      billet
      flags {
        memberGetMember
        disabled
      }
    }
  }
`;

export interface IQueryCheckCoupon {
  checkCoupon: IPublicCoupon;
}

export const QUERY_MY_REF_COUPON = gql`
  query myRefCoupon {
    myRefCoupon {
      slug
      mgmUses
    }
  }
`;

export interface IQueryMyRefCoupon {
  myRefCoupon: IMGMCoupon;
}

export const QUERY_MY_COUPONS = gql`
  query myCoupons {
    myCoupons {
      slug
      amount
    }
  }
`;

export interface IQueryMyCoupons {
  myCoupons: IPublicCoupon[];
}

export const QUERY_MY_BOOKMARKED_COUPONS = gql`
  query myBookmarkedCoupons {
    myBookmarkedCoupons {
      slug
      amount
      type
    }
  }
`;

export interface IQueryMyBookmarkedCoupons {
  myBookmarkedCoupons: IPublicCoupon[];
}

export const QUERY_COUPON_PRODUCTS = gql`
  query searchCouponProducts($productIDs: [String]) {
    searchCouponProducts(productIDs: $productIDs) {
      _id
      name
    }
  }
`;

export interface IQueryCouponProducts {
  searchCouponProducts: ICouponProducts[] ;
}
