import { FormikBag, FormikProps, withFormik } from 'formik';
import * as React from 'react';

import { GraphQLError } from 'graphql';
import { MutationFn } from 'react-apollo';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { ActionCreator, Dispatch } from 'redux';
import { IUserAction, LOG_USER } from '../../../actionReducers/User';
import {
  FormikFixedForm
} from '../../../components/styled';
import { IMutationResetPassword } from '../../../graphql/mutations/users';
import { AuthForm } from '../styled';
import { ResetPasswordFormSchema } from './ResetPasswordValidation';
import InputText from '../../../components/Inputs/InputText';
import CustomButton from '../../../components/Rebranding/CustomButton';
import arrowIcon from '../../../assets/img/Frame.svg';
import { Stack } from '@mui/material';

export interface IResetPasswordFields {
  newPassword: string;
}

export const initialValues: IResetPasswordFields = {
  newPassword: '',
};

interface IResetPasswordRawFormProps {
  logUser: ActionCreator<IUserAction>;

  email: string;
  userId: string;
  newPasswordToken: string;
  submit: MutationFn<IMutationResetPassword>;
  redirect: (path: string) => void;
}

type Props = IResetPasswordRawFormProps & FormikProps<IResetPasswordFields>;

class ResetPasswordRawForm extends React.Component<Props> {
  public render() {
    const { touched, errors, handleChange, handleBlur, handleSubmit, values } = this.props;

    return (
      <AuthForm>
        <FormikFixedForm>
          <Stack spacing={'40px'} >
            <InputText
              label="Nova Senha"
              type="password"
              name={"newPassword"}
              onBlur={handleBlur('newPassword')}
              setValue={handleChange('newPassword')}
              value={values.newPassword}
              touched={touched.newPassword}
              errorMessage={errors.newPassword}
              submitByEnter={() => handleSubmit()}
            />
            <CustomButton
              variant="contained"
              fullWidth
              size="large"
              color="primary"
              onClick={() => handleSubmit()}
              title={'Redefinir senha'}
              type="submit"
              style={{
                background: '#0099DE',
                color: '#F4F4EF',
                '&:hover': {
                  backgroundColor: '#006b9b',
                }
              }}
              endIcon={<img src={arrowIcon} />}
            />
          </Stack >
        </FormikFixedForm>
      </AuthForm>
    );
  }
}

export const ResetPasswordForm = withFormik<IResetPasswordRawFormProps, IResetPasswordFields>({
  mapPropsToValues() {
    return initialValues;
  },
  validationSchema: ResetPasswordFormSchema,
  async handleSubmit(
    values: IResetPasswordFields,
    { props }: FormikBag<IResetPasswordRawFormProps, IResetPasswordFields>,
  ) {
    const { submit, logUser, userId, newPasswordToken, redirect } = props;

    try {
      const data = await submit({ variables: { ...values, newPasswordToken, userId } });
      if (data) {
        const { resetPassword } = data.data;
        logUser(resetPassword);
        redirect('/');
        toast.success('Senha redefinida com sucesso!');
      }

    } catch (e) {
      e.graphQLErrors.map((error: GraphQLError) => toast.error(error.message));
    }
  },
})(ResetPasswordRawForm);

export default connect(null, (dispatch: Dispatch<IUserAction>) => ({
  logUser: (token: string) => dispatch(LOG_USER(token)),
}))(ResetPasswordForm);
