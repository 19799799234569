import { Action } from 'redux';
import { ICheckoutOrder, ICheckoutOrderProduct } from '../graphql/types/checkoutOrders';
import { IUserAddress } from '../graphql/types/users';
import { CheckoutRecurrenceActionTypes } from "./actionTypes";
import { IDynamicKeyCheckout, PaymentType } from './Checkout';

export interface ICheckoutRecurrenceAction extends Action<CheckoutRecurrenceActionTypes> {
    [extraProp: string]: any;
}

export interface ICreditCardRecurrence {
    number: string;
    holderName: string;
    expirationDate: string;
    cvv: string;
    errors?: boolean;
}

export interface IPayment {
    type: PaymentType;
    creditCard?: ICreditCardRecurrence;
    amount?: number;
    linkPayment?: string;
}

export interface ICheckoutRecurrenceState {
    order: ICheckoutOrder;
    orderProduct: ICheckoutOrderProduct[];
    singleCard: boolean;
    paymentEntry?: IPayment;
    paymentRecurrence?: IPayment;
};

const initialState = {
    paymentEntry: { type: PaymentType.CREDIT_CARD },
    paymentRecurrence: { type: PaymentType.CREDIT_CARD },
    singleCard: true,
} as ICheckoutRecurrenceState;

export const checkoutRecurrenceReducer = (
    state: ICheckoutRecurrenceState = initialState,
    action: ICheckoutRecurrenceAction
): ICheckoutRecurrenceState => {
    switch (action.type) {
        case CheckoutRecurrenceActionTypes.SET_ORDER: {
            const { order } = action;
            return {
                ...state,
                order,
                orderProduct: order.checkoutOrderProducts,
                singleCard: order.subscriptionPayment.entryFeeValue ? true : false
            };
        }
        case CheckoutRecurrenceActionTypes.SET_TYPE_PAYMENT_ENTRY: {
            const { typeEntry } = action;
            let amountEntry: number;
            if (typeEntry === PaymentType.BOLETO) {
                amountEntry = state.order.subscriptionPayment.entryFeeValue || 0;
            } else {
                amountEntry = state.singleCard
                    ? state.order.subscriptionPayment.entryFeeValue + state.order.subscriptionPayment.monthValue
                    : state.order.subscriptionPayment.entryFeeValue;
            }
            return {
                ...state,
                paymentEntry: {
                    type: typeEntry,
                    amount: amountEntry,
                }
            };
        }
        case CheckoutRecurrenceActionTypes.SET_TYPE_SINGLE_CARD: {
            const { singleCard } = action;
            return {
                ...state,
                singleCard,
                paymentEntry: {
                    ...state.paymentEntry,
                    type: PaymentType.CREDIT_CARD,
                    amount: singleCard
                        ? state.order.subscriptionPayment.entryFeeValue + state.order.subscriptionPayment.monthValue
                        : state.order.subscriptionPayment.entryFeeValue
                }
            };
        }
        case CheckoutRecurrenceActionTypes.SET_CARD_ENTRY: {
            const { data } = action;
            return { ...state, paymentEntry: { ...state.paymentEntry, type: PaymentType.CREDIT_CARD, creditCard: { ...state.paymentEntry.creditCard, ...data } } };
        }
        case CheckoutRecurrenceActionTypes.SET_CARD_MONTHLY: {
            const { data } = action;
            return { ...state, paymentRecurrence: { ...state.paymentRecurrence, type: PaymentType.CREDIT_CARD, creditCard: { ...state.paymentRecurrence.creditCard, ...data } } };
        }
        case CheckoutRecurrenceActionTypes.SET_AMOUNT_ENTRY: {
            const { amount } = action;
            return { ...state, paymentEntry: { ...state.paymentEntry, amount } };
        }
        case CheckoutRecurrenceActionTypes.SET_AMOUNT_MONTHLY: {
            const { amount } = action;
            return { ...state, paymentRecurrence: { ...state.paymentRecurrence, amount } };
        }
        case CheckoutRecurrenceActionTypes.SET_ADDRESS_ORDER: {
            const { address } = action;
            return { ...state, order: { ...state.order, deliveryAddress: address } };
        }
        case CheckoutRecurrenceActionTypes.SET_LINK_BOLETO: {
            const { boleto } = action;
            return { ...state, paymentEntry: { ...state.paymentEntry, linkPayment: boleto } };
        }
        case CheckoutRecurrenceActionTypes.CLEAN_CHECKOUT_RECURRENCE: {
            const nextState: ICheckoutRecurrenceState = initialState;
            return nextState;
        }
        default:
            return state;
    }
};

/**
 * Actions
 */

export const SET_TYPE_PAYMENT_ENTRY = (typeEntry: PaymentType): ICheckoutRecurrenceAction => ({
    type: CheckoutRecurrenceActionTypes.SET_TYPE_PAYMENT_ENTRY,
    typeEntry
});

export const SET_TYPE_SINGLE_CARD = (singleCard: boolean): ICheckoutRecurrenceAction => ({
    type: CheckoutRecurrenceActionTypes.SET_TYPE_SINGLE_CARD,
    singleCard
});

export const SET_CARD_ENTRY = (data: IDynamicKeyCheckout): ICheckoutRecurrenceAction => ({
    type: CheckoutRecurrenceActionTypes.SET_CARD_ENTRY,
    data
});

export const SET_CARD_MONTHLY = (data: IDynamicKeyCheckout): ICheckoutRecurrenceAction => ({
    type: CheckoutRecurrenceActionTypes.SET_CARD_MONTHLY,
    data
});

export const SET_AMOUNT_ENTRY = (amount: Number): ICheckoutRecurrenceAction => ({
    type: CheckoutRecurrenceActionTypes.SET_AMOUNT_ENTRY,
    amount
});

export const SET_AMOUNT_MONTHLY = (amount: Number): ICheckoutRecurrenceAction => ({
    type: CheckoutRecurrenceActionTypes.SET_AMOUNT_MONTHLY,
    amount
});

export const SET_ORDER = (order: ICheckoutOrder): ICheckoutRecurrenceAction => ({
    type: CheckoutRecurrenceActionTypes.SET_ORDER,
    order
});

export const SET_ADDRESS_ORDER = (address: IUserAddress): ICheckoutRecurrenceAction => ({
    type: CheckoutRecurrenceActionTypes.SET_ADDRESS_ORDER,
    address
});


export const SET_LINK_BOLETO = (boleto: string): ICheckoutRecurrenceAction => ({
    type: CheckoutRecurrenceActionTypes.SET_LINK_BOLETO,
    boleto
});

export const CLEAN_CHECKOUT_RECURRENCE = (): ICheckoutRecurrenceAction => ({
    type: CheckoutRecurrenceActionTypes.CLEAN_CHECKOUT_RECURRENCE,
});