import { Field, FieldProps, FormikBag, FormikProps, withFormik } from 'formik';
import * as React from 'react';
import { MutationFn } from 'react-apollo';
import { Suggest } from 'react-geosuggest';

import { AutocompleteBlocker, FormikFixedForm } from '../../../components/styled';
import { IMutationAddAddress, IMutationEditAddress } from '../../../graphql/mutations/users';
import { getGMapsValuesFromSuggestion, gmapsInputValidator } from '../../../helpers/gmapsAddressForm';
import { AddressGeosuggestInput, DeliveryButton, InputWrapper } from './styled';

interface IValues {
  street: string;
  code: string;
  complement: string;
  city: string;
  state: string;
  neighborhood: string;
  number: string;
  gmapsInput?: string;
  lat?: number;
  lng?: number;
  placeId?: string;
}

const initialValues: IValues = {
  street: '',
  code: '',
  complement: '',
  city: '',
  neighborhood: '',
  state: '',
  number: '',
  lat: 0,
  lng: 0,
  gmapsInput: '',
  placeId: '',
};

interface IProps {
  submit?: MutationFn<IMutationAddAddress>;
  edit?: MutationFn<IMutationEditAddress>;
  orderId?: string;

  refetch: (...args: any[]) => any;
}

type Props = IProps & FormikProps<IValues>;

class AddAddressForm extends React.Component<Props> {
  public isSuggestionEmpty = (suggest: Suggest) => {
    if (!suggest) {
      return true;
    } else if (!suggest.label || !suggest.placeId) {
      return true;
    }

    return false;
  }

  public handleSuggestSelect = (suggest: Suggest) => {
    const { setFieldTouched, setValues, values } = this.props;

    setFieldTouched('gmapsInput', true);

    if (!this.isSuggestionEmpty(suggest)) {
      const addressValues = getGMapsValuesFromSuggestion(suggest);

      setValues({
        ...values,
        ...addressValues,
        gmapsInput: suggest.label,
        lat: +suggest.location.lat,
        lng: +suggest.location.lng,
        placeId: suggest.gmaps.place_id,
      });
    }
  }

  public handleMapsInputFocus = () => {
    const {
      setFieldTouched,
      setValues,
      values: { complement },
    } = this.props;

    setValues({
      ...initialValues,
      complement,
    });
    setFieldTouched('gmapsInput', false);
  }

  public handleGmapsInputChange = (value: string) => {
    const { setFieldValue, setFieldTouched } = this.props;

    setFieldValue('gmapsInput', value);

    if (value === '') {
      setFieldTouched('gmapsInput', false);
    }
  }

  public handleSuggestNoResults = () => {
    const {
      setFieldError,
      setFieldTouched,
      values,
    } = this.props;

    if (values.gmapsInput !== '') {
      setFieldTouched('gmapsInput', true);
      setFieldError('gmapsInput', 'Endereço não encontrado.');
    }
  }

  public render() {
    const { errors, touched } = this.props;

    return (
      <FormikFixedForm>
        <Field
          name='gmapsInput'
          render={({ field }: FieldProps<IValues>) => (
            <InputWrapper>
              <label>Endereço</label>
              <AutocompleteBlocker name='gmapsInput' />

              <AddressGeosuggestInput
                {...field}
                type='text'
                placeholder='Avenida Brasil, 100'
                onSuggestSelect={this.handleSuggestSelect}
                country={'br'}
                types={['address']}
                onFocus={this.handleMapsInputFocus}
                onSuggestNoResults={this.handleSuggestNoResults}
                onChange={this.handleGmapsInputChange}
                children={<input />}
              />
              {
                errors.gmapsInput && touched.gmapsInput
                  ? <div>{ errors.gmapsInput }</div>
                  : null
              }
            </InputWrapper>
          )}
        />

        <Field
          name='complement'
          render={({ field }: FieldProps<IValues>) => (
            <InputWrapper>
              <label>Complemento</label>
              <input
                {...field}
                type='text'
                placeholder='Complemento'
                autoComplete='address-complement'
              />
            </InputWrapper>
          )}
        />

        <DeliveryButton type='submit'>
          Salvar novo endereço
        </DeliveryButton>
      </FormikFixedForm>
    );
  }
}

export default withFormik<IProps, IValues>({
  mapPropsToValues() { return initialValues; },
  async handleSubmit(
    values: IValues,
    { props, resetForm }: FormikBag<IProps, IValues>,
  ) {
    const { submit, refetch, edit, orderId } = props;
    const code = values.code.replace(/\D+/g, '');

    if (edit) {
      await edit({
        variables: {
          address: {
            ...values,
            gmapsInput: undefined,
            code,
            identifier: `${values.street} - ${values.number}`,
          },
          orderId,
        },
      });
    } else if (submit) {
      await submit({ variables: { ...values, code, identifier: `${values.street} - ${values.number}` } });
    }

    await refetch();
    resetForm();
  },
  validate(values: IValues) {
    const errors: any = {};

    const gmapsInputError = gmapsInputValidator(values);
    if (gmapsInputError) {
      errors.gmapsInput = gmapsInputError;
    }

    return errors;
  },
})(AddAddressForm);
